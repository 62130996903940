import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { IPharmacy, NewPharmacy } from '../pharmacy.model';

/**
 * A partial Type with required key is used as form input.
 */
type PartialWithRequiredKeyOf<T extends { id: unknown }> = Partial<Omit<T, 'id'>> & { id: T['id'] };

/**
 * Type for createFormGroup and resetForm argument.
 * It accepts IPharmacy for edit and NewPharmacyFormGroupInput for create.
 */
type PharmacyFormGroupInput = IPharmacy | PartialWithRequiredKeyOf<NewPharmacy>;

type PharmacyFormDefaults = Pick<NewPharmacy, 'id' | 'categories'>;

type PharmacyFormGroupContent = {
  id: FormControl<IPharmacy['id'] | NewPharmacy['id']>;
  legalName: FormControl<IPharmacy['legalName']>;
  commercialName: FormControl<IPharmacy['commercialName']>;
  pharmacyOrderId: FormControl<IPharmacy['pharmacyOrderId']>;
  address: FormControl<IPharmacy['address']>;
  phoneNumber: FormControl<IPharmacy['phoneNumber']>;
  responsibleEmail: FormControl<IPharmacy['responsibleEmail']>;
  ownerEmail: FormControl<IPharmacy['ownerEmail']>;
  // processingTime: FormControl<IPharmacy['processingTime']>;
  rib: FormControl<IPharmacy['rib']>;
  siret: FormControl<IPharmacy['siret']>;
  logo: FormControl<IPharmacy['logo']>;

  // categories: FormControl<IPharmacy['categories']>;
};

export type PharmacyFormGroup = FormGroup<PharmacyFormGroupContent>;

@Injectable({ providedIn: 'root' })
export class PharmacyFormService {
  createPharmacyFormGroup(pharmacy: PharmacyFormGroupInput = { id: null }): PharmacyFormGroup {
    const pharmacyRawValue = {
      ...this.getFormDefaults(),
      ...pharmacy,
    };
    return new FormGroup<PharmacyFormGroupContent>({
      id: new FormControl(
        { value: pharmacyRawValue.id, disabled: true },
        {
          nonNullable: true,
          validators: [Validators.required],
        },
      ),
      legalName: new FormControl(pharmacyRawValue.legalName),
      commercialName: new FormControl(pharmacyRawValue.commercialName),
      pharmacyOrderId: new FormControl(pharmacyRawValue.pharmacyOrderId),
      address: new FormControl(pharmacyRawValue.address),
      phoneNumber: new FormControl(pharmacyRawValue.phoneNumber),
      responsibleEmail: new FormControl(pharmacyRawValue.responsibleEmail),
      ownerEmail: new FormControl(pharmacyRawValue.ownerEmail),
      // processingTime: new FormControl(pharmacyRawValue.processingTime),
      rib: new FormControl(pharmacyRawValue.rib),
      siret: new FormControl(pharmacyRawValue.siret),
      // categories: new FormControl(pharmacyRawValue.categories ?? []),
      logo: new FormControl(pharmacyRawValue.logo),
    });
  }

  getPharmacy(form: PharmacyFormGroup): IPharmacy | NewPharmacy {
    return form.getRawValue() as IPharmacy | NewPharmacy;
  }

  resetForm(form: PharmacyFormGroup, pharmacy: PharmacyFormGroupInput): void {
    const pharmacyRawValue = { ...this.getFormDefaults(), ...pharmacy };
    form.reset(
      {
        ...pharmacyRawValue,
        id: { value: pharmacyRawValue.id, disabled: true },
      } as any /* cast to workaround https://github.com/angular/angular/issues/46458 */,
    );
  }

  private getFormDefaults(): PharmacyFormDefaults {
    return {
      id: null,
      categories: [],
    };
  }
}
