<div class="terms-of-use-delivery">
  <div class="conditions-generales">
    <h1 class="title" jhiTranslate="termsOfUseDelivery.title"></h1>
  </div>
  <div class="content">
    <!-- Introduction Section -->
    <div class="section">
      <p class="section-text" jhiTranslate="termsOfUseDelivery.introduction.content"></p>
    </div>

    <!-- Section 1: Acceptation des Conditions -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="termsOfUseDelivery.acceptanceOfTerms.title"></h2>
      <p jhiTranslate="termsOfUseDelivery.acceptanceOfTerms.content"></p>
    </div>

    <!-- Section 2: Accès et Utilisation -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="termsOfUseDelivery.accessAndUse.title"></h2>

      <!-- 2.1. Éligibilité -->
      <h2 class="section-title sousTitre" jhiTranslate="termsOfUseDelivery.accessAndUse.eligibility.title"></h2>
      <p jhiTranslate="termsOfUseDelivery.accessAndUse.eligibility.content"></p>

      <!-- 2.2. Compte Livreur -->
      <h2 class="section-title sousTitre" jhiTranslate="termsOfUseDelivery.accessAndUse.driverAccount.title"></h2>
      <p jhiTranslate="termsOfUseDelivery.accessAndUse.driverAccount.content"></p>

      <!-- 2.3. Utilisation de l'Application -->
      <h2 class="section-title sousTitre" jhiTranslate="termsOfUseDelivery.accessAndUse.appUsage.title"></h2>
      <p jhiTranslate="termsOfUseDelivery.accessAndUse.appUsage.content"></p>
    </div>

    <!-- Section 3: Obligations du Livreur -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="termsOfUseDelivery.driverObligations.title"></h2>

      <!-- 3.1. Normes de Sécurité -->
      <h2 class="section-title sousTitre" jhiTranslate="termsOfUseDelivery.driverObligations.safetyStandards.title"></h2>
      <p jhiTranslate="termsOfUseDelivery.driverObligations.safetyStandards.content"></p>

      <!-- 3.2. Confidentialité des Données Médicales -->
      <h2 class="section-title sousTitre" jhiTranslate="termsOfUseDelivery.driverObligations.dataConfidentiality.title"></h2>
      <p jhiTranslate="termsOfUseDelivery.driverObligations.dataConfidentiality.content"></p>

      <!-- 3.3. Livraisons à Temps -->
      <h2 class="section-title sousTitre" jhiTranslate="termsOfUseDelivery.driverObligations.onTimeDelivery.title"></h2>
      <p jhiTranslate="termsOfUseDelivery.driverObligations.onTimeDelivery.content"></p>

      <!-- 3.4. Communication -->
      <h2 class="section-title sousTitre" jhiTranslate="termsOfUseDelivery.driverObligations.communication.title"></h2>
      <p jhiTranslate="termsOfUseDelivery.driverObligations.communication.content"></p>
    </div>

    <!-- Section 4: Conformité Légale -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="termsOfUseDelivery.legalCompliance.title"></h2>

      <!-- 4.1. Réglementations Locales -->
      <h2 class="section-title sousTitre" jhiTranslate="termsOfUseDelivery.legalCompliance.localRegulations.title"></h2>
      <p jhiTranslate="termsOfUseDelivery.legalCompliance.localRegulations.content"></p>

      <!-- 4.2. Autorisation de Transport de Médicaments -->
      <h2 class="section-title sousTitre" jhiTranslate="termsOfUseDelivery.legalCompliance.medicationTransportAuthorization.title"></h2>
      <p jhiTranslate="termsOfUseDelivery.legalCompliance.medicationTransportAuthorization.content"></p>
    </div>

    <!-- Section 5: Responsabilité -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="termsOfUseDelivery.responsibility.title"></h2>

      <!-- 5.1. Dommages et Pertes -->
      <h2 class="section-title sousTitre" jhiTranslate="termsOfUseDelivery.responsibility.damagesAndLosses.title"></h2>
      <p jhiTranslate="termsOfUseDelivery.responsibility.damagesAndLosses.content"></p>

      <!-- 5.2. Suspension ou Résiliation -->
      <h2 class="section-title sousTitre" jhiTranslate="termsOfUseDelivery.responsibility.suspensionOrTermination.title"></h2>
      <p jhiTranslate="termsOfUseDelivery.responsibility.suspensionOrTermination.content"></p>
    </div>

    <!-- Section 6: Paiement -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="termsOfUseDelivery.payment.title"></h2>

      <!-- 6.1. Rémunération -->
      <h2 class="section-title sousTitre" jhiTranslate="termsOfUseDelivery.payment.compensation.title"></h2>
      <p jhiTranslate="termsOfUseDelivery.payment.compensation.content"></p>

      <!-- 6.2. Retenue de Paiement -->
      <h2 class="section-title sousTitre" jhiTranslate="termsOfUseDelivery.payment.paymentWithholding.title"></h2>
      <p jhiTranslate="termsOfUseDelivery.payment.paymentWithholding.content"></p>
    </div>

    <!-- Section 7: Protection des Données -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="termsOfUseDelivery.dataProtection.title"></h2>

      <!-- 7.1. Collecte et Utilisation des Données -->
      <h2 class="section-title sousTitre" jhiTranslate="termsOfUseDelivery.dataProtection.dataCollectionAndUsage.title"></h2>
      <p jhiTranslate="termsOfUseDelivery.dataProtection.dataCollectionAndUsage.content"></p>
    </div>

    <!-- Section 8: Modifications des Conditions -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="termsOfUseDelivery.termsModification.title"></h2>
      <p jhiTranslate="termsOfUseDelivery.termsModification.content"></p>
    </div>

    <!-- Section 9: Contact -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="termsOfUseDelivery.contact.title"></h2>
      <p jhiTranslate="termsOfUseDelivery.contact.content"></p>
    </div>
  </div>
</div>
<div class="footer" style="overflow: hidden">
  <div class="row d-flex align-items-center p-3">
    <div class="footer-bottom">
      <span
        >© 2024 <span class="pharma">PharmaGo</span> .
        <span jhiTranslate="global.menu.footer.rights">Tous les droits réservés Développé par</span>
        <span class="underline">KCA Dev Services.</span>
      </span>
    </div>
  </div>
</div>
