<div class="mentions-legales">
  <div class="section">
    <div class="conditions-generales">
      <h1 class="title">Mentions Légales</h1>
    </div>
    <div class="content">
      <div class="section">
        <h2 class="section-title">1- Identité de la société (Pharmacie)</h2>
        <p class="section-text">
          Le site Pharma-go.fr est géré par la société PHARMAGO SAS, société à responsabilité limitée de droit français au capital social de
          10 000€, dont le siège social est situé à 101, rue de Sèvres 75006 Paris, France, immatriculée au registre du commerce et des
          sociétés de Paris sous la numéro 930 703 814.
        </p>
        <p class="section-text">Représentant légal : Dr. Montassar SOUIR <br /></p>
        <p class="section-text">
          Numéro de TVA intra-communautaire : FR 16 930703814 <br />
          Numéro Siret : 93070381400010
        </p>
      </div>

      <div class="section">
        <h2 class="section-title">2- Coordonnées</h2>
        <p class="section-text">
          <strong>PHARMAGO SAS - Pharma-go.fr</strong><br />
          101 Rue De Sèvres 75006 Paris<br />
          Tel : 06 59 28 36 56 (de 9h00 à 12h30 et de 14h00 à 17h30)<br />
          E-mail : <a href="mailto:contact@pharmashopi.com">contact&#64;pharma-go.fr</a>
        </p>
        <p>
          PHARMAGO SAS a reçu l'autorisation de l'ARS île de France (Agence régionale de santé île de France) pour la mise en relation des
          pharmacies partenaires pour dispenser des médicaments hors prescription sur internet par l'intermédiaire de son site internet
          www.pharma-go.fr (voir le document)
        </p>
      </div>

      <div class="section">
        <h2 class="section-title">3- Pharmacien titulaire de l'officine</h2>
        <p class="section-text">
          Dr. Montassar SOUIR, Docteur de pharmacie, diplômée à la faculté de pharmacie de Paris Cité<br />
          Titulaire du Doctorat en Pharmacie, diplômée à la faculté de pharmacie de l’Université Paris Cité Numéro RPPS : 10102134722
          (inscription au Répertoire Partagé des Professionnels de Santé) Numéro d'inscription à l'Ordre National des Pharmaciens : 171006
          Section A
        </p>
      </div>

      <div class="section">
        <h2 class="section-title">4- Autorité de surveillance</h2>
        <p class="section-text">
          Agence Nationale de Sécurité du Médicament et des produits de santé (ANSM)<br />
          143-147, Boulevard Anatole France, 93285 Saint-Denis Cedex<br />
          Tél : 01 55 87 30 00<br />
          <a href="https://www.ansm.sante.fr">Déclaration de pharmacovigilance</a> <br />
          Agence Régionale de Santé île de France :
          <a href="https://www.iledefrance.ars.sante.fr/">https://www.iledefrance.ars.sante.fr/</a> <br />
          Agence Nationale de Sécurité du Médicament et des produits de santé (ANSM) :
          <a href="http://ansm.sante.fr">http://ansm.sante.fr</a> <br />
          L'Ordre national des Pharmaciens : <a href="http://www.ordre.pharmacien.fr">http://www.ordre.pharmacien.fr</a>
          <br />
          Ministère des Affaires sociales et de la Santé : <a href="http://www.sante.gouv.fr">http://www.sante.gouv.fr</a> <br />
        </p>
      </div>

      <div class="section">
        <h2 class="section-title">5- Hébergeur agréé santé</h2>
        <p class="section-text">
          OVH healthcare<br />
          2 rue Kellermann, 59100 Roubaix, France
        </p>
      </div>

      <div class="section">
        <h2 class="section-title">6- Réclamation - Service clients</h2>
        <p class="section-text">
          En cas de réclamation, nous vous invitons à contacter notre service client :<br />
          Tel : 06 59 28 36 56 (de 9h00 à 12h30 et de 14h00 à 17h30)<br />
          E-mail : <a href="mailto:contact@pharma-go.fr">contact&#64;pharma-go.fr</a>
        </p>
      </div>
      <div class="section">
        <h2 class="section-title">7- Pharmacovigilance</h2>
        <p class="section-text">
          Lorsque vous constatez un effet indésirable que vous suspectez être lié :<br />
          • à la prise d'un médicament,<br />
          • à l'utilisation d'un dispositif médical (pansement, produit de comblement des rides, prothèse...)<br />
          • ou d'un autre produit de santé ou cosmétique.<br />
          Patients, associations de patients, contribuez à améliorer la sécurité des produits de santé que vous utilisez.<br />
          Ainsi, si vous constatez des effets secondaires inhabituels, si vous constatez une couleur, une forme ou un état particulier ou
          bizarre sur les traitements que nous vous avons délivré, vous pouvez nous contacter directement ou faire une déclaration une
          déclaration sur le site de l'agence Nationale de sécurité du médicament et des produits de santé.<br />
        </p>
      </div>
      <div class="section">
        <h2 class="section-title">8- Droits de propriété intellectuelle</h2>
        <p class="section-text">
          La Société détient le nom de domaine www.pharma-go.fr, une œuvre de l’esprit protégée par les lois de la propriété intellectuelle.
        </p>
        <p class="section-text">
          Le Site, dans sa globalité ainsi que chacun de ses éléments (textes, arborescences, logiciels, animations, photographies,
          illustrations, schémas, logos), est la propriété exclusive de la Société, seule détentrice des droits de propriété intellectuelle
          y afférents.
        </p>
        <p class="section-text">
          L'utilisation de tout ou partie du Site, incluant le téléchargement, la reproduction, la transmission, la représentation ou la
          diffusion, pour des usages autres que personnels et privés, dans un cadre non commercial, est strictement interdite.
        </p>
        <p class="section-text">
          Toute violation expose l’utilisateur aux sanctions prévues par le Code de la Propriété Intellectuelle et le Code Pénal pour
          contrefaçon, ainsi que par le Code Civil en matière de responsabilité civile. Les marques, noms de domaine, dessins et modèles
          présents sur le Site sont la propriété exclusive de la Société.
        </p>
        <p class="section-text">
          La création de liens hypertextes vers le Site est autorisée uniquement avec l'accord écrit et préalable de la Société, qui peut
          révoquer cette autorisation à tout moment. La Société n’exerce aucun contrôle sur les sites tiers contenant des liens vers le Site
          et décline toute responsabilité quant à leur contenu ou accès (notamment éditorial). La Société se réserve le droit de modifier le
          contenu du Site à tout moment et sans préavis.
        </p>
      </div>

      <div class="section">
        <h2 class="section-title">9- Données personnelles</h2>
        <p class="section-text">
          La Société a mis en place une politique de protection des données personnelles pour son Site, conformément à la loi "Informatique
          et Libertés" du 6 janvier 1978, modifiée par celle du 6 août 2004. Vous pouvez consulter cette politique dans la section
          "Politique de confidentialité". La consultation et la navigation sur le Site ne nécessitent pas la communication de données
          personnelles. La Société est responsable du traitement de vos données personnelles et en demeure l’unique destinataire. Elle
          s’engage à assurer leur confidentialité. En vertu de la loi "Informatique et Libertés" et vous disposez d’un droit d’accès, de
          modification et de suppression de vos données personnelles. Vous pouvez exercer vos droits en adressant un courrier à : <br />
          www.pharma-go.fr - Service Clientèle, 101 rue de Sèvres 75279 Paris<br />
          Téléphone : 06 59 28 36 56<br />
          E-mail : <a href="mailto:contact@pharmashopi.com">support&#64;pharma-go.fr</a>
        </p>
      </div>

      <div class="section">
        <h2 class="section-title">10- Garanties et responsabilités</h2>
        <p class="section-text">
          La Société s'efforce de garantir l'exactitude et la mise à jour des informations accessibles via le Site. Cependant, elle ne peut
          en aucun cas assurer que ces informations soient toujours complètes, précises ou à jour, et décline toute garantie, expresse ou
          implicite, sur l'ensemble du contenu du Site. Elle décline également toute responsabilité concernant l'accès et le contenu des
          sites liés au Site.
        </p>
        <p>
          La Société ne saurait être tenue responsable de tout dommage, direct ou indirect, quelle qu'en soit la cause, résultant de
          l’utilisation ou de la consultation du Site. Elle n'assume aucune responsabilité en cas d’interruption ou d'inaccessibilité du
          Site, de bugs, ou de dommages liés à des actes frauduleux de tiers (comme des intrusions).
        </p>
        <p>
          Dans un souci d'informer pleinement ses clients dans le domaine de la parapharmacie, la Société propose des liens vers des sites
          internet tiers ("Sites Cibles"), uniquement officiels, comme ceux du ministère de la santé, de l'agence régionale de santé, et du
          conseil de l'ordre des pharmaciens. La Société n'exerce aucun contrôle sur ces Sites Cibles et ne peut être tenue responsable de
          leur contenu, ni de leurs dysfonctionnements, ou d'éventuels dommages au système d’information de l'utilisateur liés à leur usage.
        </p>
        <p>
          La Société met en place des moyens pour assurer la sécurité des fichiers constitués à partir des données personnelles collectées
          sur le Site. Elle attire néanmoins l’attention sur les risques liés à l’utilisation d’Internet, notamment quant à la
          confidentialité des données qui y transitent.
        </p>
        <p>
          Toutes les données hébergées, qu’elles soient au sein de la pharmacie ou du dossier client, sont protégées et confidentielles.
          L'hébergement du site est sécurisé et conforme aux bonnes pratiques de dispensation électronique, étant assuré par un hébergeur
          agréé par le ministère de la santé. La dispensation des médicaments respecte les bonnes pratiques fixées par l’arrêté du 20 juin
          2013 pour la vente en ligne.
        </p>
        <p>
          Pour toute question, remarque, réclamation ou suggestion, vous pouvez contacter le Docteur en pharmacie responsable au 06 59 28 36
          56 (Dr Montassar SOUIR).
        </p>
        <p>
          Dans le cadre de la dispensation électronique, le pharmacien ne peut pas directement alimenter votre Dossier Pharmaceutique (DP).
          Cependant, si vous le souhaitez, votre DP pourra être mis à jour dans une officine physique dans les quatre mois suivant votre
          commande, sous réserve de votre demande.
        </p>
        <p></p>
      </div>
      <div class="section">
        <h2 class="section-title">11- Modification des mentions légales</h2>
        <p class="section-text">
          La Société vous informe que les Mentions Légales peuvent être modifiées à tout moment. <br />
          Ces modifications sont publiées par leur mise en ligne et sont réputées acceptées sans réserve lorsque vous accédez au Site
          postérieurement à leur mise en ligne. Nous vous recommandons de consulter régulièrement la présente page.
        </p>
      </div>
      <div class="section">
        <h2 class="section-title">12- Litiges</h2>
        <p class="section-text">
          Les Mentions Légales sont établies en conformité avec le droit français et notamment les dispositions de la loi du 21 juin 2004
          pour la confiance dans l’économie numérique ainsi que la loi du 6 janvier 1978 modifiée par la loi du 6 août 2004 Informatique et
          Libertés. <br />
          Les juridictions de Paris (France) sont territorialement compétentes pour connaître de tout litige afférent au Site
        </p>
      </div>
    </div>
  </div>
</div>
