import { Subject, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { Component, OnInit, TemplateRef, ViewChild, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import SharedModule from '../../shared/shared.module';
import { HomeServiceService } from '../service/home-service.service';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CartService } from '../../cart/cart.service';
import Swal from 'sweetalert2';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Location } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@Component({
  selector: 'jhi-pharmacie-products',
  standalone: true,
  imports: [CommonModule, SharedModule, RouterModule, ReactiveFormsModule, NgxSkeletonLoaderModule, InfiniteScrollModule],
  templateUrl: './pharmacie-products.component.html',
  styleUrl: './pharmacie-products.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PharmacieProductsComponent implements OnInit {
  @ViewChild('modalProduct') modalProduct: any;
  @ViewChild('modalOrdonnance') modalOrdonnance: any;
  @ViewChild('modalProductExist') modalProductExist: any;

  PurchasesForm: FormGroup = this.createPurchasesForm();
  formData = new FormData();
  mutualCard_File: any;
  vitalCard_File: any;
  prescription_File: any;
  openedProduct: any;
  pharmacyId!: number;
  subCategoryId!: number;
  currentPage: number = 1;
  itemsPerPage = 9;
  products: any[] = [];
  totalPages: number = 1;
  isLoading: boolean = false;
  likeImagePath: string = '../../content/images/icons/Icon feather-heart (3).svg';
  pharmacyName: any;
  catName: any;
  subCatName: any;
  value: number = 1;
  isExpanded = false;
  isExpandedDescription = false;
  toggleLoading = () => (this.isLoading = !this.isLoading);

  cartItems: any[] = [];
  contentLoaded = false;
  activeTab: string = 'composition';
  searchInputSubject = new Subject<string>();
  imageUrl: string = 'https://imagedelivery.net/z1UHS7Z96nNnNVE8sIpBxg/';
  pharmacyCartItems: any;
  pharmacyCartItemsName: any;
  ordonnanceImages: Array<string | ArrayBuffer> = [];
  prescriptionFiles: File[] = [];
  vitalCardFiles: File[] = [];
  mutualCardFiles: File[] = [];
  maxFiles = 3;
  allowedFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
  maxFileSize = 5 * 1024 * 1024;
  fileUrls: string[] = [];
  documents: any;
  prescriptionUrls: string[] = [];
  vitalCardUrl: string | null = null;
  mutualCardUrl: string | null = null;
  selectedAddressId: number | null = null;
  consentGiven = false;

  constructor(
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private pharmacieService: HomeServiceService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private cartService: CartService,
    private location: Location,
    private router: Router,
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    const selectedAddressId = localStorage.getItem('selectedAddressId');
    if (selectedAddressId) {
      this.selectedAddressId = parseInt(selectedAddressId, 10);
    }
    setTimeout(() => {
      this.contentLoaded = true;
    }, 2000);
    this.getPharmacyId();
    this.pharmacyName = this.pharmacieService.getCommercialName();
    this.catName = this.pharmacieService.getCatName();
    this.subCatName = this.pharmacieService.getSubCatName();
    this.searchInputSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(searchName => {
          const data = {
            contactName: searchName.trim().toLowerCase(),
          };
          console.log('%centreprises.component.ts line:203 data search', 'color: #007acc;', data);
          return this.pharmacieService.searchProduct(searchName.trim().toLowerCase(), this.pharmacyId, this.subCategoryId).pipe();
        }),
      )
      .subscribe((res: any) => {
        this.products = res;
      });
    // this.pharmacyId = this.pharmacieService.getPharmacyId();
    //    this.pharmacyCartItems = this.cartService.getCartItems();
    this.pharmacyCartItemsName = this.cartService.getPharmacyName();
  }

  goBack() {
    this.location.back();
  }
  NavigateToPharmacy() {
    this.router.navigate(['/pharmacie-category', { pharmacieId: this.pharmacyId, T: 'P' }]);
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
  }
  getPharmacyId() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.pharmacyId = +params.get('pharmacieId');
        this.subCategoryId = +params.get('subCategoryId');
        this.getProducts(this.subCategoryId, this.pharmacyId);
      },
    });
  }
  getProducts(subCategoryId: number, pharmacyId: number): void {
    this.pharmacieService.getStockItems(subCategoryId, pharmacyId, this.currentPage, this.itemsPerPage).subscribe(
      data => {
        console.log('API Response:', data);
        this.products = [...this.products, ...data];
        this.isLoading = false;
        this.currentPage++;
        console.log('Products loaded:', this.products);
      },
      error => {
        console.error('Error fetching Products:', error);
        this.isLoading = false;
      },
    );
  }

  removeFile(fileToRemove: File): void {
    this.prescriptionFiles = this.prescriptionFiles.filter(file => file !== fileToRemove); // Supprimer le fichier
  }
  getAllFilesUrl(): void {
    const purchaseId = this.cartService.getPurchaseId();
    this.pharmacieService.getFileUrl(purchaseId).subscribe(res => {
      this.documents = res;
      this.prescriptionUrls = res.prescriptionFiles || [];
      this.vitalCardUrl = res.vitalCard || null;
      this.mutualCardUrl = res.mutualCard || null;
      console.log(`Files URL: ${res}`);
    });
  }
  addPurchases(purchaseId: any): void {
    this.PurchasesForm.markAllAsTouched();
    this.PurchasesForm.markAsDirty();
    const formData = new FormData();

    const formControls = this.PurchasesForm.controls;
    this.prescriptionFiles.forEach(file => {
      formData.append('prescription', file);
    });

    // Ajouter les autres fichiers
    ['vital_card', 'mutual_card'].forEach(fileType => {
      const file = formControls[fileType]?.value;
      if (file) {
        formData.append(fileType, file);
      }
    });
    this.uploadFiles(formData, purchaseId);
  }

  uploadFiles(formData: FormData, purchaseId: any): void {
    this.pharmacieService.uploadFiles(purchaseId, formData).subscribe({
      next: (res: any) => {
        console.log('Response for file upload:', res);
        this.toastr.success('Files uploaded successfully');
        this.getAllFilesUrl();
        this.resetForm();
        this.cartService.addPrescriptionToCart();
      },
      error: (err: any) => {
        console.log('Error uploading files', err);
        this.toastr.error('Error uploading files');
      },
    });
  }

  resetForm() {
    this.prescriptionFiles = [];
    this.fileUrls = [];
    this.vitalCardFiles = [];
    this.mutualCardFiles = [];
    this.PurchasesForm?.reset();
  }
  onFileSelectedPrescription(event: any): void {
    const files: FileList = event.target.files;

    // Check if number of files exceeds the limit
    if (this.prescriptionFiles.length + files.length > this.maxFiles) {
      this.toastr.info(`You can only upload a maximum of ${this.maxFiles} files.`, 'File Limit Exceeded');
      return;
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Validate file type
      if (!this.allowedFileTypes.includes(file.type)) {
        this.toastr.info('Only images (JPG, PNG) or PDFs are allowed.', 'Invalid File Type');
        continue;
      }

      // Validate file size
      if (file.size > this.maxFileSize) {
        this.toastr.info(`File size should not exceed ${this.maxFileSize / (1024 * 1024)} MB.`, 'File Size Exceeded');
        continue;
      }

      // Add file to list if valid
      this.prescriptionFiles.push(file);

      // Use FileReader to generate preview URL
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.fileUrls.push(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }
  removeFilePrescription(file: File): void {
    const index = this.prescriptionFiles.indexOf(file);
    if (index > -1) {
      this.prescriptionFiles.splice(index, 1);
      this.fileUrls.splice(index, 1);
    }
  }
  getFileUrl(file: File): string {
    return URL.createObjectURL(file);
  }
  cookiesCart!: any[];
  total: any;
  addOrder() {
    let purchaseStockItems: any = [];
    this.cookiesCart?.forEach(async item => {
      purchaseStockItems.push({
        stockItem: { id: item.id },
        requestedQuantity: item.quantity,
      });
    });
    const cartData = {
      pharmacyId: this.pharmacyId,
      deliveryAddressId: this.selectedAddressId,
      purchaseStockItems: purchaseStockItems,
    };
    this.pharmacieService.addToCart(cartData).subscribe(
      response => {
        this.addPurchases(response.id);
        this.cartService.setPurchaseId(response.id);
        this.cartService.setNumCommande(response.num);
      },
      error => {
        console.error('Error adding product to cart:', error);
      },
    );
  }
  onCheckboxChange(event: any): void {
    this.consentGiven = event.target.checked; // Mettez à jour l'état de la case à cocher
  }
  appendData = () => {
    this.toggleLoading();
    this.pharmacieService.getStockItems(this.subCategoryId, this.pharmacyId, this.currentPage, this.itemsPerPage).subscribe({
      next: data => {
        if (Array.isArray(data)) {
          data.forEach((element: any) => {
            if (typeof element === 'object' && element !== null) {
              this.products = [...this.products, element];
            } else {
              console.error('Invalid element:', element);
            }
          });
        } else {
          console.error('Data is not an array:', data);
        }
      },
      error: err => console.log(err),
      complete: () => this.toggleLoading(),
    });
  };
  onScroll = () => {
    this.currentPage++;
    this.appendData();
  };
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchInputSubject.next(filterValue);
  }
  searchProducts(subCategoryId: number, pharmacyId: number): void {
    this.pharmacieService.getStockItems(subCategoryId, pharmacyId, this.currentPage, 9).subscribe(
      data => {
        console.log('API Response:', data);
        this.products = [...this.products, ...data];
        this.isLoading = false;
        this.currentPage++;
        console.log('Products loaded:', this.products);
      },
      error => {
        console.error('Error fetching Products:', error);
        this.isLoading = false;
      },
    );
  }

  openproductModal(modal: any, product: any) {
    this.modalService.open(modal, { size: 'lg', centered: true });
    this.openedProduct = product;
    this.value = this.cartService.getCurrent(product);
  }
  openCommandModal(modal: any) {
    this.modalService.open(modal, { size: 'lg', centered: true });
  }
  openModalProductExist(modal: any) {
    this.modalService.open(modal, { size: 's', centered: true });
  }
  createPurchasesForm(data?: any) {
    return this.formBuilder.group({
      prescription_File: [data ? data.prescription_File : null],
      mutualCard_File: [data ? data.mutualCard_File : null],
      vitalCard_File: [data ? data.vitalCard_File : null],
    });
  }
  onFileSelected(event: Event, fileType: string): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      const file = inputElement.files[0];
      this.PurchasesForm.get(fileType)?.setValue(file);
      const files = Array.from(inputElement.files);

      if (fileType === 'prescription') {
        this.prescriptionFiles = [...this.prescriptionFiles, ...files];
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.ordonnanceImages.push(e.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        const spanElement = inputElement.nextElementSibling as HTMLElement;
        if (spanElement) {
          spanElement.textContent = file.name;
        }
      }
      files.forEach(file => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          if (fileType === 'prescription') {
            this.ordonnanceImages.push(e.target.result);
          }
        };
        reader.readAsDataURL(file);
      });
    }
  }

  increment() {
    this.value++;
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  decrement() {
    if (this.value > 1) {
      this.value--;
    }
  }
  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }
  toggleExpandDescription() {
    this.isExpandedDescription = !this.isExpandedDescription;
  }
  toggleLike(product: any, index: number) {
    if (product.liked) {
      this.unlikeProduct(product.id, index);
    } else {
      this.likeProduct(product.id, index);
    }
  }
  likeProduct(product_id: any, index: number) {
    let data = {
      favorable_type: 'StockItem',
      favorable_id: product_id,
    };
    this.pharmacieService.likeProduct(data).subscribe(
      response => {
        console.log('API Response:', response);
        this.products[index].liked = true;
        this.toastr.success('Product liked successfully!', 'Success');
      },
      error => {
        console.error('Error liking pharmacy:', error);
        this.toastr.error('Failed to like the product. Please try again.', 'Error');
      },
    );
  }
  unlikeProduct(product_id: any, index: number) {
    let data = {
      favorable_type: 'StockItem',
      favorable_id: product_id,
    };
    this.pharmacieService.unlikeProduct(data).subscribe(
      response => {
        console.log('API Response:', response);
        this.products[index].liked = false;
        this.toastr.info('Product unliked successfully.', 'Info');
      },
      error => {
        console.error('Error unliking pharmacy:', error);
        this.toastr.error('Failed to unlike the product. Please try again.', 'Error');
      },
    );
  }

  openModal(modalTemplate: TemplateRef<any>, product: any) {
    this.modalService.open(modalTemplate, { size: 's', centered: true }).result.then(
      result => {
        if (result === 'clearCart') {
          this.cartService.clearCartItems();
          if (this.openedProduct) {
            this.addToCartWithASpecificNumber();
          } else {
            this.addToCart(product, this.modalProductExist);
          }
        } else {
          console.log('User canceled the action.');
        }
      },
      reason => {
        console.log('Modal dismissed:', reason);
      },
    );
  }
  addToCart(product: any, modalTemplate: TemplateRef<any>): void {
    if (!this.cartService.checkSamePharmacy(product)) {
      this.openModal(modalTemplate, product);
    } else {
      this.cartService.addToCart(product);
      this.toastr.success('Product added to cart');
    }
  }
  // const cartData = {
  //   pharmacyId: product.pharmacy.id,
  //   deliveryAddressId: 1,
  //   purchaseStockItems: [
  //     {
  //       stockItem: { id: product.id },
  //       requestedQuantity: 1,
  //     },
  //   ],
  // };
  // this.pharmacieService.addToCart(cartData).subscribe(
  //   response => {
  //     console.log('Product added to cart:', response);
  //     const responsePharmacyLegalName = response.purchaseStockItems[0].stockItem.pharmacy.legalName;
  //     const storedCartItems = this.cartService.getCartItems();
  //     const storedPharmacyLegalName =
  //       storedCartItems.length > 0 ? storedCartItems[0].purchaseStockItems[0].stockItem.pharmacy.legalName : null;

  //     if (storedPharmacyLegalName && storedPharmacyLegalName !== responsePharmacyLegalName) {
  //       this.openModal(modalTemplate, product);
  //     } else {
  //       storedCartItems.push(response);
  //       console.log(storedCartItems, "check")
  //       this.cartService.saveCartItems(storedCartItems);
  //       this.toastr.success('Product added to cart');
  //     }
  //   },
  //   error => {
  //     console.error('Error adding product to cart:', error);
  //   },
  // );
  addToCartWithASpecificNumber() {
    if (!this.cartService.checkSamePharmacy(this.openedProduct)) {
      this.openModal(this.modalProductExist, this.openedProduct);
    } else {
      //this.cartService.setPurchaseId(response.id);
      //   console.log('Product added to cart:', response);
      this.cartService.addToCartWithASpecificNumber(this.openedProduct, this.value);
      this.toastr.success('Product added to cart');
    }
  }
  // addToCart(product: any): void {
  //   const cartData = {
  //     pharmacyId: product.pharmacy.id,
  //     deliveryAddressId: 1,
  //     purchaseStockItems: [
  //       {
  //         stockItem: { id: product.id },
  //         requestedQuantity: 1,
  //       },
  //     ],
  //   };

  //   if (this.cartService.checkSamePharmacy(product)) {
  //     this.pharmacieService.addToCart(cartData).subscribe(
  //       response => {
  //         console.log('Product added to cart:', response);
  //         const responsePharmacyLegalName = response.purchaseStockItems[0].stockItem.pharmacy.legalName;
  //         const storedCartItems = this.cartService.getCartItems();
  //         const storedPharmacyLegalName =
  //           storedCartItems.length > 0 ? storedCartItems[0].purchaseStockItems[0].stockItem.pharmacy.legalName : null;

  //         if (storedPharmacyLegalName && storedPharmacyLegalName !== responsePharmacyLegalName) {
  //           Swal.fire({
  //             icon: 'warning',
  //             title: 'Pharmacy Mismatch',
  //             text: 'Le produit doit appartenir à la même pharmacie pour être ajouté au panier.',
  //             showCancelButton: true,
  //             confirmButtonText: 'Vider le panier',
  //             cancelButtonText: 'Annuler',
  //             customClass: {
  //               confirmButton: 'swal2-confirm',
  //               cancelButton: 'swal2-cancel',
  //             },
  //           }).then(result => {
  //             if (result.isConfirmed) {
  //               this.cartService.clearCartItems();
  //               let newCartItems = [];
  //               newCartItems.push(response);
  //               this.cartService.saveCartItems(newCartItems);
  //               this.toastr.success('Product added to cart');
  //             } else if (result.isDismissed) {
  //               console.log('User canceled the action.');
  //             }
  //           });
  //         } else {
  //           storedCartItems.push(response);
  //           this.cartService.saveCartItems(storedCartItems);
  //           this.toastr.success('Product added to cart');
  //         }
  //       },
  //       error => {
  //         console.error('Error adding product to cart:', error);
  //       },
  //     );
  //   } else {
  //     Swal.fire({
  //       icon: 'warning',
  //       title: 'Pharmacy Mismatch',
  //       text: 'Les produits dans un panier doivent appartenir à la même pharmacie. Si vous confirmez la sélection de ce produit, votre panier actuel sera remplacé.',
  //       showCancelButton: true,
  //       confirmButtonText: 'Sélectionner ce produit',
  //       cancelButtonText: 'Rester sur mon panier',
  //     }).then(result => {
  //       if (result.isConfirmed) {
  //         this.cartService.clearCartItems();
  //         let newCartItems = [];
  //         newCartItems.push(cartData);
  //         this.cartService.saveCartItems(newCartItems);
  //         this.toastr.success('Product added to cart');
  //       } else if (result.isDismissed) {
  //         console.log('User canceled the action.');
  //       }
  //     });
  //   }
  // }
  storeCartItem(cartItem: any): void {
    let cartItems = this.cartService.getCartItems();
    cartItems.push(cartItem);
    this.cartService.setCookie('cartItems', JSON.stringify(cartItems), 7);
  }
}
