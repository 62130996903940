<div class="row mt-5 mb-4">
  <div class="col-sm-12">
    <h2 class="lead" style="color: #141414">Tableau de bord</h2>
  </div>
</div>
<div class="dashboard">
  <div class="card">
    <h1 class="value green">354 €</h1>
    <p>Chiffre d'affaire</p>
    <p class="green" style="font-size: 13px">40 <span class="rapport">par rapport au mois dernier</span></p>
  </div>
  <div class="card">
    <h1 class="value blue">30</h1>
    <p>Nombre des commandes</p>
    <p class="blue" style="font-size: 13px">50 <span class="rapport">par rapport au mois dernier</span></p>
  </div>
  <div class="card">
    <h1 class="value green">20</h1>
    <p>Zones d’action</p>
    <p class="green" style="font-size: 13px">10 <span class="rapport">par rapport au mois dernier</span></p>
  </div>
  <div class="card">
    <h1 class="value blue">10</h1>
    <p>Sous-Zones d’action</p>
    <p class="blue" style="font-size: 13px">20 <span class="rapport">par rapport au mois dernier</span></p>
  </div>
  <div class="card">
    <h1 class="value blue">10</h1>
    <p>Nombre de partenaires</p>
    <p class="blue" style="font-size: 13px">20 <span class="rapport">par rapport au mois dernier</span></p>
  </div>
  <div class="card">
    <h1 class="value green">20</h1>
    <p>Nombre de livreurs</p>
    <p class="green" style="font-size: 13px">10 <span class="rapport">par rapport au mois dernier</span></p>
  </div>
</div>
