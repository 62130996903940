import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import SharedModule from '../shared/shared.module';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'jhi-cgp',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './cgp.component.html',
  styleUrl: './cgp.component.scss',
})
export class CgpComponent implements OnInit {
  termsOfUseGeneral: any;
  constructor(
    private translateService: TranslateService,
    private http: HttpClient,
  ) {}
  ngOnInit(): void {
    window.scrollTo(0, 0);
    const storedLang = localStorage.getItem('selectedLanguage') ?? 'fr';
    if (storedLang) {
      this.translateService.use(storedLang);
    } else {
      this.translateService.use('fr');
    }
    this.http.get(`i18n/${storedLang}/CGP.json`).subscribe((data: any) => {
      this.termsOfUseGeneral = data.termsOfUseGeneral;
    });
  }
}
