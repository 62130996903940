import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { PaiementsService } from '../paiements.service';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PaginatorModule } from 'primeng/paginator';

interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}
@Component({
  selector: 'jhi-paiements',
  standalone: true,
  imports: [CommonModule, NgxSkeletonLoaderModule, PaginatorModule],
  templateUrl: './paiements.component.html',
  styleUrl: './paiements.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PaiementsComponent implements OnInit {
  paymentsList: any;
  contentLoaded = false;
  first: number = 0;
  size: number = 10;
  rows: number = 1;
  totalRecords: any;

  constructor(private paiementService: PaiementsService) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.contentLoaded = true;
    }, 2000);
    this.getPaymentsList();
  }

  getPaymentsList(): void {
    this.paiementService.getPayments(this.first, this.size).subscribe({
      next: res => {
        this.paymentsList = res?.content;
        this.totalRecords = res.totalPages;
      },
    });
  }
  onPageChange(event: any): void {
    const pageEvent: PageEvent = {
      first: event.first ?? 0,
      rows: event.rows ?? 1,
      page: event.page ?? 0,
      pageCount: event.pageCount ?? 0,
    };

    this.first = pageEvent.first;
    this.rows = pageEvent.rows;
    this.getPaymentsList();
  }
}
