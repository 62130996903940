import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApplicationConfigService } from '../../core/config/application-config.service';
import { Registration } from './register.model';
import { environment } from '../../../environments/environment';
interface DecodedToken {
  sub?: string; // Subject (user ID)
  exp?: number; // Expiration time
  iat?: number; // Issued at
  [key: string]: unknown; // Allow additional properties
}
@Injectable({ providedIn: 'root' })
export class RegisterService {
  private credentials: { email: string; password: string } | null = null;

  constructor(
    private http: HttpClient,
    private applicationConfigService: ApplicationConfigService,
  ) {}

  save(registration: Registration): Observable<{}> {
    return this.http.post(this.applicationConfigService.getEndpointFor('api/register'), registration);
  }
  validatToken(key: any): Observable<{}> {
    return this.http.post(`${environment.baseUrl}api/invitations/validate?token=${key}`, {});
  }
  createStripeAccount(user_id: any): Observable<{}> {
    return this.http.post(`${environment.baseUrl}api/stripe/create-stripe-account-link/${user_id}`, {});
  }

  decodeToken(token: string): DecodedToken | null {
    try {
      const parts = token.split('.'); // Split token into header, payload, and signature
      if (parts.length !== 3) {
        throw new Error('Token JWT invalide');
      }
      const payload = parts[1]; // Get the payload part
      const decodedPayload = decodeURIComponent(escape(atob(payload))); // Properly decode UTF-8
      return JSON.parse(decodedPayload) as DecodedToken; // Cast the parsed object to DecodedToken
    } catch (error) {
      console.error('Erreur lors du décodage du token:', error);
      return null;
    }
  }

  setCredentials(email: string, password: string): void {
    this.credentials = { email, password };
  }

  getCredentials(): { email: string; password: string } | null {
    return this.credentials;
  }

  clearCredentials(): void {
    this.credentials = null;
  }
}
