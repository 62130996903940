<div class="row mt-5 mb-4">
  <div class="col-sm-12">
    <h2 class="lead" style="color: #141414">Paramètre du compte</h2>
  </div>
</div>
<div class="row">
  <div class="container">
    <ul ngbNav #navProfilDetails="ngbNav" class="nav-tabs" role="tablist">
      <li [ngbNavItem]="1">
        <a ngbNavLink>Administrateur</a>
        <ng-template ngbNavContent>
          <div class="row schedule-container">
            <div class="col-sm-12 col-xl-8">
              <div class="d-flex justify-content-between">
                <div class="profile-picture-wrapper">
                  <div class="profile-picture"></div>
                </div>
                <div class="profile-btn-wrapper mt-5">
                  <button type="submit" class="btn btn-maj" (click)="updateProfile()">
                    <img src="/content/images/icons/edit.svg" alt="" /> Mettre à jour
                  </button>
                </div>
              </div>
              <div class="profile-form">
                <form action="">
                  <div class="mb-3 row" *ngIf="account">
                    <div class="col-sm-6">
                      <label for="lastName">Nom</label>
                      <input [(ngModel)]="account.lastName" type="text" name="lastName" id="lastName" />
                    </div>
                    <div class="col-sm-6">
                      <label for="firstName">Prénom</label>
                      <input [(ngModel)]="account.firstName" type="text" name="firstName" id="firstName" />
                    </div>
                  </div>
                  <div class="mb-3 row" *ngIf="account">
                    <div class="col-sm-6">
                      <label for="mail">Email</label>
                      <input [(ngModel)]="account.email" type="email" name="mail" id="mail" />
                    </div>
                    <div class="col-sm-6">
                      <label for="tel">Numéro de téléphone</label>
                      <input [(ngModel)]="phoneNumber" type="tel" name="tel" id="tel" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Pharmacie</a>
        <ng-template ngbNavContent>
          <div class="schedule-container mb-4">
            <div class="row scorllable-container">
              <div class="col">
                <h2>Horaire de travail régulier</h2>
                <div class="work-schedule">
                  <div class="day-schedule">
                    <div class="date-header" ngbDropdown>
                      <label class="day">Heures par défaut</label>
                      <span style="cursor: pointer" ngbDropdownToggle> <i class="fa-solid fa-ellipsis-vertical"></i> </span>
                      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button ngbDropdownItem (click)="openModal(true, schedules)">Modifier</button>
                      </div>
                    </div>
                    <hr style="margin-top: 0px" />

                    <div class="time-slot">
                      <label>De</label>
                      <button class="select">
                        {{ formatTimeToHHhMM(schedules?.openingHour) }}
                      </button>
                      <label>À</label>
                      <button class="select">
                        {{ formatTimeToHHhMM(schedules?.closingHour) }}
                      </button>
                    </div>
                    <div class="time-slot" *ngIf="schedules?.openingHour2 && schedules?.closingHour2">
                      <label>De</label>
                      <button class="select">
                        {{ formatTimeToHHhMM(schedules?.openingHour2) }}
                      </button>
                      <label>À</label>
                      <button class="select">
                        {{ formatTimeToHHhMM(schedules?.closingHour2) }}
                      </button>
                    </div>
                    <div class="time-slot"></div>
                  </div>
                  <div class="date-schedule-exception" *ngFor="let day of exceptionalDaysCUSTOM_EXCEPTION">
                    <div class="date-header" ngbDropdown>
                      <span style="color: #141414; font-size: 15px; font-weight: 600">{{ day?.exceptionDate }}</span>
                      <span style="cursor: pointer" ngbDropdownToggle> <i class="fa-solid fa-ellipsis-vertical"></i> </span>
                      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button ngbDropdownItem (click)="openModalExceptionalDays(true, 'CUSTOM_EXCEPTION', day)">Modifier</button>
                        <button ngbDropdownItem (click)="onDeleteClickExceptionDate(day.id)">Supprimer</button>
                      </div>
                    </div>
                    <hr />
                    <div class="time-slot">
                      <label>De</label>
                      <button class="select">
                        {{ formatTimeToHHhMM(day?.openingHour) }}
                      </button>
                      <label>À</label>
                      <button class="select">
                        {{ formatTimeToHHhMM(day?.closingHour) }}
                      </button>
                    </div>
                    <div class="time-slot" *ngIf="day?.openingHour2 && day?.closingHour2">
                      <label>De</label>
                      <button class="select">
                        {{ formatTimeToHHhMM(day?.openingHour2) }}
                      </button>
                      <label>À</label>
                      <button class="select">
                        {{ formatTimeToHHhMM(day?.closingHour2) }}
                      </button>
                    </div>
                  </div>
                  <button class="add-date-btn-exception" (click)="openModalExceptionalDays(false, 'CUSTOM_EXCEPTION')">
                    Ajouter une exception
                  </button>
                </div>
                <h2>Calendrier de garde (dimanche et jour férié)</h2>
                <div class="on-call-schedule">
                  <div class="date-schedule" *ngFor="let day of exceptionalDaysHOLIDAY">
                    <div class="date-header" ngbDropdown>
                      <span style="color: #141414; font-size: 15px; font-weight: 600">{{ day?.exceptionDate }}</span>
                      <span style="cursor: pointer" ngbDropdownToggle> <i class="fa-solid fa-ellipsis-vertical"></i> </span>
                      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button ngbDropdownItem (click)="openModalExceptionalDays(true, 'HOLIDAY', day)">Modifier</button>
                        <button ngbDropdownItem (click)="onDeleteClickExceptionDate(day.id)">Supprimer</button>
                      </div>
                    </div>
                    <hr />
                    <div class="time-slot">
                      <label>De</label>
                      <button class="select">
                        {{ formatTimeToHHhMM(day?.openingHour) }}
                      </button>
                      <label>À</label>
                      <button class="select">
                        {{ formatTimeToHHhMM(day?.closingHour) }}
                      </button>
                    </div>
                    <div class="time-slot" *ngIf="day?.openingHour2 && day?.closingHour2">
                      <label>De</label>
                      <button class="select">
                        {{ formatTimeToHHhMM(day?.openingHour2) }}
                      </button>
                      <label>À</label>
                      <button class="select">
                        {{ formatTimeToHHhMM(day?.closingHour2) }}
                      </button>
                    </div>
                  </div>
                  <button class="add-date-btn" (click)="openModalExceptionalDays(false, 'HOLIDAY')">Ajouter une date</button>
                </div>
                <h2>Calendrier des urgences (garde de nuit)</h2>
                <div class="on-call-schedule">
                  <div class="date-schedule-guard" *ngFor="let day of exceptionalDaysNIGHT_ON_DUTY">
                    <div class="date-header" ngbDropdown>
                      <span style="color: #141414; font-size: 15px; font-weight: 600">{{ day?.exceptionDate }}</span>
                      <span style="cursor: pointer" ngbDropdownToggle> <i class="fa-solid fa-ellipsis-vertical"></i> </span>
                      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button ngbDropdownItem (click)="openModalExceptionalDays(true, 'NIGHT_ON_DUTY', day)">Modifier</button>
                        <button ngbDropdownItem (click)="onDeleteClickExceptionDate(day.id)">Supprimer</button>
                      </div>
                    </div>
                    <hr />
                    <div class="time-slot">
                      <label>De</label>
                      <button class="select">
                        {{ formatTimeToHHhMM(day?.openingHour) }}
                      </button>
                      <label>À</label>
                      <button class="select">
                        {{ formatTimeToHHhMM(day?.closingHour) }}
                      </button>
                    </div>
                    <div class="time-slot" *ngIf="day?.openingHour2 && day?.closingHour2">
                      <label>De</label>
                      <button class="select">
                        {{ formatTimeToHHhMM(day?.openingHour2) }}
                      </button>
                      <label>À</label>
                      <button class="select">
                        {{ formatTimeToHHhMM(day?.closingHour2) }}
                      </button>
                    </div>
                  </div>
                  <button class="add-date-btn" (click)="openModalExceptionalDays(false, 'NIGHT_ON_DUTY')">Ajouter une date</button>
                </div>
              </div>
              <div class="col">
                <div id="calendar" class="calendar">
                  <div class="calendar-title">
                    <div class="calendar-title-text">
                      <button (click)="prevMonth()">&lt;</button>
                      <div class="monthYear">{{ monthYear }}</div>
                      <button (click)="nextMonth()">&gt;</button>
                    </div>

                    <div class="calendar-button-group">
                      <button (click)="goToToday()">Aujourd'hui</button>
                    </div>
                  </div>

                  <div class="calendar-day-name">
                    <div *ngFor="let day of weekNames">{{ day }}</div>
                  </div>
                  <div class="calendar-dates">
                    <button *ngFor="let day of prevMonthDays" class="calendar-dates-day-empty">{{ day }}</button>
                    <button *ngFor="let day of daysInMonth; let i = index" class="calendar-dates-day" [ngClass]="getDayClasses(day)">
                      <!-- 'special-day': isExceptionalDay(day),-->
                      {{ i + 1 }}
                    </button>
                    <button *ngFor="let day of nextMonthDays" class="calendar-dates-day-empty">{{ day }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="navProfilDetails" class="mt-2"></div>
  </div>
</div>

<ng-template #modalScheduler let-modalScheduler>
  <div class="modal-avis">
    <div class="modal-header">
      <div></div>
      <div class="col-1 d-flex align-items-end flex-column">
        <button type="button" class="close" (click)="modalScheduler.dismiss('Cross click')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="">
      <div class="avis-header"></div>
      <div class="title">
        {{ isEditMode ? 'Modifier la Date' : 'Ajouter une Date' }}
      </div>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      <form [formGroup]="scheduleForm">
        <div class="form-group">
          <label for="nbrSeances">Nbr de Séances</label>
          <div class="d-flex gap-3">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                id="oneSession"
                formControlName="sessionCount"
                [value]="1"
                (change)="onSessionChange(false)"
              />
              <label class="form-check-label" for="oneSession">1 Séance</label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                id="twoSessions"
                formControlName="sessionCount"
                [value]="2"
                (change)="onSessionChange(true)"
              />
              <label class="form-check-label" for="twoSessions">2 Séances</label>
            </div>
          </div>
        </div>
        <label class="form-check-label" for="twoSessions">Premiére séance</label>
        <div class="form-group formCheck" style="display: flex">
          <div style="margin-right: 10px">De</div>
          <input type="time" id="de" class="form-control" formControlName="openingHour" style="height: 40px; margin-right: 10px" />
          <div style="margin-right: 10px">À</div>
          <input type="time" id="a" class="form-control" formControlName="closingHour" style="height: 40px" />
        </div>
        <div *ngIf="scheduleForm.errors?.invalidTime1" class="text-danger">
          {{ scheduleForm.errors?.invalidTime1 }}
        </div>
        <div *ngIf="isSplit">
          <label class="form-check-label" for="twoSessions">Deuxième séance</label>
          <div class="form-group formCheck" style="display: flex">
            <div style="margin-right: 10px">De</div>
            <input type="time" id="de" class="form-control" formControlName="openingHour2" style="height: 40px; margin-right: 10px" />
            <div style="margin-right: 10px">À</div>
            <input type="time" id="a" class="form-control" formControlName="closingHour2" style="height: 40px" />
          </div>
          <div *ngIf="scheduleForm.errors?.invalidTime2" class="text-danger">
            {{ scheduleForm.errors?.invalidTime2 }}
          </div>
        </div>
        <div class="form-group"></div>

        <button
          class="submit"
          style="margin-top: 25px"
          style="margin-top: 25px"
          type="button"
          [disabled]="scheduleForm.invalid"
          (click)="submitSchedule()"
        >
          Confirmer
        </button>
      </form>
    </div>
  </div>
</ng-template>
<ng-template #modalExceptionalDays let-modalExceptionalDays>
  <div class="modal-avis">
    <div class="modal-header">
      <div></div>
      <div class="col-1 d-flex align-items-end flex-column">
        <button type="button" class="close" (click)="modalExceptionalDays.dismiss('Cross click')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="">
      <div class="avis-header"></div>
      <div class="title">
        {{ isEditMode ? 'Modifier la Date' : 'Ajouter une Date' }}
      </div>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      <form [formGroup]="scheduleExceptionalDaysForm">
        <div class="form-group">
          <label for="nbrSeances">Nbr de Séances</label>
          <div class="d-flex gap-3">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                id="oneSession"
                formControlName="sessionCount"
                [value]="1"
                (change)="onSessionChangeExceptionalDay(false)"
              />
              <label class="form-check-label" for="oneSession">1 Séance</label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                id="twoSessions"
                formControlName="sessionCount"
                [value]="2"
                (change)="onSessionChangeExceptionalDay(true)"
              />
              <label class="form-check-label" for="twoSessions">2 Séances</label>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="date"> Sélectionnez une date </label>
          <input type="date" id="date" class="form-control" formControlName="exceptionDate" />
        </div>

        <label class="form-check-label" for="twoSessions">Premiére séance</label>
        <div class="form-group formCheck" style="display: flex">
          <div style="margin-right: 10px">De</div>
          <input type="time" id="de" class="form-control" formControlName="openingHour" style="height: 40px; margin-right: 10px" />
          <div style="margin-right: 10px">À</div>
          <input type="time" id="a" class="form-control" formControlName="closingHour" style="height: 40px" />
        </div>
        <div *ngIf="scheduleExceptionalDaysForm.errors?.invalidTime1" class="text-danger">
          {{ scheduleExceptionalDaysForm.errors?.invalidTime1 }}
        </div>
        <div *ngIf="isSplitExceptionalDay">
          <label class="form-check-label" for="twoSessions">Deuxième séance</label>
          <div class="form-group formCheck" style="display: flex">
            <div style="margin-right: 10px">De</div>
            <input type="time" id="de" class="form-control" formControlName="openingHour2" style="height: 40px; margin-right: 10px" />
            <div style="margin-right: 10px">À</div>
            <input type="time" id="a" class="form-control" formControlName="closingHour2" style="height: 40px" />
          </div>
        </div>
        <div *ngIf="scheduleExceptionalDaysForm.errors?.invalidTime2" class="text-danger">
          {{ scheduleExceptionalDaysForm.errors?.invalidTime2 }}
        </div>
        <button
          class="submit"
          style="margin-top: 25px"
          style="margin-top: 25px"
          type="button"
          [disabled]="scheduleExceptionalDaysForm.invalid"
          (click)="submitExceptionalDay()"
        >
          Confirmer
        </button>
      </form>
    </div>
  </div>
</ng-template>
