<div class="row mt-5">
  <h2 class="lead" style="color: #141414">Liste des Pharmacies</h2>
</div>
<br />
<div class="row">
  <div class="col-8">
    <ul ngbNav #nav="ngbNav" class="nav-tabs" role="tablist">
      <li [ngbNavItem]="1">
        <a ngbNavLink>Global</a>
        <ng-template ngbNavContent>
          <div>
            <div class="table-container">
              <table class="table table-responsive">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">CIP</th>
                    <th scope="col">Dén Commercial</th>
                    <th scope="col">Date_Heure</th>
                    <th scope="col">Montant</th>
                    <th scope="col">ID-Com</th>
                    <th scope="col">Référence</th>
                    <th scope="col">État</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container>
                    <tr *ngIf="!contentLoaded">
                      <td colspan="5">
                        <ngx-skeleton-loader
                          count="1"
                          appearance="line"
                          [theme]="{ height: '24px', width: '100%', 'margin-bottom': '10px' }"
                        ></ngx-skeleton-loader>
                      </td>
                    </tr>
                    <tr *ngIf="contentLoaded">
                      <td>74123698523</td>
                      <td>741265852352</td>
                      <td>01/10/2024_10:24</td>

                      <td>7412</td>
                      <td>74126585</td>
                      <td>xxxx2658</td>
                      <td>Succès</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <!--
          -->
            <div class="card flex justify-content-center">
              <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords" />
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Par pharmacie</a>
        <ng-template ngbNavContent>
          <div>
            <div class="selectDate">
              Sélection de période
              <select name="pets" id="pet-select" class="form-control-select">
                <option value="">Sélection de période</option>
                <option value="dog">Le mois en cours</option>
                <option value="cat">Trimestre en cours</option>
                <option value="hamster">Semestre en cours</option>
                <option value="parrot">Année en cours</option>
              </select>
            </div>

            <div class="table-container">
              <table class="table table-responsive">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">CIP</th>
                    <th scope="col">Dén Commercial</th>
                    <th scope="col">C.A An</th>
                    <th scope="col">C.A Trim</th>
                    <th scope="col">C.A Mois</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container>
                    <tr *ngIf="!contentLoaded">
                      <td colspan="5">
                        <ngx-skeleton-loader
                          count="1"
                          appearance="line"
                          [theme]="{ height: '24px', width: '100%', 'margin-bottom': '10px' }"
                        ></ngx-skeleton-loader>
                      </td>
                    </tr>
                    <tr *ngIf="contentLoaded">
                      <td>741236987456</td>
                      <td>789632541252</td>
                      <td>xxxxx</td>
                      <td>xxxxx</td>

                      <td>xxxxx</td>
                      <td>
                        <div class="btn-wrap">
                          <button class="btn-payer" (click)="openModalPayment()">Voir détails</button>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
  </div>
  <div class="product-search col-4">
    <input type="text" class="form-control category-input" id="text" name="search" placeholder="Recherche ..." data-cy="text" />
    <span class="search-icon" style="margin: -2rem 2rem"></span>
  </div>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
<ng-template #modalDetailsPayment let-modalDetailsPayment>
  <div class="modal-header">
    <div class="col-11 d-flex justify-content-center"></div>
    <div class="col-1 d-flex align-items-end flex-column">
      <button type="button" class="close" (click)="modalDetailsPayment.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="form-container">
      <h4>Détails Paiements Livreur ID: xxxx123</h4>

      <!-- Section Gérant -->
      <p>Livreur</p>
      <div class="form-section">
        <div class="form-group">
          <input type="text" id="prenom" placeholder="Prénom & Nom" class="form-control" />
        </div>
        <div class="form-group">
          <input type="text" id="nom" placeholder="Tél Service" class="form-control" />
        </div>
      </div>
      <div class="form-section">
        <div class="form-group">
          <input type="email" id="emailService" placeholder="Heure de commande" class="form-control" />
        </div>
        <div class="form-group">
          <input type="text" id="numTelService" placeholder="Démarrage Livraison" class="form-control" />
        </div>
      </div>
      <input type="text" id="denomination" placeholder="Adresse destination" class="form-control full-width mb-3" />
      <p>Suivie de paiement</p>
      <table class="table table-responsive">
        <thead class="thead-dark">
          <tr>
            <th scope="col">Sem</th>
            <th scope="col">DU</th>
            <th scope="col">AU</th>
            <th scope="col">Montant</th>
            <th scope="col">IBAN</th>
            <th scope="col">État</th>
          </tr>
        </thead>
        <tbody>
          <ng-container>
            <tr *ngIf="!contentLoaded">
              <td colspan="5">
                <ngx-skeleton-loader
                  count="1"
                  appearance="line"
                  [theme]="{ height: '24px', width: '100%', 'margin-bottom': '10px' }"
                ></ngx-skeleton-loader>
              </td>
            </tr>
            <tr *ngIf="contentLoaded">
              <td>S_03</td>
              <td>13/01</td>
              <td>19/01</td>
              <td>95€</td>

              <td>FR124xxxxxxx123</td>
              <td>
                <div class="btn-wrap">
                  <button class="btn-payer">payé</button>
                </div>
              </td>
            </tr>
            <tr *ngIf="contentLoaded">
              <td>S_02</td>
              <td>13/01</td>
              <td>19/01</td>
              <td>135€</td>

              <td>FR124xxxxxxx123</td>
              <td>
                <div class="btn-wrap">
                  <button class="btnPayer">payé</button>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>

      <!-- Submit Button -->
    </div>
  </div>
</ng-template>
