import { Component, OnInit, ViewChild, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Account } from '../../../core/auth/account.model';
import { AccountService } from '../../../core/auth/account.service';
import { AuthServerProvider } from '../../../core/auth/auth-jwt.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ScheduleService } from 'app/pharmaBackOffice/services/Schedule/schedule.service';
import { CommonModule } from '@angular/common';
import Swal from 'sweetalert2';
import { CalendarModule } from 'primeng/calendar';
import { PrimeNGConfig } from 'primeng/api';
import dayjs from 'dayjs';
@Component({
  selector: 'jhi-pharmacy-account',
  standalone: true,
  imports: [FormsModule, NgbModule, NgbDropdownModule, CommonModule, ReactiveFormsModule, CalendarModule],
  templateUrl: './pharmacy-account.component.html',
  styleUrl: './pharmacy-account.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [{ provide: LOCALE_ID, useValue: 'fr-FR' }],
})
export class PharmacyAccountComponent implements OnInit {
  @ViewChild('modalScheduler') modalScheduler: any;
  @ViewChild('modalExceptionalDays') modalExceptionalDays: any;

  date: Date[] | undefined;
  profileImageUrl: string | ArrayBuffer | null = null;
  account!: Account;
  scheduleForm: FormGroup;
  scheduleExceptionalDaysForm: FormGroup;

  isEditMode = false;
  selectedSchedule: any = null;

  currentDate = dayjs();
  today = dayjs();
  daysInMonth: any[] = [];
  prevMonthDays: number[] = [];
  nextMonthDays: number[] = [];
  monthYear: string = '';
  weekNames: string[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  calendarData: any = {}; // Stocker les données du service

  constructor(
    private fb: FormBuilder,
    private scheduleService: ScheduleService,
    private accountService: AccountService,
    private toastrService: ToastrService,
    private modalService: NgbModal,
  ) {
    this.scheduleForm = this.fb.group({
      startDate: ['', Validators.required],
      openingHour: ['', Validators.required],
      closingHour: ['', Validators.required],
    });
    this.scheduleExceptionalDaysForm = this.fb.group({
      exceptionDate: ['', Validators.required],
      openingHour: ['', Validators.required],
      closingHour: ['', Validators.required],
      exceptionType: [''],
    });
  }

  ngOnInit(): void {
    this.loadProfile();
    this.getCalendarData();
  }
  isToday(day: string): boolean {
    return dayjs(day).isSame(this.today, 'day');
  }
  getCalendarData() {
    const fromdate = this.currentDate.startOf('month').format('YYYY-MM-DD');
    const todate = this.currentDate.endOf('month').format('YYYY-MM-DD');
    const pharmacy_id = 4; // exemple d'ID

    this.scheduleService.getAllCalendar(pharmacy_id, fromdate, todate).subscribe(data => {
      this.calendarData = data;
      this.plotDays();
    });
  }

  plotDays() {
    this.daysInMonth = Array.from({ length: this.currentDate.daysInMonth() }, (_, i) => {
      const day = this.currentDate.date(i + 1).format('YYYY-MM-DD');
      return this.calendarData[day] || { day, isExceptionalDay: false, isWeekEnd: false };
    });

    const firstDayPosition = this.currentDate.startOf('month').day() || 7;
    const prevMonthLastDate = this.currentDate.subtract(1, 'month').endOf('month').date();

    // Jours du mois précédent
    this.prevMonthDays = Array.from({ length: firstDayPosition - 1 }, (_, i) => prevMonthLastDate - i).reverse();

    // Jours du mois suivant
    const totalDays = 42 - (this.daysInMonth.length + this.prevMonthDays.length);
    this.nextMonthDays = Array.from({ length: totalDays }, (_, i) => i + 1);

    this.updateMonthYear();
  }

  updateMonthYear() {
    this.monthYear = `${this.currentDate.format('MMMM')} - ${this.currentDate.year()}`;
  }

  nextMonth() {
    this.currentDate = this.currentDate.add(1, 'month');
    this.getCalendarData();
  }

  prevMonth() {
    this.currentDate = this.currentDate.subtract(1, 'month');
    this.getCalendarData();
  }

  goToToday() {
    this.currentDate = dayjs();
    this.getCalendarData();
  }

  isWeekend(day: any): boolean {
    return day.isWeekEnd;
  }

  isExceptionalDay(day: any): boolean {
    return day.isExceptionalDay;
  }
  pharmacyId: any;
  loadProfile(): void {
    this.accountService.getAuthenticatedUser().subscribe(res => {
      this.account = res;
      this.pharmacyId = this.account.applicationUser.pharmacy?.id;
      this.getSchedule(this.pharmacyId);
      this.getExceptionalDaysPeriod(this.pharmacyId);
    });
  }

  updateProfile(): void {
    this.accountService.save(this.account).subscribe(res => {
      this.accountService.setAccount(this.account);
      this.loadProfile();
      this.toastrService.success('Profile updated successfully');
    });
  }

  openModal(isEdit: boolean, schedule: any = null) {
    this.isEditMode = isEdit;
    this.selectedSchedule = schedule;

    if (isEdit && schedule) {
      // Pré-remplir le formulaire pour modification
      this.scheduleForm.patchValue({
        startDate: schedule.day,
        openingHour: schedule.openingHour,
        closingHour: schedule.closingHour,
      });
    } else {
      this.scheduleForm?.reset();
    }

    this.modalService.open(this.modalScheduler);
  }
  selectedExceptionType: any;
  openModalExceptionalDays(isEdit: boolean, exceptionType: any, schedule?: any) {
    this.isEditMode = isEdit;
    this.selectedExceptionType = exceptionType;
    this.selectedSchedule = schedule;

    if (isEdit && schedule) {
      this.scheduleExceptionalDaysForm.patchValue({
        exceptionDate: schedule.day,
        openingHour: schedule.openingHour,
        closingHour: schedule.closingHour,
        exceptionType: exceptionType,
      });
    } else {
      this.scheduleExceptionalDaysForm?.reset();
    }

    this.modalService.open(this.modalExceptionalDays);
  }

  // Fermer le modal
  closeModal() {
    this.modalService.dismissAll();
  }

  // Formater l'heure pour correspondre à "HH:mm:ss"
  formatTime(time: string): string {
    return time ? `${time}:00` : ''; // Ajouter ":00" à l'heure sélectionnée
  }
  formatTimeExceptionType(time: string): string {
    return time ? `${time}` : ''; // Ajouter ":00" à l'heure sélectionnée
  }

  // Soumettre le formulaire
  submitSchedule() {
    if (this.scheduleForm?.invalid) {
      this.toastrService.error('Veuillez remplir tous les champs');
      return;
    }

    const scheduleData = {
      id: this.isEditMode && this.selectedSchedule ? this.selectedSchedule.id : null, // Récupérer l'ID si mode modification
      openingHour: this.formatTime(this.scheduleForm?.value.openingHour), // Format HH:mm:ss
      closingHour: this.formatTime(this.scheduleForm?.value.closingHour), // Format HH:mm:ss
      startDate: this.scheduleForm?.value.startDate,
      pharmacyId: this.account.applicationUser?.pharmacy?.id, // Valeur fixe, modifiable si nécessaire
    };

    if (this.isEditMode && scheduleData.id) {
      // Modifier un horaire existant
      this.scheduleService.putBusinessHours(scheduleData.id, scheduleData).subscribe(
        response => {
          this.toastrService.success('Horaire modifié avec succès');
          this.getExceptionalDaysPeriod();
          this.closeModal();
        },
        error => {
          this.toastrService.error("Erreur lors de la modification de l'horaire");
        },
      );
    } else {
      // Ajouter un nouvel horaire
      this.scheduleService.addBusinessHours(scheduleData).subscribe(
        response => {
          this.toastrService.success('Horaire ajouté avec succès');
          this.getExceptionalDaysPeriod();
          this.closeModal();
        },
        error => {
          this.toastrService.error("Erreur lors de l'ajout de l'horaire");
        },
      );
    }
  }
  submitExceptionalDay() {
    if (this.scheduleExceptionalDaysForm?.invalid) {
      this.toastrService.error('Veuillez remplir tous les champs');
      return;
    }

    const scheduleData = {
      id: this.isEditMode && this.selectedSchedule ? this.selectedSchedule.id : null, // Récupérer l'ID si mode modification
      openingHour: this.formatTimeExceptionType(this.scheduleExceptionalDaysForm?.value.openingHour), // Format HH:mm
      closingHour: this.formatTimeExceptionType(this.scheduleExceptionalDaysForm?.value.closingHour), // Format HH:mm
      exceptionDate: this.scheduleExceptionalDaysForm?.value.exceptionDate,
      exceptionType: this.selectedExceptionType,
      pharmacyId: this.account.applicationUser?.pharmacy?.id, // Valeur fixe, modifiable si nécessaire
    };

    if (this.isEditMode && scheduleData.id) {
      // Modifier un horaire existant
      this.scheduleService.putExceptionalDays(scheduleData.id, scheduleData).subscribe(
        response => {
          this.toastrService.success('Horaire modifié avec succès');
          this.closeModal();
        },
        error => {
          this.toastrService.error("Erreur lors de la modification de l'horaire");
        },
      );
    } else {
      // Ajouter un nouvel horaire
      this.scheduleService.addExceptionalDays(scheduleData).subscribe(
        response => {
          this.toastrService.success('Horaire ajouté avec succès');
          this.closeModal();
        },
        error => {
          this.toastrService.error("Erreur lors de l'ajout de l'horaire");
        },
      );
    }
  }
  schedules: any;
  getSchedule(pharmacyId?: any) {
    this.scheduleService.getBusinessHours(pharmacyId).subscribe({
      next: response => {
        this.schedules = response;
      },
      error: error => {
        this.toastrService.error('Erreur lors de la récupération des horaires');
      },
    });
  }
  exceptionalDays: any;
  getExceptionalDaysPeriod(pharmacyId?: any) {
    this.scheduleService.getExceptionalDaysPeriod(pharmacyId, '2024-09-01', '2024-09-30').subscribe({
      next: response => {
        this.exceptionalDays = response;
      },
      error: error => {
        this.toastrService.error('Erreur lors de la récupération des horaires');
      },
    });
  }
  formatTimeToHHhMM(time: string): string {
    if (!time) return '';
    const [hours, minutes] = time.split(':');
    return `${hours}h${minutes}`;
  }

  onDeleteClick(id_schedule: any) {
    Swal.fire({
      title: 'Êtes-vous sûr(e) de vouloir supprimer cet horaire ?',
      text: 'Cette action est irréversible !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimer !',
    }).then(result => {
      if (result.isConfirmed) {
        this.deleteSchedule(id_schedule);
      }
    });
  }
  deleteSchedule(purshase_id: any) {
    this.scheduleService.deleteBusinessHours(purshase_id).subscribe({
      next: res => {
        this.getSchedule();
      },
      error: err => {
        console.log(err);
      },
    });
  }

  onDeleteClickExceptionDate(id_schedule: any) {
    Swal.fire({
      title: 'Êtes-vous sûr(e) de vouloir supprimer cet horaire ?',
      text: 'Cette action est irréversible !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimer !',
    }).then(result => {
      if (result.isConfirmed) {
        this.deleteExceptionDate(id_schedule);
      }
    });
  }
  deleteExceptionDate(purshase_id: any) {
    this.scheduleService.deleteExceptionalDays(purshase_id).subscribe({
      next: res => {
        this.getExceptionalDaysPeriod();
      },
      error: err => {
        console.log(err);
      },
    });
  }
  displayModal: any;
  selectedDate: Date;

  onDayClick(selectedDate: Date) {
    console.log('Date sélectionnée :', selectedDate);
    const day = new Date(selectedDate).getDate();
    if (day === 15) {
      console.log('Je clique sur le jour 15');
    } else {
      console.log('Jour sélectionné :', day);
    }
  }
}
