import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../../login/login.service';

@Component({
  selector: 'jhi-stripe-connection-success',
  standalone: true,
  templateUrl: './stripe-connection-success.component.html',
  styleUrls: ['./stripe-connection-success.component.scss'],
})
export class StripeConnectionSuccessComponent {
  constructor(
    private router: Router,
    private loginService: LoginService,
  ) {}

  /**
   * Logs out the user and navigates to the specified route.
   * @param route - The route to navigate to after logging out.
   * @param queryParams - Optional query parameters for navigation.
   */
  logoutAndNavigate(route: string, queryParams: any = {}): void {
    this.loginService.logout();
    this.router.navigate([route], { queryParams });
  }

  navigateToLogin(): void {
    this.logoutAndNavigate('/login', { user: 'pharmacist' });
  }
}
