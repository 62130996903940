import { Component } from '@angular/core';

@Component({
  selector: 'jhi-legal-notices',
  standalone: true,
  imports: [],
  templateUrl: './legal-notices.component.html',
  styleUrl: './legal-notices.component.scss',
})
export class LegalNoticesComponent {}
