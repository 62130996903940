<section class="search-app mb-5" style="margin-top: 130px">
  <div class="container">
    <div class="card">
      <div class="d-flex flex-column justify-content-evenly blue-car" style="padding: 1.5rem 1rem">
        <p>
          <span class="pharm">Rechercher pharmacie </span>
        </p>
        <div class="row g-3 align-items-end">
          <div class="col-md-10 col-12">
            <label for="pharmacie" class="form-label" style="color: #3a3b3d">Pharmacie / Adresse</label>
            <input
              type="text"
              class="form-control"
              name="pharmacie"
              id="pharmacie"
              placeholder="Pharmacie ou adresse"
              [(ngModel)]="searchQuery"
              (keyup.enter)="searchPharmacy()"
            />
          </div>
          <div class="col-md-auto col-12">
            <button class="btn btn-primary w-100 success-btn" data-cy="submit" (click)="searchPharmacy()">
              <i class="fas fa-search"></i> Chercher
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="container">
  <p class="titleSearch">Pharmacies correspondant à votre recherche</p>

  <br />
  <div class="row align-items-start mw-100 w-100">
    <div class="pharmacies-list col-sm-6 col-lg-4 col mb-3" *ngFor="let pharma of pharmacy; let i = index">
      <div>
        <div *ngIf="!contentLoaded" class="card product-card" style="border: 1px solid #e5e7eb; border-radius: 16px; cursor: pointer">
          <ngx-skeleton-loader
            count="1"
            appearance="circle"
            class="prod-img-top"
            [theme]="{ width: '80px', height: '80px', 'border-radius': '10px' }"
          ></ngx-skeleton-loader>
          <div class="card-body" style="display: flex; flex-direction: column">
            <ngx-skeleton-loader count="1" appearance="line" style="width: 60%; height: 20px"></ngx-skeleton-loader>
            <div class="d-flex flex-row mb-3"></div>
            <ngx-skeleton-loader count="1" appearance="line" style="width: 40%; height: 20px"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" appearance="line" style="width: 40%; height: 20px; margin-top: 10px"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" appearance="line" style="width: 40%; height: 20px; margin-top: 10px"></ngx-skeleton-loader>
            <div class="d-flex flex-row align-items-center justify-content-between" style="margin-top: 10px">
              <div class="col-5 d-flex align-items-center flex-column"></div>
            </div>
          </div>
        </div>
        <div class="card pharmacy-card" *ngIf="contentLoaded" style="border: 1px solid #e5e7eb; border-radius: 16px">
          <img *ngIf="pharma.frontPath" [src]="imageUrl + pharma.frontPath + '/large'" class="card-img-top responsive-img" alt="Image" />
          <img *ngIf="!pharma.frontPath" src="../../content/images/NoImage.png" class="card-img-top responsive-img" alt="Image" />
          <div class="card-body" style="display: flex; flex-direction: column; background-color: #fbfbfb">
            <p [style.color]="getStatusColor(pharma.status)">
              <img *ngIf="pharma.status === 'open'" style="margin-top: -3px" src="../../content/images/icons/Ouverte.svg" alt="Ouverte" />
              <img *ngIf="pharma.status === 'closed'" style="margin-top: -3px" src="../../content/images/icons/Fermée.svg" alt="Fermée" />
              <img
                *ngIf="pharma.status === 'open_soon' || pharma.status === 'close_soon'"
                style="margin-top: -3px"
                src="../../content/images/icons/open-after.svg"
                alt="Bientôt"
              />
              {{ getStatusMessage(pharma.status) }}
            </p>

            <h5 class="card-title text-truncate">
              {{ pharma?.commercialName }}
            </h5>
            <p class="card-text address">{{ pharma?.address }}</p>
            <a class="btn btn-light d-flex delivery" *ngIf="pharma?.distance">{{ (pharma?.distance / 1000).toFixed(2) }} km</a>
            <a class="btn btn-light d-flex likeBtn">
              <img
                [src]="pharma.liked ? '../../content/images/Icon feather-heart (2).svg' : '../../content/images/Icon feather-heart (1).svg'"
                alt="Like"
              />
            </a>
            <a
              class="btn order"
              style="border: 2px solid #0078c6; border-radius: 20px; color: #0078c6; place-content: center; flex-wrap: wrap"
              (click)="openModal()"
              >Passer commande</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <p *ngIf="!pharmacy.length" style="font-size: 18px; color: #535356">Pas des pharmacies à affichier.</p>
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
</div>
<section class="about-us">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <h3 jhiTranslate="global.menu.Qui-sommes-nous-title" class="section-title">Qui sommes-nous ?</h3>
        <p jhiTranslate="global.menu.Qui-sommes-nous-parag1">
          Notre plateforme est spécialisée dans la vente et la livraison de produits parapharmaceutiques et de médicaments en ligne, offrant
          un accès rapide et sécurisé à une large gamme de produits de santé. Actifs en France, nous nous engageons à fournir des services
          fiables et de qualité, adaptés aux besoins de nos clients.
        </p>
        <p jhiTranslate="global.menu.Qui-sommes-nous-parag2">
          Nous collaborons avec des professionnels de santé et des pharmacies agréées pour garantir l'authenticité et la sécurité des
          produits proposés. Notre mission est de simplifier l'accès aux soins en ligne, tout en respectant les réglementations en vigueur,
          pour assurer le bien-être de nos utilisateurs.
        </p>
      </div>
      <div class="col-sm-12 col-md-6">
        <img
          src="https://imagedelivery.net/z1UHS7Z96nNnNVE8sIpBxg/0396868f-4735-43cf-c916-59b83b2aa600/public"
          alt="Pharmacy Image"
          class="img-fluid"
        />
      </div>
    </div>
  </div>
</section>

<ng-template #modalMaps let-modalMaps>
  <div class="modal-product">
    <div class="modal-header">
      <div class="col-11 d-flex justify-content-center"></div>
      <div class="col-1 d-flex align-items-end flex-column">
        <button type="button" class="close" (click)="modalMaps.dismiss('Cross click')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      <div class="loginCard">
        <h1 data-cy="loginTitle" class="loginTitle">Connectez-vous pour accédés</h1>
        <!-- @if (authenticationError) {
              <div class="alert alert-danger" jhiTranslate="login.messages.error.authentication" data-cy="loginError">
                <strong>Failed to sign in!</strong> Please check your credentials and try again.
              </div>
            } -->
        <form class="form" role="form" (ngSubmit)="login()" [formGroup]="loginForm">
          <div class="">
            <label class="username-label" for="username" jhiTranslate="global.form.username.label">Username</label>
            <input type="text" class="form-control" name="username" id="username" formControlName="username" #username data-cy="username" />
            @if (loginForm.get('username')!.invalid && (loginForm.get('username')!.dirty || loginForm.get('username')!.touched)) {
              <div>
                @if (loginForm.get('username')?.errors?.required) {
                  <small class="form-text text-danger" jhiTranslate="global.messages.validate.email.required"
                    >Your username is required.</small
                  >
                }
                @if (loginForm.get('username')?.errors?.invalid) {
                  <small class="form-text text-danger" jhiTranslate="global.messages.validate.email.invalid"
                    >Your username is invalid.</small
                  >
                }
              </div>
            }
          </div>
          <br />
          <label for="password" jhiTranslate="login.form.password">Password</label>
          <input type="password" class="form-control" name="password" id="password" formControlName="password" data-cy="password" />
          <div *ngIf="loginForm.get('password')!.invalid && (loginForm.get('password')!.dirty || loginForm.get('password')!.touched)">
            <div *ngIf="loginForm.get('password')?.errors?.required">
              <small class="form-text text-danger" jhiTranslate="global.messages.validate.newpassword.required"
                >Your password is required.</small
              >
            </div>
          </div>
          <br />
          <div class="form-group d-flex justify-content-between mb-3 flex-wrap">
            <div class="form-check">
              <label class="form-check-label" for="rememberMe">
                <input class="form-check-input" type="checkbox" name="rememberMe" id="rememberMe" formControlName="rememberMe" />
                <span jhiTranslate="login.form.rememberme">Remember me</span>
              </label>
            </div>
            <div>
              <a
                class="alert-link"
                routerLink="/account/reset/request"
                jhiTranslate="login.password.forgot"
                data-cy="forgetYourPasswordSelector"
                (click)="modalMaps.dismiss('Cross click')"
                >Did you forget your password?</a
              >
            </div>
          </div>
          <button
            type="submit"
            class="col-12 btn btn-primary success-btn"
            jhiTranslate="login.form.button"
            (click)="modalMaps.dismiss('Cross click')"
            data-cy="submit"
          >
            Sign in
          </button>
        </form>
        <div class="d-flex justify-content-center m-3 flex-wrap">
          <label jhiTranslate="global.messages.info.register.noaccount">Vous n'avez pas encore de compte ?</label>&nbsp;<a
            class="alert-link"
            routerLink="/account/register"
            [queryParams]="{ user: 'client' }"
            jhiTranslate="global.messages.info.register.link"
            (click)="modalMaps.dismiss('Cross click')"
            >sign in</a
          >.
        </div>
      </div>
    </div>
  </div>
</ng-template>
