import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  getKpis(pharmacy_id: any) {
    return this.http.get<any>(`${environment.baseUrl}api/dashboard/pharmacy/${pharmacy_id}/kpis`);
  }
  getRevenue(pharmacy_id: any) {
    return this.http.get<any>(`${environment.baseUrl}api/dashboard/pharmacy/${pharmacy_id}/revenue`);
  }
  getTopCategories(pharmacy_id: any) {
    return this.http.get<any>(`${environment.baseUrl}api/dashboard/pharmacy/${pharmacy_id}/top-categories`);
  }
}
