<ng-container *ngIf="isUserLoaded; else loadingContent">
  <div *ngIf="account?.authorities?.includes('ROLE_USER') || (1 && !disable); else guestContent">
    <jhi-navbar></jhi-navbar>
    <div class="container" style="margin-top: 5rem">
      <div class="row">
        <div class="col-sm-12 col-lg-12 col-xl-3">
          <jhi-side-bar [isInHome]="currentUrl"></jhi-side-bar>
        </div>
        <div [ngClass]="currentUrl?.includes('/cart') ? 'right-section' : 'col-lg-12 col-xl-9 right-section'">
          <div [hidden]="currentUrl?.includes('/cart')" class="pubImage" style="margin-top: 48px; margin-bottom: -10px">
            <img src="../../../content/images/Groupe-de-masques.png" alt="" />
          </div>
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
    <jhi-footer></jhi-footer>
  </div>

  <ng-template #guestContent>
    <jhi-navbar></jhi-navbar>
    <div class="d-flex flex-column">
      <div [ngStyle]="{ padding: !disable ? '3rem 0rem 0rem' : '' }">
        <router-outlet></router-outlet>
      </div>
      <jhi-footer></jhi-footer>
    </div>
  </ng-template>
</ng-container>

<ng-template #loadingContent>
  <div><jhi-loader></jhi-loader></div>
</ng-template>
