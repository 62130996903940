import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HomeServiceService } from '../../../home/service/home-service.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ToastrService } from 'ngx-toastr';
import { PaginatorModule } from 'primeng/paginator';
import { debounceTime, distinctUntilChanged, Subject, switchMap } from 'rxjs';
import { CartService } from 'app/cart/cart.service';
import { BrowserModule } from '@angular/platform-browser';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}
@Component({
  selector: 'jhi-configuration',
  standalone: true,
  imports: [CommonModule, NgxSkeletonLoaderModule, PaginatorModule, ReactiveFormsModule],
  templateUrl: './configuration.component.html',
  styleUrl: './configuration.component.scss',
})
export class ConfigurationComponent {
  @ViewChild('modalProduct') modalProduct: any;

  stockItems: any;
  contentLoaded = false;
  selectedFile: File | null = null;
  searchInputSubject = new Subject<string>();
  first: number = 0;
  rows: number = 1;
  totalRecords: number = 5;
  imageUrl: string = 'https://imagedelivery.net/z1UHS7Z96nNnNVE8sIpBxg/';
  isPromoActive: boolean = false;
  productForm: FormGroup;

  constructor(
    private pharmacieService: HomeServiceService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private cartService: CartService,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.contentLoaded = true;
    }, 2000);
    this.getStock();
    this.searchInputSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(searchName => {
          const data = {
            contactName: searchName.trim().toLowerCase(),
          };
          console.log('%centreprises.component.ts line:203 data search', 'color: #007acc;', data);
          return this.pharmacieService.searchStockPharmacist(searchName.trim().toLowerCase()).pipe();
        }),
      )
      .subscribe((res: any) => {
        this.stockItems = res;
      });
    this.initProductForm();
  }
  onPageChange(event: any) {
    const pageEvent: PageEvent = {
      first: event.first ?? 0,
      rows: event.rows ?? 1,
      page: event.page ?? 0,
      pageCount: event.pageCount ?? 0,
    };

    this.first = pageEvent.first;
    this.rows = pageEvent.rows;
    this.getStock();
  }
  getStock() {
    this.pharmacieService.getListStockItems(this.first).subscribe({
      next: res => {
        this.stockItems = res;
        // this.totalRecords = res.totalPages;
        this.initProductForm();

        console.log(res);
      },
      error: err => {
        console.log(err);
      },
    });
  }
  onFileChange(event: any) {
    this.selectedFile = event.target.files[0];
  }

  onUpload() {
    if (this.selectedFile) {
      const formData = new FormData();
      formData.append('file', this.selectedFile);

      this.pharmacieService.uploadStockItems(formData).subscribe(
        response => {
          console.log('File uploaded successfully', response);
          this.toastr.success('File uploaded successfully!');
          this.getStock();
          this.selectedFile = null;
        },
        error => {
          console.error('Error uploading file', error);
          this.selectedFile = null;
          this.toastr.error('Error uploading file. Please try again.');
        },
      );
    } else {
      console.error('No file selected');
      this.toastr.warning('No file selected. Please select a file to upload.');
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchInputSubject.next(filterValue);
  }
  openModal(modal: any) {
    this.modalService.open(modal, { size: 'lg', centered: true });
  }
  openedProduct: any;
  value: number = 1;
  isExpanded = false;
  isExpandedDescription = false;
  activeTab: string = 'composition';

  openproductModal(modal: any, product: any) {
    this.modalService.open(modal, { size: 'lg', centered: true });
    this.openedProduct = product;
    this.value = this.cartService.getCurrent(product);
    this.initProductForm();
  }
  setActiveTab(tab: string) {
    this.activeTab = tab;
  }
  increment() {
    this.value++;
  }
  decrement() {
    if (this.value > 1) {
      this.value--;
    }
  }
  initProductForm(): void {
    this.productForm = this.fb.group({
      price: [this.openedProduct?.price || 0, Validators.required],
      priceDiscount: [null],
      availableStock: [this.openedProduct?.availableStock || 0, Validators.required],
      discount: [null],
    });
  }

  togglePromo(): void {
    this.isPromoActive = !this.isPromoActive;
    if (!this.isPromoActive) {
      this.productForm?.get('priceDiscount')?.reset(); // Réinitialise le champ promo si désactivé
      this.productForm?.get('discount')?.reset();
    }
  }
  updateStockItem(productId: any): void {
    const stockItemData = {
      id: productId,
      price: this.productForm.value.price,
      priceDiscount: this.isPromoActive ? this.productForm.value.priceDiscount : null,
      availableStock: this.productForm.value.availableStock,
      discount: this.isPromoActive ? this.productForm.value.discount : null,
    };

    this.pharmacieService.updateStockItem(stockItemData).subscribe({
      next: response => {
        console.log('Produit mis à jour avec succès', response);
        this.toastr.success('Produit mis à jour avec succès');
      },
      error: error => {
        console.error('Erreur lors de la mise à jour du produit', error);
        this.toastr.error('Erreur lors de la mise à jour du produit');
      },
    });
  }
  toggleExpandDescription() {
    this.isExpandedDescription = !this.isExpandedDescription;
  }
  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }
}
