import { Component, OnInit, RendererFactory2, Renderer2 } from '@angular/core';
import { RouterOutlet, Router, NavigationEnd, RouterModule } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import dayjs from 'dayjs/esm';

import { AccountService } from '../../core/auth/account.service';
import { AppPageTitleStrategy } from '../../app-page-title-strategy';
import FooterComponent from '../footer/footer.component';
import PageRibbonComponent from '../profiles/page-ribbon.component';
import { filter } from 'rxjs';
import { CommonModule } from '@angular/common';
import { SideBarComponent } from '../side-bar/side-bar.component';
import NavbarComponent from '../navbar/navbar.component';
import { Account } from '../../core/auth/account.model';
import SharedModule from '../../shared/shared.module';

@Component({
  selector: 'jhi-main',
  standalone: true,
  templateUrl: './main.component.html',
  providers: [AppPageTitleStrategy],
  imports: [
    RouterOutlet,
    FooterComponent,
    PageRibbonComponent,
    CommonModule,
    SideBarComponent,
    NavbarComponent,
    RouterModule,
    SharedModule,
  ],
})
export default class MainComponent implements OnInit {
  disable = false;
  renderer: Renderer2;
  account: Account | null = null;
  isUserLoaded = false;
  currentUrl: any;
  constructor(
    private router: Router,
    private appPageTitleStrategy: AppPageTitleStrategy,
    private accountService: AccountService,
    private translateService: TranslateService,
    rootRenderer: RendererFactory2,
  ) {
    this.renderer = rootRenderer.createRenderer(document.querySelector('html'), null);
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      this.disable =
        event.url.includes('/') ||
        event.url.includes('/account/register') ||
        event.url.includes('/login') ||
        event.url.includes('/account/activate') ||
        // event.url.includes('/success') ||
        event.url.includes('type');
      this.currentUrl = event.url;
    });
  }

  ngOnInit(): void {
    this.accountService.identity().subscribe(() => {
      this.isUserLoaded = true;
    });

    this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
      this.appPageTitleStrategy.updateTitle(this.router.routerState.snapshot);
      dayjs.locale(langChangeEvent.lang);
      this.renderer.setAttribute(document.querySelector('html'), 'lang', langChangeEvent.lang);
    });

    this.accountService.getAuthenticationState().subscribe(account => {
      this.account = account;
      if (
        (!this.account?.applicationUser || !this.account.applicationUser.proProfileCompleted) &&
        this.account?.authorities.includes('ROLE_PHARMACIST')
      ) {
        this.router.navigate(['/login'], { queryParams: { user: 'completeCreation' } });
      }
    });
  }
}
