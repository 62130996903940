import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApplicationConfigService } from '../../core/config/application-config.service';
import { Registration } from './register.model';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class RegisterService {
  constructor(
    private http: HttpClient,
    private applicationConfigService: ApplicationConfigService,
  ) {}

  save(registration: Registration): Observable<{}> {
    return this.http.post(this.applicationConfigService.getEndpointFor('api/register'), registration);
  }
  validatToken(key: any): Observable<{}> {
    return this.http.post(`${environment.baseUrl}api/invitations/validate?token=${key}`, {});
  }
  createStripeAccount(user_id: any): Observable<{}> {
    return this.http.post(`${environment.baseUrl}api/stripe/create-stripe-account-link/${user_id}`, {});
  }
  decodeToken(token: string): any | null {
    try {
      const parts = token.split('.'); // Sépare le token en header, payload, et signature
      if (parts.length !== 3) {
        throw new Error('Token JWT invalide');
      }
      const payload = parts[1]; // Récupère la partie payload
      const decodedPayload = atob(payload); // Décode la chaîne Base64
      return JSON.parse(decodedPayload); // Convertit la chaîne JSON en objet
    } catch (error) {
      console.error('Erreur lors du décodage du token:', error);
      return null;
    }
  }
  private credentials: { email: string; password: string } | null = null;

  setCredentials(email: string, password: string): void {
    this.credentials = { email, password };
  }

  getCredentials(): { email: string; password: string } | null {
    return this.credentials;
  }

  clearCredentials(): void {
    this.credentials = null;
  }
}
