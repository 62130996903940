import { Component, OnInit, ViewChild, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Account } from '../../../core/auth/account.model';
import { AccountService } from '../../../core/auth/account.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ScheduleService } from '../../../pharmaBackOffice/services/Schedule/schedule.service';
import { CommonModule } from '@angular/common';
import Swal from 'sweetalert2';
import { CalendarModule } from 'primeng/calendar';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';

interface TimeOrderValidationErrors {
  invalidTime1?: string;
  invalidTime2?: string;
  [key: string]: string | undefined; // Allow additional error messages
}

interface Day {
  isWeekEnd: boolean;
  isHoliday: boolean;
  isExceptionalDay: boolean;
  exceptionalDay?: {
    exceptionType: 'HOLIDAY' | 'NIGHT_ON_DUTY' | 'CUSTOM_EXCEPTION' | 'OTHER';
  };
}
interface CalendarDay {
  day: string;
  isExceptionalDay: boolean;
  isWeekEnd: boolean;
}
@Component({
  selector: 'jhi-pharmacy-account',
  standalone: true,
  imports: [FormsModule, NgbModule, NgbDropdownModule, CommonModule, ReactiveFormsModule, CalendarModule],
  templateUrl: './pharmacy-account.component.html',
  styleUrl: './pharmacy-account.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [{ provide: LOCALE_ID, useValue: 'fr-FR' }],
})
export class PharmacyAccountComponent implements OnInit {
  @ViewChild('modalScheduler') modalScheduler: any;
  @ViewChild('modalExceptionalDays') modalExceptionalDays: any;

  date: Date[] | undefined;
  profileImageUrl: string | ArrayBuffer | null = null;
  account!: Account;
  scheduleForm: FormGroup;
  scheduleExceptionalDaysForm: FormGroup;

  isEditMode = false;
  selectedSchedule: any = null;
  displayModal: any;
  selectedDate: Date;
  currentDate = dayjs();
  today = dayjs();
  daysInMonth: CalendarDay[];
  prevMonthDays: number[] = [];
  nextMonthDays: number[] = [];
  monthYear: string = '';
  weekNames: string[] = ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'];
  calendarData: { [key: string]: CalendarDay };
  exceptionalDays: any;
  exceptionalDaysNIGHT_ON_DUTY: any;
  exceptionalDaysHOLIDAY: any;
  exceptionalDaysCUSTOM_EXCEPTION: any;
  pharmacyId: any;
  phoneNumber: any;
  selectedExceptionType: any;
  schedules: any;
  isSplitExceptionalDay: boolean = false;
  isSplit: boolean = false;

  constructor(
    private fb: FormBuilder,
    private scheduleService: ScheduleService,
    private accountService: AccountService,
    private toastrService: ToastrService,
    private modalService: NgbModal,
  ) {
    this.scheduleForm = this.fb.group(
      {
        sessionCount: [1, Validators.required],
        openingHour: ['', Validators.required],
        closingHour: ['', Validators.required],
        openingHour2: [''],
        closingHour2: [''],
      },
      { validators: this.timeOrderValidator },
    );
    this.scheduleExceptionalDaysForm = this.fb.group(
      {
        exceptionDate: ['', Validators.required],
        sessionCount: [1, Validators.required],
        openingHour: ['', Validators.required],
        closingHour: ['', Validators.required],
        openingHour2: [''],
        closingHour2: [''],
        exceptionType: [''],
      },
      { validators: this.timeOrderValidator },
    );
  }

  ngOnInit(): void {
    this.loadProfile();
  }
  timeOrderValidator(control: AbstractControl): ValidationErrors | null {
    const openingHour = control.get('openingHour')?.value;
    const closingHour = control.get('closingHour')?.value;
    const openingHour2 = control.get('openingHour2')?.value;
    const closingHour2 = control.get('closingHour2')?.value;
    const sessionCount = control.get('sessionCount')?.value;

    const errors: ValidationErrors = {};

    if (openingHour && closingHour && openingHour >= closingHour) {
      errors.invalidTime1 = "L'heure d'ouverture doit être inférieure à l'heure de fermeture.";
    }

    if (sessionCount === 2 && openingHour2 && closingHour2 && openingHour2 >= closingHour2) {
      errors.invalidTime2 = "L'heure d'ouverture (2ème séance) doit être inférieure à l'heure de fermeture.";
    }

    return Object.keys(errors).length ? errors : null;
  }
  isToday(day: string): boolean {
    return dayjs(day).isSame(this.today, 'day');
  }
  getCalendarData(pharmacyId: any): void {
    const fromdate = this.currentDate.startOf('month').format('YYYY-MM-DD');
    const todate = this.currentDate.endOf('month').format('YYYY-MM-DD');

    this.scheduleService.getAllCalendar(pharmacyId, fromdate, todate).subscribe(data => {
      this.calendarData = data;
      this.plotDays();
    });
  }

  plotDays(): void {
    this.daysInMonth = Array.from({ length: this.currentDate.daysInMonth() }, (_, i): CalendarDay => {
      const day = this.currentDate.date(i + 1).format('YYYY-MM-DD');
      return this.calendarData[day];
    });

    const firstDayPosition = this.currentDate.startOf('month').day() || 7;
    const prevMonthLastDate = this.currentDate.subtract(1, 'month').endOf('month').date();
    this.prevMonthDays = Array.from({ length: firstDayPosition - 1 }, (_, i) => prevMonthLastDate - i).reverse();
    const totalDays = 42 - (this.daysInMonth.length + this.prevMonthDays.length);
    this.nextMonthDays = Array.from({ length: totalDays }, (_, i) => i + 1);

    this.updateMonthYear();
  }

  updateMonthYear(): void {
    this.monthYear = `${this.currentDate.locale('fr').format('MMMM')} - ${this.currentDate.year()}`;
  }

  nextMonth(): void {
    this.currentDate = this.currentDate.add(1, 'month');
    this.loadProfile();
  }

  prevMonth(): void {
    this.currentDate = this.currentDate.subtract(1, 'month');
    this.loadProfile();
  }

  goToToday(): void {
    this.currentDate = dayjs();
    this.loadProfile();
  }

  isWeekend(day: Day | null | undefined): boolean {
    return day?.isWeekEnd ?? false;
  }

  isHoliday(day: Day | null | undefined): boolean {
    return day?.isHoliday ?? false;
  }

  isExceptionalDay(day: Day): string | null {
    if (day.isExceptionalDay && day.exceptionalDay) {
      const type = day.exceptionalDay.exceptionType;
      switch (type) {
        case 'HOLIDAY':
          return 'exception-holiday';
        case 'NIGHT_ON_DUTY':
          return 'exception-night-on-duty';
        case 'CUSTOM_EXCEPTION':
        case 'OTHER':
          return 'exception-custom';
        default:
          return null;
      }
    }
    return null;
  }
  getDayClasses(day: any): { [key: string]: boolean } {
    const exceptionalDay = this.isExceptionalDay(day);
    const dayClasses: { [key: string]: boolean } = {
      weekend: this.isWeekend(day),
      holiday: this.isHoliday(day),
      'today-date': this.isToday(day.day),
      normalDay: !this.isWeekend(day) && !this.isHoliday(day) && !exceptionalDay,
    };
    if (exceptionalDay) {
      dayClasses[exceptionalDay] = true;
    }
    return dayClasses;
  }

  loadProfile(): void {
    this.accountService.getAuthenticatedUser().subscribe(res => {
      this.account = res;
      this.pharmacyId = this.account.applicationUser.pharmacy?.id;
      this.phoneNumber = this.account.applicationUser.pharmacy?.phoneNumber;
      this.getSchedule(this.pharmacyId);
      this.getExceptionalDaysPeriod(this.pharmacyId);
      this.getCalendarData(this.pharmacyId);
    });
  }

  updateProfile(): void {
    this.accountService.save(this.account).subscribe(res => {
      this.accountService.setAccount(this.account);
      this.loadProfile();
      this.toastrService.success('Profile updated successfully');
    });
  }

  openModal(isEdit: boolean, schedule: any = null): void {
    this.isEditMode = isEdit;
    this.selectedSchedule = schedule;

    if (isEdit && schedule) {
      this.scheduleForm.patchValue({
        openingHour: schedule.openingHour,
        closingHour: schedule.closingHour,
        openingHour2: schedule.openingHour2,
        closingHour2: schedule.closingHour2,
      });
    } else {
      this.scheduleForm.reset();
    }

    this.modalService.open(this.modalScheduler);
  }
  openModalExceptionalDays(isEdit: boolean, exceptionType: any, schedule?: any): void {
    this.isEditMode = isEdit;
    this.selectedExceptionType = exceptionType;
    this.selectedSchedule = schedule;

    if (isEdit && schedule) {
      this.scheduleExceptionalDaysForm.patchValue({
        exceptionDate: schedule.exceptionDate,
        openingHour: schedule.openingHour,
        closingHour: schedule.closingHour,
        openingHour2: schedule.openingHour2,
        closingHour2: schedule.closingHour2,
        exceptionType,
      });
    } else {
      this.scheduleExceptionalDaysForm.reset();
    }

    this.modalService.open(this.modalExceptionalDays);
  }

  // Fermer le modal
  closeModal(): void {
    this.modalService.dismissAll();
  }

  formatTime(time: string): string {
    return time ? `${time}` : '';
  }
  formatTimeExceptionType(time: string): string {
    return time ? `${time}` : '';
  }
  onSessionChange(isSplit: boolean): void {
    this.isSplit = isSplit;
    if (!isSplit) {
      this.scheduleForm.patchValue({
        openingHour2: null,
        closingHour2: null,
      });
    }
  }

  onSessionChangeExceptionalDay(isSplitExceptionalDay: boolean): void {
    this.isSplitExceptionalDay = isSplitExceptionalDay;

    // Optionally, reset second session fields if not split
    if (!isSplitExceptionalDay) {
      this.scheduleExceptionalDaysForm.patchValue({
        openingHour2: null,
        closingHour2: null,
      });
    }
  }
  // Soumettre le formulaire
  submitSchedule(): void {
    if (this.scheduleForm.invalid) {
      this.toastrService.error('Veuillez remplir tous les champs');
      return;
    }

    const scheduleData = {
      id: this.isEditMode && this.selectedSchedule ? this.selectedSchedule.id : null, // Récupérer l'ID si mode modification
      openingHour: this.scheduleForm.value.openingHour, // Format HH:mm:ss
      closingHour: this.scheduleForm.value.closingHour, // Format HH:mm:ss
      startDate: this.scheduleForm.value.startDate,
      pharmacyId: this.account.applicationUser.pharmacy?.id, // Valeur fixe, modifiable si nécessaire
      isSplit: this.isSplit, // Add isSplit to the data
      openingHour2: this.isSplit ? this.formatTime(this.scheduleForm.value.openingHour2) : null, // Include second session if split
      closingHour2: this.isSplit ? this.formatTime(this.scheduleForm.value.closingHour2) : null, // Include second session if split
    };

    if (this.isEditMode && scheduleData.id) {
      // Modifier un horaire existant
      this.scheduleService.putBusinessHours(scheduleData.id, scheduleData).subscribe(
        response => {
          this.toastrService.success('Horaire modifié avec succès');
          this.loadProfile();
          this.closeModal();
        },
        error => {
          this.toastrService.error("Erreur lors de la modification de l'horaire");
        },
      );
    } else {
      // Ajouter un nouvel horaire
      this.scheduleService.addBusinessHours(scheduleData).subscribe(
        response => {
          this.toastrService.success('Horaire ajouté avec succès');
          this.loadProfile();
          this.closeModal();
        },
        error => {
          this.toastrService.error("Erreur lors de l'ajout de l'horaire");
        },
      );
    }
  }
  submitExceptionalDay(): void {
    if (this.scheduleExceptionalDaysForm.invalid) {
      this.toastrService.error('Veuillez remplir tous les champs');
      return;
    }

    const scheduleData = {
      id: this.isEditMode && this.selectedSchedule ? this.selectedSchedule.id : null, // Récupérer l'ID si mode modification
      openingHour: this.formatTimeExceptionType(this.scheduleExceptionalDaysForm.value.openingHour), // Format HH:mm
      closingHour: this.formatTimeExceptionType(this.scheduleExceptionalDaysForm.value.closingHour), // Format HH:mm
      isSplit: this.isSplitExceptionalDay, // Add isSplit to the data
      openingHour2: this.isSplitExceptionalDay ? this.formatTimeExceptionType(this.scheduleExceptionalDaysForm.value.openingHour2) : null, // Include second session if split
      closingHour2: this.isSplitExceptionalDay ? this.formatTimeExceptionType(this.scheduleExceptionalDaysForm.value.closingHour2) : null, // Include second session if split
      exceptionDate: this.scheduleExceptionalDaysForm.value.exceptionDate,
      exceptionType: this.selectedExceptionType,
      pharmacyId: this.account.applicationUser.pharmacy?.id, // Valeur fixe, modifiable si nécessaire
    };

    if (this.isEditMode && scheduleData.id) {
      // Modifier un horaire existant
      this.scheduleService.putExceptionalDays(scheduleData.id, scheduleData).subscribe(
        response => {
          this.toastrService.success('Horaire modifié avec succès');
          this.loadProfile();
          this.closeModal();
        },
        error => {
          this.toastrService.error("Erreur lors de la modification de l'horaire");
        },
      );
    } else {
      // Ajouter un nouvel horaire
      this.scheduleService.addExceptionalDays(scheduleData).subscribe(
        response => {
          this.toastrService.success('Horaire ajouté avec succès');
          this.loadProfile();
          this.closeModal();
        },
        error => {
          this.toastrService.error("Erreur lors de l'ajout de l'horaire");
        },
      );
    }
  }

  getSchedule(pharmacyId?: any): void {
    this.scheduleService.getBusinessHours(pharmacyId).subscribe({
      next: response => {
        this.schedules = response;
      },
      error: error => {
        this.toastrService.error('Erreur lors de la récupération des horaires');
      },
    });
  }

  getExceptionalDaysPeriod(pharmacyId?: any): void {
    const fromdate = this.currentDate.startOf('month').format('YYYY-MM-DD');
    const todate = this.currentDate.endOf('month').format('YYYY-MM-DD');
    this.scheduleService.getExceptionalDaysPeriod(pharmacyId, fromdate, todate).subscribe({
      next: response => {
        this.exceptionalDays = response;
        this.exceptionalDaysNIGHT_ON_DUTY = response.filter((day: any) => day.exceptionType === 'NIGHT_ON_DUTY');
        this.exceptionalDaysCUSTOM_EXCEPTION = response.filter((day: any) => day.exceptionType === 'CUSTOM_EXCEPTION');

        this.exceptionalDaysHOLIDAY = response.filter((day: any) => day.exceptionType === 'HOLIDAY');
      },
      error: error => {
        this.toastrService.error('Erreur lors de la récupération des horaires');
      },
    });
  }
  formatTimeToHHhMM(time: string): string {
    if (!time) {
      return '';
    }
    const [hours, minutes] = time.split(':');
    return `${hours}h${minutes}`;
  }

  onDeleteClick(id_schedule: any): void {
    Swal.fire({
      title: 'Êtes-vous sûr(e) de vouloir supprimer cet horaire ?',
      text: 'Cette action est irréversible !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimer !',
    }).then(result => {
      if (result.isConfirmed) {
        this.deleteSchedule(id_schedule);
      }
    });
  }
  deleteSchedule(purshase_id: any): void {
    this.scheduleService.deleteBusinessHours(purshase_id).subscribe({
      next: res => {
        this.getSchedule();
      },
    });
  }

  onDeleteClickExceptionDate(id_schedule: any): void {
    Swal.fire({
      title: 'Êtes-vous sûr(e) de vouloir supprimer cet horaire ?',
      text: 'Cette action est irréversible !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimer !',
    }).then(result => {
      if (result.isConfirmed) {
        this.deleteExceptionDate(id_schedule);
      }
    });
  }
  deleteExceptionDate(purshase_id: any): void {
    this.scheduleService.deleteExceptionalDays(purshase_id).subscribe({
      next: res => {
        this.loadProfile();
      },
    });
  }

  onDayClick(selectedDate: Date): void {
    const day = new Date(selectedDate).getDate();
  }
}
