export const environment = {
  production: false,
  baseUrlImage: 'http://test.pharma-go.fr/file/..',
  baseUrl: 'https://test.pharma-go.fr/',
  googleMapsApiKey: 'AIzaSyCRcEr5d8ePuI2b8V3MPFCLSrMDC1hg-z0',
  firebase: {
    apiKey: 'AIzaSyBhE5Yxyu1UIlOZ_zE5V5myQ3SQlsQzmB8',
    authDomain: 'pharmago-c0a58.firebaseapp.com',
    projectId: 'pharmago-c0a58',
    storageBucket: 'pharmago-c0a58.appspot.com',
    messagingSenderId: '392409823744',
    appId: '1:392409823744:web:9241439f50c20a7412f6e5',
    measurementId: 'G-P798YF9VB7',
    vapidKey: 'BArGAWt5xpJy_bVVizRsy2VsH700HlbLzqSdbfXu61vEw5CKeibe9TXbba9HoDApSrMXu8rnE9dEI2UPLJwDAmg',
  },
  awsConfig: {
    accessKeyId: '4490a585d93345348725fc091e1bdcd2',
    secretAccessKey: 'd094bb9ad86c412dac93ce699e7807aa',
    region: 'sbg',
    endpoint: 'https://s3.sbg.io.cloud.ovh.net/',
    s3ForcePathStyle: true,
  },
  cdnConfig: {
    fontAwesome: {
      url: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css',
      integrity: 'sha512-SnH5WK+bZxgPHs44uWIX+LLJAJ9/2PkPKZ5QiAj6Ta86w+fsb2TkcmfRyVX3pBnMFcV7oQPJkl9QevSCWr3W6A==',
      version: '6.5.2',
    },
    googleMaps: {
      apiKey: 'AIzaSyCRcEr5d8ePuI2b8V3MPFCLSrMDC1hg-z0',
      url: 'https://maps.googleapis.com/maps/api/js',
    },
  },
  SOCKET_URL: 'https://chat-test.pharma-go.fr',
};
