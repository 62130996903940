<div class="row mt-5 mb-4">
  <div class="col-sm-12">
    <h2 class="lead" style="color: #141414">Tableau de bord</h2>
  </div>
</div>
<div class="dashboard">
  <div class="card">
    <h1 class="value green">{{ dataKpis?.currentMonthRevenue }} €</h1>
    <p>Chiffre d'affaire</p>
    <p class="green" style="font-size: 13px">{{ dataKpis?.revenueGrowth }} <span class="rapport">par rapport au mois dernier</span></p>
  </div>
  <div class="card">
    <h1 class="value blue">{{ dataKpis?.currentMonthPaidOrders }}</h1>
    <p>Nombre des commandes</p>
    <p class="blue" style="font-size: 13px">{{ dataKpis?.paidOrdersGrowth }} <span class="rapport">par rapport au mois dernier</span></p>
  </div>
  <div class="card">
    <h1 class="value green">{{ dataKpis?.currentMonthProductsSold }}</h1>
    <p>Nombre d'articles</p>
    <p class="green" style="font-size: 13px">{{ dataKpis?.productsSoldGrowth }} <span class="rapport">par rapport au mois dernier</span></p>
  </div>
  <div class="card">
    <h1 class="value blue">{{ dataKpis?.currentMonthNewClients }}</h1>
    <p>Nouveaux clients</p>
    <p class="blue" style="font-size: 13px">{{ dataKpis?.newClientsGrowth }} <span class="rapport">par rapport au mois dernier</span></p>
  </div>
</div>

<div class="row">
  <div class="col">
    <div id="chart1" *ngIf="chartOptions1">
      <apx-chart
        [series]="chartOptions1.series"
        [chart]="chartOptions1.chart"
        [xaxis]="chartOptions1.xaxis"
        [stroke]="chartOptions1.stroke"
        [tooltip]="chartOptions1.tooltip"
        [dataLabels]="chartOptions1.dataLabels"
      ></apx-chart>
    </div>
  </div>
  <div class="col">
    <div id="chart2" *ngIf="chartOptions2">
      <apx-chart
        [series]="chartOptions2.series"
        [chart]="chartOptions2.chart"
        [dataLabels]="chartOptions2.dataLabels"
        [plotOptions]="chartOptions2.plotOptions"
        [yaxis]="chartOptions2.yaxis"
        [legend]="chartOptions2.legend"
        [fill]="chartOptions2.fill"
        [stroke]="chartOptions2.stroke"
        [tooltip]="chartOptions2.tooltip"
        [xaxis]="chartOptions2.xaxis"
      ></apx-chart>
    </div>
  </div>
</div>
