import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, catchError, filter, map, throwError, Subject } from 'rxjs';
import * as AWS from 'aws-sdk';
interface GeocodeResponse {
  results: { formatted_address: string }[];
}
interface Address {
  id: number;
  longitude: string;
  latitude: string;
}
@Injectable({
  providedIn: 'root',
})
export class HomeServiceService {
  selectedAddressIdSubject = new BehaviorSubject<number | null>(null);
  selectedAddressId$ = this.selectedAddressIdSubject.asObservable();

  selectedAddressCoordsSubject = new BehaviorSubject<{ lat: number; lng: number } | null>(null);
  selectedAddressCoords$ = this.selectedAddressCoordsSubject.asObservable();

  selectedAddressSubject = new BehaviorSubject<Address | null>(null);
  selectedAddress$ = this.selectedAddressSubject.asObservable();

  addressUpdatedSubject = new BehaviorSubject<void>(undefined);
  addressUpdated$ = this.addressUpdatedSubject.asObservable();

  addressChangeSubject = new Subject<void>();
  addressChange$ = this.addressChangeSubject.asObservable();

  s3: AWS.S3;
  id: string | null = null;
  idSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  pharmacyIdSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  pharmacyDataSubject = new BehaviorSubject<any>(null);
  pharmacyData$ = this.pharmacyDataSubject.asObservable();
  pharmacyIdSubjectData$ = this.pharmacyIdSubject.asObservable();
  addressSubject = new BehaviorSubject<{ lat: number; lng: number } | null>(null);

  commercialName!: string;
  parmacyId: string;
  catName!: string;
  subCatName!: string;
  subCatId: string;
  likeCounterSource = new BehaviorSubject<number>(0);
  likeCounter$ = this.likeCounterSource.asObservable();
  selectedCategory: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cookieService: CookieService,
  ) {
    const storedAddressId = localStorage.getItem('selectedAddressId');
    if (storedAddressId) {
      this.selectedAddressIdSubject.next(parseInt(storedAddressId, 10));
    }
    const storedAddressCoords = localStorage.getItem('selectedAddressCoords');
    if (storedAddressCoords) {
      this.selectedAddressCoordsSubject.next(JSON.parse(storedAddressCoords));
    }
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      let route = this.activatedRoute.root;
      while (route.firstChild) {
        route = route.firstChild;
      }
      route.params.subscribe(params => {
        this.id = params['pharmacieId'] || null;
        this.idSubject.next(this.id);
      });
    });
    AWS.config.update({
      accessKeyId: environment.awsConfig.accessKeyId,
      secretAccessKey: environment.awsConfig.secretAccessKey,
      region: environment.awsConfig.region,
    });

    this.s3 = new AWS.S3({
      endpoint: environment.awsConfig.endpoint,
      s3ForcePathStyle: environment.awsConfig.s3ForcePathStyle,
    });
  }
  updateLikeCounter(count: number): void {
    this.likeCounterSource.next(count);
  }
  getPharmacy(page: number, size: number): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/pharmacies?page=${page}&size=${size}`);
  }
  uploadFiles(purchaseId: any, formData: FormData): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}api/files/upload/${purchaseId}/all`, formData);
  }

  getFileUrl(purchaseId: any): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}api/files/${purchaseId}/documents/all`);
  }
  deleteFile(purchaseId: any, prescription: any): Observable<any> {
    return this.http.delete(`${environment.baseUrl}api/files/delete/${purchaseId}/prescription/${prescription}`, { responseType: 'text' });
  }
  getImage(bucketName: any, key: any): Promise<any> {
    const params = {
      Bucket: bucketName,
      Key: key,
    };

    return this.s3.getObject(params).promise();
  }

  searchPharmacies(searchkeys: any): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}api/pharmacies/search?searchkeys=${searchkeys}`);
  }
  getPharmacyById(pharmacyId: string): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}api/pharmacies/${pharmacyId}`);
  }
  searchPharmaciesWithLatLang(lat: any, lng: any, page: any, addressId: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/pharmacies/nearby?lat=${lat}&lng=${lng}&page=${page}&addressId=${addressId}`);
  }
  getCategories(pharmacyType: any, pharmacyId: any, page: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/categories?type=${pharmacyType}&pharmacyId=${pharmacyId}&page=${page}`);
  }
  getAllCategoriesP(): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/categories/type/P`);
  }
  getAllCategoriesM(): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/categories/type/M`);
  }
  getPromos(pharmacyId: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/stock-items/promos?pharmacyId=${pharmacyId}`);
  }
  getSubCategories(categorie_id: number): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/categories/${categorie_id}/sub_categories`);
  }
  getStockItems(categorie_id: number, pharmacyId: number, page: number, size: number): Observable<any> {
    return this.http.get(
      `${environment.baseUrl}api/sub-categories/${categorie_id}/stock-items/pharmcy/${pharmacyId}?page=${page}&size=${size}`,
    );
  }
  likePharmacy(data: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}api/favoris`, data);
  }
  getFavorisPharmacy(): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/favoris?entity=Pharmacy`);
  }
  likeProduct(data: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}api/favoris`, data);
  }
  getFavorisProduct(): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/favoris?entity=StockItem`);
  }
  unlikeProduct(data: any): Observable<any> {
    const options = {
      body: data,
    };
    return this.http.request<any>('DELETE', `${environment.baseUrl}api/favoris`, options);
  }
  unlikePharmacy(data: any): Observable<any> {
    const options = {
      body: data,
    };
    return this.http.request<any>('DELETE', `${environment.baseUrl}api/favoris`, options);
  }
  getIdObservable(): Observable<any> {
    return this.idSubject.asObservable();
  }
  addToCart(cartData: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}api/purchases`, cartData);
  }
  putCart(cartData: any, purchase_id: any): Observable<any> {
    return this.http.put<any>(`${environment.baseUrl}api/purchases/${purchase_id}`, cartData);
  }
  addPurchases(data: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}api/purchases/files`, data);
  }

  setCommercialName(name: string): void {
    this.commercialName = name;
    this.cookieService.set('commercialName', name);
  }
  setLongitude(longitude: string): void {
    this.cookieService.set('longitudePharmacy', longitude);
  }
  setLatitude(latitude: string): void {
    this.cookieService.set('latitudePharmacy', latitude);
  }
  getCommercialName(): string {
    return this.cookieService.get('commercialName') || this.commercialName;
  }
  getLongitude(): string {
    return this.cookieService.get('longitudePharmacy');
  }
  getLatitude(): string {
    return this.cookieService.get('latitudePharmacy');
  }
  setPharmacyId(pharmacyId: string): void {
    this.parmacyId = pharmacyId;
    this.pharmacyIdSubject.next(this.parmacyId);
    this.cookieService.set('pharmacyId', pharmacyId);
  }
  getPharmacyId(): string {
    const pharmacyId = this.cookieService.get('pharmacyId') as string | undefined; // assert as string
    if (typeof pharmacyId === 'string') {
      return pharmacyId;
    }
    return this.parmacyId || ''; // Fallback to empty string
  }

  setCatName(name: string): void {
    this.catName = name;
    this.cookieService.set('catName', name);
  }

  getCatName(): string {
    return this.cookieService.get('catName') || this.catName;
  }

  setSubCatName(name: string): void {
    this.subCatName = name;
    this.cookieService.set('subCatName', name);
  }
  setSubCatId(id: string): void {
    this.subCatId = id;
    this.cookieService.set('subCatId', id);
  }
  getSubCatName(): string {
    return this.cookieService.get('subCatName') || this.subCatName;
  }
  getSubCatId(): string {
    const subCatId = this.cookieService.get('subCatId') as string | undefined; // assert as string
    if (typeof subCatId === 'string') {
      return subCatId;
    }
    return this.subCatId || ''; // Fallback to empty string
  }
  searchProduct(keyword: any, pharmacyId: any, subCategoryId: any): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrl}api/stock-items/search?keyword=${keyword}&pharmacyId=${pharmacyId}&subCategoryId=${subCategoryId}`,
    );
  }
  searchPurchsesPharmacist(keyword: any): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}api/purchases/search?keyword=${keyword}`);
  }
  searchStockPharmacist(keyword: any): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}api/stock-items/pharmacist-search?keyword=${keyword}`);
  }
  setSelectedCategory(category: any): void {
    this.selectedCategory = category;
  }
  getSelectedCategory(): any {
    return this.selectedCategory;
  }
  addAdresse(data: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}api/addresses`, data);
  }
  updateAdresse(data: any, addressId: any): Observable<any> {
    return this.http.patch<any>(`${environment.baseUrl}api/addresses/${addressId}`, data);
  }
  getAdresse(): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/addresses`);
  }
  saveAdress(addressId: any, body: any): Observable<any> {
    return this.http.patch(`${environment.baseUrl}api/addresses/${addressId}/setlastused`, body);
  }
  deleteAdress(addressId: any): Observable<any> {
    return this.http.delete(`${environment.baseUrl}api/addresses/${addressId}`);
  }

  setAddressCoordinates(lat: number, lng: number): void {
    this.addressSubject.next({ lat, lng });
  }

  getAddressCoordinates(): Observable<any> {
    return this.addressSubject.asObservable();
  }
  getListStockItems(page: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/stock-items?page=${page}`);
  }
  // uploadStockItems(file: FormData): Observable<any> {
  //   return this.http.post(`${environment.baseUrl}api/stock-items/upload-csv`, file);
  // }
  uploadStockItems(formData: FormData): Observable<string> {
    return this.http.post(`${environment.baseUrl}api/stock-items/upload-csv`, formData, { responseType: 'text' });
  }

  checkUploadProgress(taskId: string): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/stock-items/upload-progress/${taskId}`);
  }
  getContactInfo(purchaseId: number): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/purchases/${purchaseId}/contact`);
  }

  updateContactInfo(purchaseId: number, contactInfo: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}api/purchases/${purchaseId}/contact`, contactInfo);
  }
  updateStockItem(data: any): Observable<any> {
    return this.http.patch(`${environment.baseUrl}api/stock-items/${data.id}`, data);
  }
  // geo.service.ts
  reverseGeocode(lat: number, lng: number): Observable<string> {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCRcEr5d8ePuI2b8V3MPFCLSrMDC1hg-z0`;
    return this.http.get<GeocodeResponse>(url).pipe(
      map(response => {
        if (response.results.length > 0) {
          return response.results[0].formatted_address;
        }
        return '';
      }),
      catchError(error => {
        console.error('Error reverse geocoding', error);
        return throwError(error);
      }),
    );
  }

  setSelectedAddress(address: any): void {
    this.selectedAddressSubject.next(address);
  }
  setSelectedAddressId(addressId: number): void {
    this.selectedAddressIdSubject.next(addressId);
    localStorage.setItem('selectedAddressId', addressId.toString());
  }
  setSelectedAddressCoords(coords: { lat: number; lng: number }): void {
    this.selectedAddressCoordsSubject.next(coords);
    localStorage.setItem('selectedAddressCoords', JSON.stringify(coords));
  }
  getSelectedAddress(): Address | null {
    return this.selectedAddressSubject.value;
  }
  notifyAddressUpdated(): void {
    this.addressUpdatedSubject.next();
  }
  notifyChangeAddress(): void {
    this.addressChangeSubject.next();
  }
  getProducts(categorie_id: number): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/products/${categorie_id}/all`);
  }

  loadResources(): void {
    this.loadFontAwesome();
    this.loadGoogleMaps();
  }

  loadFontAwesome(): void {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = environment.cdnConfig.fontAwesome.url;
    link.integrity = environment.cdnConfig.fontAwesome.integrity;
    link.crossOrigin = 'anonymous';
    link.referrerPolicy = 'no-referrer';
    document.head.appendChild(link);
  }

  loadGoogleMaps(): void {
    const script = document.createElement('script');
    script.defer = true;
    script.src = `${environment.cdnConfig.googleMaps.url}?key=${environment.cdnConfig.googleMaps.apiKey}`;
    document.head.appendChild(script);
  }
}
