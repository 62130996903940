import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Login } from '../../login/login.model';
import { ApplicationConfigService } from '../config/application-config.service';
import { StateStorageService } from './state-storage.service';
import { CartService } from '../../cart/cart.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

type JwtToken = {
  id_token: string;
};

@Injectable({ providedIn: 'root' })
export class AuthServerProvider {
  private role: string = 'ROLE_USER';
  currentUrl: any;

  constructor(
    private http: HttpClient,
    private stateStorageService: StateStorageService,
    private applicationConfigService: ApplicationConfigService,
    private route: ActivatedRoute,
    private router: Router,
    private cartService: CartService,
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      this.currentUrl = event.urlAfterRedirects;
      this.setRoleBasedOnRoute();
    });
    this.setRoleBasedOnRoute();
  }

  getToken(): string {
    return this.stateStorageService.getAuthenticationToken() ?? '';
  }

  // login(credentials: Login): Observable<void> {
  //   return this.http
  //     .post<JwtToken>(this.applicationConfigService.getEndpointFor('api/authenticate'), credentials)
  //     .pipe(map(response => this.authenticateSuccess(response, credentials.rememberMe)));
  // }
  login(credentials: Login): Observable<void> {
    console.log(credentials, 'credentials');
    return this.http
      .post<JwtToken>(this.applicationConfigService.getEndpointFor('api/authenticate'), credentials, {
        headers: new HttpHeaders({
          'X-CLIENT-TYPE': this.role,
        }),
      })
      .pipe(map(response => this.authenticateSuccess(response, credentials.rememberMe)));
  }

  private setRoleBasedOnRoute(): void {
    console.log(this.currentUrl, 'this.currentUrl');
    this.route.queryParams.subscribe(params => {
      if (params['user'] === 'pharmacist') {
        this.role = 'ROLE_PHARMACIST';
      } else if (this.currentUrl?.includes('/admin/signin')) {
        this.role = 'ROLE_ADMIN';
      } else if (this.currentUrl?.includes('/admin-pharmacie/login')) {
        this.role = 'ROLE_MODERATOR';
      } else {
        this.role = 'ROLE_USER';
      }
    });
  }

  logout(): Observable<void> {
    return new Observable(observer => {
      this.stateStorageService.clearAuthenticationToken();
      observer.complete();
      //this.cartService.clearCartItems();
    });
  }

  private authenticateSuccess(response: JwtToken, rememberMe: boolean): void {
    this.stateStorageService.storeAuthenticationToken(response.id_token, rememberMe);
  }
}
