import { Component, ElementRef, ViewChild, NgZone, Output, EventEmitter, Input, AfterViewInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ApiService, Maps } from '../../home/service/map.service';

@Component({
  selector: 'jhi-auto-complete',
  template: `
    <div>
      <input
        placeholder="Rechercher une adresse"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off"
        type="text"
        class="form-control"
        (input)="onInputChange($event)"
        #search
      />
    </div>
  `,
  styles: [
    `
      .map-container {
        z-index: 9999;
      }
      ::ng-deep .pac-container {
        z-index: 9999 !important;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutoCompleteComponent),
      multi: true,
    },
  ],
})
export class AutoCompleteComponent implements AfterViewInit, ControlValueAccessor {
  @ViewChild('search') searchElementRef!: ElementRef;
  @Output() placeSelected: EventEmitter<google.maps.places.PlaceResult> = new EventEmitter();
  @Input() optionalInput: string | undefined;

  place: google.maps.places.PlaceResult | undefined;
  disabled = false;

  constructor(
    private apiService: ApiService,
    private ngZone: NgZone,
  ) {}

  ngAfterViewInit(): void {
    this.apiService.api.then(maps => {
      this.initAutocomplete(maps);
    });

    if (this.optionalInput !== undefined) {
      this.searchElementRef.nativeElement.value = this.optionalInput;
    }
  }
  onChange: (value: string) => void = () => {};
  onTouched: () => void = () => {};

  onInputChange(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;
    this.onChange(inputValue);
  }

  writeValue(value: string): void {
    if (this.searchElementRef?.nativeElement) {
      this.searchElementRef.nativeElement.value = value || '';
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.searchElementRef.nativeElement.disabled = isDisabled;
  }
  initAutocomplete(maps: Maps): void {
    const autocomplete = new maps.places.Autocomplete(this.searchElementRef.nativeElement, {
      types: ['geocode'],
      componentRestrictions: { country: ['fr', 'tn'] },
    });

    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        this.place = autocomplete.getPlace();
        this.placeSelected.emit(this.place);
        if (this.place.formatted_address) {
          this.onChange(this.place.formatted_address);
        }
      });
    });
  }
}
