<div class="d-flex justify-content-center">
  <div class="col">
    <div class="registerLogin m-auto">
      <form name="editForm" role="form" novalidate [formGroup]="editForm" *ngIf="!ActivatedEmailAdmin">
        <h1
          id="jhi-pharmacy-heading"
          data-cy="PharmacyCreateUpdateHeading"
          jhiTranslate="pharmaApp.pharmacy.home.createLabel"
          style="margin-top: -1.2rem"
          [ngStyle]="{ 'font-size': screenHeight < 800 ? '27px' : '30px' }"
        >
          Create or edit a Pharmacy
        </h1>

        <div>
          <jhi-alert-error></jhi-alert-error>

          @if (editForm.controls.id.value !== null) {
            <div class="mb-3">
              <label class="form-label" jhiTranslate="pharmaApp.pharmacy.id" for="field_id">Id</label>
              <input type="number" class="form-control" name="id" id="field_id" data-cy="id" formControlName="id" [readonly]="true" />
            </div>
          }

          <div class="mb-3" *ngIf="!nextValidation">
            <label class="form-label" jhiTranslate="pharmaApp.pharmacy.DenominationCommerciale" for="field_responsibleEmail"
              >DenominationCommerciale</label
            >
            <input
              type="text"
              class="form-control"
              name="commercialName"
              id="field_commercialName"
              data-cy="commercialName"
              formControlName="commercialName"
            />
          </div>

          <div class="mb-3" *ngIf="!nextValidation">
            <label class="form-label" jhiTranslate="pharmaApp.pharmacy.address" for="field_address">Adresse</label>
            <jhi-auto-complete
              (placeSelected)="onPlaceSelected($event)"
              [optionalInput]="editForm.get('address')?.value || ''"
              formControlName="address"
            ></jhi-auto-complete>
          </div>
          <div class="row" *ngIf="!nextValidation">
            <div class="col mb-3">
              <label class="form-label" jhiTranslate="pharmaApp.pharmacy.phoneNumber" for="field_legalName">Phone Number</label>
              <input
                type="text"
                class="form-control"
                name="phoneNumber"
                id="field_phoneNumber"
                data-cy="phoneNumber"
                formControlName="phoneNumber"
              />
              <div
                *ngIf="editForm.get('phoneNumber')?.invalid && (editForm.get('phoneNumber')?.dirty || editForm.get('phoneNumber')?.touched)"
                class="text-danger"
              >
                <small
                  *ngIf="editForm.get('phoneNumber')?.errors?.['required']"
                  jhiTranslate="pharmaApp.pharmacy.errors.requiredPhoneNumber"
                  >Phone number is required.</small
                >
                <small *ngIf="editForm.get('phoneNumber')?.errors?.['pattern']" jhiTranslate="pharmaApp.pharmacy.errors.InvalidPhoneNumber"
                  >Enter a valid French phone number</small
                >
              </div>
            </div>

            <div class="col mb-3">
              <label class="form-label" jhiTranslate="pharmaApp.pharmacy.ServiceEmail" for="field_responsibleEmail">Email de Service</label>
              <input
                type="text"
                class="form-control"
                name="responsibleEmail"
                id="field_responsibleEmail"
                data-cy="responsibleEmail"
                formControlName="responsibleEmail"
              />
              <div
                *ngIf="
                  editForm.get('responsibleEmail')?.invalid &&
                  (editForm.get('responsibleEmail')?.dirty || editForm.get('responsibleEmail')?.touched)
                "
                class="text-danger"
              >
                <small *ngIf="editForm.get('responsibleEmail')?.errors?.['required']" jhiTranslate="pharmaApp.pharmacy.errors.requiredEmail"
                  >Email is required.</small
                >
                <small *ngIf="editForm.get('responsibleEmail')?.errors?.['email']" jhiTranslate="pharmaApp.pharmacy.errors.invalidEmail"
                  >Enter a valid email address.</small
                >
              </div>
            </div>
          </div>
          <div class="mb-3" *ngIf="nextValidation">
            <label class="form-label" jhiTranslate="pharmaApp.pharmacy.SaisirCIP" for="pharmacyOrderId">Saisir le CIP</label>
            <input
              type="text"
              class="form-control"
              name="pharmacyOrderId"
              id="pharmacyOrderId"
              data-cy="pharmacyOrderId"
              formControlName="pharmacyOrderId"
            />
            <div
              *ngIf="
                editForm.get('pharmacyOrderId')?.invalid &&
                (editForm.get('pharmacyOrderId')?.dirty || editForm.get('pharmacyOrderId')?.touched)
              "
              class="text-danger"
            >
              <small *ngIf="editForm.get('pharmacyOrderId')?.errors?.['required']" jhiTranslate="pharmaApp.pharmacy.errors.requiredCIP"
                >CIP is required.</small
              >
              <small *ngIf="editForm.get('pharmacyOrderId')?.errors?.['pattern']" jhiTranslate="pharmaApp.pharmacy.errors.noSpaces"
                >No spaces allowed.</small
              >
            </div>
          </div>
          <div class="mb-3" *ngIf="nextValidation">
            <label class="form-label" jhiTranslate="pharmaApp.pharmacy.SaisirSiret" for="siret">Saisir le Siret</label>
            <input type="text" class="form-control" name="siret" id="siret" data-cy="siret" formControlName="siret" />
            <div
              *ngIf="editForm.get('siret')?.invalid && (editForm.get('siret')?.dirty || editForm.get('siret')?.touched)"
              class="text-danger"
            >
              <small *ngIf="editForm.get('siret')?.errors?.['required']" jhiTranslate="pharmaApp.pharmacy.errors.requiredSiret"
                >Siret is required.</small
              >
              <small *ngIf="editForm.get('siret')?.errors?.['pattern']" jhiTranslate="pharmaApp.pharmacy.errors.noSpaces"
                >No spaces allowed.</small
              >
            </div>
          </div>
          <div class="mb-3" *ngIf="nextValidation">
            <label class="form-label" jhiTranslate="pharmaApp.pharmacy.SaisirIBAN" for="rib">Saisir l'IBAN</label>
            <input type="text" class="form-control" name="rib" id="rib" data-cy="rib" formControlName="rib" />
            <div *ngIf="editForm.get('rib')?.invalid && (editForm.get('rib')?.dirty || editForm.get('rib')?.touched)" class="text-danger">
              <small *ngIf="editForm.get('rib')?.errors?.['required']" jhiTranslate="pharmaApp.pharmacy.errors.requiredIBAN"
                >IBAN is required.</small
              >
              <small *ngIf="editForm.get('rib')?.errors?.['pattern']" jhiTranslate="pharmaApp.pharmacy.errors.noSpaces"
                >No spaces allowed.</small
              >
            </div>
          </div>

          <div class="mb-3" *ngIf="!nextValidation">
            <label class="form-label" jhiTranslate="pharmaApp.pharmacy.PharmacyPhoto" for="photo">Photo de l'officine</label>
            <div class="file-wrapper">
              <input class="form-control" type="file" (change)="onFileSelected($event, 'file')" id="mutuelle" accept="image/*" />
              <div *ngIf="!fileError" class="text-danger">
                <small jhiTranslate="pharmaApp.pharmacy.errors.PharmacyPhoto">Pharmacy photo is required.</small>
              </div>
            </div>
          </div>
        </div>

        <div>
          <button
            *ngIf="!nextValidation"
            (click)="nextValidationPage()"
            id="save-entity"
            data-cy="entityCreateSaveButton"
            [disabled]="isSaving"
            class="col-12 btn btn-primary success-btn mt-3"
          >
            <span jhiTranslate="pharmaApp.pharmacy.Next">Suivant</span>
          </button>
          <div class="row">
            <button
              *ngIf="nextValidation"
              (click)="returnValidationPage()"
              id="save-entity"
              class="col-5 btn btn-primary success-btn btnReturn mt-3"
            >
              <span jhiTranslate="pharmaApp.pharmacy.Back">Retour</span>
            </button>
            <button
              *ngIf="nextValidation"
              type="submit"
              (click)="login()"
              id="save-entity"
              data-cy="entityCreateSaveButton"
              [disabled]="editForm.invalid || isSaving || !fileError"
              class="col-6 btn btn-primary success-btn mt-3"
            >
              <span jhiTranslate="pharmaApp.pharmacy.Next">Suivant</span>
            </button>
          </div>
        </div>
      </form>
      <div *ngIf="ActivatedEmailAdmin">
        <p style="font-size: 25px; color: #83b011; font-weight: bold; font-family: 'ClashDisplay-Semibold'">Merci!</p>
        <p style="font-family: 'ClashDisplay-Semibold'; color: #141414; font: size 18px; font-weight: bold">
          Votre compte est soumis à la révision de l’administrateur pour validation.
        </p>
        <p style="font-family: 'ClashDisplay-Semibold'; color: #141414; font: size 18px; font-weight: bold">
          Une notification de votre demandé est envoyé à l’administrateur de la plateforme.
        </p>
        <p style="font-family: 'ClashDisplay-Semibold'; color: #141414; font: size 18px; font-weight: bold">
          Vous allez recevoir un mail dans un délais de 2 jours ouvrables pour vous permettre de finaliser l’activation de votre compte.
        </p>
      </div>

      <button
        *ngIf="ActivatedEmailAdmin"
        type="submit"
        id="save-entity"
        data-cy="entityCreateSaveButton"
        class="col-12 btn btn-primary success-btn mt-3"
        (click)="navigateToLanding()"
      >
        <span jhiTranslate="register.form.buttonCompris" (click)="navigateToLanding()">J'ai compris</span>
      </button>
    </div>
  </div>
</div>
