import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '../../cart/cart.service';

@Component({
  selector: 'jhi-refuse',
  standalone: true,
  imports: [],
  templateUrl: './refuse.component.html',
  styleUrl: './refuse.component.scss',
})
export class RefuseComponent implements OnInit {
  constructor(
    private cartService: CartService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.cartService.clearCartItems();
  }
  navigateToHome(): void {
    this.router.navigate(['/home']);
  }
}
