<div class="row mt-5 mb-4">
  <h2 class="lead" style="color: #0078c6">Mes favoris (Pharmacies)</h2>
</div>
<div class="scrolling-wrapper row flex-row flex-nowrap mt-4 mb-5 pt-2">
  <span *ngIf="!favorisPharmacy.length">
    <lottie-player
      src="../../content/images/noData.json"
      background="transparent"
      speed="1"
      style="width: 300px; height: 250px; margin: auto"
      loop
      autoplay
    ></lottie-player>
  </span>

  <div *ngFor="let pharma of favorisPharmacy; let i = index" class="pharmacies-list col-sm-6 col-lg-4 mb-3 fav">
    <div>
      <div
        class="card pharmacy-card"
        style="border: 1px solid #e5e7eb; border-radius: 16px"
        (click)="NavigateToPharmacy(pharma?.id, pharma?.commercialName)"
      >
        <img [src]="imageUrl + pharma.frontPath + '/large'" class="card-img-top" alt="Image" />
        <div class="card-body" style="display: flex; flex-direction: column">
          <p style="color: #83b011">
            <img style="margin-top: -3px" src="../../content/images/icons/Ouverte.svg" alt="Ouverte" />
            Ouverte
          </p>
          <h5 class="card-title text-truncate">{{ pharma?.commercialName }}</h5>
          <p class="card-text address">{{ pharma?.address }}</p>
          <!-- <a class="btn btn-light d-flex delivery">Livraison en 30m</a>-->

          <a class="btn btn-light d-flex likeBtn" (click)="stopEventPropagation($event); unlikePharmacy(pharma, i)">
            <img
              [src]="pharma.liked ? '../../content/images/Icon feather-heart (1).svg' : '../../content/images/Icon feather-heart (2).svg'"
              alt="Like"
            />
          </a>
          <a class="btn order" style="border: 2px solid #0078c6; border-radius: 20px; color: #0078c6">Passer commande</a>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col">
  <h2 class="lead" style="color: #0078c6">Mes favoris (Produits)</h2>
</div>
<div class="scrolling-wrapper row flex-row flex-nowrap mt-4 mb-5 pt-2">
  <span *ngIf="!favorisProduct.length">
    <lottie-player
      src="../../content/images/noData.json"
      background="transparent"
      speed="1"
      style="width: 300px; height: 250px; margin: auto"
      loop
      autoplay
    ></lottie-player>
  </span>
  <div *ngFor="let product of favorisProduct; let i = index" class="pharmacies-list col-sm-12 col-lg-6 col-xl-6 col-xxl-4 mb-3 fav">
    <div>
      <div
        (click)="openproductModal(modalProduct, product)"
        class="card product-card"
        style="border: 1px solid #e5e7eb; border-radius: 16px; cursor: pointer"
      >
        <img [src]="imageUrl + product?.product?.productImage + '/large'" class="prod-img-top" alt="Image" />
        <div class="card-body" style="display: flex; flex-direction: column">
          <h5 class="card-title">{{ product?.pharmacy?.legalName }}</h5>
          <h5 class="card-title">{{ product?.product?.title }}</h5>

          <div class="d-flex flex-row mb-3">
            <img src="../../content/images/icons/yellow-star-2.svg" alt="" />
            <img src="../../content/images/icons/yellow-star-2.svg" alt="" />
            <img src="../../content/images/icons/yellow-star-2.svg" alt="" />
            <img src="../../content/images/icons/star-icon.svg" alt="" />
            <img src="../../content/images/icons/star-icon.svg" alt="" />
          </div>
          <a *ngIf="product.discount != '0'" class="btn btn-light d-flex delivery">promo </a>

          <a class="btn btn-light d-flex likeBtn" (click)="stopEventPropagation($event); unlikeProduct(product, i)">
            <img
              [src]="
                product.liked
                  ? '../../content/images/icons/Icon feather-heart (4).svg'
                  : '../../content/images/icons/Icon feather-heart (3).svg'
              "
              alt="Like"
            />
          </a>
          <div class="d-flex flex-row align-items-center justify-content-between">
            <div class="col-5 d-flex align-items-center flex-column">
              <h3
                *ngIf="product.discount != '0'"
                class="price"
                style="font-size: 15px; text-decoration: line-through; font-family: 'ClashDisplay-Regular'"
              >
                {{ product.price }}
              </h3>
              <h1 class="price m-auto">{{ product.price * (1 - product.discount / 100) }}€</h1>
            </div>
            <div class="col-7 d-flex justify-content-end">
              <a class="btn order" (click)="stopEventPropagation($event); addToCart(product, modalProductExist)">Ajouter au panier</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modalProduct let-modalProduct>
  <div class="modal-product">
    <div class="modal-header">
      <div class="col-11 d-flex justify-content-start">
        <span class="title">name/{{ openedProduct?.product?.title }}</span>
      </div>
      <div class="col-1 d-flex align-items-end flex-column">
        <button type="button" class="close" (click)="modalProduct.dismiss('Cross click')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      <div class="d-flex row align-items-center">
        <div class="col-12 col-lg-5">
          <div class="prod-img">
            <img [src]="imageUrl + openedProduct?.product?.productImage + '/large'" class="product-card prod-img-top" alt="Image" />
          </div>
        </div>
        <div class="col-12 col-lg-7 ml-4">
          <h3 class="name">{{ openedProduct?.product?.title }}</h3>
          <div class="product-card card-body" style="display: flex; flex-direction: column">
            <div class="d-flex flex-row align-items-center product-rating">
              <div class="d-flex flex-row">
                <img src="../../content/images/icons/yellow-star-2.svg" alt="" />
                <img src="../../content/images/icons/yellow-star-2.svg" alt="" />
                <img src="../../content/images/icons/yellow-star-2.svg" alt="" />
                <img src="../../content/images/icons/star-icon.svg" alt="" />
                <img src="../../content/images/icons/star-icon.svg" alt="" />
              </div>
              <span class="available ml-3">En stock</span>
            </div>
            <div class="producer">
              <img src="../../content/images/hyfaccc-removebg-preview.png" alt="" style="width: 100%" />
            </div>
            <p class="mb-3 descriptionDetail" [ngClass]="{ expanded: isExpandedDescription }" (click)="toggleExpandDescription()">
              {{ openedProduct?.product?.description }}
            </p>
            <h3 class="price">{{ openedProduct?.price * (1 - openedProduct?.discount / 100) }}€</h3>
            <div class="d-flex flex-row align-items-center">
              <div class="col-2 d-flex align-items-center" style="margin-right: 1rem">
                <div class="number d-flex">
                  <span (click)="decrement()">-</span>
                  <span>{{ value }}</span>
                  <span (click)="increment()">+</span>
                </div>
              </div>
              <div class="col d-flex ml-3">
                <a class="btn order" (click)="addToCartWithASpecificNumber(); modalProduct.dismiss('Cross click')">Ajouter au panier</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 d-flex flex-column description">
        <div class="tab-container">
          <ul class="tabs">
            <li [ngClass]="{ active: activeTab === 'composition' }" (click)="setActiveTab('composition')">Composition</li>
            <li [ngClass]="{ active: activeTab === 'posologie' }" (click)="setActiveTab('posologie')">Posologie</li>
          </ul>
          <div class="tab-content">
            <div *ngIf="activeTab === 'composition'">
              <p class="detail" [ngClass]="{ expanded: isExpanded }" (click)="toggleExpand()">
                Composition ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
                magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
                no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.
              </p>
            </div>
            <div *ngIf="activeTab === 'posologie'">
              <p class="detail" [ngClass]="{ expanded: isExpanded }" (click)="toggleExpand()">
                Posologie ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
                magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
                no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #modalProductExist let-modalProductExist>
  <div class="modal-header">
    <div class="col-11 d-flex justify-content-center"></div>
    <div class="col-1 d-flex align-items-end flex-column">
      <button type="button" class="close" (click)="modalProductExist.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="d-flex flex-column align-items-center">
      <img src="../../content/images/problem.svg" alt="" />

      <p class="swalModal">Vous avez ajouté un produit qui existe dans une autre pharmacie</p>
      <span class="mb-4 productExist">
        Vous devez choisir entre payer le panier de la pharmacie <span style="color: #0078c6">{{ pharmacyCartItemsName }}</span
        >, ou vider votre panier et démarrer un nouveau panier avec la pharmacie <span style="color: #0078c6">{{ pharmacyName }}</span
        >.
      </span>

      <div class="d-flex justify-content-between w-100"></div>
      <button class="btn btn-success mt-3 mb-3 saveAdresse" (click)="modalProductExist.dismiss('cancel')">PROCÉDER AU PAIEMENT</button>
      <div class="d-flex align-items-center down-input">
        <span style="cursor: pointer" (click)="modalProductExist.close('clearCart')">VIDER LA PANIER</span>
      </div>
      <div class="col-12 d-flex flex-column align-items-start down-input"></div>
    </div>
  </div>
</ng-template>
