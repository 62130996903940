<div class="conditions-utilisation">
  <div class="conditions-generales">
    <h1 class="title">Rappels de lots</h1>
  </div>
  <div class="table-container">
    <table>
      <thead>
        <tr>
          <th>Laboratoire</th>
          <th>Désignation</th>
          <th>Code EAN</th>
          <th>N° de lot</th>
          <th>DLUO</th>
          <th>Motif du rappel</th>
          <th>Conduite à tenir</th>
          <th>N° de contact</th>
        </tr>
      </thead>
      <tbody>
        <!-- P&G Health France Section -->
        <tr>
          <td rowspan="16">P&G Health France</td>
          <td rowspan="16">Oropolis goût miel citron</td>
          <td rowspan="16">3401546697346</td>
          <td>1278C60101</td>
          <td>31/08/2024</td>
          <td rowspan="16">Dépassement des limites légales d'HAP (Hydrocarbures Aromatiques Polycycliques)</td>
          <td rowspan="16">Arrêt utilisation</td>
          <td rowspan="16">0 800 941 452</td>
        </tr>
        <tr>
          <td>1279C60101</td>
          <td>31/08/2024</td>
        </tr>
        <tr>
          <td>1279C60104</td>
          <td>31/08/2024</td>
        </tr>
        <tr>
          <td>1315C60100</td>
          <td>31/08/2024</td>
        </tr>
        <tr>
          <td>1316C60100</td>
          <td>31/08/2024</td>
        </tr>
        <tr>
          <td>1321C60106</td>
          <td>31/08/2024</td>
        </tr>
        <tr>
          <td>1348C60105</td>
          <td>31/10/2024</td>
        </tr>
        <tr>
          <td>2019C60106</td>
          <td>30/11/2024</td>
        </tr>
        <tr>
          <td>2019C60107</td>
          <td>30/11/2024</td>
        </tr>
        <tr>
          <td>2020C60101</td>
          <td>30/11/2024</td>
        </tr>
        <tr>
          <td>2094C60102</td>
          <td>28/02/2025</td>
        </tr>
        <tr>
          <td>2095C60100</td>
          <td>31/01/2025</td>
        </tr>
        <tr>
          <td>2091C60102</td>
          <td>31/01/2025</td>
        </tr>
        <tr>
          <td>2097C60101</td>
          <td>31/01/2025</td>
        </tr>
        <tr>
          <td>2117C60101</td>
          <td>28/02/2025</td>
        </tr>
        <tr>
          <td>2133C60104</td>
          <td>31/03/2025</td>
        </tr>

        <!-- Ortis Section -->
        <tr>
          <td>Ortis</td>
          <td>Duo fruits & fibres regular 30 comps x 2</td>
          <td>5411386890829</td>
          <td>U291/21196958</td>
          <td>31/03/2024</td>
          <td>Produits non conformes</td>
          <td>Arrêt utilisation</td>
          <td>0320301967</td>
        </tr>

        <!-- Anaca 3 Shot perte de poids Section -->
        <tr>
          <td rowspan="13">Anaca 3</td>
          <td rowspan="6">Shot perte de poids</td>
          <td rowspan="6">3760007335402</td>
          <td>58AC001B</td>
          <td>12/2023</td>
          <td rowspan="13">Mise à jour étiquetage</td>
          <td rowspan="13">
            Les produits concernés ne sont pas impropres à la consommation ou dangereux et ne présentent aucun risque pour le consommateur.
          </td>
          <td rowspan="13">X</td>
        </tr>
        <tr>
          <td>58AC001A</td>
          <td>12/2023</td>
        </tr>
        <tr>
          <td>58AC001C</td>
          <td>12/2023</td>
        </tr>
        <tr>
          <td>58AC001E</td>
          <td>01/2024</td>
        </tr>
        <tr>
          <td>58AC001D</td>
          <td>01/2024</td>
        </tr>
        <tr>
          <td>60AC002A</td>
          <td>03/2024</td>
        </tr>

        <!-- More data for Anaca 3 Shot Brûleur de graisses -->
        <tr>
          <td rowspan="7">Shot Brûleur de graisses</td>
          <td rowspan="7">3760007335495</td>
          <td>55AC001C</td>
          <td>09/2023</td>
        </tr>
        <tr>
          <td>55AC001B</td>
          <td>09/2023</td>
        </tr>
        <tr>
          <td>55AC001A</td>
          <td>09/2023</td>
        </tr>
        <tr>
          <td>59AC002B</td>
          <td>01/2024</td>
        </tr>
        <tr>
          <td>59AC002C</td>
          <td>01/2024</td>
        </tr>
        <tr>
          <td>59AC002D</td>
          <td>01/2024</td>
        </tr>
        <tr>
          <td>70AC001A</td>
          <td>12/2024</td>
        </tr>

        <!-- Granions Section -->
        <tr>
          <td>Granions</td>
          <td>Collagène marin type I et II 3000 mg Granions</td>
          <td>3760155216585</td>
          <td>GRA3015</td>
          <td>04/2025</td>
          <td>Présence d'une capsule anti-humidité (dessiccant) pouvant être confondue avec un comprimé.</td>
          <td>Arrêt d'utilisation</td>
          <td>0810 121 707</td>
        </tr>

        <!-- Bayer Section -->
        <tr>
          <td>Bayer</td>
          <td>Euphytose 180 comprimés</td>
          <td>3400930088340</td>
          <td>SX2298</td>
          <td>03/2025</td>
          <td>Présence d'un comprimé de Phenergan (couleur bleue) dans un tube d'Euphytose</td>
          <td>Ne pas utiliser</td>
          <td>0 800 87 54 54</td>
        </tr>

        <!-- Sanofi - Opella Healthcare Section -->
        <tr>
          <td>Sanofi - Opella Healthcare</td>
          <td>Doliprane 2,4 % suspension buvable</td>
          <td>3400934615467</td>
          <td>3KLR12D1</td>
          <td>31/05/2026</td>
          <td>Certains médicaments fournis avec une pipette non graduée</td>
          <td>Bien vérifier la présence de graduations</td>
          <td>0801907577</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="footer" style="overflow: hidden">
    <div class="row d-flex align-items-center p-3">
      <div class="footer-bottom">
        <span
          >© 2024 <span class="pharma">PharmaGo</span> .
          <span jhiTranslate="global.menu.footer.rights">Tous les droits réservés Développé par</span>
          <span class="underline">KCA Dev Services.</span>
        </span>
      </div>
    </div>
  </div>
</div>
