import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InvitationService {
  constructor(private http: HttpClient) {}

  createdInvitation(data: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}api/invitations`, data);
  }
  // ?status=CREATED&status=PENDING&status=ACCEPTED&status=EXPIRED
  getInvitations(first: any): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}api/invitations?page=${first}&size=8`);
  }
  sendInvitation(invitationId: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}api/invitations/send/${invitationId}`, {});
  }
  getPharmacyByStatus(first: any, statuses: string[]): Observable<any> {
    const statusParams = statuses.map(status => `status=${status}`).join('&');
    return this.http.get(`${environment.baseUrl}api/pharmacies/by-status?page=${first}&${statusParams}&size=8`);
  }
  verifiedPharmacy(pharmacy_id: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}api/admin/activatebyadmin?id=${pharmacy_id}`, {}, { responseType: 'text' });
  }
  visibility(pharmacy_id: any, toggleValue: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}api/pharmacies/visibility/${pharmacy_id}/${toggleValue}`, {});
  }
}
