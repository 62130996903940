<div class="d-flex">
  <span style="margin-top: 1rem"
    ><a (click)="goBack()">{{ pharmacyName }}</a> / {{ catName }}</span
  >
</div>
<div class="row mt-2 mb-2">
  <div class="col-md-12 col-lg-5">
    <h2 class="lead" style="color: #0078c6">Choisir {{ catName }}</h2>
  </div>

  <div class="col-md-12 col-lg-7 d-sm-flex justify-content-lg-end flex-column flex-sm-row">
    <button class="btn btn-primary btn-bill m-2 mr-0" (click)="openCommandModal(modalOrdonnance)">Déposer une ordonnance</button>
  </div>
</div>
<span *ngIf="!sousCategories.length">
  <lottie-player
    src="../../content/images/noData.json"
    background="transparent"
    speed="1"
    style="width: 300px; height: 250px; margin: auto"
    loop
    autoplay
  ></lottie-player>
</span>

<div class="row">
  <div class="col-6 col-md-4 col-xl-3 mb-3" *ngFor="let subCat of sousCategories">
    <div *ngIf="!contentLoaded">
      <ngx-skeleton-loader
        count="1"
        appearance="circle"
        class="prod-img-top"
        [theme]="{ width: '130px', height: '60px', 'border-radius': '10px' }"
      ></ngx-skeleton-loader>
    </div>
    <div *ngIf="contentLoaded" class="card mini-card sous-category" (click)="NavigateToProducts(subCat.id, subCat.name)">
      <div class="d-flex align-items-end">
        <h3>{{ subCat.name }}</h3>
      </div>
      <div class="col-2 d-flex align-items-center justify-content-end">
        <span class="arrow-hover"></span>
      </div>
    </div>
  </div>
</div>
<ng-template #modalOrdonnance let-modalOrdonnance>
  <div class="modal-product">
    <div class="modal-header">
      <div class="col-11 d-flex justify-content-start">
        <div class="title">
          Nouvelle Commande chez {{ pharmacyName }}
          <!--<p class="command-id" *ngIf="numCommande">ID: {{ numCommande }}</p>-->
        </div>
      </div>
      <div class="col-1 d-flex align-items-end flex-column">
        <button type="button" class="close" (click)="modalOrdonnance.dismiss('Cross click')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      <form [formGroup]="PurchasesForm">
        <div class="row">
          <div class="col-sm-12 col-lg-3">
            <label for="ordonnance">Ordonnance</label>
            <div class="file-wrapper">
              <div class="add-date-btn">
                <input class="form-control" type="file" (change)="onFileSelectedPrescription($event)" id="ordonnance" multiple />
                <span class="file-placeholder-ordonnance"></span>
                Téléversez vos ordonnances
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-9 d-flex align-items-center">
            <div *ngIf="prescriptionFiles.length > 0" class="image-wrapper d-flex">
              <div class="image-container" *ngFor="let fileUrl of fileUrls; let i = index">
                <img [src]="fileUrl" *ngIf="fileUrl.includes('image')" alt="Ordonnance Image" class="img-fluid" />
                <button type="button" (click)="removeFilePrescription(prescriptionFiles[i])" class="btn btn-danger btn-sm">X</button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-lg-6">
            <label for="carte-vitale">Carte Vitale</label>
            <div class="file-wrapper">
              <input class="form-control" type="file" (change)="onFileSelected($event, 'vital_card')" id="carte-vitale" />
              <span class="file-placeholder"></span>
            </div>
          </div>
          <div class="col-sm-12 col-lg-6">
            <label for="mutuelle">Carte mutuelle</label>
            <div class="file-wrapper">
              <input class="form-control" type="file" (change)="onFileSelected($event, 'mutual_card')" id="mutuelle" />
              <span class="file-placeholder"></span>
            </div>
          </div>
        </div>
        <div class="modal-bottom">
          <label for="consentement" class="consentement">
            <input id="consentement" type="checkbox" name="consentement" class="form-check-input" (change)="onCheckboxChange($event)" />
            Je valide mon consentement pour le traitement de mes données personnelles.
          </label>
        </div>
        <button class="submit" type="button" [disabled]="!consentGiven" (click)="addOrder(); modalOrdonnance.dismiss('Cross click')">
          Confirmer
        </button>
        <button type="button" class="btn btn-annuler" (click)="modalOrdonnance.dismiss('Cross click')" aria-label="Close">Annuler</button>
      </form>
    </div>
  </div>
</ng-template>
