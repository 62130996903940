import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CartService } from '../../cart/cart.service';
import { HomeServiceService } from '../../home/service/home-service.service';
import SharedModule from '../../shared/shared.module';
import { ToastrService } from 'ngx-toastr';
interface PharmacySchedule {
  openingHour: string;
  closingHour: string;
}
interface Pharmacy {
  schedule?: PharmacySchedule;
  status?: string;
}
@Component({
  selector: 'jhi-favorites',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './favorites.component.html',
  styleUrl: './favorites.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FavoritesComponent implements OnInit {
  @ViewChild('modalProductExist') modalProductExist: any;

  likeImagePath: string = '../../content/images/icons/Icon feather-heart (4).svg';
  likeImagePathPharmacie: string = '../../content/images/Icon feather-heart (1).svg';
  activeTab: string = 'composition';
  favorisPharmacy: any;
  favorisProduct: any;
  lengthLike!: number;
  likeCounter!: number;
  openedProduct: any;
  value: number = 1;
  isExpandedDescription = false;
  isExpanded = false;
  pharmacyCartItemsName: any;
  pharmacyName: any;
  imageUrl: string = 'https://imagedelivery.net/z1UHS7Z96nNnNVE8sIpBxg/';

  constructor(
    private pharmacieService: HomeServiceService,
    private router: Router,
    private homeService: HomeServiceService,
    private modalService: NgbModal,
    private cartService: CartService,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.pharmacyName = this.pharmacieService.getCommercialName();
    this.pharmacyCartItemsName = this.cartService.getPharmacyName();

    window.scrollTo(0, 0);
    this.getFavorisPharmacy();
    this.getFavorisProduct();
  }

  getFavorisPharmacy(): void {
    this.pharmacieService.getFavorisPharmacy().subscribe({
      next: (res: any) => {
        this.favorisPharmacy = res;
        this.favorisPharmacy = this.favorisPharmacy.map((ph: Pharmacy) => {
          if (ph.schedule) {
            const { openingHour, closingHour } = ph.schedule;
            const currentDate = new Date();
            const currentTime = currentDate.getTime();

            // Parse opening and closing hours into Date objects
            const openingTime = new Date(currentDate.toISOString().split('T')[0] + 'T' + openingHour);
            const closingTime = new Date(currentDate.toISOString().split('T')[0] + 'T' + closingHour);

            // Calculate time differences in milliseconds
            const timeToOpen = openingTime.getTime() - currentTime;
            const timeToClose = closingTime.getTime() - currentTime;

            // Define thresholds for "soon" (1 hour = 3600000 milliseconds)
            const SOON_THRESHOLD = 3600000;

            // Determine the pharmacy status
            if (currentTime >= openingTime.getTime() && currentTime < closingTime.getTime()) {
              ph.status = timeToClose <= SOON_THRESHOLD ? 'close_soon' : 'open'; // Closing soon or Open
            } else if (currentTime < openingTime.getTime()) {
              ph.status = timeToOpen <= SOON_THRESHOLD ? 'open_soon' : 'closed'; // Opening soon or Closed
            } else {
              ph.status = 'closed'; // Default to closed if outside operational hours
            }
          } else {
            ph.status = 'closed'; // Default to closed if no schedule is available
          }
          return ph; // Now safely typed as Pharmacy
        });
      },
      error(err: any) {
        console.error('Error fetching favorisPharmacy:', err);
      },
    });
  }
  getFavorisProduct(): void {
    this.pharmacieService.getFavorisProduct().subscribe({
      next: (res: any) => {
        this.favorisProduct = res;
      },
    });
  }
  increment(): void {
    this.value++;
  }
  decrement(): void {
    if (this.value > 1) {
      this.value--;
    }
  }
  stopEventPropagation(event: Event): void {
    event.stopPropagation();
  }
  NavigateToPharmacy(id: number, pharmacyName: any): void {
    // this.router.navigate(['/pharmacie-category', { pharmacieId: id, T: 'P' }]);
    this.router.navigate(['/pharmacie-category', id, 'P']);
    this.setPharmacyName(pharmacyName);
    this.setPharmacyId(id);
  }
  setPharmacyName(commercialName: any): void {
    this.homeService.setCommercialName(commercialName);
  }
  setPharmacyId(pharmacyId: any): void {
    this.homeService.setPharmacyId(pharmacyId);
  }
  unlikePharmacy(pharmacy: any, index: number): void {
    const data = {
      favorable_type: 'Pharmacy',
      favorable_id: pharmacy.id,
    };
    this.pharmacieService.unlikePharmacy(data).subscribe(
      response => {
        this.favorisPharmacy[index].liked = false;
        this.lengthLike = this.favorisPharmacy.length;
        this.lengthLike--;
        this.getFavorisPharmacy();
        this.updateLikeCounter();
        this.toastr.info('Pharmacie retirée des favoris avec succès.', 'Info');
      },
      error => {
        console.error('Error unliking pharmacy:', error);
        this.toastr.error('Échec du retrait du pharmacie des favoris. Veuillez réessayer.', 'Error');
      },
    );
  }
  setActiveTab(tab: string): void {
    this.activeTab = tab;
  }
  unlikeProduct(product: any, index: number): void {
    const data = {
      favorable_type: 'StockItem',
      favorable_id: product.id,
    };
    this.pharmacieService.unlikeProduct(data).subscribe(
      response => {
        this.favorisProduct[index].liked = false;
        this.getFavorisProduct();
        this.toastr.info('Produit retiré des favoris avec succès.', 'Info');
      },
      error => {
        console.error('Error unliking pharmacy:', error);
        this.toastr.error('Échec du retrait du produit des favoris. Veuillez réessayer.', 'Error');
      },
    );
  }
  updateLikeCounter(): void {
    this.pharmacieService.updateLikeCounter(this.lengthLike);
  }
  openproductModal(modal: any, product: any): void {
    this.modalService.open(modal, { size: 'lg', centered: true });
    this.openedProduct = product;
    this.value = this.cartService.getCurrent(product);
  }
  openModal(modalTemplate: TemplateRef<any>, product: any): void {
    this.modalService.open(modalTemplate, { size: 's', centered: true }).result.then(result => {
      if (result === 'clearCart') {
        this.cartService.clearCartItems();
        if (this.openedProduct) {
          this.addToCartWithASpecificNumber();
        } else {
          this.addToCart(product, this.modalProductExist);
        }
      }
    });
  }
  addToCartWithASpecificNumber(): void {
    if (!this.cartService.checkSamePharmacy(this.openedProduct)) {
      this.openModal(this.modalProductExist, this.openedProduct);
    } else {
      this.cartService.addToCartWithASpecificNumber(this.openedProduct, this.value);
      this.toastr.success('Produit ajouté au panier.');
    }
  }
  addToCart(product: any, modalTemplate: TemplateRef<any>): void {
    if (!this.cartService.checkSamePharmacy(product)) {
      this.openModal(modalTemplate, product);
    } else {
      this.cartService.addToCart(product);
      this.toastr.success('Produit ajouté au panier.');
    }
  }
  toggleExpandDescription(): void {
    this.isExpandedDescription = !this.isExpandedDescription;
  }
  toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
  }
  getStatusColor(status: string): string {
    switch (status) {
      case 'open':
        return '#83b011'; // Green
      case 'closed':
        return 'red'; // Red
      case 'open_soon':
      case 'close_soon':
        return 'orange'; // Orange
      default:
        return 'black'; // Default color
    }
  }

  getStatusMessage(status: string): string {
    switch (status) {
      case 'open':
        return 'Ouverte';
      case 'closed':
        return 'Fermée';
      case 'open_soon':
        return 'Ouverte bientôt';
      case 'close_soon':
        return 'Ferme bientôt';
      default:
        return '';
    }
  }
}
