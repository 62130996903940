<div>
  <div class="d-flex justify-content-center">
    <div
      class="col-md-4 registerLogin loginclass"
      [ngStyle]="{ 'margin-left': screenWidth > 1300 ? '2rem' : 'initial', padding: screenWidth > 1300 ? '1rem' : '0rem 3rem' }"
    >
      <div class="row d-flex header">
        <div class="col-6"><span routerLink="/" class="logo-pharma" style="margin: 3rem auto"></span></div>
        <div class="col d-flex align-items-center justify-content-end">
          <a ngbDropdown class="nav-item dropdown pointer" display="dynamic" style="text-decoration: none; color: unset">
            <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="languagesnavBarDropdown">
              <span>
                <span>{{ delfaultLang | findLanguageFromKey }}</span>
              </span>
            </a>
            <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="languagesnavBarDropdown">
              @for (language of languages; track $index) {
                <li>
                  <a
                    class="dropdown-item"
                    [jhiActiveMenu]="language"
                    href="javascript:void(0);"
                    (click)="changeLanguage(language); collapseNavbar()"
                    >{{ language | findLanguageFromKey }}</a
                  >
                </li>
              }
            </ul>
          </a>
        </div>
      </div>
      @if (params && params == 'completeCreation') {
        <jhi-pharmacy-update></jhi-pharmacy-update>
      } @else {
        <h1 style="margin-top: 4rem" data-cy="loginTitle">Connexion à modérateur pharmaciens</h1>
        <!-- @if (authenticationError) {
          <div class="alert alert-danger" jhiTranslate="login.messages.error.authentication" data-cy="loginError">
            <strong>Failed to sign in!</strong> Please check your credentials and try again.
          </div>
        } -->
        <form class="form" role="form" (ngSubmit)="login()" [formGroup]="loginForm">
          <div class="mb-3">
            <label class="form-label" for="username" jhiTranslate="global.form.username.label">Username</label>
            <input
              type="text"
              class="form-control"
              name="username"
              id="username"
              placeholder="{{ 'global.form.username.placeholder' | translate }}"
              formControlName="username"
              #username
              data-cy="username"
            />
            @if (loginForm.get('username')!.invalid && (loginForm.get('username')!.dirty || loginForm.get('username')!.touched)) {
              <div>
                @if (loginForm.get('username')?.errors?.required) {
                  <small class="form-text text-danger" jhiTranslate="global.messages.validate.email.required"
                    >Your username is required.</small
                  >
                }
              </div>
            }
          </div>
          <div class="mb-3">
            <label for="password" class="form-label" jhiTranslate="login.form.password">Password</label>
            <input
              type="password"
              class="form-control"
              name="password"
              id="password"
              placeholder="{{ 'login.form.password.placeholder' | translate }}"
              formControlName="password"
              data-cy="password"
            />
          </div>
          <br />
          <button type="submit" class="col-12 btn btn-primary success-btn" jhiTranslate="login.form.button" data-cy="submit">
            Sign in
          </button>
        </form>
      }
    </div>
    @if (screenWidth > 1024) {
      <div *ngIf="screenWidth > 1024" class="col col-log-register" [ngStyle]="{ display: screenWidth < 1024 ? 'none' : '' }">
        <img class="login-register-image" src="../../content/images/pharmacy-login.png" alt="" />
      </div>
    }
  </div>
</div>
