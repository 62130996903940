import { ConfigurationComponent } from './adminPharmacyBackOffice/pages/configuration/configuration.component';
import { ListPharmacyComponent } from './adminPharmacyBackOffice/pages/list-pharmacy/list-pharmacy.component';
import { LayoutAdminPharmacyComponent } from './adminPharmacyBackOffice/layout/layout-admin-pharmacy/layout-admin-pharmacy.component';
import { ListPaymentComponent } from './pharmaBackOffice/pages/list-payment/list-payment.component';
import { FavoritesComponent } from './favorites/favorites/favorites.component';
import { OrdersComponent } from './orders/orders/orders.component';
import { RouterModule, Routes } from '@angular/router';

import { Authority } from './config/authority.constants';
import { UserRouteAccessService } from './core/auth/user-route-access.service';
import { errorRoute } from './layouts/error/error.route';

import HomeComponent from './home/home.component';
import NavbarComponent from './layouts/navbar/navbar.component';
import LoginComponent from './login/login.component';
import FooterComponent from './layouts/footer/footer.component';
import { LandComponent } from './land/land.component';
import LoginAdminComponent from './admin/login-admin/login-admin.component';
import { HomeAdminComponent } from './admin/home-admin/home-admin.component';
import { CartComponent } from './cart/cart.component';
import { SideBarComponent } from './layouts/side-bar/side-bar.component';
import { PaiementsComponent } from './paiements/paiements/paiements.component';
import { ProfileComponent } from './profile/profile/profile.component';
import { OrderDetailsComponent } from './orders/order-details/order-details.component';
import { PharmacieDetailsComponent } from './home/pharmacie-details/pharmacie-details.component';
import { PharmacieSubCategoryComponent } from './home/pharmacie-sub-category/pharmacie-sub-category.component';
import { PharmacieProductsComponent } from './home/pharmacie-products/pharmacie-products.component';
import MainComponent from './layouts/main/main.component';
import { PaymentDetailsComponent } from './paiements/payment-details/payment-details.component';
import { SuccessComponent } from './paiements/success/success.component';
import { RefuseComponent } from './paiements/refuse/refuse.component';
import { DashboardComponent } from './pharmaBackOffice/pages/dashboard/dashboard.component';
import { ListPurchasesComponent } from './pharmaBackOffice/pages/list-purchases/list-purchases.component';
import { ListStockComponent } from './pharmaBackOffice/pages/list-stock/list-stock.component';
import { DetailsPurchaseComponent } from './pharmaBackOffice/pages/details-purchase/details-purchase.component';
import { PharmacyAccountComponent } from './pharmaBackOffice/pages/pharmacy-account/pharmacy-account.component';
import { CGVComponent } from './cgv/cgv.component';
import { CGUComponent } from './cgu/cgu.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { StripeConnectionSuccessComponent } from './pharmaBackOffice/pages/stripe-connection-success/stripe-connection-success.component';
import { DashboardAdminPharmacyComponent } from './adminPharmacyBackOffice/pages/dashboard-admin-pharmacy/dashboard-admin-pharmacy.component';
import { AdminPaymentsComponent } from './adminPharmacyBackOffice/pages/admin-payments/admin-payments.component';
import { AdminPharmacyLoginComponent } from './adminPharmacyBackOffice/pages/admin-pharmacy-login/admin-pharmacy-login.component';
import { RecallOfLotsComponent } from './recall-of-lots/recall-of-lots.component';
import { LegalNoticesComponent } from './legal-notices/legal-notices.component';
import { PharmacovigilanceComponent } from './pharmacovigilance/pharmacovigilance.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    title: 'home.title',
    children: [
      {
        path: '',
        component: LandComponent,
        title: 'home.title',
      },
      {
        path: 'home',
        component: HomeComponent,
        title: 'home.title',
      },
      {
        path: 'pharmacie-category',
        component: PharmacieDetailsComponent,
        title: 'home.title',
      },
      {
        path: 'pharmacie-sub-category',
        component: PharmacieSubCategoryComponent,
        title: 'home.title',
      },
      {
        path: 'pharmacie-products',
        component: PharmacieProductsComponent,
        title: 'home.title',
      },
      {
        path: 'cart',
        component: CartComponent,
      },
      {
        path: 'orders',
        component: OrdersComponent,
      },
      {
        path: 'order-details',
        component: OrderDetailsComponent,
      },
      {
        path: 'favorites',
        component: FavoritesComponent,
      },
      {
        path: 'payments',
        component: PaiementsComponent,
      },
      {
        path: 'payments-details',
        component: PaymentDetailsComponent,
      },
      {
        path: 'profile',
        component: ProfileComponent,
      },
    ],
  },
  {
    path: 'pharmacie',
    loadComponent() {
      return import('./pharmaBackOffice/layout/layout.component').then(c => c.LayoutComponent);
    },
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        title: 'home.title',
      },
      {
        path: 'purchases',
        component: ListPurchasesComponent,
        title: 'home.title',
      },
      {
        path: 'payment',
        component: ListPaymentComponent,
        title: 'home.title',
      },
      {
        path: 'stock',
        component: ListStockComponent,
        title: 'home.title',
      },
      {
        path: 'details-purchase',
        component: DetailsPurchaseComponent,
        title: 'home.title',
      },
      {
        path: 'profile',
        component: PharmacyAccountComponent,
        title: 'home.title',
      },
    ],
  },
  {
    path: 'admin-pharmacie',
    loadComponent() {
      return import('./adminPharmacyBackOffice/layout/layout-admin-pharmacy/layout-admin-pharmacy.component').then(
        c => c.LayoutAdminPharmacyComponent,
      );
    },
    children: [
      {
        path: 'dashboard',
        component: DashboardAdminPharmacyComponent,
        title: 'home.titlePharmacy',
      },
      {
        path: 'pharmacies',
        component: ListPharmacyComponent,
        title: 'home.titlePharmacy',
      },
      {
        path: 'paiements',
        component: AdminPaymentsComponent,
        title: 'home.titlePharmacy',
      },
      {
        path: 'configuration',
        component: ConfigurationComponent,
        title: 'home.titlePharmacy',
      },
    ],
  },
  {
    path: '',
    component: NavbarComponent,
    outlet: 'navbar',
  },
  {
    path: '',
    component: FooterComponent,
    outlet: 'footer',
  },
  {
    path: '',
    component: SideBarComponent,
    outlet: 'sideBar',
  },
  {
    path: 'admin-pharmacie/login',
    component: AdminPharmacyLoginComponent,
    title: 'home.titlePharmacy',
  },
  {
    path: 'success',
    component: SuccessComponent,
    title: 'home.title',
  },
  {
    path: 'stripe-connection-success',
    component: StripeConnectionSuccessComponent,
    title: 'home.title',
  },
  {
    path: 'refuse',
    component: RefuseComponent,
    title: 'home.title',
  },
  {
    path: 'CGV',
    component: CGVComponent,
    title: 'home.title',
  },
  {
    path: 'CGU',
    component: CGUComponent,
    title: 'home.title',
  },
  {
    path: 'Rappel-des-lots',
    component: RecallOfLotsComponent,
    title: 'home.title',
  },
  {
    path: 'Pharmacovigilance',
    component: PharmacovigilanceComponent,
    title: 'home.title',
  },
  {
    path: 'Mention-legales',
    component: LegalNoticesComponent,
    title: 'home.title',
  },
  {
    path: 'politique-de-confidentialite',
    component: PrivacyPolicyComponent,
    title: 'home.title',
  },

  {
    path: 'admin',
    data: {
      authorities: [Authority.ADMIN],
    },
    canActivate: [UserRouteAccessService],
    loadChildren: () => import('./admin/admin.routes'),
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.route'),
  },
  {
    path: 'login',
    component: LoginComponent,
    title: 'login.title',
  },
  {
    path: 'admin/',
    component: LoginAdminComponent,
    title: 'login.title',
  },
  {
    path: 'admin/home',
    component: HomeAdminComponent,
    title: 'home.title',
  },
  {
    path: '',
    loadChildren: () => import(`./entities/entity.routes`),
  },

  ...errorRoute,
];

export default routes;
