import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, ReplaySubject, of } from 'rxjs';
import { shareReplay, tap, catchError } from 'rxjs/operators';

import { StateStorageService } from '../../core/auth/state-storage.service';
import { Account } from '../../core/auth/account.model';
import { ApplicationConfigService } from '../config/application-config.service';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class AccountService {
  userIdentity: Account | null = null;
  authenticationState = new ReplaySubject<Account | null>(1);
  accountCache$?: Observable<Account> | null;
  accountSubject = new BehaviorSubject<Account | null>(null);

  activatedEmailAdminSubject = new BehaviorSubject<boolean>(false);
  activatedEmailAdmin$ = this.activatedEmailAdminSubject.asObservable();

  constructor(
    private translateService: TranslateService,
    private http: HttpClient,
    private stateStorageService: StateStorageService,
    private router: Router,
    private applicationConfigService: ApplicationConfigService,
  ) {}

  save(account: Account): Observable<{}> {
    return this.http.post(this.applicationConfigService.getEndpointFor('api/account'), account);
  }
  updateContact(email: string, phoneNumber: string): Observable<void> {
    return this.http.post<void>(this.applicationConfigService.getEndpointFor('api/account/update-contact'), {
      email,
      phoneNumber,
    });
  }
  confirmContact(phoneNumber: string, code: string): Observable<void> {
    return this.http.post<void>(this.applicationConfigService.getEndpointFor('api/account/confirm-contact'), { phoneNumber, code });
  }

  deleteAccount(): Observable<{}> {
    return this.http.post(this.applicationConfigService.getEndpointFor('api/account/logical_deleteuser'), {});
  }
  resendCode(login: any): Observable<{}> {
    return this.http.get(this.applicationConfigService.getEndpointFor('api/resendcode'), login);
  }
  activate(userLogin: string, code: string): Observable<{}> {
    // Construct the query parameters
    const params = new HttpParams().set('user_login', userLogin).set('code', code);

    // Make the POST request with the constructed query parameters
    return this.http.get(this.applicationConfigService.getEndpointFor('api/activatecode'), { params });
  }
  authenticate(identity: Account | null): void {
    this.userIdentity = identity;
    this.authenticationState.next(this.userIdentity);
    if (!identity) {
      this.accountCache$ = null;
    }
  }

  getAuthenticatedUser(): Observable<Account> {
    return this.http.get<Account>(this.applicationConfigService.getEndpointFor('api/account'));
  }

  hasAnyAuthority(authorities: string[] | string): boolean {
    if (!this.userIdentity) {
      return false;
    }
    if (!Array.isArray(authorities)) {
      authorities = [authorities];
    }
    return this.userIdentity.authorities.some((authority: string) => authorities.includes(authority));
  }

  identity(force?: boolean): Observable<Account | null> {
    if (!this.accountCache$ || force) {
      this.accountCache$ = this.fetch().pipe(
        tap((account: Account) => {
          this.authenticate(account);

          // After retrieve the account info, the language will be changed to
          // the user's preferred language configured in the account setting
          // unless user have choosed other language in the current session
          if (!this.stateStorageService.getLocale()) {
            this.translateService.use(account.langKey);
          }

          this.navigateToStoredUrl();
        }),
        shareReplay(),
      );
    }
    return this.accountCache$.pipe(catchError(() => of(null)));
  }

  isAuthenticated(): boolean {
    return this.userIdentity !== null;
  }

  getAuthenticationState(): Observable<Account | null> {
    return this.authenticationState.asObservable();
  }

  fetch(): Observable<Account> {
    return this.http.get<Account>(this.applicationConfigService.getEndpointFor('api/account'));
  }

  navigateToStoredUrl(): void {
    // previousState can be set in the authExpiredInterceptor and in the userRouteAccessService
    // if login is successful, go to stored previousState and clear previousState
    const previousUrl = this.stateStorageService.getUrl();
    if (previousUrl) {
      this.stateStorageService.clearUrl();
      this.router.navigateByUrl(previousUrl);
    }
  }
  setAccount(account: Account): void {
    this.accountSubject.next(account);
  }

  getAccount(): Observable<Account | null> {
    return this.accountSubject.asObservable();
  }

  setActivatedEmailAdmin(value: boolean): void {
    this.activatedEmailAdminSubject.next(value);
  }
  getPublishedLogs(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}api/blogs/published`);
  }
}
