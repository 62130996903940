import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit, ViewChild } from '@angular/core';
import { AccountService } from '../../../core/auth/account.service';
import { DashboardService } from '../../../pharmaBackOffice/services/dashboard/dashboard.service';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexTooltip,
  ApexStroke,
  NgApexchartsModule,
  ApexPlotOptions,
  ApexYAxis,
  ApexFill,
  ApexLegend,
} from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
};

@Component({
  selector: 'jhi-dashboard',
  standalone: true,
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, NgApexchartsModule],
})
export class DashboardComponent implements OnInit {
  @ViewChild('chart1') chart1: ChartComponent;
  @ViewChild('chart2') chart2: ChartComponent;
  // public chartOptions1: ChartOptions;
  // public chartOptions2: ChartOptions;
  account: any;
  dataKpis: any;
  dataRevenue: any;
  dataTopCategories: any;
  pharmacyId: any;

  chartOptions1: any;
  chartOptions2: any;

  constructor(
    private dashboardService: DashboardService,
    private accountService: AccountService,
  ) {
    // this.chartOptions1 = {
    //   series: [
    //     {
    //       name: 'series1',
    //       data: [31, 40, 28, 51, 42, 109, 100],
    //     },
    //     {
    //       name: 'series2',
    //       data: [11, 32, 45, 32, 34, 52, 41],
    //     },
    //   ],
    //   chart: {
    //     height: 350,
    //     type: 'area',
    //   },
    //   dataLabels: {
    //     enabled: false,
    //   },
    //   stroke: {
    //     curve: 'smooth',
    //   },
    //   xaxis: {
    //     type: 'datetime',
    //     categories: [
    //       '2018-09-19T00:00:00.000Z',
    //       '2018-09-19T01:30:00.000Z',
    //       '2018-09-19T02:30:00.000Z',
    //       '2018-09-19T03:30:00.000Z',
    //       '2018-09-19T04:30:00.000Z',
    //       '2018-09-19T05:30:00.000Z',
    //       '2018-09-19T06:30:00.000Z',
    //     ],
    //   },
    //   tooltip: {
    //     x: {
    //       format: 'dd/MM/yy HH:mm',
    //     },
    //   },
    //   plotOptions: {
    //     bar: {
    //       horizontal: false,
    //       columnWidth: '55%',
    //     },
    //   },
    //   yaxis: {
    //     title: {
    //       text: 'Value',
    //     },
    //   },
    //   fill: {
    //     opacity: 1,
    //   },
    //   legend: {
    //     show: true,
    //   },
    // };
    // this.chartOptions2 = {
    //   series: [
    //     {
    //       name: 'Net Profit',
    //       data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
    //     },
    //     {
    //       name: 'Revenue',
    //       data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
    //     },
    //     {
    //       name: 'Free Cash Flow',
    //       data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
    //     },
    //   ],
    //   chart: {
    //     type: 'bar',
    //     height: 350,
    //   },
    //   plotOptions: {
    //     bar: {
    //       horizontal: false,
    //       columnWidth: '55%',
    //     },
    //   },
    //   dataLabels: {
    //     enabled: false,
    //   },
    //   stroke: {
    //     show: true,
    //     width: 2,
    //     colors: ['transparent'],
    //   },
    //   xaxis: {
    //     categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
    //   },
    //   yaxis: {
    //     title: {
    //       text: '$ (thousands)',
    //     },
    //   },
    //   fill: {
    //     opacity: 1,
    //   },
    //   tooltip: {
    //     y: {
    //       formatter: function (val) {
    //         return '$ ' + val + ' thousands';
    //       },
    //     },
    //   },
    //   legend: {
    //     show: true,
    //   },
    // };
  }

  ngOnInit(): void {
    this.accountPharmacy();
  }
  accountPharmacy(): void {
    this.accountService.getAuthenticatedUser().subscribe(res => {
      this.account = res;
      this.pharmacyId = this.account?.applicationUser?.pharmacy?.id;
      this.getDataKpis(this.pharmacyId);
      this.getDataRevenue(this.pharmacyId);
      this.getDatatopCategories(this.pharmacyId);
    });
  }
  getDataKpis(pharmacyId: any): void {
    this.dashboardService.getKpis(pharmacyId).subscribe({
      next: res => {
        this.dataKpis = {
          ...res,
          currentMonthRevenue: res.currentMonthRevenue ?? '---',
          revenueGrowth: this.calculatePercentage(res.currentMonthRevenue, res.previousMonthRevenue),
          currentMonthPaidOrders: res.currentMonthPaidOrders ?? '---',
          paidOrdersGrowth: this.calculatePercentage(res.currentMonthPaidOrders, res.previousMonthPaidOrders),
          currentMonthNewClients: res.currentMonthNewClients ?? '---',
          newClientsGrowth: this.calculatePercentage(res.currentMonthNewClients, res.previousMonthNewClients),
          currentMonthProductsSold: res.currentMonthProductsSold ?? '---',
          productsSoldGrowth: this.calculatePercentage(res.currentMonthProductsSold, res.previousMonthProductsSold),
        };
      },
    });
  }
  calculatePercentage(current: number | null, previous: number | null): string {
    if (current !== null && previous !== null && previous !== 0) {
      const growth = ((current - previous) / previous) * 100;
      return growth >= 0 ? `${growth.toFixed(2)}%` : '---';
    }
    return '---';
  }

  getDataRevenue(pharmacyId: any): void {
    this.dashboardService.getRevenue(pharmacyId).subscribe({
      next: res => {
        this.dataRevenue = res;
        this.updateLineChart();
      },
    });
  }
  getDatatopCategories(pharmacyId: any): void {
    this.dashboardService.getTopCategories(pharmacyId).subscribe({
      next: res => {
        this.dataTopCategories = res;
        this.updateBarChart();
      },
    });
  }
  updateLineChart(): void {
    const categories = Object.keys(this.dataRevenue); // ['JANUARY', 'FEBRUARY', ...]
    const data = Object.values(this.dataRevenue); // [0, 0, ..., 130240]

    this.chartOptions1 = {
      series: [
        {
          name: 'Revenue',
          data,
        },
      ],
      chart: {
        height: 350,
        type: 'line',
      },
      xaxis: {
        categories,
      },
      stroke: {
        curve: 'smooth',
      },
      tooltip: {
        x: {
          format: 'MM/yyyy',
        },
      },
      dataLabels: {
        enabled: false,
      },
    };
  }

  updateBarChart(): void {
    const months = Object.keys(this.dataTopCategories); // ['JANUARY', 'FEBRUARY', ...]
    const categoriesMap = new Map<string, number[]>(); // Map pour stocker les catégories et leurs données de vente
    const categoriesSet = new Set<string>();

    months.forEach(month => {
      this.dataTopCategories[month].forEach((category: any) => {
        const categoryName = category.categoryName;
        categoriesSet.add(categoryName);

        // Si la catégorie n'existe pas dans la map, initialisez-la avec des ventes à 0 pour chaque mois
        if (!categoriesMap.has(categoryName)) {
          categoriesMap.set(categoryName, new Array(months.length).fill(0));
        }

        // Ajouter la quantité vendue pour le mois en cours
        const monthIndex = months.indexOf(month); // Trouver l'index du mois
        categoriesMap.get(categoryName)![monthIndex] = category.totalQuantitySold;
      });
    });

    // Préparer les données de série pour le chart
    const seriesData = Array.from(categoriesMap.keys()).map(categoryName => ({
      name: categoryName,
      data: categoriesMap.get(categoryName) ?? [],
    }));

    // Configuration du chart avec les mois sur l'axe X et les catégories en série
    this.chartOptions2 = {
      series: seriesData,
      chart: {
        type: 'bar',
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      xaxis: {
        categories: months, // Mois sur l'axe X
      },
      yaxis: {
        title: {
          text: 'Quantity Sold',
        },
      },
      fill: {
        opacity: 1,
      },
      dataLabels: {
        enabled: false,
      },
    };
  }
}
