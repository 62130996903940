import { Component, HostListener, NgZone, OnInit, Renderer2, ViewChild, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { StateStorageService } from '../../core/auth/state-storage.service';
import SharedModule from '../../shared/shared.module';
import HasAnyAuthorityDirective from '../../shared/auth/has-any-authority.directive';
import { VERSION } from '../../app.constants';
import { LANGUAGES } from '../../config/language.constants';
import { Account } from '../../core/auth/account.model';
import { AccountService } from '../../core/auth/account.service';
import { EntityNavbarItems } from '../../entities/entity-navbar-items';
import ActiveMenuDirective from './active-menu.directive';
import NavbarItem from './navbar-item.model';
import { filter } from 'rxjs';
import { HomeServiceService } from '../../home/service/home-service.service';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule, DatePipe } from '@angular/common';
import { CartService } from '../../cart/cart.service';
import { GoogleMap, GoogleMapsModule } from '@angular/google-maps';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from '../../notification/notification.service';
import { ProfileService } from '../profiles/profile.service';
import { LoginService } from '../../login/login.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';

@Component({
  standalone: true,
  selector: 'jhi-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    SharedModule,
    GoogleMapsModule,
    HasAnyAuthorityDirective,
    ActiveMenuDirective,
    ReactiveFormsModule,
  ],
  providers: [DatePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export default class NavbarComponent implements OnInit {
  @ViewChild('modalgeoAdd') modalgeoAdd: any;
  @ViewChild('modalgeo') modalgeo: any;
  @ViewChild('modalgeoTow') modalgeoTow: any;
  @ViewChild('modalMaps') modalMaps: any;
  @ViewChild('modalDeliveryMan') modalDeliveryMan: any;
  @ViewChild('mapComponent', { static: false }) mapComponent!: GoogleMap;

  addresses: any[] = [];
  address: any;

  map!: google.maps.Map;
  center: google.maps.LatLngLiteral = { lat: 46.603354, lng: 1.888334 };
  zoom: number = 10;
  latitude: number = 0;
  longitude: number = 0;
  markerPosition!: google.maps.LatLngLiteral;
  markerOptions: google.maps.MarkerOptions = {
    draggable: true,
  };
  inProduction?: boolean;
  isNavbarCollapsed = true;
  languages = LANGUAGES;
  openAPIEnabled?: boolean;
  version = '';
  account: Account | null = null;
  accountFromService: any;
  entitiesNavbarItems: NavbarItem[] = [];
  disable = false;
  delfaultLang!: any;
  screenHeight = window.innerHeight;
  screenWidth = window.innerWidth;
  currentUrl: any;
  likeCounter: number = 0;
  searchForm: FormGroup;
  cartItemsCount: number = 0;
  cartItemsCountPrescription: number = 0;
  categoriesP: any[] = [];
  categoriesM: any[] = [];
  addressId: any;
  readNotif: any;
  selectedAddressId: number | null = null;
  imageUrl: string = 'https://imagedelivery.net/z1UHS7Z96nNnNVE8sIpBxg/';
  recentAddress: any;
  notifications: any;
  selectedPlace!: google.maps.places.PlaceResult;
  selectedCategoryId: number | null = null;
  adresseForm = new FormGroup({
    id: new FormControl(''),
    appartmentNumber: new FormControl(''),
    name: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    streetNumber: new FormControl(''),
    streetName: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    postalCode: new FormControl(''),
    country: new FormControl(''),
    address: new FormControl(''),
  });
  constructor(
    private loginService: LoginService,
    private translateService: TranslateService,
    private stateStorageService: StateStorageService,
    private accountService: AccountService,
    private profileService: ProfileService,
    private router: Router,
    private pharmacyService: HomeServiceService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private cartService: CartService,
    private toastr: ToastrService,
    private ngZone: NgZone,
    private notificationService: NotificationService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      this.disable = event.url.includes('/account/register') || event.url.includes('/login') || event.url.includes('/account/activate');
    });
    if (VERSION) {
      this.version = VERSION.toLowerCase().startsWith('v') ? VERSION : `v${VERSION}`;
    }
    this.currentUrl = router.url;
    this.searchForm = this.fb.group({
      searchkeys: [''],
    });
    this.cartService.cartItems$.subscribe(cartItems => {
      this.cartItemsCount = 0;
      cartItems.forEach(item => {
        const quantity = parseInt(item.quantity, 10); // Add radix parameter
        if (!isNaN(quantity)) {
          this.cartItemsCount += quantity;
        }
      });
    });
  }

  ngOnInit(): void {
    this.getCategoriesM();
    this.getCategoriesP();
    this.clearSelection();
    this.route.params.subscribe(params => {
      if (params['catId']) {
        this.selectedCategoryId = +params['catId'];
      } else {
        this.selectedCategoryId = null;
      }
    });
    this.delfaultLang = this.stateStorageService.getLocale()
      ? this.stateStorageService.getLocale()
      : this.translateService.getDefaultLang();
    this.entitiesNavbarItems = EntityNavbarItems;
    this.profileService.getProfileInfo().subscribe(profileInfo => {
      this.inProduction = profileInfo.inProduction;
      this.openAPIEnabled = profileInfo.openAPIEnabled;
    });
    this.accountService.getAuthenticationState().subscribe(account => {
      this.account = account;
    });
    this.getAccount();
    this.pharmacyService.selectedAddressId$.subscribe(addressId => {
      this.selectedAddressId = addressId;
    });
    if (this.account) {
      this.loadAddresses();
      this.pharmacyService.selectedAddress$.subscribe(address => {
        if (address) {
          this.selectedAddressId = address.id;
        }
      });
    }
    if (this.account) {
      this.getNotifications();
      this.notificationService.currentMessage.subscribe(message => {
        if (message) {
          this.notifications.unshift(message.notification);
          this.updateLikeCounter();
        }
      });
      this.pharmacyService.addressChange$.subscribe(() => {
        this.OpenAddressModalModalgeo();
      });
    }
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: Event): void {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }
  getAccount(): void {
    this.accountService.getAuthenticatedUser().subscribe(account => {
      this.accountFromService = account;
      this.accountService.setAccount(account);
    });
  }
  changeLanguage(languageKey: string): void {
    this.stateStorageService.storeLocale(languageKey);
    this.translateService.use(languageKey);
    this.delfaultLang = languageKey;
    localStorage.setItem('selectedLanguage', languageKey);
  }

  collapseNavbar(): void {
    this.isNavbarCollapsed = true;
  }

  login(): void {
    this.router.navigate(['/login']);
  }

  logout(): void {
    this.collapseNavbar();

    if (this.account?.authorities.includes('ROLE_ADMIN')) {
      this.loginService.logout();

      this.router.navigate(['/admin/signin']);
    } else {
      this.loginService.logout();

      this.router.navigate(['/']);
    }
  }

  toggleNavbar(): void {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }

  clickElementByClassName(className: string): void {
    const element = document.querySelector(`.${className}`);
    if (element) {
      (element as HTMLElement).click(); // Ensure the element is cast to HTMLElement
    } else {
      console.warn(`Element with class name ${className} not found.`);
    }
  }

  goToCart(): void {
    this.clickElementByClassName('panier');
  }

  goToFavorit(): void {
    this.clickElementByClassName('favoris');
  }

  goToProfile(): void {
    this.clickElementByClassName('profile');
  }
  onSearch(): void {
    const searchkeys = this.searchForm.get('searchkeys')?.value;
    this.router.navigate(['/home'], { queryParams: { searchkeys } });
    this.searchForm.reset();
  }
  openModal(modalRef: any): void {
    this.adresseForm.reset();
    this.modalService.open(modalRef, { size: 'l', centered: true });
  }

  useCurrentLocation(): void {
    navigator.geolocation.getCurrentPosition(
      position => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        this.getAddressFromCoordinates(lat, lng);
      },
      error => {
        console.error('Error getting location', error);
        this.toastr.error("Impossible d'obtenir votre position.");
      },
    );
  }

  getAddressFromCoordinates(lat: number, lng: number): void {
    this.pharmacyService.reverseGeocode(lat, lng).subscribe(
      (address: string) => {
        this.adresseForm.controls['address'].setValue(address);
      },
      error => {
        this.toastr.error("Erreur lors de la récupération de l'adresse.");
      },
    );
  }
  onUseMyPositionClick(geolocationData: any): void {
    const results = geolocationData.results[0];

    let streetNumber = '';
    let streetName = '';
    let city = '';
    let state = '';
    let postalCode = '';
    let country = '';

    results.address_components.forEach((component: any) => {
      if (component.types.includes('street_number')) {
        streetNumber = component.long_name;
      }
      if (component.types.includes('route')) {
        streetName = component.long_name;
      }
      if (component.types.includes('locality')) {
        city = component.long_name;
      }
      if (component.types.includes('administrative_area_level_1')) {
        state = component.long_name;
      }
      if (component.types.includes('postal_code')) {
        postalCode = component.long_name;
      }
      if (component.types.includes('country')) {
        country = component.long_name;
      }
    });

    // Mise à jour du formulaire avec les données extraites
    this.adresseForm.patchValue({
      streetNumber,
      streetName,
      city,
      state,
      postalCode,
      country,
    });
  }

  openMapInHome(): void {
    this.modalService.open(this.modalMaps, { size: 'l', centered: true });
    this.markerPosition = { lat: 46.553354, lng: 1.925334 };
  }

  onPlaceSelected(place: google.maps.places.PlaceResult): void {
    this.selectedPlace = place;
    if (this.account?.applicationUser) {
      const applicationUser = {
        ...this.account.applicationUser,
        deliveryAddress: this.selectedPlace.formatted_address,
      };
    }

    if (place.address_components) {
      this.ngZone.run(() => {
        const components = place.address_components as google.maps.GeocoderAddressComponent[];
        const getAddressComponent = (type: string): string => components.find(component => component.types.includes(type))?.long_name ?? '';
        this.adresseForm.patchValue({
          appartmentNumber: this.adresseForm.get('appartmentNumber')?.value ?? '',
          name: this.adresseForm.get('name')?.value ?? '',
          streetNumber: getAddressComponent('street_number'),
          streetName: getAddressComponent('route'),
          city: getAddressComponent('locality'),
          state: getAddressComponent('administrative_area_level_1'),
          postalCode: getAddressComponent('postal_code'),
          country: getAddressComponent('country'),
        });
      });
    }
  }
  loadAddresses(): void {
    this.pharmacyService.getAdresse().subscribe(
      data => {
        this.addresses = data;

        if (this.account && this.account.authorities.includes('ROLE_USER') && this.addresses.length === 0) {
          this.modalService.open(this.modalgeoAdd, { size: 'l', centered: true });
        }
        this.recentAddress = this.addresses
          .filter((address: any) => address.lastUsedDate)
          .sort((a: any, b: any) => new Date(b.lastUsedDate).getTime() - new Date(a.lastUsedDate).getTime())[0];
        if (this.selectedAddressId) {
          const selectedAddress = this.addresses.find(address => address.id === this.selectedAddressId);
          if (selectedAddress) {
            this.onAddressSelection(selectedAddress);
          } else {
            this.selectedAddressId = null;
          }
        }
        if (!this.selectedAddressId && this.recentAddress) {
          this.onAddressSelection(this.recentAddress);
        }
        if (this.selectedAddressId) {
          const selectedAddressIndex = this.addresses.findIndex(address => address.id === this.selectedAddressId);
          if (selectedAddressIndex > -1) {
            const selectedAddress = this.addresses.splice(selectedAddressIndex, 1)[0];
            this.addresses.unshift(selectedAddress);
          }
        }
      },
      error => {
        this.toastr.error('Erreur lors de la récupération des adresses');
      },
    );
  }

  getNotifications(): void {
    this.notificationService.getNotifications().subscribe(
      data => {
        this.notifications = data;
        this.updateLikeCounter();
      },
      error => {
        this.toastr.error('Erreur lors de la récupération des notifications', error);
      },
    );
  }

  formatNotificationDate(date: string): string {
    const now = new Date();
    const notificationDate = new Date(date);

    if (now.toDateString() === notificationDate.toDateString()) {
      return this.datePipe.transform(notificationDate, 'HH:mm') ?? '';
    } else {
      return this.datePipe.transform(notificationDate, 'dd/MM HH:mm') ?? '';
    }
  }

  updateLikeCounter(): void {
    if (this.notifications?.content) {
      this.likeCounter = this.notifications.content.filter((notif: any) => !notif.read).length;
    }
  }

  onAddressChange(address: any): void {
    this.adresseForm.patchValue({
      id: address.id,
      appartmentNumber: address.appartmentNumber,
      name: address.name,
      streetNumber: address.streetNumber,
      streetName: address.streetName,
      city: address.city,
      state: address.state,
      postalCode: address.postalCode,
      country: address.country,
      address,
    });
    // this.address = address.streetName;
    this.address = `${address.streetNumber} ${address.streetName}, ${address.city}, ${address.state}, ${address.postalCode}, ${address.country}`;
    this.addressId = address.id;
    this.modalService.open(this.modalgeoTow, { size: 'l', centered: true });
  }
  addAdressToclient(): void {
    if (this.adresseForm.valid) {
      this.pharmacyService.addAdresse(this.adresseForm.value).subscribe(
        data => {
          this.toastr.success('Adresse ajoutée avec succès');
          this.addresses.push(data);
          this.selectedAddressId = data.id;
          this.pharmacyService.setSelectedAddress(data);
          this.onSave();
        },
        error => {
          this.toastr.error('Veuillez saisir une adresse complète!');
        },
      );
    } else {
      this.toastr.error('Veuillez remplir tous les champs obligatoires');
    }
  }
  readNotification(notificationId: any): void {
    this.notificationService.readNotification(notificationId).subscribe(
      data => {
        const index = this.notifications?.content.findIndex((notif: any) => notif.id === notificationId);
        if (index !== -1) {
          this.notifications.content[index].read = true;
        }
        this.getNotifications();
        const notification = this.notifications?.content.find((notif: any) => notif.id === notificationId);
        if (notification?.entityType === 'Purchase') {
          // this.router.navigate(['/order-details', { purchase_id: notification.entityId }]);
          this.router.navigate(['/order-details', notification.entityId]);
        }
      },
      error => {
        console.error('Erreur lors de la lecture de la notification:', error);
      },
    );
  }
  updateAdresse(): void {
    if (this.adresseForm.valid) {
      this.pharmacyService.updateAdresse(this.adresseForm.value, this.addressId).subscribe(
        data => {
          this.toastr.success('Adresse modifieé avec succès');
          this.loadAddresses();
        },
        error => {
          this.toastr.error("Erreur lors de la modification de l'adresse");
        },
      );
    } else {
      this.toastr.error('Veuillez remplir tous les champs obligatoires');
    }
  }
  onAddressSelection(address: any): void {
    this.selectedAddressId = address.id;
    this.pharmacyService.setSelectedAddress(address);
  }
  onSave(): void {
    if (!this.selectedAddressId) {
      this.toastr.error('Veuillez sélectionner une adresse.');
      return;
    }
    const selectedAddress = this.addresses.find(address => address.id === this.selectedAddressId);
    if (!selectedAddress) {
      this.toastr.error('Adresse sélectionnée introuvable.');
      return;
    }
    const body = {
      id: selectedAddress.id,
      streetNumber: selectedAddress.streetNumber,
      streetName: selectedAddress.streetName,
      city: selectedAddress.city,
      postalCode: selectedAddress.postalCode,
      country: selectedAddress.country,
    };
    this.pharmacyService.saveAdress(selectedAddress.id, body).subscribe(
      response => {
        this.toastr.success('Adresse mise à jour avec succès');
        this.loadAddresses();
        this.getAccount();
        this.pharmacyService.setSelectedAddress(selectedAddress);
        this.pharmacyService.setAddressCoordinates(response.latitude, response.longitude);
        this.pharmacyService.setSelectedAddressId(selectedAddress.id);
        localStorage.setItem('selectedAddressId', selectedAddress.id);
        this.pharmacyService.setSelectedAddressCoords({ lat: response.latitude, lng: response.longitude });
        // localStorage.setItem('selectedAddressCoords', JSON.stringify({ lat: response.latitude, lng: response.longitude }));
        localStorage.setItem(
          'selectedAddress',
          JSON.stringify({ city: selectedAddress.city, country: selectedAddress.country, street: selectedAddress.streetName }),
        );
        this.pharmacyService.notifyAddressUpdated();
      },
      error => {
        this.toastr.error('Veuillez saisir une adresse complète!');
        console.error('Error updating address:', error);
      },
    );
  }

  deleteAdress(addressId: any): void {
    this.pharmacyService.deleteAdress(addressId).subscribe(data => {
      this.loadAddresses();
      this.toastr.success('Adresse supprimé avec succès');
    });
  }
  logCenter(event: any): void {
    const newCenter: any = this.mapComponent.getCenter();
    this.getAddress(event.latLng.lat(), event.latLng.lng());
  }

  getAddress(lat: number, lng: number): void {
    const geocoder = new google.maps.Geocoder();
    const latlng = { lat, lng };

    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results?.[0]) {
          // Safely check for results
          this.address = results[0].formatted_address;
        } else {
          console.error('No results found');
        }
      } else {
        console.error('Geocoder failed due to:', status);
      }
    });
  }
  reOpenAddressModal(): void {
    this.modalService.open(this.modalgeoAdd, { size: 'l', centered: true });
    this.onPlaceSelected(this.selectedPlace);
  }
  OpenAddressModal(): void {
    this.adresseForm.reset();
    this.modalService.open(this.modalgeoAdd, { size: 'l', centered: true });
  }
  OpenAddressModalModalgeo(): void {
    this.modalService.open(this.modalgeo, { size: 'l', centered: true });
  }
  getCategoriesP(): void {
    this.pharmacyService.getAllCategoriesP().subscribe({
      next: (res: any) => {
        this.categoriesP = res;
      },
    });
  }
  getCategoriesM(): void {
    this.pharmacyService.getAllCategoriesM().subscribe({
      next: (res: any) => {
        this.categoriesM = res;
      },
    });
  }
  NavigateToProduct(catId: number, catName: string, dropdown: NgbDropdown): void {
    this.selectedCategoryId = catId;
    dropdown.close();
    this.router.navigate(['/produit', catId, catName]);
  }

  isCategorySelected(catId: number): boolean {
    return this.router.url.startsWith('/produit') && this.selectedCategoryId === catId;
  }

  clearSelection(): void {
    this.selectedCategoryId = null;
  }
  openModalDeliveryMan(): void {
    this.modalService.open(this.modalDeliveryMan, { size: 'l', centered: true });
  }
}
