import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Account } from '../core/auth/account.model';
import { AccountService } from '../core/auth/account.service';
import { LoginService } from '../login/login.service';
import SharedModule from '../shared/shared.module';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HomeServiceService } from '../home/service/home-service.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
interface Pharmacy {
  schedule?: PharmacySchedule;
  status?: string;
}
interface PharmacySchedule {
  openingHour: string;
  closingHour: string;
}
@Component({
  selector: 'jhi-pharmacy-search',
  standalone: true,
  imports: [SharedModule, FormsModule, ReactiveFormsModule, RouterModule, NgxSkeletonLoaderModule],
  templateUrl: './pharmacy-search.component.html',
  styleUrl: './pharmacy-search.component.scss',
})
export class PharmacySearchComponent implements OnInit {
  account: Account | null = null;
  @ViewChild('modalMaps') modalMaps: any;
  searchQuery: string = '';
  params: any;
  contentLoaded: boolean = false;
  searchkeys: any;
  pharmacy: any[] = [];
  imageUrl: string = 'https://imagedelivery.net/z1UHS7Z96nNnNVE8sIpBxg/';
  isSearchActive = false;
  authenticationError = false;
  loginForm = new FormGroup({
    username: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    password: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    rememberMe: new FormControl(false, { nonNullable: true, validators: [Validators.required] }),
  });
  private readonly destroy$ = new Subject<void>();

  constructor(
    private accountService: AccountService,
    private loginService: LoginService,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private _toaster: ToastrService,
    private modalService: NgbModal,
    private homeService: HomeServiceService,
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.contentLoaded = true;
    }, 2000);
    this.getSearchKey();

    localStorage.setItem('isLandComponentActive', 'true');
    this.accountService.identity().subscribe(() => {
      if (this.accountService.isAuthenticated()) {
        this.accountService
          .getAuthenticationState()
          .pipe(takeUntil(this.destroy$))
          .subscribe(account => (this.account = account));
        if (
          (!this.account?.applicationUser || !this.account.applicationUser.proProfileCompleted) &&
          this.account?.authorities.includes('ROLE_PHARMACIST')
        ) {
          this.router.navigate(['/login'], { queryParams: { user: 'completeCreation' } });
        } else {
          this.router.navigate(['/home']);
        }
      }
    });
    this.route.queryParams.subscribe(params => {
      if (params['type']) {
        this.params = params['type'];
      }
    });
  }

  login(): void {
    this.loginService.login(this.loginForm.getRawValue()).subscribe({
      next: () => {
        this.authenticationError = false;
        if (!this.router.getCurrentNavigation()) {
          this.accountService
            .getAuthenticationState()
            .pipe(takeUntil(this.destroy$))
            .subscribe(account => (this.account = account));
          if (!this.account?.applicationUser || !this.account.applicationUser.proProfileCompleted) {
            this.router.navigate(['/login'], { queryParams: { user: 'completeCreation' } });
          } else {
            this.router.navigate(['/home']);
          }
        }
      },
      error: () => {
        this.authenticationError = true;
        this._toaster.error(this.translateService.instant('login.messages.error.authentication'));
      },
    });
  }
  openModal(): void {
    this.modalService.open(this.modalMaps, { size: 'l', centered: true });
  }
  getStatusColor(status: string): string {
    switch (status) {
      case 'open':
        return '#83b011';
      case 'closed':
        return 'red';
      case 'open_soon':
      case 'close_soon':
        return 'orange';
      default:
        return 'black';
    }
  }
  getStatusMessage(status: string): string {
    switch (status) {
      case 'open':
        return 'Ouverte';
      case 'closed':
        return 'Fermée';
      case 'open_soon':
        return 'Ouverte bientôt';
      case 'close_soon':
        return 'Ferme bientôt';
      default:
        return '';
    }
  }
  getSearchKey(): void {
    this.route.queryParams.subscribe(params => {
      this.searchkeys = params['searchkeys'] || '';
      if (this.searchkeys) {
        this.searchPharmacies(this.searchkeys);
      }
    });
  }

  searchPharmacies(searchkeys: string): void {
    this.homeService.searchPharmacies(searchkeys).subscribe({
      next: (data: any) => {
        this.pharmacy = data;
        this.isSearchActive = true;
        this.pharmacy = this.pharmacy.map((ph: Pharmacy) => {
          if (ph.schedule) {
            const { openingHour, closingHour } = ph.schedule;
            const currentDate = new Date();
            const currentTime = currentDate.getTime();

            // Parse opening and closing hours into Date objects
            const openingTime = new Date(currentDate.toISOString().split('T')[0] + 'T' + openingHour);
            const closingTime = new Date(currentDate.toISOString().split('T')[0] + 'T' + closingHour);

            // Calculate time differences in milliseconds
            const timeToOpen = openingTime.getTime() - currentTime;
            const timeToClose = closingTime.getTime() - currentTime;

            // Define thresholds for "soon" (1 hour = 3600000 milliseconds)
            const SOON_THRESHOLD = 3600000;

            // Determine the pharmacy status
            if (currentTime >= openingTime.getTime() && currentTime < closingTime.getTime()) {
              ph.status = timeToClose <= SOON_THRESHOLD ? 'close_soon' : 'open'; // Closing soon or Open
            } else if (currentTime < openingTime.getTime()) {
              ph.status = timeToOpen <= SOON_THRESHOLD ? 'open_soon' : 'closed'; // Opening soon or Closed
            } else {
              ph.status = 'closed'; // Default to closed if outside operational hours
            }
          } else {
            ph.status = 'closed'; // Default to closed if no schedule is available
          }
          return ph; // Now safely typed as Pharmacy
        });
      },
      error: (err: any) => {
        console.error('Erreur lors de la recherche:', err);
        this.isSearchActive = false;
      },
    });
  }
  searchPharmacy(): void {
    if (this.searchQuery.trim()) {
      this.router.navigate(['/pharmacie'], { queryParams: { searchkeys: this.searchQuery } });
    }
  }
}
