import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HomeServiceService } from '../../../home/service/home-service.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ToastrService } from 'ngx-toastr';
import { PaginatorModule } from 'primeng/paginator';
import { debounceTime, distinctUntilChanged, Subject, switchMap } from 'rxjs';
import { CartService } from '../../../cart/cart.service';
import { BrowserModule } from '@angular/platform-browser';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}
@Component({
  selector: 'jhi-list-stock',
  standalone: true,
  imports: [CommonModule, NgxSkeletonLoaderModule, PaginatorModule, ReactiveFormsModule],
  templateUrl: './list-stock.component.html',
  styleUrl: './list-stock.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ListStockComponent implements OnInit, OnDestroy {
  @ViewChild('modalProduct') modalProduct: any;

  stockItems: any;
  contentLoaded = false;
  selectedFile: File | null = null;
  searchInputSubject = new Subject<string>();
  first: number = 0;
  rows: number = 1;
  totalRecords: number = 5;
  imageUrl: string = 'https://imagedelivery.net/z1UHS7Z96nNnNVE8sIpBxg/';
  isPromoActive: boolean = false;
  productForm: FormGroup;
  isUploading: boolean = false;
  openedProduct: any;
  value: number = 1;
  isExpanded = false;
  isExpandedDescription = false;
  activeTab: string = 'composition';
  uploadProgress: number = 0;
  private progressCheckInterval: any;
  currentTaskId: string | null = null;

  constructor(
    private pharmacieService: HomeServiceService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private cartService: CartService,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.contentLoaded = true;
    }, 2000);
    this.getStock();
    this.searchInputSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(searchName => {
          const data = {
            contactName: searchName.trim().toLowerCase(),
          };
          return this.pharmacieService.searchStockPharmacist(searchName.trim().toLowerCase()).pipe();
        }),
      )
      .subscribe((res: any) => {
        this.stockItems = res;
      });
    this.initProductForm();
    this.checkExistingUpload();
  }
  onPageChange(event: any): void {
    const pageEvent: PageEvent = {
      first: event.first ?? 0,
      rows: event.rows ?? 1,
      page: event.page ?? 0,
      pageCount: event.pageCount ?? 0,
    };

    this.first = pageEvent.first;
    this.rows = pageEvent.rows;
    this.getStock();
  }
  getStock(): void {
    this.pharmacieService.getListStockItems(this.first).subscribe({
      next: res => {
        this.stockItems = res;
        this.initProductForm();
      },
    });
  }

  // onUpload(modalUpload: any): void {
  //   if (this.selectedFile) {
  //     this.isUploading = true;
  //     const formData = new FormData();
  //     formData.append('file', this.selectedFile);

  //     this.pharmacieService.uploadStockItems(formData).subscribe(
  //       response => {
  //         this.toastr.success('Fichiers téléchargés avec succès!');
  //         this.getStock();
  //         this.selectedFile = null;
  //         this.isUploading = false;

  //         modalUpload.dismiss('Cross click');
  //       },
  //       error => {
  //         console.error('Error uploading file', error);
  //         this.selectedFile = null;
  //         this.toastr.error('Erreur lors du téléchargement des fichiers.');
  //         this.isUploading = false;
  //       },
  //     );
  //   } else {
  //     console.error('No file selected');
  //     this.toastr.warning('Aucun fichier sélectionné. Veuillez sélectionner un fichier à télécharger.');
  //   }
  // }

  onFileChange(event: any): void {
    this.selectedFile = event.target.files[0];
  }

  onUpload(modalUpload: any): void {
    if (this.selectedFile) {
      this.isUploading = true;
      this.uploadProgress = 0;
      const formData = new FormData();
      formData.append('file', this.selectedFile);

      this.pharmacieService.uploadStockItems(formData).subscribe({
        next: (taskId: string) => {
          // Store taskId in localStorage
          localStorage.setItem('currentUploadTaskId', taskId);
          this.currentTaskId = taskId;
          this.checkUploadProgress(taskId, modalUpload);
        },
        error: error => {
          this.handleUploadError(error, modalUpload);
        },
      });
    } else {
      this.toastr.warning('Aucun fichier sélectionné. Veuillez sélectionner un fichier à télécharger.');
    }
  }

  checkExistingUpload(): void {
    const storedTaskId = localStorage.getItem('currentUploadTaskId');
    if (storedTaskId) {
      this.currentTaskId = storedTaskId;
      this.isUploading = true;
      this.checkUploadProgress(storedTaskId, null);
    }
  }

  checkUploadProgress(taskId: string, modalUpload: any): void {
    // Clear any existing interval
    if (this.progressCheckInterval) {
      clearInterval(this.progressCheckInterval);
    }

    this.progressCheckInterval = setInterval(() => {
      this.pharmacieService.checkUploadProgress(taskId).subscribe({
        next: progressResponse => {
          this.uploadProgress = progressResponse.progress;

          if (progressResponse.status === 'COMPLETED') {
            this.handleUploadCompletion(modalUpload);
          } else if (progressResponse.status === 'ERROR') {
            this.handleUploadError(null, modalUpload);
          }
        },
        error: error => {
          this.handleUploadError(error, modalUpload);
        },
      });
    }, 1000);
  }

  handleUploadCompletion(modalUpload: any): void {
    if (this.progressCheckInterval) {
      clearInterval(this.progressCheckInterval);
    }

    // Clean up storage
    localStorage.removeItem('currentUploadTaskId');
    this.currentTaskId = null;

    this.toastr.success('Fichier téléchargé et traité avec succès!');
    this.getStock();
    this.selectedFile = null;
    this.isUploading = false;
    this.uploadProgress = 0;

    if (modalUpload) {
      modalUpload.dismiss('Cross click');
    }
  }

  handleUploadError(error: any, modalUpload: any): void {
    if (this.progressCheckInterval) {
      clearInterval(this.progressCheckInterval);
    }

    // Clean up storage
    localStorage.removeItem('currentUploadTaskId');
    this.currentTaskId = null;

    if (error) {
      console.error('Error:', error);
      this.toastr.error("Erreur lors de l'opération.");
    }

    this.selectedFile = null;
    this.isUploading = false;
    this.uploadProgress = 0;

    if (modalUpload) {
      modalUpload.dismiss('Cross click');
    }
  }
  cancelUpload(): void {
    if (confirm('Voulez-vous vraiment annuler le téléchargement en cours?')) {
      this.handleUploadError(null, null);
    }
  }
  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchInputSubject.next(filterValue);
  }
  openModal(modal: any): void {
    this.modalService.open(modal, { size: 'lg', centered: true });
  }

  openproductModal(modal: any, product: any): void {
    this.modalService.open(modal, { size: 'lg', centered: true });
    this.openedProduct = product;
    this.value = this.cartService.getCurrent(product);
    this.initProductForm();
  }
  setActiveTab(tab: string): void {
    this.activeTab = tab;
  }
  increment(): void {
    this.value++;
  }
  decrement(): void {
    if (this.value > 1) {
      this.value--;
    }
  }
  initProductForm(): void {
    this.productForm = this.fb.group({
      price: [this.openedProduct?.price || 0, Validators.required],
      priceDiscount: [this.openedProduct?.priceDiscount || 0],
      availableStock: [this.openedProduct?.availableStock || 0, Validators.required],
      discount: [this.openedProduct?.discount || 0],
    });
    this.isPromoActive = !!this.openedProduct?.discount;
  }

  togglePromo(): void {
    this.isPromoActive = !this.isPromoActive;
    if (!this.isPromoActive) {
      this.productForm.get('priceDiscount')?.reset(); // Réinitialise le champ promo si désactivé
      this.productForm.get('discount')?.reset();
    }
  }
  updateStockItem(productId: any): void {
    const stockItemData = {
      id: productId,
      price: this.productForm.value.price,
      priceDiscount: this.isPromoActive ? this.productForm.value.priceDiscount : null,
      availableStock: this.productForm.value.availableStock,
      discount: this.isPromoActive ? this.productForm.value.discount : null,
    };

    this.pharmacieService.updateStockItem(stockItemData).subscribe({
      next: response => {
        this.toastr.success('Produit mis à jour avec succès');
      },
      error: error => {
        this.toastr.error('Erreur lors de la mise à jour du produit');
      },
    });
  }
  toggleExpandDescription(): void {
    this.isExpandedDescription = !this.isExpandedDescription;
  }
  toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
  }
  ngOnDestroy(): void {
    if (this.progressCheckInterval) {
      clearInterval(this.progressCheckInterval);
    }
  }
}
