import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeServiceService } from '../../../home/service/home-service.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ToastrService } from 'ngx-toastr';
import { PaginatorModule } from 'primeng/paginator';
import { debounceTime, distinctUntilChanged, Subject, switchMap } from 'rxjs';
import { CartService } from '../../../cart/cart.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaymentService } from '../../../adminPharmacyBackOffice/services/payment.service';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}
interface City {
  name: string;
  code: string;
}
@Component({
  selector: 'jhi-admin-payments',
  standalone: true,
  imports: [NgbModule, CommonModule, NgxSkeletonLoaderModule, PaginatorModule, MultiSelectModule, DropdownModule],
  templateUrl: './admin-payments.component.html',
  styleUrl: './admin-payments.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AdminPaymentsComponent implements OnInit {
  @ViewChild('modalProduct') modalProduct: any;
  @ViewChild('modalDetailsPayment') modalDetailsPayment: any;
  cities!: City[];

  stockItems: any;
  contentLoaded = false;
  selectedFile: File | null = null;
  searchInputSubject = new Subject<string>();
  first: number = 0;
  rows: number = 1;
  totalRecords: number = 5;
  imageUrl: string = 'https://imagedelivery.net/z1UHS7Z96nNnNVE8sIpBxg/';
  isPromoActive: boolean = false;
  productForm: FormGroup;
  payments: any;
  pharmacies: any[] = [];
  selectedPharmacy: any;
  selectedDate: string = '';
  openedProduct: any;
  value: number = 1;
  isExpanded = false;
  isExpandedDescription = false;
  activeTab: string = 'composition';

  constructor(
    private pharmacieService: HomeServiceService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private cartService: CartService,
    private fb: FormBuilder,
    private paymentService: PaymentService,
  ) {}

  ngOnInit(): void {
    this.getAllPharmacies();
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.contentLoaded = true;
    }, 2000);
    this.getAllPayments();
    this.searchInputSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(searchName => {
          const data = {
            contactName: searchName.trim().toLowerCase(),
          };
          return this.pharmacieService.searchStockPharmacist(searchName.trim().toLowerCase()).pipe();
        }),
      )
      .subscribe((res: any) => {
        this.payments = res;
      });
    this.initProductForm();
  }
  onPageChange(event: any): void {
    const pageEvent: PageEvent = {
      first: event.first ?? 0,
      rows: event.rows ?? 1,
      page: event.page ?? 0,
      pageCount: event.pageCount ?? 0,
    };

    this.first = pageEvent.first;
    this.rows = pageEvent.rows;
    this.getAllPayments();
  }

  openModal(modal: any): void {
    this.modalService.open(modal, { size: 'lg', centered: true });
  }
  initProductForm(): void {
    this.productForm = this.fb.group({
      price: [this.openedProduct?.price || 0, Validators.required],
      priceDiscount: [null],
      availableStock: [this.openedProduct?.availableStock || 0, Validators.required],
      discount: [null],
    });
  }

  openModalPayment(): void {
    this.modalService.open(this.modalDetailsPayment, { size: 'l', centered: true });
  }
  getAllPayments(): void {
    this.paymentService.getAllPayments(this.first).subscribe({
      next: (res: any) => {
        this.payments = res.content;
        this.totalRecords = res.totalPages;
      },
      error(err) {
        console.error(err);
      },
    });
  }

  getAllPharmacies(): void {
    this.paymentService.getAllPharmacy().subscribe({
      next: (res: any) => {
        this.pharmacies = res.map((pharmacy: any) => ({
          label: pharmacy.commercialName, // Adjust based on your API response structure
          value: pharmacy.id,
        }));
      },
      error(err) {
        console.error(err);
      },
    });
  }

  getPayments(): void {
    if (!this.selectedPharmacy || !this.selectedDate) {
      console.error('Pharmacy and date must be selected.');
      return;
    }
    const year = parseInt(this.selectedDate.split('-')[0], 10);
    const month = parseInt(this.selectedDate.split('-')[1], 10);
    const startDate = `${this.selectedDate}-01`; // Start of the selected month
    const endDate = new Date(year, month, 0).toISOString().split('T')[0]; // Last day of the selected month

    this.paymentService.getPaymentByPharmacy(this.first, this.selectedPharmacy, startDate, endDate).subscribe({
      next: (res: any) => {
        this.payments = res.content;
        this.totalRecords = res.totalPages;
      },
      error(err) {
        console.error(err);
      },
    });
  }

  resetFilters(): void {
    this.selectedPharmacy = null;
    this.selectedDate = '';
    this.payments = [];
    this.getAllPayments();
  }
}
