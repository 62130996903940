import { Component } from '@angular/core';

@Component({
  selector: 'jhi-dashboard-admin-pharmacy',
  standalone: true,
  imports: [],
  templateUrl: './dashboard-admin-pharmacy.component.html',
  styleUrl: './dashboard-admin-pharmacy.component.scss',
})
export class DashboardAdminPharmacyComponent {}
