import { HomeServiceService } from './home/service/home-service.service';
import { Component, OnInit } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import locale from '@angular/common/locales/en';
import dayjs from 'dayjs/esm';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationConfigService } from './core/config/application-config.service';
import { fontAwesomeIcons } from './config/font-awesome-icons';
import MainComponent from './layouts/main/main.component';
import { RouterModule } from '@angular/router';
import { environment } from '../environments/environment';
import { NotificationService } from './notification/notification.service';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';

@Component({
  selector: 'jhi-app',
  standalone: true,
  template: '<router-outlet></router-outlet>',
  imports: [MainComponent, RouterModule],
})
export default class AppComponent implements OnInit {
  constructor(
    applicationConfigService: ApplicationConfigService,
    iconLibrary: FaIconLibrary,
    dpConfig: NgbDatepickerConfig,
    private notificationService: NotificationService,
    private homeServiceService: HomeServiceService,
  ) {
    applicationConfigService.setEndpointPrefix(environment.baseUrl);
    registerLocaleData(locale);
    iconLibrary.addIcons(...fontAwesomeIcons);
    dpConfig.minDate = { year: dayjs().subtract(100, 'year').year(), month: 1, day: 1 };
  }

  ngOnInit(): void {
    this.homeServiceService.loadResources();
    if (typeof window !== 'undefined') {
      const firebaseApp = initializeApp(environment.firebase);
      const messaging = getMessaging(firebaseApp);
      this.notificationService.receiveMessage().subscribe(message => {});
      this.requestPermission();
      // Register the service worker
      navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then(registration => {})
        .catch(err => {});

      // Listen for messages when the app is open
      onMessage(messaging, payload => {});
    }
  }
  requestPermission(): void {
    let deviceId = localStorage.getItem('deviceId');
    if (!deviceId) {
      deviceId = this.generateDeviceId();
      localStorage.setItem('deviceId', deviceId);
    }
    const messaging = getMessaging();
    getToken(messaging, { vapidKey: environment.firebase.vapidKey })
      .then(currentToken => {
        if (currentToken) {
          this.notificationService.sendTokenToServer(currentToken, deviceId).subscribe(
            response => {},
            error => {},
          );
        }
      })
      .catch(error => {
        console.error('An error occurred while retrieving the token:', error);
      });
  }
  generateDeviceId(): string {
    return 'device-' + Math.random().toString(36).substr(2, 9);
  }
}
