<div class="row mt-5 mb-4">
  <div class="col-sm-12">
    <h2 class="lead" style="color: #0078c6">Liste des paiements</h2>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <div class="table-container" *ngIf="paymentsList?.length">
      <table class="table table-responsive">
        <thead class="thead-dark">
          <tr>
            <th scope="col">Réf Commande</th>
            <th scope="col">Montant</th>
            <th scope="col">Date et heure</th>
            <th scope="col">Réf paiement</th>
            <th scope="col">Statut</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let payment of paymentsList">
            <tr *ngIf="!contentLoaded">
              <td colspan="5">
                <ngx-skeleton-loader
                  count="1"
                  appearance="line"
                  [theme]="{ height: '24px', width: '100%', 'margin-bottom': '10px' }"
                ></ngx-skeleton-loader>
              </td>
            </tr>
            <tr *ngIf="contentLoaded">
              <td>{{ payment?.id }}</td>
              <td>
                <span *ngIf="payment?.paymentStatus === 'succeeded'" class="bolder">{{ payment?.amount / 100 }}€</span>
                <span *ngIf="payment?.paymentStatus === 'échec'" class="bolder">---</span>
              </td>
              <td>{{ payment?.purchase?.orderDate | date: "dd/MM/yyyy HH'h'mm" }}</td>
              <td *ngIf="payment?.paymentStatus === 'succeeded'">{{ payment?.stripePaymentIntentId }}</td>
              <td *ngIf="payment?.paymentStatus === 'échec'">---</td>

              <td>
                <div class="btn-wrap">
                  <span
                    class="badge"
                    [ngClass]="{ 'bg-red': payment?.paymentStatus === 'échec', 'bg-green': payment?.paymentStatus === 'succeeded' }"
                  >
                    {{ payment?.paymentStatus }}
                  </span>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <div class="card flex justify-content-center" *ngIf="paymentsList.length">
      <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords" />
    </div>
    <div *ngIf="!paymentsList?.length">
      <lottie-player
        src="../../content/images/noData.json"
        background="transparent"
        speed="1"
        style="width: 300px; height: 250px; margin: auto"
        loop
        autoplay
      ></lottie-player>
    </div>
  </div>
</div>
