import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { environment } from '../../environments/environment';
import { Observable, tap } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { getMessaging, onMessage } from 'firebase/messaging';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  messaging = getMessaging();
  messageSource = new BehaviorSubject<any>(null);
  currentMessage = this.messageSource.asObservable();

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private http: HttpClient,
  ) {
    this.listenForMessages();
  }

  listenForMessages(): void {
    onMessage(this.messaging, payload => {
      this.messageSource.next(payload);
    });
  }
  sendTokenToServer(token: any, deviceId: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}api/push-notification/tokens`, { token, deviceId }).pipe(tap(response => {}));
  }
  requestPermission(): void {
    const deviceId = localStorage.getItem('deviceId');
    this.angularFireMessaging.requestToken.subscribe(token => {
      this.sendTokenToServer(token, deviceId).subscribe(response => {});
    });
  }
  receiveMessage(): Observable<any> {
    return new Observable(observer => {
      this.angularFireMessaging.messages.subscribe(
        message => {
          observer.next(message);
        },
        error => {
          observer.error(error);
        },
      );
    });
  }
  getNotifications(): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/push-notification/notifications`);
  }
  readNotification(notificationId: any): Observable<any> {
    return this.http.patch<any>(`${environment.baseUrl}api/push-notification/read/${notificationId}`, {});
  }
  deleteNotification(notificationId: any): Observable<any> {
    return this.http.delete(`${environment.baseUrl}api/push-notification/${notificationId}`);
  }
}
