import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Account } from '../core/auth/account.model';
import { AccountService } from '../core/auth/account.service';
import { LoginService } from '../login/login.service';
import SharedModule from '../shared/shared.module';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { HomeServiceService } from '../home/service/home-service.service';

@Component({
  selector: 'jhi-product-offline-view',
  standalone: true,
  imports: [SharedModule, FormsModule, ReactiveFormsModule, RouterModule, NgxSkeletonLoaderModule],
  templateUrl: './product-offline-view.component.html',
  styleUrl: './product-offline-view.component.scss',
})
export class ProductOfflineViewComponent implements OnInit {
  account: Account | null = null;
  @ViewChild('modalMaps') modalMaps: any;
  contentLoaded = false;
  params: any;
  products: any;
  categoryId: any;
  categoryName: any;
  imageUrl: string = 'https://imagedelivery.net/z1UHS7Z96nNnNVE8sIpBxg/';
  authenticationError = false;
  searchQuery: string = '';
  loginForm = new FormGroup({
    username: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    password: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    rememberMe: new FormControl(false, { nonNullable: true, validators: [Validators.required] }),
  });
  private readonly destroy$ = new Subject<void>();

  constructor(
    private accountService: AccountService,
    private loginService: LoginService,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private pharmacieService: HomeServiceService,
    private _toaster: ToastrService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.contentLoaded = true;
    }, 2000);
    this.getCategoryId();

    localStorage.setItem('isLandComponentActive', 'true');
    this.accountService.identity().subscribe(() => {
      if (this.accountService.isAuthenticated()) {
        this.accountService
          .getAuthenticationState()
          .pipe(takeUntil(this.destroy$))
          .subscribe(account => (this.account = account));
        if (
          (!this.account?.applicationUser || !this.account.applicationUser.proProfileCompleted) &&
          this.account?.authorities.includes('ROLE_PHARMACIST')
        ) {
          this.router.navigate(['/login'], { queryParams: { user: 'completeCreation' } });
        } else {
          this.router.navigate(['/home']);
        }
      }
    });
    this.route.queryParams.subscribe(params => {
      if (params['type']) {
        this.params = params['type'];
      }
    });
  }
  getCategoryId(): void {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.categoryId = +params.get('categoryId');
        this.categoryName = params.get('categoryName');

        this.getProducts(this.categoryId);
      },
    });
  }
  login(): void {
    this.loginService.login(this.loginForm.getRawValue()).subscribe({
      next: () => {
        this.authenticationError = false;
        if (!this.router.getCurrentNavigation()) {
          this.accountService
            .getAuthenticationState()
            .pipe(takeUntil(this.destroy$))
            .subscribe(account => (this.account = account));
          if (!this.account?.applicationUser || !this.account.applicationUser.proProfileCompleted) {
            this.router.navigate(['/login'], { queryParams: { user: 'completeCreation' } });
          } else {
            this.router.navigate(['/home']);
          }
        }
      },
      error: () => {
        this.authenticationError = true;
        this._toaster.error(this.translateService.instant('login.messages.error.authentication'));
      },
    });
  }
  openModal(): void {
    this.modalService.open(this.modalMaps, { size: 'l', centered: true });
  }
  searchPharmacy(): void {
    if (this.searchQuery.trim()) {
      this.router.navigate(['/pharmacie'], { queryParams: { searchkeys: this.searchQuery } });
    }
  }
  getProducts(categoryId: number): void {
    this.pharmacieService.getProducts(categoryId).subscribe(data => {
      this.products = data.content;
    });
  }
}
