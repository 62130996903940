import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import SharedModule from '../shared/shared.module';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'jhi-privacy-policy-delivery',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './privacy-policy-delivery.component.html',
  styleUrl: './privacy-policy-delivery.component.scss',
})
export class PrivacyPolicyDeliveryComponent implements OnInit {
  privacyPolicyDelivery: any;
  constructor(
    private translateService: TranslateService,
    private http: HttpClient,
  ) {}
  ngOnInit(): void {
    window.scrollTo(0, 0);
    const storedLang = localStorage.getItem('selectedLanguage') ?? 'fr';
    if (storedLang) {
      this.translateService.use(storedLang);
    } else {
      this.translateService.use('fr');
    }
    this.http.get(`i18n/${storedLang}/privacy-policy-delivery.json`).subscribe((data: any) => {
      this.privacyPolicyDelivery = data.privacyPolicyDelivery;
    });
  }
}
