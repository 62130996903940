<div class="row mt-5 mb-4">
  <div class="col-sm-12">
    <h2 class="lead" style="color: #141414">Paramètre du compte</h2>
  </div>
</div>
<div class="row">
  <div class="container">
    <ul ngbNav #navProfilDetails="ngbNav" class="nav-tabs" role="tablist">
      <li [ngbNavItem]="1">
        <a ngbNavLink>Administrateur</a>
        <ng-template ngbNavContent>
          <div class="row schedule-container">
            <div class="col-sm-12 col-xl-8">
              <div class="d-flex justify-content-between">
                <div class="profile-picture-wrapper">
                  <div class="profile-picture"></div>
                </div>
                <div class="profile-btn-wrapper mt-5">
                  <button type="submit" class="btn btn-maj" (click)="updateProfile()">
                    <img src="/content/images/icons/edit.svg" alt="" /> Mettre à jour
                  </button>
                </div>
              </div>
              <div class="profile-form">
                <form action="">
                  <div class="mb-3 row" *ngIf="account">
                    <div class="col-sm-6">
                      <label for="lastName">Nom</label>
                      <input [(ngModel)]="account.lastName" type="text" name="lastName" id="lastName" />
                    </div>
                    <div class="col-sm-6">
                      <label for="firstName">Prénom</label>
                      <input [(ngModel)]="account.firstName" type="text" name="firstName" id="firstName" />
                    </div>
                  </div>
                  <div class="mb-3 row" *ngIf="account">
                    <div class="col-sm-6">
                      <label for="mail">Email</label>
                      <input [(ngModel)]="account.email" type="email" name="mail" id="mail" />
                    </div>
                    <div class="col-sm-6">
                      <label for="tel">Numéro de téléphone</label>
                      <input [(ngModel)]="account.applicationUser.phoneNumber" type="tel" name="tel" id="tel" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Pharmacie</a>
        <ng-template ngbNavContent>
          <div class="schedule-container mb-4">
            <div class="row scorllable-container">
              <div class="col">
                <h2>Horaire de travail régulier</h2>
                <div class="work-schedule">
                  <div class="day-schedule">
                    <label class="day">Heures par défaut</label>
                    <hr style="margin-top: 0px" />

                    <div class="time-slot">
                      <label>De</label>
                      <select>
                        <option>08h30</option>
                      </select>
                    </div>
                    <div class="time-slot">
                      <label>À</label>
                      <select>
                        <option>19h30</option>
                      </select>
                    </div>
                  </div>
                  <button class="add-date-btn-exception" (click)="openModal(false)">Ajouter une exception</button>
                </div>
                <h2>Calendrier de garde (dimanche et jour férié)</h2>
                <div class="on-call-schedule">
                  <div class="date-schedule" *ngFor="let day of exceptionalDays">
                    <div class="date-header" ngbDropdown>
                      <span style="color: #141414; font-size: 15px; font-weight: 600">{{ day?.exceptionDate }}</span>
                      <span style="cursor: pointer" ngbDropdownToggle> <i class="fa-solid fa-ellipsis-vertical"></i> </span>
                      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button ngbDropdownItem (click)="openModalExceptionalDays(true, 'HOLIDAY', day)">Modifier</button>
                        <button ngbDropdownItem (click)="onDeleteClickExceptionDate(day.id)">Supprimer</button>
                      </div>
                    </div>
                    <hr />
                    <div class="time-slot">
                      <label>De</label>
                      <select>
                        <option>{{ formatTimeToHHhMM(day?.openingHour) }}</option>
                      </select>
                    </div>
                    <div class="time-slot">
                      <label>À</label>
                      <select>
                        <option>{{ formatTimeToHHhMM(day?.closingHour) }}</option>
                      </select>
                    </div>
                  </div>
                  <button class="add-date-btn" (click)="openModalExceptionalDays(false, 'HOLIDAY')">Ajouter une date</button>
                </div>
                <h2>Calendrier des urgences (garde de nuit)</h2>
                <div class="on-call-schedule">
                  <div class="date-schedule-guard">
                    <div class="date-header" ngbDropdown>
                      <span style="color: #141414; font-size: 15px; font-weight: 600">Dimanche 14/07/24</span>
                      <span style="cursor: pointer" ngbDropdownToggle> <i class="fa-solid fa-ellipsis-vertical"></i> </span>
                      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button ngbDropdownItem>Modifier</button>
                        <button ngbDropdownItem>Supprimer</button>
                      </div>
                    </div>
                    <hr />
                    <div class="time-slot">
                      <label>De</label>
                      <select>
                        <option>08h30</option>
                      </select>
                    </div>
                    <div class="time-slot">
                      <label>À</label>
                      <select>
                        <option>19h30</option>
                      </select>
                    </div>
                  </div>
                  <button class="add-date-btn" (click)="openModal(false)">Ajouter une date</button>
                </div>
              </div>
              <!--
               <div class="col">
                <p-calendar 
                  class="max-w-full"
                  [(ngModel)]="date" 
                  [inline]="true" 
                  [dayTemplate]="customDayTemplate" 
                  [showWeek]="true"
                  (onSelect)="onDayClick($event);openModal(false)">
                </p-calendar>
              
                <ng-template #customDayTemplate let-date>
                  <span>
                    {{ date.day }}
                  </span>
                </ng-template>
              </div>
              -->
              <div class="col">
                <!--  <div id="calendar" class="calendar">
                  <div class="calendar-title">
                    <div class="calendar-title-text">{{ monthYear }}</div>
                    <div class="calendar-button-group">
                      <button (click)="prevMonth()">&lt;</button>
                      <button (click)="goToToday()">Today</button>
                      <button (click)="nextMonth()">&gt;</button>
                    </div>
                  </div>
                  <div class="calendar-day-name">
                    <div *ngFor="let day of weekNames">{{ day }}</div>
                  </div>
                  <div class="calendar-dates">
                    <button *ngFor="let day of prevMonthDays" class="calendar-dates-day-empty">{{ day }}</button>
                    <button
                      *ngFor="let day of daysInMonth; let i = index"
                      class="calendar-dates-day"
                      [ngClass]="
                        isWeekend(i + 1)
                          ? 'weekend'
                          : i + 1 === specialDay
                            ? 'special-day'
                            : i + 1 === currentDate.date()
                              ? 'today-date'
                              : i + 1 === guardDay
                                ? 'guard-day'
                                : 'normalDay'
                      "
                    >
                      {{ day }}
                    </button>
                    <button *ngFor="let day of nextMonthDays" class="calendar-dates-day-empty">{{ day }}</button>
                  </div>
                </div>-->
                <div id="calendar" class="calendar">
                  <div class="calendar-title">
                    <div class="calendar-title-text">{{ monthYear }}</div>
                    <div class="calendar-button-group">
                      <button (click)="prevMonth()">&lt;</button>
                      <button (click)="goToToday()">Today</button>
                      <button (click)="nextMonth()">&gt;</button>
                    </div>
                  </div>

                  <div class="calendar-day-name">
                    <div *ngFor="let day of weekNames">{{ day }}</div>
                  </div>
                  <div class="calendar-dates">
                    <button *ngFor="let day of prevMonthDays" class="calendar-dates-day-empty">{{ day }}</button>
                    <button
                      *ngFor="let day of daysInMonth; let i = index"
                      class="calendar-dates-day"
                      [ngClass]="{
                        weekend: isWeekend(day),
                        'today-date': isToday(day.day),
                        'special-day': isExceptionalDay(day),
                        normalDay: !isWeekend(day) && !isExceptionalDay(day)
                      }"
                    >
                      <!-- 'special-day': isExceptionalDay(day),-->
                      {{ i + 1 }}
                    </button>
                    <button *ngFor="let day of nextMonthDays" class="calendar-dates-day-empty">{{ day }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="navProfilDetails" class="mt-2"></div>
  </div>
</div>

<ng-template #modalScheduler let-modalScheduler>
  <div class="modal-avis">
    <div class="modal-header">
      <div></div>
      <div class="col-1 d-flex align-items-end flex-column">
        <button type="button" class="close" (click)="modalScheduler.dismiss('Cross click')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="">
      <div class="avis-header"></div>
      <div class="title">
        {{ isEditMode ? 'Modifier la Date' : 'Ajouter une Date' }}
      </div>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      <form [formGroup]="scheduleForm">
        <div class="form-group">
          <label for="date"> Sélectionnez une date </label>
          <input type="date" id="date" class="form-control" formControlName="startDate" />
        </div>

        <div class="form-group">
          <label for="de"> De </label>
          <input type="time" id="de" class="form-control" formControlName="openingHour" />
        </div>

        <div class="form-group">
          <label for="a"> À </label>
          <input type="time" id="a" class="form-control" formControlName="closingHour" />
        </div>

        <button
          class="submit"
          style="margin-top: 25px"
          style="margin-top: 25px"
          type="button"
          [disabled]="scheduleForm.invalid"
          (click)="submitSchedule()"
        >
          Confirmer
        </button>
      </form>
    </div>
  </div>
</ng-template>
<ng-template #modalExceptionalDays let-modalExceptionalDays>
  <div class="modal-avis">
    <div class="modal-header">
      <div></div>
      <div class="col-1 d-flex align-items-end flex-column">
        <button type="button" class="close" (click)="modalExceptionalDays.dismiss('Cross click')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="">
      <div class="avis-header"></div>
      <div class="title">
        {{ isEditMode ? 'Modifier la Date' : 'Ajouter une Date' }}
      </div>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      <form [formGroup]="scheduleExceptionalDaysForm">
        <div class="form-group">
          <label for="date"> Sélectionnez une date </label>
          <input type="date" id="date" class="form-control" formControlName="exceptionDate" />
        </div>

        <div class="form-group">
          <label for="de"> De </label>
          <input type="time" id="de" class="form-control" formControlName="openingHour" />
        </div>

        <div class="form-group">
          <label for="a"> À </label>
          <input type="time" id="a" class="form-control" formControlName="closingHour" />
        </div>

        <button
          class="submit"
          style="margin-top: 25px"
          style="margin-top: 25px"
          type="button"
          [disabled]="scheduleExceptionalDaysForm.invalid"
          (click)="submitExceptionalDay()"
        >
          Confirmer
        </button>
      </form>
    </div>
  </div>
</ng-template>
