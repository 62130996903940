import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  HostListener,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  NgZone,
} from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Subject } from 'rxjs';
import SharedModule from '../shared/shared.module';
import { AccountService } from '../core/auth/account.service';
import { Account } from '../core/auth/account.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HomeServiceService } from './service/home-service.service';
import { GoogleMap, GoogleMapsModule, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
interface Pharmacy {
  latitude: number;
  longitude: number;
}
interface PharmacySchedule {
  openingHour: string;
  closingHour: string;
}
interface Pharmacy {
  schedule?: PharmacySchedule;
  status?: string;
}
@Component({
  standalone: true,
  selector: 'jhi-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  imports: [CommonModule, SharedModule, RouterModule, GoogleMapsModule, NgxSkeletonLoaderModule, InfiniteScrollModule, ReactiveFormsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export default class HomeComponent implements OnInit, OnDestroy {
  @ViewChild('mapComponent', { static: false }) mapComponent!: GoogleMap;
  account: Account | null = null;
  numbers: any[] = Array.from({ length: 9 }, () => 0); // Initialize array with 6 zeros
  profileImageUrl: string | ArrayBuffer | null = null;
  openedProduct: any;
  openedCommand: any;
  activeButton: string = 'left';
  @ViewChild('modalgeo') modalgeo: any;
  @ViewChild('modalProduct') modalProduct: any;
  @ViewChild('modalDetailsPharmacy') modalDetailsPharmacy: any;
  @ViewChild('MapModalComponent') MapModalComponent: any;
  @ViewChild('modalOrdonnance') modalOrdonnance: any;
  @ViewChild('modalAvis') modalAvis: any;
  @ViewChild('modalgeoAdresse') modalgeoAdresse: any;
  @ViewChild('modalgeoTow') modalgeoTow: any;
  @ViewChild('modalMaps') modalMaps: any;
  @ViewChild('modalCookies') modalCookies: any;
  @ViewChild('modalCookiesOne') modalCookiesOne: any;

  @ViewChild(MapInfoWindow, { static: false }) infoWindow!: MapInfoWindow;
  @ViewChild('mapElement', { static: true }) mapElement!: ElementRef;

  step1 = 'initial';
  step2 = '';
  step3 = '';
  commandDetails = false;
  value: number = 1;
  isExpanded = false;
  selectedAddressId: any;
  cookiesCart!: any[];
  total: number = 0;
  likeImagePath: string = '../../content/images/Icon feather-heart (1).svg';
  pharmacy: any[] = [];
  currentPage: number = 0;
  itemsPerPage = 9;
  totalPages: number = 1;
  isLoading: boolean = false;
  lengthLike: number = 0;
  likeCounter: number = 0;
  contentLoaded: boolean = false;
  imageUrl: string = 'https://imagedelivery.net/z1UHS7Z96nNnNVE8sIpBxg/';
  addresses: any[] = [];
  place!: google.maps.places.PlaceResult;
  map!: google.maps.Map;
  center: google.maps.LatLngLiteral = { lat: 46.603354, lng: 1.888334 };
  zoom: number = 13;
  latitude: number = 0;
  longitude: number = 0;
  isOpen: boolean = false;
  isByLat!: boolean;
  mapOptions: google.maps.MapOptions = {
    center: { lat: 46.603354, lng: 1.888334 },
    zoom: this.zoom,
  };
  markerOptions: google.maps.MarkerOptions = {
    draggable: true,
  };
  options: any = {
    icon: {
      url: '../../content/images/PG_Pin.svg',
      scaledSize: new google.maps.Size(50, 50),
    },
  };
  markerPosition!: google.maps.LatLngLiteral;
  showMap: boolean = false;
  selectedAddressCoords: { lat: number; lng: number } | null = null;
  adresseForm = new FormGroup({
    appartmentNumber: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    name: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    streetNumber: new FormControl(''),
    streetName: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    postalCode: new FormControl(''),
    country: new FormControl(''),
    address: new FormControl(''),
  });
  mapMarker!: MapMarker;
  showSearchButton: boolean = false;
  address: any;
  newCenterLat!: number;
  newCenterLng!: number;
  selectedPharmacy: any;
  id: any;
  recentAddress: any;
  isSearchActive = false;
  searchkeys: any;
  selectedPlace!: google.maps.places.PlaceResult;
  readonly destroy$ = new Subject<void>();

  constructor(
    private accountService: AccountService,
    private router: Router,
    private modalService: NgbModal,
    private homeService: HomeServiceService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private ngZone: NgZone,
  ) {}

  ngOnInit(): void {
    const storedCoords = localStorage.getItem('selectedAddressCoords');
    const storedAddressId = localStorage.getItem('selectedAddressId');
    if (storedAddressId) {
      this.homeService.selectedAddressId$.subscribe(addressId => {
        this.selectedAddressId = addressId;
      });
    } else {
      this.loadAddresses();
    }
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.contentLoaded = true;
    }, 2000);
    this.getAuthState();
    this.getSearchKey();
    if (storedCoords) {
      this.homeService.selectedAddressCoords$.subscribe(coords => {
        this.selectedAddressCoords = coords;
        if (!this.searchkeys) {
          this.currentPage = 0;
          this.handleAddresses();
        }
      });
    } else {
      this.loadAddresses();
    }
  }
  toggleLoading = (): void => {
    this.isLoading = !this.isLoading;
  };
  openInfoWindow(marker: MapMarker, pharma: any): void {
    this.selectedPharmacy = pharma;
    this.infoWindow.open(marker);
  }

  addAdressToclient(): void {
    if (this.adresseForm.valid) {
      this.homeService.addAdresse(this.adresseForm.value).subscribe(
        data => {
          this.toastr.success('Adresse ajoutée avec succès');
          this.loadAddresses();
        },
        error => {
          this.toastr.error("Erreur lors de l'ajout de l'adresse");
        },
      );
    } else {
      this.toastr.error('Veuillez remplir tous les champs obligatoires');
    }
  }

  loadAddresses(): void {
    this.homeService.getAdresse().subscribe(
      data => {
        this.addresses = data;
        const mostRecentAddress = this.getMostRecentAddress(data);
        if (mostRecentAddress) {
          this.selectedAddressCoords = {
            lat: mostRecentAddress.latitude,
            lng: mostRecentAddress.longitude,
          };
          this.selectedAddressId = mostRecentAddress.id;
          localStorage.setItem('selectedAddressCoords', JSON.stringify(this.selectedAddressCoords));
          localStorage.setItem('selectedAddressId', JSON.stringify(this.selectedAddressId));
          this.homeService.setSelectedAddressCoords(this.selectedAddressCoords);
          this.homeService.setSelectedAddressId(this.selectedAddressId);
        }
        this.handleAddresses();
      },
      error => {
        this.toastr.error('Erreur lors de la récupération des adresses');
      },
    );
  }
  getMostRecentAddress(addresses: any[]): any {
    if (addresses.length === 0) {
      return null;
    }
    const sortedAddresses = [...addresses].sort((a, b) => {
      return new Date(b.lastUsedDate).getTime() - new Date(a.lastUsedDate).getTime();
    });
    return sortedAddresses[0];
  }

  handleAddresses(): void {
    console.log('selectedAddressId', this.selectedAddressId, 'selectedAddressCoords', this.selectedAddressCoords);
    if (this.selectedAddressId && this.selectedAddressCoords) {
      this.searchPharmacyLatLng(
        this.selectedAddressCoords.lat,
        this.selectedAddressCoords.lng,
        this.currentPage,
        this.selectedAddressId,
        false,
      );
      this.isByLat = true;
    } else {
      this.getPharmacies();
      this.isByLat = false;
    }
  }

  NavigateToPharmacy(id: number, pharmacyName: any, longitude: any, latitude: any): void {
    this.router.navigate(['/pharmacie-category', id, 'P']);
    this.setPharmacyName(pharmacyName);
    this.setLongitude(longitude);
    this.setLatitude(latitude);
    this.setPharmacyId(id);
  }
  openModal(modalRef: any): void {
    this.modalService.open(modalRef, { size: 'l', centered: true });
  }
  setPharmacyName(commercialName: any): void {
    this.homeService.setCommercialName(commercialName);
  }
  setLongitude(longitude: any): void {
    this.homeService.setLongitude(longitude);
  }
  setLatitude(latitude: any): void {
    this.homeService.setLatitude(latitude);
  }
  setPharmacyId(pharmacyId: any): void {
    this.homeService.setPharmacyId(pharmacyId);
  }

  getSearchKey(): void {
    this.route.queryParams.subscribe(params => {
      this.searchkeys = params['searchkeys'] || '';
      if (this.searchkeys) {
        this.searchPharmacies(this.searchkeys);
      }
    });
  }

  searchPharmacies(searchkeys: string): void {
    this.homeService.searchPharmacies(searchkeys).subscribe({
      next: (data: any) => {
        this.pharmacy = data;
        this.isSearchActive = true;
        this.pharmacy = this.pharmacy.map((ph: Pharmacy) => {
          if (ph.schedule) {
            const { openingHour, closingHour } = ph.schedule;
            const currentDate = new Date();
            const currentTime = currentDate.getTime();

            // Parse opening and closing hours into Date objects
            const openingTime = new Date(currentDate.toISOString().split('T')[0] + 'T' + openingHour);
            const closingTime = new Date(currentDate.toISOString().split('T')[0] + 'T' + closingHour);

            // Calculate time differences in milliseconds
            const timeToOpen = openingTime.getTime() - currentTime;
            const timeToClose = closingTime.getTime() - currentTime;

            // Define thresholds for "soon" (1 hour = 3600000 milliseconds)
            const SOON_THRESHOLD = 3600000;

            // Determine the pharmacy status
            if (currentTime >= openingTime.getTime() && currentTime < closingTime.getTime()) {
              ph.status = timeToClose <= SOON_THRESHOLD ? 'close_soon' : 'open'; // Closing soon or Open
            } else if (currentTime < openingTime.getTime()) {
              ph.status = timeToOpen <= SOON_THRESHOLD ? 'open_soon' : 'closed'; // Opening soon or Closed
            } else {
              ph.status = 'closed'; // Default to closed if outside operational hours
            }
          } else {
            ph.status = 'closed'; // Default to closed if no schedule is available
          }
          return ph; // Now safely typed as Pharmacy
        });
      },
      error: (err: any) => {
        console.error('Erreur lors de la recherche:', err);
        this.isSearchActive = false;
      },
    });
  }
  onScroll = (): void => {
    if (this.isSearchActive) {
      return;
    }
    if (this.isByLat) {
      this.searchPharmacyLatLng(
        this.selectedAddressCoords?.lat,
        this.selectedAddressCoords?.lng,
        this.currentPage,
        this.selectedAddressId,
        true,
      );
    } else {
      this.getPharmacies();
    }
  };

  getPharmacies(): void {
    this.toggleLoading();
    this.homeService.getPharmacy(this.currentPage, 9).subscribe(
      data => {
        this.processPharmacies(data);
      },
      error => {
        this.toggleLoading();
      },
    );
  }
  searchPharmacyLatLng(lat: any, lng: any, page: any, addressId, isNewSearch: boolean): void {
    if (!isNewSearch) {
      this.pharmacy = [];
      this.currentPage = 0;
    }
    this.contentLoaded = false;
    this.homeService.searchPharmaciesWithLatLang(lat, lng, page, addressId).subscribe(
      (data: any) => {
        this.processPharmacies(data);
        setTimeout(() => {
          this.contentLoaded = true;
        }, 2000);
      },
      error => {
        console.error('Error fetching pharmacies:', error);
        setTimeout(() => {
          this.contentLoaded = true;
        }, 2000);
      },
    );
  }
  processPharmacies(data: any): void {
    this.pharmacy = [...this.pharmacy, ...data];
    this.currentPage++;

    if (this.pharmacy.length > 0) {
      const latitudes: number[] = this.pharmacy.map((ph: Pharmacy) => ph.latitude);
      const longitudes: number[] = this.pharmacy.map((ph: Pharmacy) => ph.longitude);
      const averageLat: number = latitudes.reduce((a: number, b: number) => a + b, 0) / latitudes.length;
      const averageLng: number = longitudes.reduce((a: number, b: number) => a + b, 0) / longitudes.length;
      this.center = { lat: averageLat, lng: averageLng };
    }

    this.pharmacy = this.pharmacy.map((ph: Pharmacy) => {
      if (ph.schedule) {
        const { openingHour, closingHour } = ph.schedule;
        const currentDate = new Date();
        const currentTime = currentDate.getTime();

        // Parse opening and closing hours into Date objects
        const openingTime = new Date(currentDate.toISOString().split('T')[0] + 'T' + openingHour);
        const closingTime = new Date(currentDate.toISOString().split('T')[0] + 'T' + closingHour);

        // Calculate time differences in milliseconds
        const timeToOpen = openingTime.getTime() - currentTime;
        const timeToClose = closingTime.getTime() - currentTime;

        // Define thresholds for "soon" (1 hour = 3600000 milliseconds)
        const SOON_THRESHOLD = 3600000;

        // Determine the pharmacy status
        if (currentTime >= openingTime.getTime() && currentTime < closingTime.getTime()) {
          ph.status = timeToClose <= SOON_THRESHOLD ? 'close_soon' : 'open'; // Closing soon or Open
        } else if (currentTime < openingTime.getTime()) {
          ph.status = timeToOpen <= SOON_THRESHOLD ? 'open_soon' : 'closed'; // Opening soon or Closed
        } else {
          ph.status = 'closed'; // Default to closed if outside operational hours
        }
      } else {
        ph.status = 'closed'; // Default to closed if no schedule is available
      }
      return ph; // Now safely typed as Pharmacy
    });

    this.toggleLoading();
  }
  getStatusColor(status: string): string {
    switch (status) {
      case 'open':
        return '#83b011'; // Green
      case 'closed':
        return 'red'; // Red
      case 'open_soon':
      case 'close_soon':
        return 'orange'; // Orange
      default:
        return 'black'; // Default color
    }
  }

  getStatusMessage(status: string): string {
    switch (status) {
      case 'open':
        return 'Ouverte';
      case 'closed':
        return 'Fermée';
      case 'open_soon':
        return 'Ouverte bientôt';
      case 'close_soon':
        return 'Ferme bientôt';
      default:
        return '';
    }
  }

  appendData = (): void => {
    if (!this.isByLat) {
      this.toggleLoading();
      this.homeService.getPharmacy(this.currentPage, this.itemsPerPage).subscribe({
        next: data => {
          if (Array.isArray(data)) {
            data.forEach((element: any) => {
              if (typeof element === 'object' && element !== null) {
                this.pharmacy = [...this.pharmacy, element];
              } else {
                console.error('Invalid element:', element);
              }
            });
          } else {
            console.error('Data is not an array:', data);
          }
        },
        complete: () => this.toggleLoading(),
      });
    }
  };

  getAuthState(): void {
    this.accountService.getAuthenticationState().subscribe(account => {
      this.account = account;
      // if (
      //   (!this.account?.applicationUser || !this.account.applicationUser.proProfileCompleted) &&
      //   this.account?.authorities.includes('ROLE_PHARMACIST')
      // ) {
      // }
    });
  }
  toggleLike(pharma: any, index: number): void {
    if (pharma.liked) {
      this.unlikePharmacy(pharma.id, index);
    } else {
      this.likePharmacy(pharma.id, index);
    }
  }
  likePharmacy(pharmacy_id: any, index: number): void {
    const data = {
      favorable_type: 'Pharmacy',
      favorable_id: pharmacy_id,
    };
    this.homeService.likePharmacy(data).subscribe(
      response => {
        this.pharmacy[index].liked = true;
        this.lengthLike++;
        this.updateLikeCounter();
        this.toastr.success('Pharmacie ajoutée aux favoris avec succès !', 'Success');
      },
      error => {
        this.toastr.error("Échec de l'ajout de la pharmacie aux favoris. Veuillez réessayer.", 'Error');
      },
    );
  }

  unlikePharmacy(pharmacy_id: any, index: number): void {
    const data = {
      favorable_type: 'Pharmacy',
      favorable_id: pharmacy_id,
    };
    this.homeService.unlikePharmacy(data).subscribe(
      response => {
        this.pharmacy[index].liked = false;
        this.lengthLike--;
        this.updateLikeCounter();
        this.toastr.info('Pharmacie retirée des favoris avec succès.', 'Info');
      },
      error => {
        console.error('Error unliking pharmacy:', error);
        this.toastr.error('Échec du retrait du pharmacie des favoris. Veuillez réessayer.', 'Error');
      },
    );
  }

  updateLikeCounter(): void {
    this.likeCounter = this.pharmacy.filter(pharma => pharma.liked).length;
    this.homeService.updateLikeCounter(this.likeCounter);
  }

  onMarkerClick(pharmacy: any): void {
    this.selectedPharmacy = pharmacy;
    this.modalService.open(this.modalDetailsPharmacy, { size: 'm', centered: true });
  }

  login(): void {
    this.router.navigate(['/login']);
  }

  openMapInHome(): void {
    this.modalService.open(this.modalMaps, { size: 'l', centered: true });
    this.markerPosition = { lat: 46.553354, lng: 1.925334 };
  }
  openModalCookies(): void {
    this.modalService.open(this.modalCookies, { size: 'l', centered: true });
  }
  openModalCookiesOne(): void {
    this.modalService.open(this.modalCookiesOne, { size: 's', centered: true });
  }

  logCenter(): void {
    this.showSearchButton = true;
    const newCenter: any = this.mapComponent.getCenter();
    this.newCenterLat = newCenter.lat();
    this.newCenterLng = newCenter.lng();
  }
  logCenter2(event: any): void {
    const newCenter: any = this.mapComponent.getCenter();
    this.getAddress(event.latLng.lat(), event.latLng.lng());
  }

  getAddress(lat: number, lng: number): void {
    const geocoder = new google.maps.Geocoder();
    const latlng = { lat, lng };

    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results?.[0]) {
          // Safely check for results
          this.address = results[0].formatted_address;
        } else {
          console.error('No results found');
        }
      } else {
        console.error('Geocoder failed due to:', status);
      }
    });
  }
  searchInCurrentArea(): void {
    this.searchPharmacyLatLng(this.newCenterLat, this.newCenterLng, this.currentPage, this.recentAddress.id, false);
  }

  updateMapLocation(lat: number, lng: number): void {
    this.map.setCenter({ lat, lng });
    this.markerPosition = { lat, lng };
  }

  openCommandModal(modal: any): void {
    this.modalService.open(modal, { size: 'lg', centered: true });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  activateListItem(event: MouseEvent) {
    const target = event.currentTarget as HTMLElement;

    const allLiElements = document.querySelectorAll('li.nav-item.dropdown.pointer');
    allLiElements.forEach(li => {
      if (li !== target) {
        li.classList.remove('active');
      }
    });
    // Get the ID of the target element
    const id = target.id;
    if (!target.classList.contains('active')) {
      target.classList.add('active');
    }
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  activateButton(button: string): void {
    this.activeButton = button;
    if (button === 'right') {
      this.showMap = true;
    } else {
      this.showMap = false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  increment(): void {
    this.value++;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  decrement(): void {
    if (this.value > 1) {
      this.value--;
    }
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
  }

  confirmOrder(): void {
    alert('Order confirmed!');
  }

  onFileSelected(event: Event): void {
    const inputElement = event.target as HTMLInputElement;

    if (inputElement.files && inputElement.files.length > 0) {
      const fileName = inputElement.files[0].name;
      const spanElement = inputElement.nextElementSibling;
      if (spanElement instanceof HTMLElement) {
        spanElement.textContent = fileName;
      }
    }
  }
  onImgSelected(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input.files) {
      const file = input.files[0];
      const reader = new FileReader();

      reader.onload = e => {
        // Explicitly check that the result is not undefined
        if (e.target?.result) {
          this.profileImageUrl = e.target.result;
        }
      };

      reader.readAsDataURL(file);
    }
  }
  onSaveAddress(): void {
    if (this.selectedPlace.formatted_address) {
      this.searchPharmacies(this.selectedPlace.formatted_address);
    } else {
      console.error('Selected place is not defined.');
    }
  }
  useCurrentLocation(): void {
    navigator.geolocation.getCurrentPosition(position => {
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;
      this.updateMapLocation(this.latitude, this.longitude);
      this.searchPharmacyLatLng(this.latitude, this.longitude, this.currentPage, this.recentAddress.id, false);
    });
  }

  stopEventPropagation(event: Event): void {
    event.stopPropagation();
  }
  openMapModal(): void {
    this.modalService.open(this.MapModalComponent, { size: 'm', centered: true });
  }
  reOpenAddressModal(): void {
    this.modalService.open(this.modalgeoTow, { size: 'l', centered: true });
    this.onPlaceSelected(this.selectedPlace);
  }
  onPlaceSelected(place: google.maps.places.PlaceResult): void {
    this.selectedPlace = place;
    if (this.account?.applicationUser) {
      const applicationUser = {
        ...this.account.applicationUser,
        deliveryAddress: this.selectedPlace.formatted_address,
      };
    } else {
      console.error('Application user is not defined.');
    }

    if (place.address_components) {
      this.ngZone.run(() => {
        const components = place.address_components as google.maps.GeocoderAddressComponent[];
        const getAddressComponent = (type: string): string => components.find(component => component.types.includes(type))?.long_name ?? '';
        this.adresseForm.patchValue({
          appartmentNumber: this.adresseForm.get('appartmentNumber')?.value ?? '',
          name: this.adresseForm.get('name')?.value ?? '',
          streetNumber: getAddressComponent('street_number'),
          streetName: getAddressComponent('route'),
          city: getAddressComponent('locality'),
          state: getAddressComponent('administrative_area_level_1'),
          postalCode: getAddressComponent('postal_code'),
          country: getAddressComponent('country'),
        });
      });
    } else {
      console.error('Address components are not available.');
    }
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
