import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterModule, Event, ActivatedRoute } from '@angular/router';
import HasAnyAuthorityDirective from '../../shared/auth/has-any-authority.directive';
import SharedModule from '../../shared/shared.module';
import { filter } from 'rxjs/operators';
import ActiveMenuDirective from '../navbar/active-menu.directive';
import { HomeServiceService } from '../../home/service/home-service.service';
import { Subscription } from 'rxjs';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CartService } from '../../cart/cart.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'jhi-side-bar',
  standalone: true,
  imports: [RouterModule, SharedModule, HasAnyAuthorityDirective, ActiveMenuDirective, NgxSkeletonLoaderModule, CommonModule],
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
  @Input() isInHome: string = '';
  @ViewChild('modalCartVide') modalCartVide: any;

  activeItem: string = 'pharmacies';
  pharmacyId: any;
  currentUrl: any;
  contentLoaded = false;
  pharmacieDetails: any;
  currentSchedule: any;
  disable: boolean = false;
  idSubscription!: Subscription;
  purchaseId: number | null = null;
  isPharmaciesRouteActive = false;
  isOrdersRouteActive = false;
  documents: any;
  prescriptionUrls: string[] = [];
  vitalCardUrl: string[] = [];
  mutualCardUrl: string[] = [];
  cookiesCart!: any[];
  cartItems: any[] = [];

  constructor(
    private router: Router,
    private pharmacieService: HomeServiceService,
    private cartService: CartService,
    private modalService: NgbModal,
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      this.currentUrl = event.url;
    });
  }

  ngOnInit(): void {
    this.updateActiveState(this.router.url);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateActiveState(event.urlAfterRedirects);
      }
    });
    this.getPurchaseID();
    this.pharmacieService.pharmacyIdSubjectData$.subscribe(value => {
      this.pharmacyId = value;
      this.getPharmacyById(this.pharmacyId);
    });
    setTimeout(() => {
      this.contentLoaded = true;
    }, 2000);
    this.router.events
      .pipe(filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.disable = this.checkRoute(event.urlAfterRedirects);
      });
    this.loadCartItems();
  }
  setActive(item: string): void {
    this.activeItem = item;
  }
  updateActiveState(currentUrl: string): void {
    this.isPharmaciesRouteActive =
      currentUrl.includes('/home') ||
      currentUrl.includes('/pharmacie-category') ||
      currentUrl.includes('/pharmacie-sub-category') ||
      currentUrl.includes('/pharmacie-products');
    this.isOrdersRouteActive = currentUrl.includes('/orders') || currentUrl.includes('/order-details');
  }

  getPurchaseID(): void {
    this.cartService.purchaseId$.subscribe(res => {
      if (res) {
        this.purchaseId = res;
      } else {
        this.purchaseId = null;
      }
    });
  }

  loadCartItems(): void {
    this.cartService.cartItems$.subscribe(cartItems => {
      this.cookiesCart = cartItems;
      this.cartItems = cartItems;
    });
  }
  isCartAccessible(): boolean {
    return this.cartItems.length > 0 || !!this.purchaseId;
  }

  navigateToCart(): void {
    this.getPurchaseID();
    if (this.isCartAccessible()) {
      this.router.navigate(['/cart']);
    } else {
      this.openModal();
    }
  }
  openModal(): void {
    this.modalService.open(this.modalCartVide, { size: 's', centered: true });
  }

  checkRoute(url: string): boolean {
    const routesToDisable = ['/pharmacie-category', '/pharmacie-sub-category', '/pharmacie-products'];
    return routesToDisable.some(route => url.includes(route));
  }

  getPharmacyById(pharmacyId: any): void {
    this.pharmacieService.getPharmacyById(pharmacyId).subscribe({
      next: res => {
        this.pharmacieDetails = res;
      },
    });
  }

  formatHour(openingHour: string | null, closingHour: string | null, openingHour2?: string | null, closingHour2?: string | null): string {
    if (!openingHour || !closingHour) {
      return 'Horaires non disponibles';
    }

    const formatPart = (part: string): string => part.padStart(2, '0');

    const formatTime = (hour: string, minute: string): string => `${formatPart(hour)}h${formatPart(minute)}`;

    const [openingHourPart, openingMinutePart] = openingHour.split(':');
    const [closingHourPart, closingMinutePart] = closingHour.split(':');

    let result = `${formatTime(openingHourPart, openingMinutePart)}–${formatTime(closingHourPart, closingMinutePart)}`;

    if (openingHour2 && closingHour2) {
      const [openingHour2Part, openingMinute2Part] = openingHour2.split(':');
      const [closingHour2Part, closingMinute2Part] = closingHour2.split(':');
      result += ` / ${formatTime(openingHour2Part, openingMinute2Part)}–${formatTime(closingHour2Part, closingMinute2Part)}`;
    }

    return result;
  }
}
