<div class="row mt-5 mb-4"></div>
<div class="row">
  <div class="col-sm-12 col-md-5">
    <h2 class="lead" style="color: #0078c6">Mon Panier</h2>
  </div>
  <div class="col-sm-12 col-md-7 d-sm-flex justify-content-md-end flex-column flex-sm-row">
    <button class="btn btn-primary btn-bill" (click)="openCommandModal(modalOrdonnance)">Déposer une ordonnance</button>
  </div>
  <div class="col-sm-8">
    <div class="cart-items mb-5">
      <div class="card mb-5">
        <div class="card-header">Détail Produit</div>
        <div class="card-body">
          <div class="left-section mb-3" *ngIf="cartItems.length">
            <div class="header-image">
              <img src="../../../../content/images/Groupe de masques 1.png" />
            </div>
            <div class="pharmacy-details">
              <span class="pharmacy-name">{{ pharmacyName ? pharmacyName : '---' }} </span>
              <span class="pharmacy-address">
                <span>
                  <img src="../../../../content/images/Icon feather-map-pin.png" />
                </span>
                <span>{{ pharmacyAddress ? pharmacyAddress : '---' }}</span>
              </span>
            </div>
          </div>
          <div class="cart-item mb-3" *ngFor="let item of cartItems; let i = index">
            <div class="d-flex flex-column flex-sm-row" *ngIf="item.title !== 'Ordonnance'">
              <div class="img-item-wrap col-sm-2 d-flex justify-content-center flex-column">
                <img [src]="imageUrl + item.productImage + '/large'" />
              </div>
              <div class="item-desc col-sm-5">
                <h6>{{ item.title }}</h6>
                <!--
                <p class="descriptionDetail" [ngClass]="{ expanded: isExpandedDescription }" (click)="toggleExpandDescription()">
                  {{ item.description }}
                </p>
                -->
              </div>
              <div class="col-sm-2 d-flex justify-content-center">
                <div class="qty">
                  <button (click)="decreaseQty(item.id)" class="btn btn-decrese">-</button>
                  <input
                    type="number"
                    name="qty"
                    [value]="item.quantity"
                    readonly
                    class="form-control mx-2 text-center"
                    style="width: 60px"
                  />
                  <button (click)="increaseQty(item.id)" class="btn btn-increse">+</button>
                </div>
              </div>
              <div class="price-wrap col-sm-2 d-flex justify-content-center">
                <span class="price">{{ (item?.price * item?.quantity).toFixed(2) }}€</span>
              </div>
              <div class="remove-item col-sm-1 d-flex justify-content-center">
                <button class="btn btn-remove" (click)="removeItem(item?.id)">X</button>
              </div>
            </div>
          </div>
          <!--*ngIf="prescriptionFiles?.length > 0 || vitalCardFiles?.length > 0 || mutualCardFiles?.length > 0"-->
          <div class="cart-item mb-3" *ngIf="testing">
            <div class="d-flex" (click)="openCommandModal(modalOrdonnance)">
              <div class="img-item-wrap col-sm-2 d-flex justify-content-center flex-column">
                <img height="48" src="/content/images/icons/frais-ordonnance.svg" />
              </div>
              <div class="item-desc col-sm-5">
                <h6>Ordonnance num {{ purchase_id }}</h6>
              </div>
              <div class="col-sm-2 d-flex justify-content-center"></div>
              <div class="price-wrap col-sm-2">
                <span class="price">--- €</span>
              </div>
              <div class="remove-item col-sm-1 d-flex justify-content-center">
                <button class="btn btn-remove">X</button>
              </div>
            </div>
          </div>
          <div *ngIf="!cartItems.length && testing === null">
            <lottie-player
              src="../../content/images/noData.json"
              background="transparent"
              speed="1"
              style="width: 300px; height: 250px; margin: auto"
              loop
              autoplay
            ></lottie-player>
          </div>
          <!--
            *ngIf="cartItems.length || validDocuments"
          -->
          <button *ngIf="cartItems.length || testing" class="btn btn-produit btn-block mt-3" (click)="confirmAndScroll()">CONFIRMER</button>
        </div>
      </div>

      <div class="card" *ngIf="deliveryDetails">
        <div class="card-header">Détail livraison</div>
        <div class="card-body">
          <form (ngSubmit)="updateContactInfo()">
            <div class="mb-3 row">
              <div class="col-sm-6">
                <label for="firstName">Prénom</label>
                <input type="text" name="firstName" [(ngModel)]="account.firstname" [disabled]="clicAndCollect" id="firstName" />
              </div>
              <div class="col-sm-6">
                <label for="lastName">Nom</label>
                <input type="text" name="lastName" id="lastName" [(ngModel)]="account.lastname" [disabled]="clicAndCollect" />
              </div>
            </div>
            <div class="mb-3 row">
              <div class="col-sm-6">
                <label for="tel">Num Tél</label>
                <input type="tel" name="tel" id="tel" [(ngModel)]="account.phoneNumber" [disabled]="clicAndCollect" />
              </div>
              <div class="col-sm-6">
                <label for="mail">Mail</label>
                <input type="email" name="mail" id="mail" [(ngModel)]="account.email" [disabled]="clicAndCollect" />
              </div>
            </div>
            <div class="mb-3 row">
              <div class="col-sm-12">
                <label for="address">Adresse</label>
                <input
                  type="text"
                  name="address"
                  id="address"
                  [ngModel]="
                    recentAddress
                      ? recentAddress.streetNumber +
                        ' ' +
                        recentAddress.streetName +
                        ', ' +
                        recentAddress.city +
                        ' ' +
                        recentAddress.state +
                        ', ' +
                        recentAddress.postalCode +
                        ', ' +
                        recentAddress.country
                      : ''
                  "
                  [disabled]="clicAndCollect"
                  readonly
                />
              </div>
            </div>
            <button class="btn btn-produit btn-block mt-3 mb-5" type="submit" (click)="nextStepToPaymentDetails()">METTRE À JOUR</button>
            <div>
              <input type="checkbox" [(ngModel)]="clicAndCollect" name="clicAndCollect" (change)="onClicAndCollectChange($event)" />
              Clic & Collect
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div [ngClass]="deliveryDetails ? 'col-sm-4 fixed-block' : 'col-sm-4 first-fixed-block'">
    <div class="card">
      <div class="card-header">Détail Paiement</div>
      <div class="card-body">
        <div class="command-summary">
          <div class="d-flex flex-column">
            <div class="summary">
              <div class="summary-item">
                <!--
                {{ numCommande }}
                -->
                <span>Ref commande </span><span>{{ purchase_id ? purchase_id : '---' }}</span>
              </div>
              <div class="summary-item">
                <span>Sous-total </span><span>{{ subtotal.toFixed(2) }}€</span>
              </div>
              <div class="summary-item">
                <span>Frais de service </span><span>{{ serviceFee.toFixed(2) }}€</span>
              </div>
              <div class="summary-item">
                <span>Livraison </span><span>{{ deliveryFee.toFixed(2) }}€</span>
              </div>
              <div class="summary-item summary-total">
                <span>Totale </span><span class="total-price">{{ total.toFixed(2) }}€</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!---->
      <button class="btn btn-confirm btn-block mt-3" [disabled]="!paymentDetails" (click)="checkoutCommande()">PAYER</button>
      <button class="btn btn-annuler btn-block mt-3" (click)="NavigateToProductsOrPharmacy()">CONTINUER MES ACHATS</button>
    </div>
  </div>
</div>
<ng-template #modalOrdonnance let-modalOrdonnance>
  <div class="modal-product">
    <div class="modal-header">
      <div class="col-11 d-flex justify-content-start">
        <div class="title">
          Nouvelle Commande chez {{ pharmacyName }}
          <p class="command-id" *ngIf="numCommande">ID: {{ numCommande }}</p>
        </div>
      </div>
      <div class="col-1 d-flex align-items-end flex-column">
        <button type="button" class="close" (click)="modalOrdonnance.dismiss('Cross click')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      <form [formGroup]="PurchasesForm">
        <div class="row">
          <div class="col-sm-12 col-lg-3">
            <label for="ordonnance">Ordonnance</label>
            <div class="file-wrapper">
              <div class="add-date-btn">
                <input class="form-control" type="file" (change)="onFileSelectedPrescription($event)" id="ordonnance" multiple />
                <span class="file-placeholder-ordonnance"></span>
                Téléversez vos ordonnances
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-9 d-flex align-items-center">
            <div *ngIf="fileUrls.length > 0 || fileUrlsFromOvh.length > 0" class="image-wrapper d-flex">
              <div class="image-container" *ngFor="let fileUrl of fileUrlsFromOvh; let i = index">
                <img [src]="fileUrl" alt="Ordonnance Image" class="img-fluid" />
                <button type="button" (click)="removeFilePrescription(fileUrl, i)" class="btn btn-danger btn-sm">X</button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-lg-6">
            <label for="carte-vitale">Carte Vitale</label>
            <div class="file-wrapper">
              <input class="form-control" type="file" (change)="onFileSelected($event, 'vital_card')" id="carte-vitale" />
              <span class="file-placeholder">{{ PurchasesForm.get('vital_card')?.value?.name || '' }}</span>
            </div>
          </div>
          <div class="col-sm-12 col-lg-6">
            <label for="mutuelle">Carte mutuelle</label>
            <div class="file-wrapper">
              <input class="form-control" type="file" (change)="onFileSelected($event, 'mutual_card')" id="mutuelle" />
              <span class="file-placeholder">{{ PurchasesForm.get('mutual_card')?.value?.name || '' }}</span>
            </div>
          </div>
        </div>
        <div class="modal-bottom">
          <label for="consentement" class="consentement">
            <input id="consentement" type="checkbox" name="consentement" class="form-check-input" (change)="onCheckboxChange($event)" />
            Je valide mon consentement pour le traitement de mes données personnelles.
          </label>
        </div>
        <button
          class="submit"
          type="button"
          [disabled]="!consentGiven"
          (click)="checkAndAddPurchases(); modalOrdonnance.dismiss('Cross click')"
        >
          Confirmer
        </button>
        <div #scrollTarget></div>
        <button type="button" class="btn btn-annuler" (click)="modalOrdonnance.dismiss('Cross click')" aria-label="Close">Annuler</button>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #confirmedAddOrder let-confirmedAddOrder>
  <div class="modal-header">
    <div class="col-11 d-flex justify-content-center"></div>
    <div class="col-1 d-flex align-items-end flex-column">
      <button type="button" class="close" (click)="confirmedAddOrder.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="d-flex flex-column align-items-center">
      <img src="../../content/images/success.svg" alt="" />
      <p class="swalModal">Commande envoyée avec succès !</p>
      <span class="mb-4 productExist">Votre commande a été envoyée. Merci de patienter pour la réponse du pharmacien.</span>
      <div class="d-flex justify-content-between w-100">
        <button class="btn btn-success mt-3 mb-3 saveAdresse" (click)="navigateToOrdersList(); confirmedAddOrder.close('clearCart')">
          OK
        </button>
      </div>
      <div class="col-12 d-flex flex-column align-items-start down-input"></div>
    </div>
  </div>
</ng-template>
