import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HomeServiceService } from '../service/home-service.service';
import { ThisReceiver } from '@angular/compiler';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import SharedModule from '../../shared/shared.module';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CartService } from '../../cart/cart.service';
import { ToastrService } from 'ngx-toastr';
interface SubCategory {
  stockItemsAccount: number;
}
interface Category {
  subCategories: SubCategory[];
}
@Component({
  selector: 'jhi-pharmacie-details',
  standalone: true,
  imports: [CommonModule, RouterModule, SharedModule, NgxSkeletonLoaderModule, ReactiveFormsModule],
  templateUrl: './pharmacie-details.component.html',
  styleUrl: './pharmacie-details.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PharmacieDetailsComponent implements OnInit {
  @ViewChild('modalProductExist') modalProductExist: any;
  @ViewChild('modalAddressExist') modalAddressExist: any;
  @ViewChild('modalOrdonnance') modalOrdonnance: any;

  currentPage: number = 1;
  categories: any;
  pharmacyId!: number;
  pharmacyType: any;
  pharmacyName: any;
  contentLoaded = false;
  activeCategory: string = 'P';
  imageUrl: string = 'https://imagedelivery.net/z1UHS7Z96nNnNVE8sIpBxg/';
  promos: any;
  PurchasesForm: FormGroup;
  selectedAddressId: number | null = null;
  maxFileSize = 5 * 1024 * 1024; // 5 MB
  allowedFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
  maxFiles = 3;
  fileUrls: string[] = [];
  isExpandedDescription = false;
  isExpanded = false;
  openedProduct: any;
  value: number = 1;
  activeTab: string = 'composition';
  pharmacyCartItemsName: any;
  ordonnanceImages: Array<string | ArrayBuffer> = [];
  prescriptionFiles: File[] = [];
  vitalCardFiles: File[] = [];
  mutualCardFiles: File[] = [];
  cookiesCart!: any[];
  total: any;
  consentGiven = false;
  documents: any;
  prescriptionUrls: string[] = [];
  vitalCardUrl: string | null = null;
  mutualCardUrl: string | null = null;
  isLoading = false;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private pharmacieService: HomeServiceService,
    private activatedRoute: ActivatedRoute,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private cartService: CartService,
    private toastr: ToastrService,
  ) {
    this.PurchasesForm = this.createPurchasesForm();
  }

  ngOnInit(): void {
    this.pharmacyCartItemsName = this.cartService.getPharmacyName();
    this.pharmacieService.selectedAddressId$.subscribe(addressId => {
      this.selectedAddressId = addressId;
    });
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.contentLoaded = true;
    }, 1000);
    this.getPharmacyId();
    this.pharmacyName = this.pharmacieService.getCommercialName();
    this.getParmacyPromos();
  }
  stopEventPropagation(event: Event): void {
    event.stopPropagation();
  }
  getPharmacyId(): void {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.pharmacyId = +params.get('pharmacieId');
        this.pharmacyType = params.get('T');
        this.getCategories(this.pharmacyType);
      },
    });
  }

  getPharmacyName(): void {
    this.route.queryParams.subscribe(params => {
      this.pharmacyName = params['pharmacyName'] || '';
    });
  }
  changeCategoryP(): void {
    this.activeCategory = 'P';
    this.router.navigate(['/pharmacie-category', this.pharmacyId, 'P']);
  }

  changeCategoryM(): void {
    this.activeCategory = 'M';
    this.router.navigate(['/pharmacie-category', this.pharmacyId, 'M']);
  }
  // navigateToSubCategory(category: any) {
  //   this.router.navigate(['/pharmacie-sub-category', { pharmacieId: this.pharmacyId, categoryId: category.id }]);
  //   this.setCatName(category.name);
  //   this.pharmacieService.setSelectedCategory(category);
  // }
  navigateToSubCategory(category: any): void {
    this.router.navigate(['/pharmacie-sub-category', this.pharmacyId, category.id]);
    this.setCatName(category.name);
    this.pharmacieService.setSelectedCategory(category);
  }
  setCatName(catName: any): void {
    this.pharmacieService.setCatName(catName);
  }
  openCommandModal(modal: any): void {
    this.modalService.open(modal, { size: 'lg', centered: true });
  }

  onFileSelected(event: Event, fileType: string): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      const file = inputElement.files[0];
      this.PurchasesForm.get(fileType)?.setValue(file);
      const files = Array.from(inputElement.files);

      if (fileType === 'prescription') {
        this.prescriptionFiles = [...this.prescriptionFiles, ...files];
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.ordonnanceImages.push(e.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        const spanElement = inputElement.nextElementSibling;
        if (spanElement instanceof HTMLElement) {
          spanElement.textContent = file.name;
        }
      }
      files.forEach(uploadedFile => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          if (fileType === 'prescription') {
            this.ordonnanceImages.push(e.target.result);
          }
        };
        reader.readAsDataURL(uploadedFile);
      });
    }
  }
  getCategories(pharmacyType: string): void {
    if (this.currentPage) {
      this.pharmacieService.getCategories(pharmacyType, this.pharmacyId, 0).subscribe({
        next: (res: Category[]) => {
          this.categories = res.filter((category: Category) =>
            category.subCategories.some((subCategory: SubCategory) => subCategory.stockItemsAccount !== 0),
          );
        },
      });
    }
  }
  getParmacyPromos(): void {
    if (this.currentPage) {
      this.pharmacieService.getPromos(this.pharmacyId).subscribe({
        next: (res: any) => {
          this.promos = res;
        },
        error(err: any) {
          console.error('Error fetching pharmacies:', err);
        },
      });
    } else {
      console.error('Current page is not defined');
    }
  }
  createPurchasesForm(data?: any): FormGroup {
    return this.formBuilder.group({
      firstName: [data ? data.firstName : ''],
      lastName: [data ? data.lastName : ''],
      prescription: [data ? data.prescription : null],
      mutual_card: [data ? data.mutual_card : null],
      vital_card: [data ? data.vital_card : null],
    });
  }
  onCheckboxChange(event: any): void {
    this.consentGiven = event.target.checked; // Mettez à jour l'état de la case à cocher
  }
  removeFile(fileToRemove: File): void {
    this.prescriptionFiles = this.prescriptionFiles.filter(file => file !== fileToRemove); // Supprimer le fichier
  }
  addPurchases(purchaseId: any): void {
    this.PurchasesForm.markAllAsTouched();
    this.PurchasesForm.markAsDirty();
    const formData = new FormData();

    const formControls = this.PurchasesForm.controls;
    this.prescriptionFiles.forEach(file => {
      formData.append('prescription', file);
    });

    // Ajouter les autres fichiers
    ['vital_card', 'mutual_card'].forEach(fileType => {
      const file = formControls[fileType].value;
      if (file) {
        formData.append(fileType, file);
      }
    });
    this.uploadFiles(formData, purchaseId);
  }

  uploadFiles(formData: FormData, purchaseId: any): void {
    this.pharmacieService.uploadFiles(purchaseId, formData).subscribe({
      next: (res: any) => {
        this.toastr.success('Fichiers téléchargés avec succès.');
        this.getAllFilesUrl();
        this.resetForm();
        this.cartService.addPrescriptionToCart();
      },
      error: (err: any) => {
        this.toastr.error('Erreur lors du téléchargement des fichiers.');
      },
    });
  }

  resetForm(): void {
    this.prescriptionFiles = [];
    this.fileUrls = [];
    this.vitalCardFiles = [];
    this.mutualCardFiles = [];
    this.PurchasesForm.reset();
  }

  getAllFilesUrl(): void {
    const purchaseId = this.cartService.getPurchaseId();
    this.pharmacieService.getFileUrl(purchaseId).subscribe(res => {
      this.documents = res;
      this.prescriptionUrls = res.prescriptionFiles || [];
      this.vitalCardUrl = res.vital_card || null;
      this.mutualCardUrl = res.mutual_card || null;
    });
  }
  getFileUrl(file: File): string {
    return URL.createObjectURL(file);
  }

  async addOrder(modal: any): Promise<void> {
    this.isLoading = true;
    const purchaseStockItems: any = [];
    if (Array.isArray(this.cookiesCart)) {
      for (const item of this.cookiesCart) {
        purchaseStockItems.push({
          stockItem: { id: item.id },
          requestedQuantity: item.quantity,
        });
      }
    }
    const cartData = {
      pharmacyId: this.pharmacyId,
      deliveryAddressId: this.selectedAddressId,
      purchaseStockItems,
    };

    try {
      const response = await this.pharmacieService.addToCart(cartData).toPromise();
      this.addPurchases(response.id);
      this.cartService.setPurchaseId(response.id);
      this.cartService.setNumCommande(response.num);
      modal.dismiss();
    } catch (error) {
      console.error('Error adding product to cart:', error);
      if (error.error?.message === 'error.invaliddeliveryfee') {
        this.openModalAddress();
      } else {
        this.toastr.error('Une erreur est survenue. Veuillez réessayer.', 'Erreur');
      }
    } finally {
      this.isLoading = false;
    }
  }

  openModalAddress(): void {
    this.modalService.open(this.modalAddressExist, { size: 'l', centered: true });
  }
  notifyChangeAddress(): void {
    this.pharmacieService.notifyChangeAddress();
  }

  onFileSelectedPrescription(event: any): void {
    const files: FileList = event.target.files;
    if (this.prescriptionFiles.length + files.length > this.maxFiles) {
      this.toastr.info(`Vous pouvez télécharger un maximum de ${this.maxFiles} fichiers.`, 'Limite de fichiers dépassée');
      return;
    }
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (!this.allowedFileTypes.includes(file.type)) {
        this.toastr.info('Seuls les fichiers images (JPG, PNG) ou PDF sont autorisés.`, Type de fichier invalide');
        continue;
      }
      if (file.size > this.maxFileSize) {
        this.toastr.info(`La taille du fichier ne doit pas dépasser ${this.maxFileSize / (1024 * 1024)} Mo.`, 'Taille de fichier dépassée');
        continue;
      }
      this.prescriptionFiles.push(file);
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.fileUrls.push(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }
  removeFilePrescription(file: File): void {
    const index = this.prescriptionFiles.indexOf(file);
    if (index > -1) {
      this.prescriptionFiles.splice(index, 1);
      this.fileUrls.splice(index, 1);
    }
  }

  increment(): void {
    this.value++;
  }
  decrement(): void {
    if (this.value > 1) {
      this.value--;
    }
  }
  openproductModal(modal: any, product: any): void {
    this.modalService.open(modal, { size: 'lg', centered: true });
    this.openedProduct = product;
    this.value = this.cartService.getCurrent(product);
  }
  openModal(modalTemplate: TemplateRef<any>, product: any): void {
    this.modalService.open(modalTemplate, { size: 's', centered: true }).result.then(result => {
      if (result === 'clearCart') {
        this.cartService.clearCartItems();
        if (this.openedProduct) {
          this.addToCartWithASpecificNumber();
        } else {
          this.addToCart(product, this.modalProductExist);
        }
      }
    });
  }

  addToCartWithASpecificNumber(): void {
    if (!this.cartService.checkSamePharmacy(this.openedProduct)) {
      this.openModal(this.modalProductExist, this.openedProduct);
    } else {
      this.cartService.addToCartWithASpecificNumber(this.openedProduct, this.value);
      this.toastr.success('Produit ajouté au panier.');
    }
  }
  addToCart(product: any, modalTemplate: TemplateRef<any>): void {
    if (!this.cartService.checkSamePharmacy(product)) {
      this.openModal(modalTemplate, product);
    } else {
      this.cartService.addToCart(product);
      this.toastr.success('Produit ajouté au panier.');
    }
  }
  toggleExpandDescription(): void {
    this.isExpandedDescription = !this.isExpandedDescription;
  }
  toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
  }
  setActiveTab(tab: string): void {
    this.activeTab = tab;
  }
  toggleLike(product: any, index: number): void {
    if (product.liked) {
      this.unlikeProduct(product.id, index);
    } else {
      this.likeProduct(product.id, index);
    }
  }
  likeProduct(product_id: any, index: number): void {
    const data = {
      favorable_type: 'StockItem',
      favorable_id: product_id,
    };
    this.pharmacieService.likeProduct(data).subscribe(response => {
      this.promos[index].liked = true;
    });
  }
  unlikeProduct(product_id: any, index: number): void {
    const data = {
      favorable_type: 'StockItem',
      favorable_id: product_id,
    };
    this.pharmacieService.unlikeProduct(data).subscribe(response => {
      this.promos[index].liked = false;
    });
  }
}
