<div class="row mt-5">
  <h2 class="lead" style="color: #141414">Liste des Pharmacies</h2>
</div>
<div class="row">
  <div class="col-9">
    <ul ngbNav #nav="ngbNav" class="nav-tabs" role="tablist">
      <li [ngbNavItem]="1">
        <a ngbNavLink>Invité</a>
        <ng-template ngbNavContent>
          <div [ngClass]="createdWithPrescriptionPurchasesCount > 8 ? 'scorllable-container ' : ''">
            <div class="table-container">
              <table class="table table-responsive">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">CIP</th>
                    <th scope="col">Dén Commercial</th>
                    <th scope="col">Adresse</th>
                    <th scope="col">Détails</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container>
                    <tr *ngIf="!contentLoaded">
                      <td colspan="5">
                        <ngx-skeleton-loader
                          count="1"
                          appearance="line"
                          [theme]="{ height: '24px', width: '100%', 'margin-bottom': '10px' }"
                        ></ngx-skeleton-loader>
                      </td>
                    </tr>
                    <tr *ngIf="contentLoaded">
                      <td>74123698523</td>
                      <td>741265852352</td>
                      <td>azerty azerty azerty</td>

                      <td>
                        <div class="btn-wrap">
                          <button class="btn-command btn-view"></button>
                        </div>
                      </td>
                      <td>
                        <div class="btn-wrap">
                          <button class="btn-payer">Envoi Invitation</button>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <!--
            -->
            <div class="card flex justify-content-center">
              <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords" />
            </div>
          </div>
        </ng-template>
      </li>

      <li [ngbNavItem]="2">
        <a ngbNavLink>Actif</a>
        <ng-template ngbNavContent>
          <div [ngClass]="inProgressPurchasesCount > 8 ? 'scorllable-container ' : ''">
            <div class="table-container">
              <table class="table table-responsive">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">CIP</th>
                    <th scope="col">Dén Commercial</th>
                    <th scope="col">Adresse</th>
                    <th scope="col">Détails</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container>
                    <tr *ngIf="!contentLoaded">
                      <td colspan="5">
                        <ngx-skeleton-loader
                          count="1"
                          appearance="line"
                          [theme]="{ height: '24px', width: '100%', 'margin-bottom': '10px' }"
                        ></ngx-skeleton-loader>
                      </td>
                    </tr>
                    <tr *ngIf="contentLoaded">
                      <td>741236987456</td>
                      <td>789632541252</td>
                      <td>azerty azerty azerty azerty</td>

                      <td>
                        <div class="btn-wrap">
                          <button class="btn-command btn-view"></button>
                        </div>
                      </td>
                      <td>
                        <div class="btn-wrap">
                          <button class="btn-payer">Voir Détails</button>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink>En attente</a>
        <ng-template ngbNavContent>
          <div [ngClass]="paymentInstancePurchasesCount > 8 ? 'scorllable-container ' : ''">
            <div class="table-container">
              <table class="table table-responsive">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">CIP</th>
                    <th scope="col">Dén Commercial</th>
                    <th scope="col">Adresse</th>
                    <th scope="col">Détails</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container>
                    <tr *ngIf="!contentLoaded">
                      <td colspan="5">
                        <ngx-skeleton-loader
                          count="1"
                          appearance="line"
                          [theme]="{ height: '24px', width: '100%', 'margin-bottom': '10px' }"
                        ></ngx-skeleton-loader>
                      </td>
                    </tr>
                    <tr *ngIf="contentLoaded">
                      <td>74125896358</td>
                      <td>74125896325</td>
                      <td>azerty azerty azerty azerty</td>

                      <td>
                        <div class="btn-wrap">
                          <button class="btn-command btn-view"></button>
                        </div>
                      </td>

                      <td>
                        <div class="btn-wrap">
                          <button class="btn-payer">Valider</button>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="4">
        <a ngbNavLink>Archivé</a>
        <ng-template ngbNavContent>
          <div [ngClass]="packagingPurchasesCount > 8 ? 'scorllable-container ' : ''">
            <div class="table-container">
              <table class="table table-responsive">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">CIP</th>
                    <th scope="col">Dén Commercial</th>
                    <th scope="col">Adresse</th>
                    <th scope="col">Détails</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container>
                    <tr *ngIf="!contentLoaded">
                      <td colspan="5">
                        <ngx-skeleton-loader
                          count="1"
                          appearance="line"
                          [theme]="{ height: '24px', width: '100%', 'margin-bottom': '10px' }"
                        ></ngx-skeleton-loader>
                      </td>
                    </tr>
                    <tr *ngIf="contentLoaded">
                      <td>7418525852852</td>
                      <td>321232123212</td>
                      <td>azerty azerty azerty</td>
                      <td>
                        <div class="btn-wrap">
                          <button class="btn-command btn-view"></button>
                        </div>
                      </td>
                      <td>
                        <div class="btn-wrap">
                          <button class="btn-payer">Réactiver</button>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
  </div>
  <div class="col-3">
    <div class="btn-wrap">
      <button class="btn-invite" (click)="openModal()">+ Inviter Pharmacien</button>
    </div>
  </div>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
<ng-template #modalgeo let-modalgeo>
  <div class="modal-header">
    <div class="col-11 d-flex justify-content-center"></div>
    <div class="col-1 d-flex align-items-end flex-column">
      <button type="button" class="close" (click)="modalgeo.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="form-container">
      <h4>Créer une pharmacie par invitation</h4>

      <!-- Section Gérant -->
      <p>Gérant</p>
      <div class="form-section">
        <div class="form-group">
          <input type="text" id="prenom" placeholder="Prénom" class="form-control" />
        </div>
        <div class="form-group">
          <input type="text" id="nom" placeholder="Nom" class="form-control" />
        </div>
      </div>

      <div class="form-section">
        <div class="form-group">
          <input type="email" id="email" placeholder="Email" class="form-control" />
        </div>
        <div class="form-group">
          <input type="text" id="numTel" placeholder="Num Tél" class="form-control" />
        </div>
      </div>

      <!-- Section Pharmacie -->
      <p>Pharmacie</p>
      <input type="text" id="denomination" placeholder="Dénomination Commerciale" class="form-control full-width mb-3" />
      <input type="text" id="adresse" placeholder="Adresse Pharmacie" class="form-control full-width mb-3" />

      <div class="form-section">
        <div class="form-group">
          <input type="email" id="emailService" placeholder="Email de service" class="form-control" />
        </div>
        <div class="form-group">
          <input type="text" id="numTelService" placeholder="Num Tél Service" class="form-control" />
        </div>
      </div>

      <div class="form-section">
        <div class="form-group">
          <input type="text" id="cip" placeholder="CIP" class="form-control" />
        </div>
        <div class="form-group">
          <input type="text" id="siret" placeholder="Siret" class="form-control" />
        </div>
      </div>

      <!-- Submit Button -->
      <button class="btn btn-submit">Valider</button>
    </div>
  </div>
</ng-template>
