import { Component, ElementRef, HostListener, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';

import SharedModule from '../../../shared/shared.module';
import { FormControl, FormGroup, FormsModule, Validators, ReactiveFormsModule, FormBuilder } from '@angular/forms';

import { ICategory } from '../../../entities/category/category.model';
import { CategoryService } from '../../../entities/category/service/category.service';
import { IPharmacy } from '../pharmacy.model';
import { PharmacyService } from '../service/pharmacy.service';
import { PharmacyFormService, PharmacyFormGroup } from './pharmacy-form.service';
import { ToastrService } from 'ngx-toastr';
import { RegisterService } from '../../../account/register/register.service';
import { LoginService } from '../../../login/login.service';
import { AccountService } from '../../../core/auth/account.service';

@Component({
  selector: 'jhi-pharmacy-update',
  templateUrl: './pharmacy-update.component.html',
  styleUrl: './pharmacy-update.component.scss',
  standalone: true,
  imports: [SharedModule, FormsModule, ReactiveFormsModule],
})
export class PharmacyUpdateComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput') fileInput!: ElementRef;
  isSaving = false;
  pharmacy: IPharmacy | null = null;

  categoriesSharedCollection: ICategory[] = [];
  nextValidation: boolean = false;
  ActivatedEmailAdmin: boolean = false;
  selectedFile: File | null = null;

  editForm: PharmacyFormGroup = this.pharmacyFormService.createPharmacyFormGroup();
  currentUrl: string;
  screenHeight = window.innerHeight;
  screenWidth = window.innerWidth;
  key: any;
  decodedToken: any;
  cloudflareForm: FormGroup;
  uploadedFileId: any;
  loginForm = new FormGroup({
    username: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    password: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    rememberMe: new FormControl(false, { nonNullable: true, validators: [Validators.required] }),
  });
  subscription: Subscription;
  selectedPlace!: google.maps.places.PlaceResult;
  address: any;
  fileError = false;
  countryNameToCode: { [key: string]: string } = {
    France: 'FR',
  };

  constructor(
    protected pharmacyService: PharmacyService,
    protected pharmacyFormService: PharmacyFormService,
    protected categoryService: CategoryService,
    protected activatedRoute: ActivatedRoute,
    private registerService: RegisterService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private fb: FormBuilder,
    private ngZone: NgZone,
    private accountService: AccountService,
  ) {
    this.currentUrl = router.url;
    this.cloudflareForm = this.fb.group({
      file: [null],
    });
  }

  compareCategory = (o1: ICategory | null, o2: ICategory | null): boolean => this.categoryService.compareCategory(o1, o2);

  ngOnInit(): void {
    this.subscription = this.accountService.activatedEmailAdmin$.subscribe(value => {
      this.ActivatedEmailAdmin = value;
    });
    this.route.queryParams.subscribe(key => {
      if (key['key']) {
        this.key = key['key'];
      }
    });
    if (this.key) {
      this.decodeToken();
    }
    this.activatedRoute.data.subscribe(({ pharmacy }) => {
      this.pharmacy = pharmacy;
      if (pharmacy) {
        this.updateForm(pharmacy);
      }

      this.loadRelationshipsOptions();
    });
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: Event): void {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }
  navigateToLanding(): void {
    this.router.navigate(['/login'], { queryParams: { user: 'pharmacist' } });
  }
  decodeToken(): void {
    const token = this.key;
    if (token) {
      this.decodedToken = this.registerService.decodeToken(token);
      if (this.decodedToken) {
        this.editForm.patchValue({
          commercialName: this.decodedToken.pharmacyCommercialName || '',
          address: this.decodedToken.pharmacyAddress || '',
          responsibleEmail: this.decodedToken.pharmacyResponsibleEmail || '',
          pharmacyOrderId: this.decodedToken.pharmacyOrderId || '',
          siret: this.decodedToken.pharmacySiret || '',
          phoneNumber: this.decodedToken.pharmacyPhoneNumber || '',
        });
      }
    }
  }
  nextValidationPage(): void {
    this.nextValidation = true;
  }
  returnValidationPage(): void {
    this.nextValidation = false;
  }
  previousState(): void {
    this.ActivatedEmailAdmin = true;
  }
  login(): void {
    if (this.key) {
      this.handleWithLogin();
    } else {
      this.handleWithoutLogin();
    }
  }
  handleWithLogin(): void {
    const credentials = this.registerService.getCredentials();
    if (!credentials) {
      this.toastr.error('Erreur : identifiants manquants');
      return;
    }

    const { email, password } = credentials;

    this.loginService
      .login({ username: email, password, rememberMe: false })
      .pipe(
        tap(() => this.toastr.success('Connexion réussie')),
        switchMap(() => this.uploadFile()),
        tap(() => this.save()),
        catchError(() => {
          this.toastr.error('Une erreur est survenue lors de la validation de votre compte');
          return of(null);
        }),
        finalize(() => this.registerService.clearCredentials()),
      )
      .subscribe();
  }
  handleWithoutLogin(): void {
    this.uploadFile()
      .pipe(
        tap(() => this.save()),
        catchError(() => {
          this.toastr.error('Une erreur est survenue lors de la validation de votre compte');
          return of(null);
        }),
      )
      .subscribe();
  }
  save(): void {
    this.isSaving = true;
    const pharmacy = this.pharmacyFormService.getPharmacy(this.editForm);
    pharmacy.frontPath = this.uploadedFileId;
    if (pharmacy.id !== null) {
      this.subscribeToSaveResponse(this.pharmacyService.update(pharmacy));
    } else {
      this.subscribeToSaveResponse(this.pharmacyService.create(pharmacy, this.uploadedFileId));
    }
  }

  uploadFile(): Observable<string> {
    if (this.selectedFile) {
      return this.pharmacyService.uploadFile(this.selectedFile).pipe(
        tap(response => {
          this.uploadedFileId = response;
        }),
        catchError((error: unknown) => throwError(() => new Error(String(error)))),
      );
    } else {
      return throwError(() => new Error('No file selected to upload.'));
    }
  }

  subscribeToSaveResponse(result: Observable<HttpResponse<IPharmacy>>): void {
    result.pipe(finalize(() => this.onSaveFinalize())).subscribe({
      next: () => this.onSaveSuccess(),
      error: () => this.onSaveError(),
    });
  }

  onSaveSuccess(): void {
    this.toastr.success('Votre compte a été validé avec succès');
    this.previousState();
    if (this.key) {
      this.router.navigate(['/login'], { queryParams: { user: 'stripeConnected' } });
    }
  }

  onSaveError(): void {
    this.toastr.error('Erreur de validation du compte');
  }

  onSaveFinalize(): void {
    this.isSaving = false;
  }

  updateForm(pharmacy: IPharmacy): void {
    this.pharmacy = pharmacy;
    this.pharmacyFormService.resetForm(this.editForm, pharmacy);

    this.categoriesSharedCollection = this.categoryService.addCategoryToCollectionIfMissing<ICategory>(
      this.categoriesSharedCollection,
      ...(pharmacy.categories ?? []),
    );
  }

  loadRelationshipsOptions(): void {
    this.categoryService
      .query()
      .pipe(map((res: HttpResponse<ICategory[]>) => res.body ?? []))
      .pipe(
        map((categories: ICategory[]) =>
          this.categoryService.addCategoryToCollectionIfMissing<ICategory>(categories, ...(this.pharmacy?.categories ?? [])),
        ),
      )
      .subscribe((categories: ICategory[]) => (this.categoriesSharedCollection = categories));
  }
  onFileSelected(event: Event, fileType: string): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      const file = inputElement.files[0];
      this.selectedFile = file;
      this.editForm.get(fileType)?.setValue(file);
      this.fileError = true;
      const spanElement = inputElement.nextElementSibling;
      if (spanElement instanceof HTMLElement) {
        spanElement.textContent = file.name;
      }
    } else {
      this.fileError = false; // Show error if no file is selected
    }
  }
  triggerFileInput(): void {
    this.fileInput.nativeElement.click();
  }

  onPlaceSelected(place: google.maps.places.PlaceResult): void {
    this.selectedPlace = place;
    if (place.address_components && place.address_components.length > 0) {
      this.ngZone.run(() => {
        const streetNumber = place.address_components?.find(c => c.types.includes('street_number'))?.long_name ?? '';
        const streetName = place.address_components?.find(c => c.types.includes('route'))?.long_name ?? '';
        const city = place.address_components?.find(c => c.types.includes('locality'))?.long_name ?? '';
        const postalCode = place.address_components?.find(c => c.types.includes('postal_code'))?.long_name ?? '';
        const countryName = place.address_components?.find(c => c.types.includes('country'))?.long_name ?? '';
        const countryCode = place.address_components?.find(c => c.types.includes('country'))?.short_name ?? '';
        const convertedCountryCode = this.countryNameToCode[countryName] || countryCode;

        if (streetNumber && streetName && city && postalCode && convertedCountryCode) {
          const completeAddress = `${streetNumber} ${streetName}, ${city}, ${postalCode}, ${convertedCountryCode}`;
          this.editForm.get('address')?.setValue(completeAddress);
        } else {
          this.toastr.error('Veuillez sélectionner une adresse complète.');
        }
      });
    } else {
      console.error('Address components are not available.');
      this.toastr.error('Veuillez sélectionner une adresse complète.');
    }
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
