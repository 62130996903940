import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { OrdersService } from '../../../orders/orders.service';
import { PaginatorModule } from 'primeng/paginator';
import { debounceTime, distinctUntilChanged, Subject, switchMap } from 'rxjs';
import { HomeServiceService } from '../../../home/service/home-service.service';
import { ToastrService } from 'ngx-toastr';
import { InvitationService } from 'app/adminPharmacyBackOffice/services/invitation.service';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';

interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}
@Component({
  selector: 'jhi-list-pharmacy',
  standalone: true,
  imports: [NgbModule, CommonModule, NgxSkeletonLoaderModule, PaginatorModule, ReactiveFormsModule],
  templateUrl: './list-pharmacy.component.html',
  styleUrl: './list-pharmacy.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ListPharmacyComponent {
  @ViewChild('modalgeo') modalgeo: any;
  @ViewChild('modalDetails') modalDetails: any;

  purshases: any;
  contentLoaded = false;
  first: number = 0;
  rows: number = 1;
  totalRecords: number = 0;
  searchInputSubject = new Subject<string>();
  paymentInstancePurchasesCount: number = 0;
  readyPurchasesCount: number = 0;
  packagingPurchasesCount: number = 0;
  inProgressPurchasesCount: number = 0;
  createdWithPrescriptionPurchasesCount: number = 0;
  currentStatuses: string[];
  invitations: any;
  invitationForme: FormGroup;
  pharmacy: any;

  constructor(
    private router: Router,
    private orderService: OrdersService,
    private invitationService: InvitationService,
    private modalService: NgbModal,
    private pharmacieService: HomeServiceService,
    private fb: FormBuilder,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.contentLoaded = true;
    }, 2000);
    this.getInvitation();
    this.getPharmacyByStatus();
    this.initForm();
    this.searchInputSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(searchName => {
          const data = {
            contactName: searchName.trim().toLowerCase(),
          };
          console.log('%centreprises.component.ts line:203 data search', 'color: #007acc;', data);
          return this.pharmacieService.searchPurchsesPharmacist(searchName.trim().toLowerCase()).pipe();
        }),
      )
      .subscribe((res: any) => {
        this.purshases = res.content;
      });
  }
  initForm(): void {
    this.invitationForme = this.fb.group({
      firstName: ['', [Validators.required, Validators.minLength(2)]],
      lastName: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.pattern(/^\+?\d{10,15}$/)]],
      pharmacyCommercialName: ['', [Validators.required]],
      pharmacyAddress: ['', [Validators.required]],
      pharmacyResponsibleEmail: ['', [Validators.required]],
      pharmacyPhoneNumber: ['', [Validators.required]],
      pharmacyOrderId: ['', [Validators.required]],
      pharmacySiret: ['', [Validators.required]],
    });
  }

  createInvitation(): void {
    if (this.invitationForme.valid) {
      this.invitationService.createdInvitation(this.invitationForme.value).subscribe({
        next: res => {
          this.getInvitation();
          this.toastr.success('Invitation créée avec succès.');
          this.invitationForme.reset(); // Réinitialiser le formulaire après succès
        },
        error: err => {
          console.error(err);
          this.toastr.error("Échec de la création de l'invitation.");
        },
      });
    } else {
      this.toastr.warning('Veuillez remplir correctement le formulaire.');
    }
  }
  getPharmacyByStatus(statuses: string[] = ['activated']) {
    this.invitationService.getPharmacyByStatus(this.first, statuses).subscribe({
      next: res => {
        this.pharmacy = res.content;
        this.totalRecords = res.totalPages;
      },
      error: err => {
        console.log(err);
      },
    });
  }
  getInvitation(): void {
    this.invitationService.getInvitations(this.first).subscribe({
      next: res => {
        this.invitations = res.content;
        this.totalRecords = res.totalPages;

        console.log('Invitations:', res);
      },
      error: err => {
        console.error(err);
      },
    });
  }
  verified(pharmacy_id: any) {
    this.invitationService.verifiedPharmacy(pharmacy_id).subscribe({
      next: res => {
        console.log('Invitation envoyée avec succès.', res);
        this.toastr.success('Invitation envoyée avec succès.');
      },
      error: err => {
        console.error(err);
        this.toastr.error("Échec de l'envoi de l'invitation.");
      },
    });
  }
  sendInvitation(invitationId: any) {
    this.invitationService.sendInvitation(invitationId).subscribe({
      next: res => {
        this.getInvitation();
        console.log('Invitation envoyée avec succès.', res);
        this.toastr.success('Invitation envoyée avec succès.');
      },
      error: err => {
        console.error(err);
        this.toastr.error("Échec de l'envoi de l'invitation.");
      },
    });
  }
  onPageChange(event: any) {
    const pageEvent: PageEvent = {
      first: event.first ?? 0,
      rows: event.rows ?? 1,
      page: event.page ?? 0,
      pageCount: event.pageCount ?? 0,
    };

    this.first = pageEvent.first;
    this.rows = pageEvent.rows;
    this.getInvitation();
  }
  changeStatus(...statuses: string[]) {
    console.log('Change status', statuses);
    this.first = 0; // Réinitialiser la pagination à la première page
    this.currentStatuses = statuses; // Mise à jour du statut sélectionné
    this.getPharmacyByStatus(this.currentStatuses); // Appel de l'API avec les nouveaux statuts
  }

  onDeleteClick(purshase_id: any) {
    Swal.fire({
      title: 'Êtes-vous sûr(e) de vouloir supprimer cet commande ?',
      text: 'Cette action est irréversible !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimer !',
    }).then(result => {
      if (result.isConfirmed) {
        this.deletePurshases(purshase_id);
      }
    });
  }
  deletePurshases(purshase_id: any) {
    this.orderService.deletePurchases(purshase_id).subscribe({
      next: res => {
        this.getInvitation();
      },
      error: err => {
        console.log(err);
      },
    });
  }
  navigateToPurchaseDetails(purchase_id: any) {
    this.router.navigate(['/pharmacie/details-purchase', { purchase_id: purchase_id }]);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchInputSubject.next(filterValue);
  }
  openModal() {
    this.modalService.open(this.modalgeo, { size: 'l', centered: true });
  }
  selectedInvitation: any;
  openModalDetails(invitation: any) {
    if (invitation) {
      this.invitationForme.patchValue({
        firstName: invitation.firstName || '',
        lastName: invitation.lastName || '',
        email: invitation.email || '',
        phone: invitation.phone || '',
        pharmacyCommercialName: invitation.pharmacyCommercialName || '',
        pharmacyAddress: invitation.pharmacyAddress || '',
        pharmacyResponsibleEmail: invitation.pharmacyResponsibleEmail || '',
        pharmacyPhoneNumber: invitation.pharmacyPhoneNumber || '',
        pharmacyOrderId: invitation.pharmacyOrderId || '',
        pharmacySiret: invitation.pharmacySiret || '',
      });
    } else {
      this.resetForm();
    }

    this.modalService.open(this.modalDetails, { size: 'l', centered: true });
  }
  closeModal(modalRef: any) {
    modalRef.dismiss();
    this.resetForm();
  }

  resetForm() {
    this.invitationForme.reset({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      pharmacyCommercialName: '',
      pharmacyAddress: '',
      pharmacyResponsibleEmail: '',
      pharmacyPhoneNumber: '',
      pharmacyOrderId: '',
      pharmacySiret: '',
    });
  }
}
