// import { environment } from './../environments/environment.preprod';
import { Component, OnInit, inject } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import locale from '@angular/common/locales/en';
import dayjs from 'dayjs/esm';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationConfigService } from './core/config/application-config.service';
import { fontAwesomeIcons } from './config/font-awesome-icons';
import MainComponent from './layouts/main/main.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule } from '@angular/router';
import { environment } from '../environments/environment';
import { NotificationService } from './notification/notification.service';
import { getMessaging, getToken } from 'firebase/messaging';

@Component({
  selector: 'jhi-app',
  standalone: true,
  template: '<router-outlet></router-outlet>',
  imports: [
    MainComponent,
    RouterModule,
    // jhipster-needle-angular-add-module JHipster will add new module here
  ],
})
export default class AppComponent implements OnInit {
  // private readonly _messaging = inject(Messaging);

  constructor(
    applicationConfigService: ApplicationConfigService,
    iconLibrary: FaIconLibrary,
    dpConfig: NgbDatepickerConfig,
    private notificationService: NotificationService,
  ) {
    // Set endpoint prefix based on environment configuration
    applicationConfigService.setEndpointPrefix(environment.baseUrl);
    registerLocaleData(locale);
    iconLibrary.addIcons(...fontAwesomeIcons);
    dpConfig.minDate = { year: dayjs().subtract(100, 'year').year(), month: 1, day: 1 };
  }

  ngOnInit() {
    this.notificationService.receiveMessage().subscribe(message => {
      if (message) {
        console.log('New message received:', message);
      } else {
        console.log('no message received:', message);
      }
    });
    // this.requestPermission();
    // this._onMessage();
  }
  // requestPermission() {
  //   const messaging = getMessaging();
  //   getToken(messaging, { vapidKey: environment.firebase.vapidKey })
  //     .then(currentToken => {
  //       if (currentToken) {
  //         console.log('Got FCM registration token:', currentToken);
  //         this.notificationService.sendTokenToServer(currentToken).subscribe(
  //           response => {
  //             console.log('Token sent to server successfully:', response);
  //           },
  //           error => {
  //             console.error('Error sending token to server:', error);
  //           },
  //         );
  //       } else {
  //         console.log('No registration token available. Request permission to generate one.');
  //       }
  //     })
  //     .catch(error => {
  //       console.error('An error occurred while retrieving the token:', error);
  //     });
  // }
}
