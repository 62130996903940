import { Component, HostListener, OnInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import SharedModule from '../../../shared/shared.module';
import { FormControl, FormGroup, FormsModule, Validators, ReactiveFormsModule, FormBuilder } from '@angular/forms';

import { ICategory } from '../../../entities/category/category.model';
import { CategoryService } from '../../../entities/category/service/category.service';
import { IPharmacy } from '../pharmacy.model';
import { PharmacyService } from '../service/pharmacy.service';
import { PharmacyFormService, PharmacyFormGroup } from './pharmacy-form.service';
import { ToastrService } from 'ngx-toastr';
import { RegisterService } from 'app/account/register/register.service';
import { LoginService } from 'app/login/login.service';

@Component({
  standalone: true,
  selector: 'jhi-pharmacy-update',
  templateUrl: './pharmacy-update.component.html',
  styleUrl: './pharmacy-update.component.scss',

  imports: [SharedModule, FormsModule, ReactiveFormsModule],
})
export class PharmacyUpdateComponent implements OnInit {
  isSaving = false;
  pharmacy: IPharmacy | null = null;

  categoriesSharedCollection: ICategory[] = [];
  nextValidation: boolean = false;
  ActivatedEmailAdmin: boolean = false;

  editForm: PharmacyFormGroup = this.pharmacyFormService.createPharmacyFormGroup();
  currentUrl: string;
  screenHeight = window.innerHeight;
  screenWidth = window.innerWidth;
  key: any;
  decodedToken: any;
  cloudflareForm: FormGroup;
  uploadedFileId: string | null = null;

  loginForm = new FormGroup({
    username: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    password: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    rememberMe: new FormControl(false, { nonNullable: true, validators: [Validators.required] }),
  });
  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getScreenSize(event?: Event) {
    console.log(event);

    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    protected pharmacyService: PharmacyService,
    protected pharmacyFormService: PharmacyFormService,
    protected categoryService: CategoryService,
    protected activatedRoute: ActivatedRoute,
    private registerService: RegisterService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private fb: FormBuilder,
  ) {
    this.currentUrl = router.url;
    this.cloudflareForm = this.fb.group({
      file: [null],
    });
  }

  compareCategory = (o1: ICategory | null, o2: ICategory | null): boolean => this.categoryService.compareCategory(o1, o2);

  ngOnInit(): void {
    this.route.queryParams.subscribe(key => {
      if (key['key']) {
        this.key = key['key'];
        console.log('key', this.key);
      }
    });
    if (this.key) {
      this.decodeToken();
    }
    this.activatedRoute.data.subscribe(({ pharmacy }) => {
      this.pharmacy = pharmacy;
      if (pharmacy) {
        this.updateForm(pharmacy);
      }

      this.loadRelationshipsOptions();
    });
  }
  decodeToken() {
    const token = this.key;
    if (token) {
      this.decodedToken = this.registerService.decodeToken(token);
      if (this.decodedToken) {
        console.log('Token décodé :', this.decodedToken);
        this.editForm.patchValue({
          commercialName: this.decodedToken.pharmacyCommercialName || '',
          address: this.decodedToken.pharmacyAddress || '',
          responsibleEmail: this.decodedToken.pharmacyResponsibleEmail || '',
          pharmacyOrderId: this.decodedToken.pharmacyOrderId || '',
          siret: this.decodedToken.pharmacySiret || '',
          phoneNumber: this.decodedToken.pharmacyPhoneNumber || '',
        });
      }
    } else {
      console.warn('Aucun token trouvé dans le localStorage');
    }
  }
  nextValidationPage() {
    this.nextValidation = true;
  }
  previousState(): void {
    this.ActivatedEmailAdmin = true;
  }
  login(): void {
    const credentials = this.registerService.getCredentials();
    if (!credentials) {
      this.toastr.error('Erreur : identifiants manquants');
      return;
    }

    const { email, password } = credentials;

    this.loginService.login({ username: email, password, rememberMe: false }).subscribe({
      next: () => {
        this.toastr.success('Connexion réussie');
        this.uploadFile();
        this.save();
      },
      error: () => {
        this.toastr.error('Erreur lors de la connexion');
      },
      complete: () => {
        this.registerService.clearCredentials(); // Nettoyer les identifiants
      },
    });
  }
  save(): void {
    this.isSaving = true;
    const pharmacy = this.pharmacyFormService.getPharmacy(this.editForm);
    if (pharmacy.id !== null) {
      this.subscribeToSaveResponse(this.pharmacyService.update(pharmacy));
    } else {
      this.subscribeToSaveResponse(this.pharmacyService.create(pharmacy, this.uploadedFileId));
    }
  }

  protected subscribeToSaveResponse(result: Observable<HttpResponse<IPharmacy>>): void {
    result.pipe(finalize(() => this.onSaveFinalize())).subscribe({
      next: () => this.onSaveSuccess(),
      error: () => this.onSaveError(),
    });
  }

  protected onSaveSuccess(): void {
    this.toastr.success('Votre compte a été validé avec succès');
    this.previousState();
    if (this.key) {
      this.router.navigate(['/login'], { queryParams: { user: 'stripeConnected' } });
    }
  }

  protected onSaveError(): void {
    this.toastr.error('Erreur de validation du compte');
  }

  protected onSaveFinalize(): void {
    this.isSaving = false;
  }

  protected updateForm(pharmacy: IPharmacy): void {
    this.pharmacy = pharmacy;
    this.pharmacyFormService.resetForm(this.editForm, pharmacy);

    this.categoriesSharedCollection = this.categoryService.addCategoryToCollectionIfMissing<ICategory>(
      this.categoriesSharedCollection,
      ...(pharmacy.categories ?? []),
    );
  }

  protected loadRelationshipsOptions(): void {
    this.categoryService
      .query()
      .pipe(map((res: HttpResponse<ICategory[]>) => res.body ?? []))
      .pipe(
        map((categories: ICategory[]) =>
          this.categoryService.addCategoryToCollectionIfMissing<ICategory>(categories, ...(this.pharmacy?.categories ?? [])),
        ),
      )
      .subscribe((categories: ICategory[]) => (this.categoriesSharedCollection = categories));
  }
  selectedFile: File | null = null;
  onFileSelected(event: Event, fileType: string): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      const file = inputElement.files[0];
      this.selectedFile = file;
      this.editForm.get(fileType)?.setValue(file);
      const spanElement = inputElement.nextElementSibling as HTMLElement;
      if (spanElement) {
        spanElement.textContent = file.name;
      }
    }
  }
  uploadFile(): void {
    if (this.selectedFile) {
      this.pharmacyService.uploadFile(this.selectedFile).subscribe({
        next: response => {
          this.uploadedFileId = response.id;
          console.log('File uploaded successfully. ID:', this.uploadedFileId);
        },
        error: error => {
          console.error('File upload failed:', error);
        },
      });
    } else {
      console.warn('No file selected to upload.');
    }
  }
}
