import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { environment } from '../../environments/environment';
interface MessagesResponse {
  content: Message[];
  totalPages: number;
  totalElements: number;
}
interface Message {
  id: string;
  text: string;
  sender: string;
  createdAt: string;
  avatar: string;
}
@Injectable({
  providedIn: 'root',
})
export class SocketService {
  socket: Socket | null = null;
  readonly SOCKET_URL = environment.SOCKET_URL;
  socketResponseSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  socketResponse$ = this.socketResponseSubject.asObservable();

  constructor(private http: HttpClient) {
    this.initSocket();
  }

  setQuery(query: any): void {
    if (this.socket) {
      this.socket.io.opts.query = query;
    } else {
      console.error('Socket is not initialized.');
    }
  }

  initSocket(): void {
    this.socket?.disconnect();
    this.socket = io(this.SOCKET_URL, {
      transports: ['websocket', 'polling'],
      withCredentials: true,
      reconnection: true,
    });

    this.socket.on('connect', () => {});

    this.socket.on('receive_message', (res: any) => {
      this.socketResponseSubject.next(res);
    });

    this.socket.on('disconnect', (reason: string) => {});

    this.socket.on('connect_error', (error: any) => {
      console.error('Connection error:', error);
    });

    this.socket.on('error', (error: any) => {
      console.error('Socket error:', error);
    });
  }

  joinRoom(room: string): void {
    this.socket?.emit('join', room);
  }

  sendMessage(data: any, room: any): void {
    this.socket?.emit('send_message', data, room);
  }

  disconnect(): void {
    this.socket?.disconnect();
  }
  getMessages(room: string, page: number, size: number): Observable<MessagesResponse> {
    return this.http.get<MessagesResponse>(`${environment.baseUrl}api/messages/room/${room}?page=${page}&size=${size}`);
  }

  saveMessage(messageData: { text: string; room: string }): Observable<any> {
    return this.http.post(`${environment.baseUrl}api/messages`, messageData);
  }
}
