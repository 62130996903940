import { Subject, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { Component, OnInit, TemplateRef, ViewChild, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import SharedModule from '../../shared/shared.module';
import { HomeServiceService } from '../service/home-service.service';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CartService } from '../../cart/cart.service';
import Swal from 'sweetalert2';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Location } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@Component({
  selector: 'jhi-pharmacie-products',
  standalone: true,
  imports: [CommonModule, SharedModule, RouterModule, ReactiveFormsModule, NgxSkeletonLoaderModule, InfiniteScrollModule],
  templateUrl: './pharmacie-products.component.html',
  styleUrl: './pharmacie-products.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PharmacieProductsComponent implements OnInit {
  @ViewChild('modalProduct') modalProduct: any;
  @ViewChild('modalOrdonnance') modalOrdonnance: any;
  @ViewChild('modalProductExist') modalProductExist: any;
  @ViewChild('modalAddressExist') modalAddressExist: any;

  PurchasesForm: FormGroup = this.createPurchasesForm();
  formData = new FormData();
  mutualCard_File: any;
  vitalCard_File: any;
  prescription_File: any;
  openedProduct: any;
  pharmacyId!: number;
  subCategoryId!: number;
  currentPage: number = 1;
  itemsPerPage = 9;
  products: any[] = [];
  totalPages: number = 1;
  isLoading: boolean = false;
  likeImagePath: string = '../../content/images/icons/Icon feather-heart (3).svg';
  pharmacyName: any;
  catName: any;
  subCatName: any;
  value: number = 1;
  isExpanded = false;
  isExpandedDescription = false;
  isLoadingModal = false;

  cartItems: any[] = [];
  contentLoaded = false;
  activeTab: string = 'composition';
  searchInputSubject = new Subject<string>();
  imageUrl: string = 'https://imagedelivery.net/z1UHS7Z96nNnNVE8sIpBxg/';
  pharmacyCartItems: any;
  pharmacyCartItemsName: any;
  ordonnanceImages: Array<string | ArrayBuffer> = [];
  prescriptionFiles: File[] = [];
  vitalCardFiles: File[] = [];
  mutualCardFiles: File[] = [];
  maxFiles = 3;
  allowedFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
  maxFileSize = 5 * 1024 * 1024;
  fileUrls: string[] = [];
  documents: any;
  prescriptionUrls: string[] = [];
  vitalCardUrl: string | null = null;
  mutualCardUrl: string | null = null;
  selectedAddressId: number | null = null;
  consentGiven = false;
  cookiesCart!: any[];
  total: any;
  constructor(
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private pharmacieService: HomeServiceService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private cartService: CartService,
    private location: Location,
    private router: Router,
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.pharmacieService.selectedAddressId$.subscribe(addressId => {
      this.selectedAddressId = addressId;
    });
    setTimeout(() => {
      this.contentLoaded = true;
    }, 2000);
    this.getPharmacyId();
    this.pharmacyName = this.pharmacieService.getCommercialName();
    this.catName = this.pharmacieService.getCatName();
    this.subCatName = this.pharmacieService.getSubCatName();
    this.searchInputSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(searchName => {
          const data = {
            contactName: searchName.trim().toLowerCase(),
          };
          return this.pharmacieService.searchProduct(searchName.trim().toLowerCase(), this.pharmacyId, this.subCategoryId).pipe();
        }),
      )
      .subscribe((res: any) => {
        this.products = res;
      });

    this.pharmacyCartItemsName = this.cartService.getPharmacyName();
  }

  toggleLoading = (): void => {
    this.isLoading = !this.isLoading;
  };

  goBack(): void {
    this.location.back();
  }

  NavigateToPharmacy(): void {
    this.router.navigate(['/pharmacie-category', this.pharmacyId, 'P']);
  }

  setActiveTab(tab: string): void {
    this.activeTab = tab;
  }
  getPharmacyId(): void {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.pharmacyId = +params.get('pharmacieId');
        this.subCategoryId = +params.get('subCategoryId');
        this.getProducts(this.subCategoryId, this.pharmacyId);
      },
    });
  }
  getProducts(subCategoryId: number, pharmacyId: number): void {
    this.pharmacieService.getStockItems(subCategoryId, pharmacyId, this.currentPage, this.itemsPerPage).subscribe(
      data => {
        this.products = [...this.products, ...data];
        this.isLoading = false;
        this.currentPage++;
      },
      error => {
        this.isLoading = false;
      },
    );
  }

  removeFile(fileToRemove: File): void {
    this.prescriptionFiles = this.prescriptionFiles.filter(file => file !== fileToRemove); // Supprimer le fichier
  }
  getAllFilesUrl(): void {
    const purchaseId = this.cartService.getPurchaseId();
    this.pharmacieService.getFileUrl(purchaseId).subscribe(res => {
      this.documents = res;
      this.prescriptionUrls = res.prescriptionFiles || [];
      this.vitalCardUrl = res.vital_card || null;
      this.mutualCardUrl = res.mutual_card || null;
    });
  }
  addPurchases(purchaseId: any): void {
    this.PurchasesForm.markAllAsTouched();
    this.PurchasesForm.markAsDirty();
    const formData = new FormData();

    const formControls = this.PurchasesForm.controls;
    this.prescriptionFiles.forEach(file => {
      formData.append('prescription', file);
    });

    // Ajouter les autres fichiers
    ['vital_card', 'mutual_card'].forEach(fileType => {
      const file = formControls[fileType].value;
      if (file) {
        formData.append(fileType, file);
      }
    });
    this.uploadFiles(formData, purchaseId);
  }

  uploadFiles(formData: FormData, purchaseId: any): void {
    this.pharmacieService.uploadFiles(purchaseId, formData).subscribe({
      next: (res: any) => {
        this.toastr.success('Fichiers téléchargés avec succès.');
        this.getAllFilesUrl();
        this.resetForm();
        this.cartService.addPrescriptionToCart();
      },
      error: (err: any) => {
        this.toastr.error('Erreur lors du téléchargement des fichiers.');
      },
    });
  }

  resetForm(): void {
    this.prescriptionFiles = [];
    this.fileUrls = [];
    this.vitalCardFiles = [];
    this.mutualCardFiles = [];
    this.PurchasesForm.reset();
  }
  onFileSelectedPrescription(event: any): void {
    const files: FileList = event.target.files;

    // Check if number of files exceeds the limit
    if (this.prescriptionFiles.length + files.length > this.maxFiles) {
      this.toastr.info(`Vous pouvez télécharger un maximum de ${this.maxFiles} fichiers.`, 'Limite de fichiers dépassée');
      return;
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Validate file type
      if (!this.allowedFileTypes.includes(file.type)) {
        this.toastr.info('Seuls les fichiers images (JPG, PNG) ou PDF sont autorisés.`, Type de fichier invalide');
        continue;
      }

      // Validate file size
      if (file.size > this.maxFileSize) {
        this.toastr.info(`La taille du fichier ne doit pas dépasser ${this.maxFileSize / (1024 * 1024)} Mo.`, 'Taille de fichier dépassée');
        continue;
      }

      // Add file to list if valid
      this.prescriptionFiles.push(file);

      // Use FileReader to generate preview URL
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.fileUrls.push(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }
  removeFilePrescription(file: File): void {
    const index = this.prescriptionFiles.indexOf(file);
    if (index > -1) {
      this.prescriptionFiles.splice(index, 1);
      this.fileUrls.splice(index, 1);
    }
  }
  getFileUrl(file: File): string {
    return URL.createObjectURL(file);
  }

  async addOrder(modal: any): Promise<void> {
    this.isLoadingModal = true;
    const purchaseStockItems: any = [];
    if (Array.isArray(this.cookiesCart)) {
      for (const item of this.cookiesCart) {
        purchaseStockItems.push({
          stockItem: { id: item.id },
          requestedQuantity: item.quantity,
        });
      }
    }
    const cartData = {
      pharmacyId: this.pharmacyId,
      deliveryAddressId: this.selectedAddressId,
      purchaseStockItems,
    };

    try {
      const response = await this.pharmacieService.addToCart(cartData).toPromise();
      this.addPurchases(response.id);
      this.cartService.setPurchaseId(response.id);
      this.cartService.setNumCommande(response.num);
      modal.dismiss();
    } catch (error) {
      console.error('Error adding product to cart:', error);
      if (error.error?.message === 'error.invaliddeliveryfee') {
        this.openModalAddress();
      } else {
        this.toastr.error('Une erreur est survenue. Veuillez réessayer.', 'Erreur');
      }
    } finally {
      this.isLoadingModal = false;
    }
  }
  openModalAddress(): void {
    this.modalService.open(this.modalAddressExist, { size: 'l', centered: true });
  }
  notifyChangeAddress(): void {
    this.pharmacieService.notifyChangeAddress();
  }
  onCheckboxChange(event: any): void {
    this.consentGiven = event.target.checked;
  }
  appendData = (): void => {
    this.toggleLoading();
    this.pharmacieService.getStockItems(this.subCategoryId, this.pharmacyId, this.currentPage, this.itemsPerPage).subscribe({
      next: data => {
        if (Array.isArray(data)) {
          data.forEach((element: any) => {
            if (typeof element === 'object' && element !== null) {
              this.products = [...this.products, element];
            } else {
              console.error('Invalid element:', element);
            }
          });
        } else {
          console.error('Data is not an array:', data);
        }
      },
      complete: () => this.toggleLoading(),
    });
  };
  onScroll = (): void => {
    this.currentPage++;
    this.appendData();
  };
  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchInputSubject.next(filterValue);
  }
  searchProducts(subCategoryId: number, pharmacyId: number): void {
    this.pharmacieService.getStockItems(subCategoryId, pharmacyId, this.currentPage, 9).subscribe(
      data => {
        this.products = [...this.products, ...data];
        this.isLoading = false;
        this.currentPage++;
      },
      error => {
        this.isLoading = false;
      },
    );
  }

  openproductModal(modal: any, product: any): void {
    this.modalService.open(modal, { size: 'lg', centered: true });
    this.openedProduct = product;
    this.value = this.cartService.getCurrent(product);
  }
  openCommandModal(modal: any): void {
    this.modalService.open(modal, { size: 'lg', centered: true });
  }
  openModalProductExist(modal: any): void {
    this.modalService.open(modal, { size: 's', centered: true });
  }
  createPurchasesForm(data?: any): FormGroup {
    return this.formBuilder.group({
      prescription: [data ? data.prescription : null],
      mutual_card: [data ? data.mutual_card : null],
      vital_card: [data ? data.vital_card : null],
    });
  }
  onFileSelected(event: Event, fileType: string): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      const file = inputElement.files[0];
      this.PurchasesForm.get(fileType)?.setValue(file);
      const files = Array.from(inputElement.files);

      if (fileType === 'prescription') {
        this.prescriptionFiles = [...this.prescriptionFiles, ...files];
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.ordonnanceImages.push(e.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        const spanElement = inputElement.nextElementSibling;
        if (spanElement instanceof HTMLElement) {
          spanElement.textContent = file.name;
        }
      }

      files.forEach(uploadedFile => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          if (fileType === 'prescription') {
            this.ordonnanceImages.push(e.target.result);
          }
        };
        reader.readAsDataURL(uploadedFile);
      });
    }
  }

  increment(): void {
    this.value++;
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  decrement(): void {
    if (this.value > 1) {
      this.value--;
    }
  }
  toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
  }
  toggleExpandDescription(): void {
    this.isExpandedDescription = !this.isExpandedDescription;
  }
  toggleLike(product: any, index: number): void {
    if (product.liked) {
      this.unlikeProduct(product.id, index);
    } else {
      this.likeProduct(product.id, index);
    }
  }
  likeProduct(product_id: any, index: number): void {
    const data = {
      favorable_type: 'StockItem',
      favorable_id: product_id,
    };
    this.pharmacieService.likeProduct(data).subscribe(
      response => {
        this.products[index].liked = true;
        this.toastr.success('Produit ajoutée aux favoris avec succès !', 'Success');
      },
      error => {
        this.toastr.error("Échec de l'ajout du produit aux favoris. Veuillez réessayer.", 'Error');
      },
    );
  }
  unlikeProduct(product_id: any, index: number): void {
    const data = {
      favorable_type: 'StockItem',
      favorable_id: product_id,
    };
    this.pharmacieService.unlikeProduct(data).subscribe(
      response => {
        this.products[index].liked = false;
        this.toastr.info('Produit retiré des favoris avec succès.', 'Info');
      },
      error => {
        this.toastr.error('Échec du retrait du produit des favoris. Veuillez réessayer.', 'Error');
      },
    );
  }

  openModal(modalTemplate: TemplateRef<any>, product: any): void {
    this.modalService.open(modalTemplate, { size: 's', centered: true }).result.then(result => {
      if (result === 'clearCart') {
        this.cartService.clearCartItems();
        if (this.openedProduct) {
          this.addToCartWithASpecificNumber();
        } else {
          this.addToCart(product, this.modalProductExist);
        }
      }
    });
  }
  addToCart(product: any, modalTemplate: TemplateRef<any>): void {
    if (!this.cartService.checkSamePharmacy(product)) {
      this.openModal(modalTemplate, product);
    } else {
      this.cartService.addToCart(product);
      this.toastr.success('Produit ajouté au panier.');
    }
  }

  addToCartWithASpecificNumber(): void {
    if (!this.cartService.checkSamePharmacy(this.openedProduct)) {
      this.openModal(this.modalProductExist, this.openedProduct);
    } else {
      this.cartService.addToCartWithASpecificNumber(this.openedProduct, this.value);
      this.toastr.success('Produit ajouté au panier.');
    }
  }

  storeCartItem(cartItem: any): void {
    const cartItems = this.cartService.getCartItems();
    cartItems.push(cartItem);
    this.cartService.setCookie('cartItems', JSON.stringify(cartItems), 7);
  }
}
