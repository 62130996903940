<div class="conditions-utilisation">
  <div class="conditions-generales">
    <h1 class="title">Pharmacovigilance</h1>
  </div>
  <div class="content">
    <div class="section">
      <h2 class="section-title">1- Pharmacovigilance : surveiller et évaluer l'impact des effets indésirables des produits de santé</h2>
      <p class="section-text">
        Les médicaments ne sont pas des produits commes les autres. C'est pourquoi, le rôle du pharmacien de votre pharmacie en ligne est
        essentiel. Il conseille et informe les patients, s'assure que les médicaments commandés ne présentent aucune contre-indications ou
        interactions qui pourraient nuire à leur santé. Parallèlement, il existe aujourd'hui un processus qui permet à tout un chacun de
        déclarer un effet indésirable susceptible d'avoir été provoqué par un médicament. C'est ce qu'on appelle la pharmacovigilance. Il
        s'agit en effet d'un système de
        <a href="https://ansm.sante.fr/documents/reference/bonnes-pratiques-de-pharmacovigilance"
          >"surveillance des médicaments et de prévention du risque d'effet indésirable résultant de leur utilisation"</a
        >. Grâce à la pharmacovigilance, l'Agence Nationale de Sécurité du Médicament (ANSM), recueille, enregistre et évalue les effets
        indésirables déclarés pour un médicament. Elle est donc aujourd'hui à même de prendre des mesures correctives s'il s'avère que c'est
        nécessaire. Ainsi, de nouvelles précautions ou resctrictions d'emploi sont par exemple, ajoutées à la notice. Il arrive également
        que, grâce aux déclarations spontanées fournis par les patients, un médicament soit retiré du marché si l'on constate lors de
        nouvelles études, un risque trop élevé pour la santé.
      </p>
    </div>
    <div class="section">
      <h2 class="section-title">2- Lutter contre la iatrogenèse grâce à la pharmacovigilance</h2>
      <p class="section-text">
        Lorsqu'un patient suit un traitement médical, il peut arriver qu'un médicament provoque des effets indésirables non souhaités, c'est
        ce qu'on appelle la iatrogénèse. Il s'agit plus précisément de "l'ensemble des conséquences indésirables ou négatives sur létat de
        santé individuel ou collectif de tout acte ou mesure pratiqués ou precrits par un professionnel habilité et qui vise à préserver,
        améliorer ou rétablir la santé"1. Ces effets secondaires peuvent provenir soit du médicament lui-même, soit de son association avec
        un autre médicament, ou de l'incompatibilité avec une pathologie portée par le patient. Prévenir et diminuer les risques
        d'iatrogénèse est, depuis 1996, une priorité du Ministère de la Santé. La pharmacovigilance est une des méthodes qui permet de
        réduire l'impact et la fréquence de la iatrogénie médicamenteuse. Grâce aux informations collectées, le Ministère de la santé peut :
        Repérer les situations à risques iatrogènes Renforcer les stratégies publiques d'information Former les professionnels de santé
        Développer l'utilisation de référentiels
      </p>
    </div>
    <div class="section">
      <h2 class="section-title">3- Pharmacovigilance : comprendre et agir</h2>
      <p class="section-text">
        Pour mieux comprendre l'intérêt et l'importance du système de pharmacovigilance mis à disposition des français, l'organisation Leem
        (Les Entreprises du Médicament) a créé un webdocumentaire qui permet de découvrir et de suivre le fonctionnement du processus de
        pharmacovigilance : "de la découverte d'un effet indésirable pour le patient, ici une éruption cutanée, jusqu'à la décision finale
        pour y remédier".
      </p>
    </div>
  </div>
</div>
<div class="footer" style="overflow: hidden">
  <div class="row d-flex align-items-center p-3">
    <div class="footer-bottom">
      <span
        >© 2024 <span class="pharma">PharmaGo</span> .
        <span jhiTranslate="global.menu.footer.rights">Tous les droits réservés Développé par</span>
        <span class="underline">KCA Dev Services.</span>
      </span>
    </div>
  </div>
</div>
