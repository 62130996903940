import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HomeServiceService } from '../service/home-service.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CartService } from 'app/cart/cart.service';

@Component({
  selector: 'jhi-pharmacie-sub-category',
  standalone: true,
  imports: [CommonModule, RouterModule, NgxSkeletonLoaderModule, ReactiveFormsModule],
  templateUrl: './pharmacie-sub-category.component.html',
  styleUrl: './pharmacie-sub-category.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PharmacieSubCategoryComponent implements OnInit {
  pharmacyId!: number;
  categoryId!: number;
  // sousCategories: any;
  pharmacyName: any;
  catName: any;
  contentLoaded = false;
  sousCategories: any[] = [];
  ordonnanceImages: Array<string | ArrayBuffer> = [];
  prescriptionFiles: File[] = [];
  vitalCardFiles: File[] = [];
  mutualCardFiles: File[] = [];
  selectedAddressId: number | null = null;

  PurchasesForm: FormGroup;
  maxFiles = 3;
  allowedFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
  maxFileSize = 5 * 1024 * 1024;
  fileUrls: string[] = [];
  documents: any;
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private pharmacieService: HomeServiceService,
    private location: Location,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private cartService: CartService,
  ) {
    this.PurchasesForm = this.createPurchasesForm();
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    const selectedAddressId = localStorage.getItem('selectedAddressId');
    if (selectedAddressId) {
      this.selectedAddressId = parseInt(selectedAddressId, 10);
    }
    setTimeout(() => {
      this.contentLoaded = true;
    }, 1000);
    this.getPharmacyId();
    this.pharmacyName = this.pharmacieService.getCommercialName();
    this.catName = this.pharmacieService.getCatName();
    this.getSubCategories();
  }

  goBack() {
    this.location.back();
  }
  getPharmacyId() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.pharmacyId = +params.get('pharmacieId');
        this.categoryId = +params.get('categoryId');
        console.log('pharmacieId', this.pharmacyId);
      },
    });
  }
  createPurchasesForm(data?: any): FormGroup {
    return this.formBuilder.group({
      firstName: [data ? data.firstName : ''],
      lastName: [data ? data.lastName : ''],
      prescription: [data ? data.prescription : null],
      mutual_card: [data ? data.mutual_card : null],
      vital_card: [data ? data.vital_card : null],
    });
  }
  consentGiven = false;
  onCheckboxChange(event: any): void {
    this.consentGiven = event.target.checked; // Mettez à jour l'état de la case à cocher
  }
  removeFile(fileToRemove: File): void {
    this.prescriptionFiles = this.prescriptionFiles.filter(file => file !== fileToRemove); // Supprimer le fichier
  }
  addPurchases(purchaseId: any): void {
    this.PurchasesForm.markAllAsTouched();
    this.PurchasesForm.markAsDirty();
    const formData = new FormData();

    const formControls = this.PurchasesForm.controls;
    this.prescriptionFiles.forEach(file => {
      formData.append('prescription', file);
    });

    // Ajouter les autres fichiers
    ['vital_card', 'mutual_card'].forEach(fileType => {
      const file = formControls[fileType]?.value;
      if (file) {
        formData.append(fileType, file);
      }
    });
    this.uploadFiles(formData, purchaseId);
  }

  uploadFiles(formData: FormData, purchaseId: any): void {
    this.pharmacieService.uploadFiles(purchaseId, formData).subscribe({
      next: (res: any) => {
        console.log('Response for file upload:', res);
        this.toastr.success('Files uploaded successfully');
        this.getAllFilesUrl();
        this.resetForm();
        this.cartService.addPrescriptionToCart();
      },
      error: (err: any) => {
        console.log('Error uploading files', err);
        this.toastr.error('Error uploading files');
      },
    });
  }

  resetForm() {
    this.prescriptionFiles = [];
    this.fileUrls = [];
    this.vitalCardFiles = [];
    this.mutualCardFiles = [];
    this.PurchasesForm?.reset();
  }

  prescriptionUrls: string[] = [];
  vitalCardUrl: string | null = null;
  mutualCardUrl: string | null = null;
  getAllFilesUrl(): void {
    const purchaseId = this.cartService.getPurchaseId();
    this.pharmacieService.getFileUrl(purchaseId).subscribe(res => {
      this.documents = res;
      this.prescriptionUrls = res.prescriptionFiles || [];
      this.vitalCardUrl = res.vitalCard || null;
      this.mutualCardUrl = res.mutualCard || null;
      console.log(`Files URL: ${res}`);
    });
  }
  getFileUrl(file: File): string {
    return URL.createObjectURL(file);
  }
  cookiesCart!: any[];
  total: any;
  addOrder() {
    let purchaseStockItems: any = [];
    this.cookiesCart?.forEach(async item => {
      purchaseStockItems.push({
        stockItem: { id: item.id },
        requestedQuantity: item.quantity,
      });
    });
    const cartData = {
      pharmacyId: this.pharmacyId,
      deliveryAddressId: this.selectedAddressId,
      purchaseStockItems: purchaseStockItems,
    };
    this.pharmacieService.addToCart(cartData).subscribe(
      response => {
        this.addPurchases(response.id);
        this.cartService.setPurchaseId(response.id);
        this.cartService.setNumCommande(response.num);
      },
      error => {
        console.error('Error adding product to cart:', error);
      },
    );
  }

  onFileSelectedPrescription(event: any): void {
    const files: FileList = event.target.files;

    // Check if number of files exceeds the limit
    if (this.prescriptionFiles.length + files.length > this.maxFiles) {
      this.toastr.info(`You can only upload a maximum of ${this.maxFiles} files.`, 'File Limit Exceeded');
      return;
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Validate file type
      if (!this.allowedFileTypes.includes(file.type)) {
        this.toastr.info('Only images (JPG, PNG) or PDFs are allowed.', 'Invalid File Type');
        continue;
      }

      // Validate file size
      if (file.size > this.maxFileSize) {
        this.toastr.info(`File size should not exceed ${this.maxFileSize / (1024 * 1024)} MB.`, 'File Size Exceeded');
        continue;
      }

      // Add file to list if valid
      this.prescriptionFiles.push(file);

      // Use FileReader to generate preview URL
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.fileUrls.push(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }
  removeFilePrescription(file: File): void {
    const index = this.prescriptionFiles.indexOf(file);
    if (index > -1) {
      this.prescriptionFiles.splice(index, 1);
      this.fileUrls.splice(index, 1);
    }
  }
  getSubCategories(): void {
    const selectedCategory = this.pharmacieService.getSelectedCategory();
    if (selectedCategory) {
      this.sousCategories = selectedCategory.subCategories
        .filter((subCat: any) => subCat.stockItemsAccount > 0)
        .sort((a: any, b: any) => a.name.localeCompare(b.name));
    }
  }

  NavigateToProducts(subCategoryId: number, subCatName: any) {
    this.router.navigate(['/pharmacie-products', { pharmacieId: this.pharmacyId, subCategoryId: subCategoryId }]);
    this.setSubCatName(subCatName);
    this.setSubCatId(subCategoryId);
  }
  setSubCatName(subCatName: any) {
    this.pharmacieService.setSubCatName(subCatName);
  }
  setSubCatId(subCatId: any) {
    this.pharmacieService.setSubCatId(subCatId);
  }
  openCommandModal(modal: any) {
    this.modalService.open(modal, { size: 'lg', centered: true });
  }
  onFileSelected(event: Event, fileType: string): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      const file = inputElement.files[0];
      this.PurchasesForm.get(fileType)?.setValue(file);
      const files = Array.from(inputElement.files);

      if (fileType === 'prescription') {
        this.prescriptionFiles = [...this.prescriptionFiles, ...files];
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.ordonnanceImages.push(e.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        const spanElement = inputElement.nextElementSibling as HTMLElement;
        if (spanElement) {
          spanElement.textContent = file.name;
        }
      }
      files.forEach(file => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          if (fileType === 'prescription') {
            this.ordonnanceImages.push(e.target.result);
          }
        };
        reader.readAsDataURL(file);
      });
    }
  }
}
