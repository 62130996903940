/* eslint-disable no-console */
import { Component, ViewChild, ElementRef, HostListener } from '@angular/core';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

import SharedModule from '../../../shared/shared.module';
import { LoginService } from '../../../login/login.service';
import { AccountService } from '../../../core/auth/account.service';
import { StateStorageService } from '../../../core/auth/state-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { LANGUAGES } from '../../../config/language.constants';
import ActiveMenuDirective from '../../../layouts/navbar/active-menu.directive';
import { ToastrService } from 'ngx-toastr';
import { Subject, filter, takeUntil } from 'rxjs';
import { Account } from '../../../core/auth/account.model';
import { PharmacyUpdateComponent } from '../../../entities/pharmacy/update/pharmacy-update.component';
@Component({
  selector: 'jhi-admin-pharmacy-login',
  standalone: true,
  imports: [SharedModule, FormsModule, ReactiveFormsModule, RouterModule, ActiveMenuDirective, PharmacyUpdateComponent],
  templateUrl: './admin-pharmacy-login.component.html',
  styleUrl: './admin-pharmacy-login.component.scss',
})
export class AdminPharmacyLoginComponent {
  languages = LANGUAGES;
  @ViewChild('username', { static: false })
  url: any;
  username!: ElementRef;

  authenticationError = false;

  loginForm = new FormGroup({
    username: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    password: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    rememberMe: new FormControl(false, { nonNullable: true, validators: [Validators.required] }),
  });
  screenHeight = window.innerHeight;
  screenWidth = window.innerWidth;
  delfaultLang: any;
  isNavbarCollapsed = true;
  params: any;
  private readonly destroy$ = new Subject<void>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  account: Account | null = null;
  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getScreenSize(event?: Event) {
    console.log(event);

    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    private accountService: AccountService,
    private router: Router,
    private stateStorageService: StateStorageService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private _toaster: ToastrService,
  ) {
    this.delfaultLang = this.stateStorageService.getLocale()
      ? this.stateStorageService.getLocale()
      : this.translateService.getDefaultLang();
    this.route.queryParams.subscribe(params => {
      if (params['user']) {
        this.params = params['user'];
      }
    });
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    // if already authenticated then navigate to home page
    this.accountService.identity().subscribe(() => {
      if (this.accountService.isAuthenticated()) {
        this.accountService
          .getAuthenticationState()
          .pipe(takeUntil(this.destroy$))
          .subscribe(account => (this.account = account));
        console.log(this.account);
        if (
          (!this.account?.applicationUser || !this.account.applicationUser.proProfileCompleted) &&
          this.account?.authorities.includes('ROLE_PHARMACIST')
        )
          // eslint-disable-next-line curly
          this.router.navigate(['/login'], { queryParams: { user: 'completeCreation' } });
        // eslint-disable-next-line curly
        else this.router.navigate(['/home']);
      }
    });
  }

  ngAfterViewInit(): void {
    this.username.nativeElement.focus();
  }
  changeLanguage(languageKey: string): void {
    this.stateStorageService.storeLocale(languageKey);
    this.translateService.use(languageKey);
    this.delfaultLang = languageKey;
  }

  collapseNavbar(): void {
    this.isNavbarCollapsed = true;
  }

  login(): void {
    this.loginService.login(this.loginForm.getRawValue()).subscribe({
      next: () => {
        this.authenticationError = false;
        if (!this.router.getCurrentNavigation()) {
          this.accountService
            .getAuthenticationState()
            .pipe(takeUntil(this.destroy$))
            .subscribe(account => (this.account = account));
          if (
            (!this.account?.applicationUser || !this.account.applicationUser.proProfileCompleted) &&
            this.account?.authorities[0] === 'ROLE_PHARMACIST'
          ) {
            this.router.navigate(['/admin-pharmacie/login'], { queryParams: { user: 'completeCreation' } });
          } else if (
            (this.account?.applicationUser || this.account?.applicationUser.proProfileCompleted) &&
            this.account?.authorities[0] === 'ROLE_PHARMACIST'
          ) {
            console.log(this.account, 'oktestdqs');
            this.router.navigate(['/admin-pharmacie/dashboard']);
          } else this.router.navigate(['/admin-pharmacie/dashboard']);
        }
      },
      error: () => {
        this.authenticationError = true;
        this._toaster.error(this.translateService.instant('login.messages.error.authentication'));
      },
    });
  }
  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
