import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { OrdersService } from '../../../orders/orders.service';
import { PaginatorModule } from 'primeng/paginator';
import { debounceTime, distinctUntilChanged, Subject, switchMap } from 'rxjs';
import { HomeServiceService } from '../../../home/service/home-service.service';
import { ToastrService } from 'ngx-toastr';
import { InvitationService } from '../../../adminPharmacyBackOffice/services/invitation.service';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import SharedModule from '../../../shared/shared.module';

interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}
@Component({
  selector: 'jhi-list-pharmacy',
  standalone: true,
  imports: [SharedModule, NgbModule, CommonModule, NgxSkeletonLoaderModule, PaginatorModule, ReactiveFormsModule],
  templateUrl: './list-pharmacy.component.html',
  styleUrl: './list-pharmacy.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ListPharmacyComponent implements OnInit {
  @ViewChild('modalgeo') modalgeo: any;
  @ViewChild('modalDetails') modalDetails: any;
  @ViewChild('modalDetailsActif') modalDetailsActif: any;
  @Output() placeSelected = new EventEmitter<google.maps.places.PlaceResult>();

  purshases: any;
  contentLoaded = false;
  pharmaDetails: any;
  first: number = 0;
  rows: number = 1;
  totalRecords: number = 0;
  searchInputSubject = new Subject<string>();
  paymentInstancePurchasesCount: number = 0;
  readyPurchasesCount: number = 0;
  packagingPurchasesCount: number = 0;
  inProgressPurchasesCount: number = 0;
  createdWithPrescriptionPurchasesCount: number = 0;
  currentStatuses: string[];
  invitations: any;
  invitationForme: FormGroup;
  pharmacy: any;
  selectedInvitation: any;
  pharmaName: any;
  pharmacyAddress: any;
  selectedPlace!: google.maps.places.PlaceResult;
  countryNameToCode: { [key: string]: string } = {
    France: 'FR',
  };
  constructor(
    private router: Router,
    private orderService: OrdersService,
    private invitationService: InvitationService,
    private modalService: NgbModal,
    private pharmacieService: HomeServiceService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private ngZone: NgZone,
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.contentLoaded = true;
    }, 2000);
    this.getInvitation();
    // this.getPharmacyByStatus();
    this.initForm();
    this.searchInputSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(searchName => {
          const data = {
            contactName: searchName.trim().toLowerCase(),
          };
          return this.pharmacieService.searchPurchsesPharmacist(searchName.trim().toLowerCase()).pipe();
        }),
      )
      .subscribe((res: any) => {
        this.purshases = res.content;
      });
  }
  initForm(): void {
    this.invitationForme = this.fb.group({
      firstName: ['', [Validators.required, Validators.minLength(2)]],
      lastName: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.pattern(/^\+?\d{10,15}$/)]],
      pharmacyCommercialName: ['', [Validators.required]],
      pharmacyAddress: ['', [Validators.required]],
      pharmacyResponsibleEmail: ['', [Validators.required]],
      pharmacyPhoneNumber: ['', [Validators.required]],
      pharmacyOrderId: ['', [Validators.required]],
      pharmacySiret: ['', [Validators.required]],
    });
  }

  createInvitation(): void {
    if (this.invitationForme.valid) {
      this.invitationService.createdInvitation(this.invitationForme.value).subscribe({
        next: res => {
          this.getInvitation();
          this.toastr.success('Invitation créée avec succès.');
          this.invitationForme.reset(); // Réinitialiser le formulaire après succès
        },
        error: err => {
          console.error(err);
          this.toastr.error("Échec de la création de l'invitation.");
        },
      });
    } else {
      this.toastr.warning('Veuillez remplir correctement le formulaire.');
    }
  }
  getPharmacyByStatus(statuses: string[] = ['activated']): void {
    this.invitationService.getPharmacyByStatus(this.first, statuses).subscribe({
      next: res => {
        this.pharmacy = res.content;
        this.totalRecords = res.totalPages;
      },
    });
  }
  getInvitation(): void {
    this.invitationService.getInvitations(this.first).subscribe({
      next: res => {
        this.invitations = res.content;
        this.totalRecords = res.totalPages;
      },
      error(err) {
        console.error(err);
      },
    });
  }
  verified(pharmacy_id: any): void {
    this.invitationService.verifiedPharmacy(pharmacy_id).subscribe({
      next: (res: any) => {
        this.toastr.success('La confirmation de la pharmacie a été effectuée avec succès.');
      },
      error: (err: any) => {
        console.error('Erreur lors de la confirmation de la pharmacie :', err);
        const errorMessage = err?.error?.message || 'Une erreur est survenue lors de la confirmation de la pharmacie.';
        this.toastr.error(errorMessage);
      },
    });
  }

  sendInvitation(invitationId: any): void {
    this.invitationService.sendInvitation(invitationId).subscribe({
      next: res => {
        this.getInvitation();
        this.toastr.success('Invitation envoyée avec succès.');
      },
      error: err => {
        console.error(err);
        this.toastr.error("Échec de l'envoi de l'invitation.");
      },
    });
  }
  onPageChangeInvitation(event: any): void {
    const pageEvent: PageEvent = {
      first: event.first ?? 0,
      rows: event.rows ?? 1,
      page: event.page ?? 0,
      pageCount: event.pageCount ?? 0,
    };

    this.first = pageEvent.first;
    this.rows = pageEvent.rows;
    this.getInvitation();
  }
  onPageChange(event: any): void {
    const pageEvent: PageEvent = {
      first: event.first ?? 0,
      rows: event.rows ?? 1,
      page: event.page ?? 0,
      pageCount: event.pageCount ?? 0,
    };

    this.first = pageEvent.first;
    this.rows = pageEvent.rows;
    this.getPharmacyByStatus(this.currentStatuses);
  }
  changeStatus(...statuses: string[]): void {
    this.first = 0;
    this.currentStatuses = statuses;
    this.getPharmacyByStatus(this.currentStatuses);
  }

  onDeleteClick(purshase_id: any): void {
    Swal.fire({
      title: 'Êtes-vous sûr(e) de vouloir supprimer cet commande ?',
      text: 'Cette action est irréversible !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimer !',
    }).then(result => {
      if (result.isConfirmed) {
        this.deletePurshases(purshase_id);
      }
    });
  }
  deletePurshases(purshase_id: any): void {
    this.orderService.deletePurchases(purshase_id).subscribe({
      next: res => {
        this.getInvitation();
      },
    });
  }
  navigateToPurchaseDetails(purchase_id: any): void {
    this.router.navigate(['/pharmacie/details-purchase', { purchase_id }]);
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchInputSubject.next(filterValue);
  }
  openModal(): void {
    this.modalService.open(this.modalgeo, { size: 'l', centered: true });
  }

  openModalDetails(invitation: any): void {
    if (invitation) {
      this.invitationForme.patchValue({
        firstName: invitation.firstName || '',
        lastName: invitation.lastName || '',
        email: invitation.email || '',
        phone: invitation.phone || '',
        pharmacyCommercialName: invitation.pharmacyCommercialName || '',
        pharmacyAddress: invitation.pharmacyAddress || '',
        pharmacyResponsibleEmail: invitation.pharmacyResponsibleEmail || '',
        pharmacyPhoneNumber: invitation.pharmacyPhoneNumber || '',
        pharmacyOrderId: invitation.pharmacyOrderId || '',
        pharmacySiret: invitation.pharmacySiret || '',
      });
    } else {
      this.resetForm();
    }
    this.pharmaName = invitation.pharmacyCommercialName;
    this.modalService.open(this.modalDetails, { size: 'l', centered: true });
  }
  openModalDetailsActif(pharma: any): void {
    this.modalService.open(this.modalDetailsActif, { size: 'l', centered: true });
    this.pharmaDetails = pharma;
  }
  closeModal(modalRef: any): void {
    modalRef.dismiss();
    this.resetForm();
  }

  resetForm(): void {
    this.invitationForme.reset({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      pharmacyCommercialName: '',
      pharmacyAddress: '',
      pharmacyResponsibleEmail: '',
      pharmacyPhoneNumber: '',
      pharmacyOrderId: '',
      pharmacySiret: '',
    });
  }
  pharmacyVisibility(pharmacyId: any, isVisible: boolean): void {
    const toggleValue = isVisible ? false : true;
    this.invitationService.visibility(pharmacyId, toggleValue).subscribe({
      next: response => {
        const message = toggleValue ? 'Pharmacie activée avec succès' : 'Pharmacie désactivée avec succès';
        this.toastr.success(message, 'Succès');
        this.changeStatus('activated');
      },
      error: error => {
        this.toastr.error('Erreur lors de la modification de la visibilité', 'Erreur');
      },
    });
  }

  onPlaceSelected(place: google.maps.places.PlaceResult): void {
    this.selectedPlace = place;
    if (place.address_components && place.address_components.length > 0) {
      this.ngZone.run(() => {
        const formattedAddress = place.formatted_address ?? '';

        const streetNumber = place.address_components?.find(c => c.types.includes('street_number'))?.long_name ?? '';
        const streetName = place.address_components?.find(c => c.types.includes('route'))?.long_name ?? '';
        const city = place.address_components?.find(c => c.types.includes('locality'))?.long_name ?? '';
        const postalCode = place.address_components?.find(c => c.types.includes('postal_code'))?.long_name ?? '';
        const countryName = place.address_components?.find(c => c.types.includes('country'))?.long_name ?? '';
        const countryCode = place.address_components?.find(c => c.types.includes('country'))?.short_name ?? '';

        const convertedCountryCode = this.countryNameToCode[countryName] || countryCode;
        if (streetNumber && streetName && city && postalCode && convertedCountryCode) {
          const completeAddress = `${streetNumber} ${streetName}, ${city}, ${postalCode}, ${convertedCountryCode}`;
          this.invitationForme.get('pharmacyAddress')?.setValue(completeAddress);
        } else {
          this.toastr.error('Veuillez sélectionner une adresse complète.');
        }
      });
    } else {
      this.toastr.error('Veuillez sélectionner une adresse complète.');
    }
  }
}
