<div class="terms-of-use-general">
  <div class="conditions-generales">
    <h1 class="title" jhiTranslate="termsOfUseGeneral.title"></h1>
  </div>
  <div class="content">
    <!-- Introduction Section -->
    <div class="section">
      <p class="section-text" jhiTranslate="termsOfUseGeneral.introduction.content"></p>
    </div>

    <!-- Article 1: Définitions -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="termsOfUseGeneral.definitions.title"></h2>
      <p jhiTranslate="termsOfUseGeneral.definitions.content"></p>
      <ul>
        <li *ngFor="let key of termsOfUseGeneral?.definitions?.items">
          {{ key }}
        </li>
      </ul>
    </div>

    <!-- Article 2: Objet -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="termsOfUseGeneral.object.title"></h2>
      <p jhiTranslate="termsOfUseGeneral.object.content"></p>
    </div>

    <!-- Article 3: Éditeur -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="termsOfUseGeneral.editor.title"></h2>
      <p jhiTranslate="termsOfUseGeneral.editor.content"></p>
    </div>

    <!-- Article 4: Droits concédés -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="termsOfUseGeneral.rights.title"></h2>

      <!-- 4.1. Droits concédés sur l’Application -->
      <h2 class="section-title sousTitre" jhiTranslate="termsOfUseGeneral.rights.applicationRights.title"></h2>
      <p jhiTranslate="termsOfUseGeneral.rights.applicationRights.content"></p>

      <!-- 4.2. Droits concédés sur le Service -->
      <h2 class="section-title sousTitre" jhiTranslate="termsOfUseGeneral.rights.serviceRights.title"></h2>
      <p jhiTranslate="termsOfUseGeneral.rights.serviceRights.content"></p>

      <!-- 4.3. Stipulations communes à l’Application et au Service -->
      <h2 class="section-title sousTitre" jhiTranslate="termsOfUseGeneral.rights.commonStipulations.title"></h2>
      <p jhiTranslate="termsOfUseGeneral.rights.commonStipulations.content"></p>
    </div>

    <!-- Article 5: Configuration requise – Sécurité -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="termsOfUseGeneral.configuration.title"></h2>
      <p jhiTranslate="termsOfUseGeneral.configuration.content"></p>
    </div>

    <!-- Article 6: Service -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="termsOfUseGeneral.service.title"></h2>

      <!-- 6.1. Compte -->
      <h2 class="section-title sousTitre" jhiTranslate="termsOfUseGeneral.service.account.title"></h2>
      <p jhiTranslate="termsOfUseGeneral.service.account.content"></p>

      <!-- 6.2. Renonciation expresse au droit de rétractation -->
      <h2 class="section-title sousTitre" jhiTranslate="termsOfUseGeneral.service.waiver.title"></h2>
      <p jhiTranslate="termsOfUseGeneral.service.waiver.content"></p>

      <!-- 6.3. Description des Services -->
      <h2 class="section-title sousTitre" jhiTranslate="termsOfUseGeneral.service.description.title"></h2>
      <p jhiTranslate="termsOfUseGeneral.service.description.content"></p>

      <!-- 6.3.1 Généralités -->
      <h3 class="section-title sousTitre" jhiTranslate="termsOfUseGeneral.service.description.general.title"></h3>
      <p jhiTranslate="termsOfUseGeneral.service.description.general.content"></p>

      <!-- 6.3.2 Envoi d’ordonnance -->
      <h3 class="section-title sousTitre" jhiTranslate="termsOfUseGeneral.service.description.prescription.title"></h3>
      <p jhiTranslate="termsOfUseGeneral.service.description.prescription.content"></p>

      <!-- 6.3.3 Récupération des Médicaments -->
      <h3 class="section-title sousTitre" jhiTranslate="termsOfUseGeneral.service.description.medicationRecovery.title"></h3>
      <p jhiTranslate="termsOfUseGeneral.service.description.medicationRecovery.content"></p>

      <!-- 6.3.4 Commande de produits de parapharmacie et livraison de parapharmacie -->
      <h3 class="section-title sousTitre" jhiTranslate="termsOfUseGeneral.service.description.parapharmacyOrder.title"></h3>
      <p jhiTranslate="termsOfUseGeneral.service.description.parapharmacyOrder.content"></p>

      <!-- 6.3.5 Paiement en ligne -->
      <h3 class="section-title sousTitre" jhiTranslate="termsOfUseGeneral.service.description.onlinePayment.title"></h3>
      <p jhiTranslate="termsOfUseGeneral.service.description.onlinePayment.content"></p>

      <!-- 6.3.6 Autres Services -->
      <h3 class="section-title sousTitre" jhiTranslate="termsOfUseGeneral.service.description.otherServices.title"></h3>
      <p jhiTranslate="termsOfUseGeneral.service.description.otherServices.content"></p>

      <!-- 6.4 Relations avec le Pharmacien -->
      <h2 class="section-title sousTitre" jhiTranslate="termsOfUseGeneral.service.pharmacistRelations.title"></h2>
      <p jhiTranslate="termsOfUseGeneral.service.pharmacistRelations.content"></p>
    </div>

    <!-- Article 7: Durée – Fin du contrat -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="termsOfUseGeneral.duration.title"></h2>
      <p jhiTranslate="termsOfUseGeneral.duration.content"></p>
    </div>

    <!-- Article 8: Obligations de l’Utilisateur -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="termsOfUseGeneral.userObligations.title"></h2>
      <p jhiTranslate="termsOfUseGeneral.userObligations.content"></p>
    </div>

    <!-- Article 9: Garantie de l’Utilisateur -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="termsOfUseGeneral.userGuarantee.title"></h2>
      <p jhiTranslate="termsOfUseGeneral.userGuarantee.content"></p>
    </div>

    <!-- Article 10: Comportements prohibés -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="termsOfUseGeneral.prohibitedBehaviors.title"></h2>
      <p jhiTranslate="termsOfUseGeneral.prohibitedBehaviors.content"></p>
    </div>

    <!-- Article 11: Sanctions des manquements Pharmago -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="termsOfUseGeneral.sanctions.title"></h2>
      <p jhiTranslate="termsOfUseGeneral.sanctions.content"></p>
    </div>

    <!-- Article 12: Responsabilité – Force majeure -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="termsOfUseGeneral.responsibility.title"></h2>
      <p jhiTranslate="termsOfUseGeneral.responsibility.content"></p>
    </div>

    <!-- Article 13: Preuve -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="termsOfUseGeneral.evidence.title"></h2>
      <p jhiTranslate="termsOfUseGeneral.evidence.content"></p>
    </div>

    <!-- Article 14: Secret médical -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="termsOfUseGeneral.medicalSecrecy.title"></h2>
      <p jhiTranslate="termsOfUseGeneral.medicalSecrecy.content"></p>
    </div>

    <!-- Article 15: Informatique et Libertés -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="termsOfUseGeneral.dataProtection.title"></h2>
      <p jhiTranslate="termsOfUseGeneral.dataProtection.content"></p>
    </div>

    <!-- Article 16: Propriété intellectuelle -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="termsOfUseGeneral.intellectualProperty.title"></h2>
      <p jhiTranslate="termsOfUseGeneral.intellectualProperty.content"></p>
    </div>

    <!-- Article 17: Cession du contrat – Sous-traitance -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="termsOfUseGeneral.contractAssignment.title"></h2>
      <p jhiTranslate="termsOfUseGeneral.contractAssignment.content"></p>
    </div>

    <!-- Article 18: Réclamations – Loi applicable et tribunaux compétents -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="termsOfUseGeneral.claims.title"></h2>
      <p jhiTranslate="termsOfUseGeneral.claims.content"></p>
    </div>

    <!-- Article 19: Stipulations diverses -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="termsOfUseGeneral.miscellaneous.title"></h2>
      <p jhiTranslate="termsOfUseGeneral.miscellaneous.content"></p>
    </div>
  </div>
</div>
