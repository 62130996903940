import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { isPresent } from '../../../core/util/operators';
import { ApplicationConfigService } from '../../../core/config/application-config.service';
import { createRequestOption } from '../../../core/request/request-util';
import { IPharmacy, NewPharmacy } from '../pharmacy.model';

export type PartialUpdatePharmacy = Partial<IPharmacy> & Pick<IPharmacy, 'id'>;

export type EntityResponseType = HttpResponse<IPharmacy>;
export type EntityArrayResponseType = HttpResponse<IPharmacy[]>;

@Injectable({ providedIn: 'root' })
export class PharmacyService {
  protected resourceUrl = this.applicationConfigService.getEndpointFor('api/pharmacies');

  constructor(
    protected http: HttpClient,
    protected applicationConfigService: ApplicationConfigService,
  ) {}
  create(pharmacy: NewPharmacy, uploadedFileId?: any | null, frontPath?: any | null): Observable<HttpResponse<IPharmacy>> {
    const formData: FormData = new FormData();
    formData.append('pharmacy', JSON.stringify(pharmacy));
    frontPath ? formData.append('frontPath ', uploadedFileId) : null;
    return this.http.post<IPharmacy>(this.resourceUrl, formData, { observe: 'response' });
  }

  update(pharmacy: IPharmacy): Observable<EntityResponseType> {
    return this.http.put<IPharmacy>(`${this.resourceUrl}/${this.getPharmacyIdentifier(pharmacy)}`, pharmacy, { observe: 'response' });
  }

  partialUpdate(pharmacy: PartialUpdatePharmacy): Observable<EntityResponseType> {
    return this.http.patch<IPharmacy>(`${this.resourceUrl}/${this.getPharmacyIdentifier(pharmacy)}`, pharmacy, { observe: 'response' });
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<IPharmacy>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IPharmacy[]>(this.resourceUrl, { params: options, observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  getPharmacyIdentifier(pharmacy: Pick<IPharmacy, 'id'>): number {
    return pharmacy.id;
  }

  comparePharmacy(o1: Pick<IPharmacy, 'id'> | null, o2: Pick<IPharmacy, 'id'> | null): boolean {
    return o1 && o2 ? this.getPharmacyIdentifier(o1) === this.getPharmacyIdentifier(o2) : o1 === o2;
  }

  addPharmacyToCollectionIfMissing<Type extends Pick<IPharmacy, 'id'>>(
    pharmacyCollection: Type[],
    ...pharmaciesToCheck: (Type | null | undefined)[]
  ): Type[] {
    const pharmacies: Type[] = pharmaciesToCheck.filter(isPresent);
    if (pharmacies.length > 0) {
      const pharmacyCollectionIdentifiers = pharmacyCollection.map(pharmacyItem => this.getPharmacyIdentifier(pharmacyItem)!);
      const pharmaciesToAdd = pharmacies.filter(pharmacyItem => {
        const pharmacyIdentifier = this.getPharmacyIdentifier(pharmacyItem);
        if (pharmacyCollectionIdentifiers.includes(pharmacyIdentifier)) {
          return false;
        }
        pharmacyCollectionIdentifiers.push(pharmacyIdentifier);
        return true;
      });
      return [...pharmaciesToAdd, ...pharmacyCollection];
    }
    return pharmacyCollection;
  }
  uploadFile(file: File): Observable<{ id: string }> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<{ id: string }>(this.applicationConfigService.getEndpointFor('api/cloudflare/upload'), formData);
  }
}
