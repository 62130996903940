import { Component, OnInit } from '@angular/core';
import { RegisterService } from '../../../account/register/register.service';
import { AccountService } from '../../../core/auth/account.service';
import { CommonModule } from '@angular/common';
import { LoginService } from '../../../login/login.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'jhi-pharmacy-strip-conneced',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './pharmacy-strip-conneced.component.html',
  styleUrl: './pharmacy-strip-conneced.component.scss',
})
export class PharmacyStripConnecedComponent implements OnInit {
  user_id: any;
  accountClient: any = {};
  isLoading = false;

  constructor(
    private registerService: RegisterService,
    private accountService: AccountService,
    private loginService: LoginService,
    private toastr: ToastrService,
  ) {}
  ngOnInit(): void {
    this.loadProfile();
  }
  loadProfile(): void {
    this.accountService.getAuthenticatedUser().subscribe(res => {
      this.accountClient = res;
    });
  }
  stripeAccount(): void {
    this.isLoading = true; // Start the loader
    this.registerService.createStripeAccount(this.accountClient.id).subscribe({
      next: res => {
        this.accountService.getAuthenticatedUser().subscribe({
          next: updatedProfile => {
            this.accountClient = updatedProfile;
            if (this.accountClient.applicationUser?.onBoardingUrl) {
              window.location.href = this.accountClient.applicationUser.onBoardingUrl;
              this.loginService.logout();
            }
            this.isLoading = false;
          },
          error: err => {
            this.isLoading = false;
            this.toastr.error('Échec de la mise à jour du profil.', 'Erreur');
            this.loginService.logout();
          },
        });
      },
      error: err => {
        this.isLoading = false;
        this.toastr.error('Échec de la création du compte Stripe.', 'Erreur');
        this.loginService.logout();
      },
    });
  }
}
