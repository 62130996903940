<div class="politique-de-confidentialite">
  <div class="conditions-generales">
    <h1 class="title" jhiTranslate="privacyPolicyDelivery.title"></h1>
  </div>
  <div class="content">
    <div class="section">
      <p class="section-text" jhiTranslate="privacyPolicyDelivery.introduction.content"></p>
    </div>

    <!-- Section 1: Données Collectées -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="privacyPolicyDelivery.dataCollected.title"></h2>

      <!-- Pharmaciens -->
      <h2 class="section-title sousTitre" jhiTranslate="privacyPolicyDelivery.dataCollected.pharmacistData.title"></h2>
      <p jhiTranslate="privacyPolicyDelivery.dataCollected.pharmacistData.content"></p>
      <ul>
        <li *ngFor="let item of privacyPolicyDelivery?.dataCollected.pharmacistData.items">
          {{ item }}
        </li>
      </ul>

      <!-- Livreurs -->
      <h2 class="section-title sousTitre" jhiTranslate="privacyPolicyDelivery.dataCollected.deliveryPersonnelData.title"></h2>
      <p jhiTranslate="privacyPolicyDelivery.dataCollected.deliveryPersonnelData.content"></p>
      <ul>
        <li *ngFor="let item of privacyPolicyDelivery?.dataCollected.deliveryPersonnelData.items">{{ item }}</li>
      </ul>

      <!-- Clients -->
      <h2 class="section-title sousTitre" jhiTranslate="privacyPolicyDelivery.dataCollected.customerData.title"></h2>
      <p jhiTranslate="privacyPolicyDelivery.dataCollected.customerData.content"></p>
      <ul>
        <li *ngFor="let item of privacyPolicyDelivery?.dataCollected.customerData.items">{{ item }}</li>
      </ul>
    </div>

    <!-- Section 2: Finalité du Traitement des Données -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="privacyPolicyDelivery.dataProcessingPurpose.title"></h2>

      <!-- Pharmaciens -->
      <h2 class="section-title sousTitre" jhiTranslate="privacyPolicyDelivery.dataProcessingPurpose.pharmacists.title"></h2>
      <p jhiTranslate="privacyPolicyDelivery.dataProcessingPurpose.pharmacists.content"></p>
      <ul>
        <li *ngFor="let item of privacyPolicyDelivery?.dataProcessingPurpose.pharmacists.items">{{ item }}</li>
      </ul>

      <!-- Livreurs -->
      <h2 class="section-title sousTitre" jhiTranslate="privacyPolicyDelivery.dataProcessingPurpose.deliveryPersonnel.title"></h2>
      <p jhiTranslate="privacyPolicyDelivery.dataProcessingPurpose.deliveryPersonnel.content"></p>
      <ul>
        <li *ngFor="let item of privacyPolicyDelivery?.dataProcessingPurpose.deliveryPersonnel.items">{{ item }}</li>
      </ul>

      <!-- Clients -->
      <h2 class="section-title sousTitre" jhiTranslate="privacyPolicyDelivery.dataProcessingPurpose.customers.title"></h2>
      <p jhiTranslate="privacyPolicyDelivery.dataProcessingPurpose.customers.content"></p>
      <ul>
        <li *ngFor="let item of privacyPolicyDelivery?.dataProcessingPurpose.customers.items">{{ item }}</li>
      </ul>
    </div>

    <!-- Section 3: Base Légale du Traitement -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="privacyPolicyDelivery.legalBasis.title"></h2>
      <p class="section-text" jhiTranslate="privacyPolicyDelivery.legalBasis.content"></p>
      <ul>
        <li *ngFor="let item of privacyPolicyDelivery?.legalBasis.items">{{ item }}</li>
      </ul>
    </div>

    <!-- Section 4: Partage des Données -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="privacyPolicyDelivery.dataSharing.title"></h2>

      <!-- Pharmaciens et Livreurs -->
      <h2 class="section-title sousTitre" jhiTranslate="privacyPolicyDelivery.dataSharing.pharmacistsAndDelivery.title"></h2>
      <p jhiTranslate="privacyPolicyDelivery.dataSharing.pharmacistsAndDelivery.content"></p>

      <!-- Partenaires Tiers -->
      <h2 class="section-title sousTitre" jhiTranslate="privacyPolicyDelivery.dataSharing.thirdParties.title"></h2>
      <p jhiTranslate="privacyPolicyDelivery.dataSharing.thirdParties.content"></p>
    </div>

    <!-- Section 5: Durée de Conservation des Données -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="privacyPolicyDelivery.dataRetention.title"></h2>
      <p class="section-text" jhiTranslate="privacyPolicyDelivery.dataRetention.content"></p>
      <ul>
        <li *ngFor="let item of privacyPolicyDelivery?.dataRetention.items">{{ item }}</li>
      </ul>
    </div>

    <!-- Section 6: Droits des Utilisateurs -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="privacyPolicyDelivery.userRights.title"></h2>
      <p class="section-text" jhiTranslate="privacyPolicyDelivery.userRights.content"></p>
      <ul>
        <li *ngFor="let item of privacyPolicyDelivery?.userRights.items">{{ item }}</li>
      </ul>
      <p class="section-text" jhiTranslate="privacyPolicyDelivery.userRights.contact"></p>
    </div>

    <!-- Section 7: Sécurité des Données -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="privacyPolicyDelivery.dataSecurity.title"></h2>
      <p class="section-text" jhiTranslate="privacyPolicyDelivery.dataSecurity.content"></p>
      <ul>
        <li *ngFor="let item of privacyPolicyDelivery?.dataSecurity.items">{{ item }}</li>
      </ul>
    </div>

    <!-- Section 8: Modifications de la Politique de Confidentialité -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="privacyPolicyDelivery.policyChanges.title"></h2>
      <p class="section-text" jhiTranslate="privacyPolicyDelivery.policyChanges.content"></p>
    </div>

    <!-- Section 9: Contact -->
    <div class="section">
      <h2 class="section-title" jhiTranslate="privacyPolicyDelivery.contact.title"></h2>
      <p class="section-text" jhiTranslate="privacyPolicyDelivery.contact.content"></p>
    </div>
  </div>
</div>

<div class="footer" style="overflow: hidden">
  <div class="row d-flex align-items-center p-3">
    <div class="footer-bottom">
      <span>
        © 2024 <span class="pharma">PharmaGo</span>.
        <span jhiTranslate="global.menu.footer.rights"></span>
        <span class="underline">KCA Dev Services.</span>
      </span>
    </div>
  </div>
</div>
