<div class="row mt-5 mb-4">
  <div class="row">
    <div class="col-10">
      <h2 class="lead" style="color: #141414">Liste des paiements</h2>
    </div>
    <div class="col-2 btn-wrap" (click)="connectToStripe()">
      <span class="badge bg-green-stripe"> Connecté à Stripe </span>
    </div>
  </div>
</div>
<div [ngClass]="paymentsList?.length > 5 ? 'scorllable-container row ' : 'row'">
  <div class="col-sm-12">
    <div class="table-container">
      <table class="table table-responsive">
        <thead class="thead-dark">
          <tr>
            <th scope="col">Réf Commande</th>
            <th scope="col">Montant</th>
            <th scope="col">Date et heure</th>
            <th scope="col">Réf paiement</th>
            <th scope="col">Statut</th>
            <th scope="col">Client</th>
            <th scope="col">Action</th>

            <!--
            <th scope="col">Détails</th>
            -->
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let payment of paymentsList">
            <tr *ngIf="!contentLoaded">
              <td colspan="5">
                <ngx-skeleton-loader
                  count="1"
                  appearance="line"
                  [theme]="{ height: '24px', width: '100%', 'margin-bottom': '10px' }"
                ></ngx-skeleton-loader>
              </td>
            </tr>
            <tr *ngIf="contentLoaded">
              <td>{{ payment?.id }}</td>
              <td>
                <span class="bolder">{{ payment?.amount / 100 }}€</span>
              </td>
              <td>{{ payment?.purchase?.orderDate | date: "dd/MM/yyyy HH'h'mm" }}</td>
              <td>{{ payment?.stripePaymentIntentId }}</td>

              <td>
                <div class="btn-wrap">
                  <span
                    class="badge"
                    [ngClass]="{ 'bg-red': payment?.paymentStatus === 'échec', 'bg-green': payment?.paymentStatus === 'succeeded' }"
                  >
                    {{ payment?.paymentStatus }}
                  </span>
                </div>
              </td>
              <td>{{ payment?.purchase?.user?.internalUser?.login }}</td>
              <td><button class="bg-greenRefund" (click)="paymentsRefund(payment?.id)">Remboursement</button></td>

              <!--
              <td>
                <div class="btn-wrap">
                  <button class="btn-command btn-view"></button>
                </div>
              </td>
              -->
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <div class="card flex justify-content-center" *ngIf="paymentsList?.length">
      <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalPages" />
    </div>
    <ng-container *ngIf="!paymentsList?.length">
      <lottie-player
        src="../../content/images/noData.json"
        background="transparent"
        speed="1"
        style="width: 300px; height: 250px; margin: auto"
        loop
        autoplay
      ></lottie-player>
    </ng-container>
  </div>
</div>
