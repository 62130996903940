import { Component, OnInit, OnDestroy, ElementRef, ViewChild, NgZone, AfterViewChecked } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SocketService } from '../socket.service';
import { OrdersService } from '../orders.service';
import { AccountService } from '../../core/auth/account.service';
import { Account } from '../../core/auth/account.model';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CartService } from '../../cart/cart.service';
import { ImageModule } from 'primeng/image';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HomeServiceService } from '../../home/service/home-service.service';
import { ToastrService } from 'ngx-toastr';

interface StatusMapping {
  userStatus: string;
  actions: string[];
}
interface MessagesResponse {
  content: Message[];
  totalPages: number;
  totalElements: number;
}
interface TransformedMessage {
  sender: string;
  avatar?: string;
  text: string;
  createdAt: string;
  isCurrentUser: boolean;
}
interface User {
  id: string;
  name: string;
  avatar?: string;
  genericAvatar?: string;
}
interface Message {
  id: string;
  user: User;
  text: string;
  sender: string;
  createdAt: string;
  avatar: string;
}
@Component({
  selector: 'jhi-order-details',
  standalone: true,
  imports: [NgbModule, CommonModule, FormsModule, ImageModule, ReactiveFormsModule],
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('modalOrdonnance') modalOrdonnance!: any;
  @ViewChild('messageContainer') messageContainer!: ElementRef;

  messageSubscription!: Subscription;

  account!: Account;
  purchase_id: any;
  purchaseDetails: any;
  room: string = '';
  messages: any[] = [];
  message = '';
  numCommande: any;
  fileUrlsFromOvh: string[] = [];
  isFilesExistFromOvh = false;
  prescriptionFilesFromOvh: any[] = [];
  vitalCardFilesFromOvh: string[] = [];
  mutualCardFilesFromOvh: string[] = [];
  prescriptionFiles: File[] = [];
  vitalCardFiles: File[] = [];
  mutualCardFiles: File[] = [];
  pharmacyName: any;
  PurchasesForm: FormGroup;
  userId!: string;
  page = 0;
  size = 10;
  hasMoreMessages = true;
  consentGiven = false;
  maxFileSize = 5 * 1024 * 1024; // 5 MB
  allowedFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
  maxFiles = 3;
  fileUrls: string[] = [];
  ordonnanceImages: Array<string | ArrayBuffer> = [];
  statusMappings: Record<string, StatusMapping> = {
    DRAFT: { userStatus: 'PANIER', actions: ['CONFIRMER', 'ANNULER', 'VOIR DÉTAILS'] },
    CREATED_WITH_PRESCRIPTION: { userStatus: 'NOUVELLE', actions: ['ANNULER', 'VOIR DÉTAILS'] },
    IN_PROGRESS: { userStatus: 'EN COURS', actions: ['ANNULER', 'VOIR DÉTAILS'] },
    PAYMENT_INSTANCE: { userStatus: 'EN COURS', actions: ['PAYER', 'ANNULER'] },
    PACKAGING: { userStatus: 'PRETE', actions: ['VOIR DÉTAILS'] },
    READY: { userStatus: 'PRETE', actions: ['VOIR DÉTAILS'] },
    DELIVERING: { userStatus: 'EN LIVRAISON', actions: ['VOIR DÉTAILS'] },
    DELIVERED: { userStatus: 'RÉALISÉ', actions: ['VOIR DÉTAILS'] },
    RECOVERING: { userStatus: 'EN LIVRAISON', actions: ['VOIR DÉTAILS'] },
    RECOVERED: { userStatus: 'RÉALISÉ', actions: ['VOIR DÉTAILS'] },
    RETRACTED: { userStatus: 'ARCHIVÉ', actions: ['VOIR DÉTAILS'] },
    CANCELED: { userStatus: 'ARCHIVÉ', actions: ['VOIR DÉTAILS'] },
    REJECTED: { userStatus: 'ARCHIVÉ', actions: ['VOIR DÉTAILS'] },
    LOST: { userStatus: 'ARCHIVÉ', actions: [] },
    ARCHIVED: { userStatus: '---', actions: [] },
  };

  constructor(
    private socketService: SocketService,
    private route: ActivatedRoute,
    private router: Router,
    private orderService: OrdersService,
    private accountService: AccountService,
    private pharmacieService: HomeServiceService,
    public cartService: CartService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private ngZone: NgZone,
  ) {
    this.PurchasesForm = this.createPurchasesForm();
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.getPurchaseId();
    this.loadProfile();
    this.pharmacyName = this.pharmacieService.getCommercialName();

    this.route.paramMap.subscribe(params => {
      this.room = params.get('purchase_id')!;
      this.socketService.setQuery(`room=${this.room}`);
      this.initializeChat();
    });

    this.messageSubscription = this.socketService.socketResponse$.subscribe(response => {
      if (response) {
        this.addMessage(response, true);
      }
    });

    this.numCommande = this.cartService.getNumCommande();
    this.getAllFilesUrlFromOvh();
  }
  ngAfterViewChecked(): void {
    this.scrollToBottom();
  }
  getAllFilesUrlFromOvh(): void {
    this.orderService.getFileUrl(this.purchase_id).subscribe(res => {
      const prescription = Array.isArray(res?.prescription) ? res.prescription : [];
      const vitalCard = Array.isArray(res?.vital_card) ? res.vital_card : [];
      const mutualCard = Array.isArray(res?.mutual_card) ? res.mutual_card : [];

      this.prescriptionFilesFromOvh = prescription;
      this.vitalCardFilesFromOvh = vitalCard;
      this.mutualCardFilesFromOvh = mutualCard;
      const files = [...prescription, ...vitalCard, ...mutualCard];
      if (files.length > 0) {
        this.isFilesExistFromOvh = true;
        this.fileUrlsFromOvh = [];
        files.forEach((fileKey, index) => {
          this.orderService
            .getImage('test-documents-cmd-ordonnance', fileKey)
            .then(data => {
              const blob = new Blob([data.Body], { type: data.ContentType });
              const reader = new FileReader();
              reader.onload = () => {
                const base64 = reader.result as string;
                this.fileUrlsFromOvh.push(base64);
              };
              reader.readAsDataURL(blob);
            })
            .catch(err => {
              console.error("Erreur lors du chargement de l'image :", err);
            });
        });
      }
    });
  }
  downloadImage(fileUrl: string, fileName: string): void {
    const anchor = document.createElement('a');
    anchor.href = fileUrl;
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }

  loadProfile(): void {
    this.accountService.getAuthenticatedUser().subscribe(res => {
      this.account = res;
      this.userId = res.id;
      this.loadMessages();
    });
  }

  getPurchaseId(): void {
    this.route.paramMap.subscribe(params => {
      this.purchase_id = +params.get('purchase_id')!;
      this.getPurchaseDetails(this.purchase_id);
    });
  }

  getPurchaseDetails(purchase_id: any): void {
    this.orderService.getPurchaseById(purchase_id).subscribe({
      next: (res: any) => {
        this.purchaseDetails = res;
      },
      error(err) {
        console.error(err);
      },
    });
  }

  getUserStatus(status: string): string {
    return this.statusMappings[status].userStatus || 'UNKNOWN';
  }

  getActions(status: string): string[] {
    return this.statusMappings[status].actions;
  }

  initializeChat(): void {
    this.socketService.joinRoom(this.room);
  }

  loadMessages(): void {
    if (!this.hasMoreMessages) {
      return;
    }

    this.socketService.getMessages(this.purchase_id, this.page, this.size).subscribe((response: MessagesResponse) => {
      if (response.content.length > 0) {
        this.messages = [...response.content.map((msg: Message) => this.transformMessage(msg)).reverse(), ...this.messages];
        this.page++;
      } else {
        this.hasMoreMessages = false;
      }
    });
  }
  formattedCreatedDate(date: any): string | null {
    if (!date) {
      return null;
    }
    const epochTime = date > 9999999999 ? date : date * 1000;
    const formattedDate = new Date(epochTime);
    const day = String(formattedDate.getDate()).padStart(2, '0');
    const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
    const hours = String(formattedDate.getHours()).padStart(2, '0');
    const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
    return `${day}/${month} ${hours}:${minutes}`;
  }
  transformMessage(message: Message): TransformedMessage {
    const isCurrentUser = message.user.id === this.userId;
    return {
      sender: isCurrentUser ? 'Vous' : message.user.name,
      avatar: isCurrentUser ? message.user.genericAvatar : message.user.avatar,
      text: message.text,
      createdAt: message.createdAt,
      isCurrentUser,
    };
  }

  addMessage(data: any, isReceived: boolean): void {
    const transformed = this.transformMessage(data);
    this.messages.push({
      ...transformed,
      received: isReceived,
    });
    this.scrollToBottom();
  }
  scrollToBottom(): void {
    try {
      const container = this.messageContainer.nativeElement;
      container.scrollTop = container.scrollHeight;
    } catch (err) {
      console.error('Error scrolling to bottom:', err);
    }
  }
  sendMessage(): void {
    if (this.message.trim()) {
      const messageData = {
        text: this.message,
        room: this.room,
      };

      this.socketService.saveMessage(messageData).subscribe(response => {
        const data = {
          id: response.id,
          text: response.text,
          user: response.user,
          createdAt: response.createdAt,
          room: response.room,
          image: response.image,
          video: response.video,
          audio: response.audio,
          system: response.system,
          sent: response.sent,
          received: response.received,
          pending: response.pending,
          quickReplies: response.quickReplies,
        };

        this.socketService.sendMessage(data, this.room);
        this.addMessage(response, false);
        this.message = '';
      });
    }
  }

  navigateToPurchaseLite(): void {
    this.router.navigate(['/orders']);
  }
  onCheckboxChange(event: any): void {
    this.consentGiven = event.target.checked; // Mettez à jour l'état de la case à cocher
  }
  checkAndAddPurchases(): void {
    this.addPrescription();
  }

  onFileSelectedPrescription(event: any): void {
    const files: FileList = event.target.files;

    // Check if number of files exceeds the limit
    if (this.prescriptionFiles.length + files.length > this.maxFiles) {
      this.toastr.info(`Vous pouvez télécharger un maximum de ${this.maxFiles} fichiers.`, 'Limite de fichiers dépassée');
      return;
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Validate file type
      if (!this.allowedFileTypes.includes(file.type)) {
        this.toastr.info('Seuls les fichiers images (JPG, PNG) ou PDF sont autorisés.`, Type de fichier invalide');
        continue;
      }

      // Validate file size
      if (file.size > this.maxFileSize) {
        this.toastr.info(`La taille du fichier ne doit pas dépasser ${this.maxFileSize / (1024 * 1024)} Mo.`, 'Taille de fichier dépassée');
        continue;
      }

      // Add file to list if valid
      this.prescriptionFiles.push(file);

      // Use FileReader to generate preview URL
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.fileUrls.push(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }
  removeFilePrescription(fileUrl: string, index: number): void {
    this.prescriptionFiles.splice(index, 1);
    this.fileUrls.splice(index, 1);
    this.fileUrlsFromOvh.splice(index, 1);
  }
  openCommandModal(modal: any): void {
    this.modalService.open(modal, { size: 'l', centered: true });
  }
  resetForm(): void {
    this.fileUrls = [];
    this.PurchasesForm.reset();
  }
  createPurchasesForm(data?: any): FormGroup {
    return this.formBuilder.group({
      firstName: [data ? data.firstName : ''],
      lastName: [data ? data.lastName : ''],
      prescription: [data ? data.prescription : null],
      mutual_card: [data ? data.mutual_card : null],
      vital_card: [data ? data.vital_card : null],
    });
  }

  onFileSelected(event: Event, fileType: string): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      const file = inputElement.files[0];
      this.PurchasesForm.get(fileType)?.setValue(file);
      const files = Array.from(inputElement.files);

      if (fileType === 'prescription') {
        this.prescriptionFiles = [...this.prescriptionFiles, ...files];
        files.forEach(uploadedFile => {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            this.ngZone.run(() => {
              this.ordonnanceImages.push(e.target.result);
            });
          };
          reader.readAsDataURL(uploadedFile);
        });
      } else {
        const spanElement = inputElement.nextElementSibling;
        if (spanElement instanceof HTMLElement) {
          spanElement.textContent = file.name;
        }
      }
    }
  }
  getExistingFileUrl(fileType: string): string | null {
    switch (fileType) {
      case 'vital_card':
        return this.vitalCardFilesFromOvh.length > 0 ? this.vitalCardFilesFromOvh[0] : null;
      case 'mutual_card':
        return this.mutualCardFilesFromOvh.length > 0 ? this.mutualCardFilesFromOvh[0] : null;
      default:
        return null;
    }
  }
  addPrescription(): void {
    this.PurchasesForm.markAllAsTouched();
    this.PurchasesForm.markAsDirty();
    const formData = new FormData();

    // Ajouter les fichiers d'ordonnance (nouvelles ou existantes)
    this.prescriptionFiles.forEach((file, index) => {
      formData.append('prescription', file);
    });

    // Ajouter les autres fichiers (Carte Vitale, Carte Mutuelle)
    ['vital_card', 'mutual_card'].forEach(fileType => {
      const file = this.PurchasesForm.get(fileType)?.value;
      if (file && file instanceof File) {
        formData.append(fileType, file);
      } else if (this.isFilesExistFromOvh) {
        const existingFileUrl = this.getExistingFileUrl(fileType);
        if (existingFileUrl) {
          formData.append(fileType, existingFileUrl);
        }
      }
    });

    this.uploadFiles(formData);
  }
  uploadFiles(formData: FormData): void {
    const purchaseId = this.cartService.getPurchaseId();
    this.pharmacieService.uploadFiles(purchaseId, formData).subscribe({
      next: (res: any) => {
        this.toastr.success('Fichiers téléchargés avec succès!');
        this.getAllFilesUrlFromOvh();
        this.resetForm();
        this.cartService.addPrescriptionToCart();
      },
      error: (err: any) => {
        this.toastr.error('Erreur lors du téléchargement des fichiers');
      },
    });
  }
  ngOnDestroy(): void {
    this.messageSubscription.unsubscribe();
    this.socketService.disconnect();
  }
}
