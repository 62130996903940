import { Component, OnInit } from '@angular/core';
import { Router, Navigation } from '@angular/router';
import { RegisterService } from 'app/account/register/register.service';
import { AccountService } from 'app/core/auth/account.service';

@Component({
  selector: 'jhi-pharmacy-strip-conneced',
  standalone: true,
  imports: [],
  templateUrl: './pharmacy-strip-conneced.component.html',
  styleUrl: './pharmacy-strip-conneced.component.scss',
})
export class PharmacyStripConnecedComponent implements OnInit {
  user_id: any;
  accountClient: any = {};

  constructor(
    private registerService: RegisterService,
    private accountService: AccountService,
  ) {}

  ngOnInit() {
    this.loadProfile();
  }
  loadProfile(): void {
    this.accountService.getAuthenticatedUser().subscribe(res => {
      this.accountClient = res;
    });
  }
  stripeAccount(): void {
    this.registerService.createStripeAccount(this.accountClient.id).subscribe({
      next: res => {
        console.log('Stripe account created:', res);
        this.accountService.getAuthenticatedUser().subscribe({
          next: updatedProfile => {
            this.accountClient = updatedProfile;
            if (this.accountClient.applicationUser?.onBoardingUrl) {
              window.open(this.accountClient.applicationUser.onBoardingUrl, '_blank');
            } else {
              console.error('onBoardingUrl is still null after creating Stripe account');
            }
          },
          error: err => {
            console.error('Failed to reload profile after creating Stripe account:', err);
          },
        });
      },
      error: err => {
        console.error('Failed to create Stripe account:', err);
      },
    });
  }
}
